import { vars } from '@etg/wings';
import { css, mediaQueries } from '@eti/styles';
export const bodyClassName = css`
  height: 100%;
  overflow: hidden;
  width: 100%;
`;
export const backdropStyles = css`
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 12;

  &::after {
    backdrop-filter: blur(2px);
    background-color: rgb(0 0 0 / 50%);
    content: '';
    display: block;
    height: 100vh;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 200ms ease-in-out;
    width: 100vw;
  }

  &.ReactModal__Overlay--after-open::after {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close::after {
    opacity: 0;
  }

  @media ${mediaQueries.medium.up} {
    align-items: center;
  }
`;
export const modalStyles = type => {
  return css`
    background: ${vars.colors.pageBackdrop};
    border-radius: 3px;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: column;
    ${type === 'drawer' ? 'height: 100%;' : 'max-height: 100%;'};
    outline: none;
    position: relative;
    transform: translateY(100%);
    transition: transform 500ms cubic-bezier(0.75, 0, 0.175, 1);
    width: 100%;
    z-index: 1;

    &.ReactModal__Content--after-open {
      transform: translateY(0);
    }

    &.ReactModal__Content--before-close {
      transform: translateY(100%);
    }
  `;
};