import createValidator from 'revalidate/lib/createValidator';
import memoize from 'lodash/memoize';
import { INVALID_DATE_OF_BIRTH } from '../constants/defaultErrorMessages';
const getDateFormatRegex = memoize(format => {
  const [first, second, third] = format.split('-').map(x => x.length);
  return new RegExp(`^\\d{${first}}-\\d{${second}}-\\d{${third}}$`);
});
const isValidDate = function (format) {
  if (format === void 0) {
    format = 'yyyy-MM-dd';
  }
  return createValidator(message => date => {
    const dateRegex = getDateFormatRegex(format);
    return date && !dateRegex.test(date) ? message || INVALID_DATE_OF_BIRTH : null;
  }, INVALID_DATE_OF_BIRTH);
};
export default isValidDate;