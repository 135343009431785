import { device } from '@eti/utils';
const flightData = {
  schema: 'iglu:com.etgrs2/flight/jsonschema/1-0-0',
  data: {
    airSystems: null,
    bookingClasses: null,
    destinationAirportIatas: null,
    domestic: null,
    inboundDates: null,
    inboundNumSegments: null,
    inboundTripDuration: null,
    isMultiStopTrip: null,
    isViTrip: null,
    journeyType: null,
    marketingCarrierCodes: null,
    metaPrice: null,
    offeredBrandedFare: null,
    operatingCarrierCodes: null,
    originAirportIatas: null,
    outboundDates: null,
    outboundNumSegments: null,
    outboundTripDuration: null,
    price: null,
    priceSeenPaymentMethod: null,
    selectedBrandedFare: null,
    selectedFromEntry: null,
    totalAmount: null,
    tripType: null,
    validatingCarrierCodes: null
  }
};
const passengerData = {
  schema: 'iglu:com.etgrs2/passenger/jsonschema/1-0-0',
  data: {
    numAdults: null,
    numChildren: null,
    numInfants: null,
    numTeenChildren: null,
    numYoungChildren: null
  }
};
const productData = {
  schema: 'iglu:com.etgrs2/product/jsonschema/1-0-0',
  data: {
    selectedProducts: null,
    offeredProducts: null
  }
};
const referrerData = {
  schema: 'iglu:com.etgrs2/referrer/jsonschema/1-0-0',
  data: {
    entryType: null,
    partner: null
  }
};
const siteData = {
  schema: 'iglu:com.etgrs2/site/jsonschema/1-0-0',
  data: {
    scenarios: null,
    siteCountry: null,
    siteCurrency: null,
    siteLanguage: null,
    siteName: null
  }
};
const userData = {
  schema: 'iglu:com.etgrs2/user/jsonschema/1-0-0',
  data: {
    browser: device.browserType(),
    deviceType: device.isOfType()
  }
};
export default [flightData, passengerData, productData, referrerData, siteData, userData];