const executeAbTestCallbacksForRoute = function (callbacks, route) {
  if (callbacks === void 0) {
    callbacks = [];
  }
  callbacks.forEach(callback => {
    try {
      callback();
    } catch (error) {
      console.log('Callback function defined in AB test caused an error.', error, route); // eslint-disable-line no-console
    }
  });
};
export const executeAbTestCallbacks = route => {
  const {
    abTestCallbacks
  } = window;
  if (abTestCallbacks !== null && abTestCallbacks !== void 0 && abTestCallbacks[route]) {
    executeAbTestCallbacksForRoute(abTestCallbacks[route], route);
  }
};