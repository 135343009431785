export const ALPHA_NUMERIC_VALIDATION_MESSAGE = 'Only numbers and letters allowed';
export const REQUIRED_DOCUMENT_VALIDATION_MESSAGE = 'Document number is required';
export const REQUIRED_CPF_VALIDATION_MESSAGE = 'CPF is required';
export const INVALID_CPF_VALIDATION_MESSAGE = 'Invalid CPF';
export const INVALID_DOCUMENT_VALIDATION_MESSAGE = 'Invalid document number';
export const INVALID_PASSPORT_VALIDATION_MESSAGE = 'Invalid passport number';
export const REQUIRED_PASSPORT_VALIDATION_MESSAGE = 'Passport number is required';
export const RUSSIAN_INTERNATIONAL_PASSPORT_NUMBER_INPUT = 'traveler-russian-international-passport-number';
export const RUSSIAN_PASSPORT_NUMBER_INPUT = 'traveler-russian-passport-number';
export const BIRTH_CERTIFICATE_NUMBER_INPUT = 'traveler-birth-certificate-number';
export const MILITARY_ID_NUMBER_INPUT = 'traveler-military-id-number';
export const INTERNATIONAL_PASSPORT_NUMBER_INPUT = 'traveler-international-passport-number';
export const FOREIGN_PASSPORT_NUMBER_INPUT = 'traveler-foreign-passport-number';
export const FOREIGN_DOCUMENT_NUMBER_INPUT = 'traveler-foreign-document-number';
export const CPF = 'cpf';
export const PASSPORT = 'passport';
export const DOCUMENT = 'document';