import { gql } from '@eti/schema-types';
export const AUTHENTICATE_USER = gql(/* GraphQL */`
  mutation authenticationPackageAuthenticateUser(
    $authenticationType: AuthenticationType!
    $email: String
    $orderNumber: String
    $token: String
    $loginSource: LoginSource
    $allowSendingChallengeEmail: Boolean
  ) {
    authenticateUser(
      authenticationType: $authenticationType
      email: $email
      orderNumber: $orderNumber
      token: $token
      loginSource: $loginSource
      allowSendingChallengeEmail: $allowSendingChallengeEmail
    ) {
      authenticated
      orderDetailsUrlForActualSite
      urlForActualSite
      brandCodeForActualSite
      brandNameForActualSite
      redirectionRoute
      user {
        email
        loginType
        loginOrderNumber
        sessionOrderNumber
      }
    }
  }
`);
export const LOGOUT_USER = gql(/* GraphQL */`
  mutation authenticationPackageLogout {
    logoutUser {
      response
    }
  }
`);
export const SEND_CHALLENGE_EMAIL_WITH_CODE = gql(/* GraphQL */`
  mutation sendChallengeEmailWithCode($crypticCode: String!) {
    sendChallengeEmail(crypticCode: $crypticCode) {
      success
    }
  }
`);