import { getPrice, getSelected } from '../single-choice/utils/selection';
import { CONNECTION_GUARANTEE } from '../products';
import ConnectionGuarantee from './components/ConnectionGuarantee';
import getIcon from './utils/icon';
const formSection = CONNECTION_GUARANTEE.name;
export const connectionGuarantee = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  ...CONNECTION_GUARANTEE
};
export default ConnectionGuarantee;