import { css, mediaQueries } from '@eti/styles';
import { standardGrid as illustrationCss } from '../../common/main-layout/illustration/styles';
import { standardGrid as headerCss } from '../../common/main-layout/header/styles';
export const smallCardGrid = css`
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 3fr 7fr;
  grid-template-rows: repeat(4, auto);
  padding: 16px;

  > *:not(:last-child) {
    margin-bottom: 8px;
  }

  .illustration {
    grid-area: 1 / 1 / 4 / 2;
    margin-bottom: 0;
  }

  .header {
    grid-area: 1 / 2 / 2 / 3;
    padding: 0;
  }

  .body {
    grid-area: 3 / 2 / 4 / 3;
    padding: 0;
  }

  .readMore {
    grid-area: 3 / 2 / 4 / 3;
    padding: 0;
  }

  .cta {
    grid-area: 4 / 2 / 5 / 3;
    padding: 0;

    .selection {
      grid-area: 1 / 2 / 2 / 3;
      margin-bottom: 8px;

      @media ${mediaQueries.small.only} {
        grid-area: 2 / 1 / 3 / 2;
        margin-bottom: 0;
      }
    }
  }

  .children {
    display: contents;
  }

  @media ${mediaQueries.small.only} {
    grid-column-gap: 0;
    grid-template-columns: 0 1fr;
  }
`;
export const customIllustrationStyle = css`
  padding-inline: 8px;

  html[dir='rtl'] & {
    transform: scaleX(-1);
  }
  ${illustrationCss}
`;
export const customHeaderStyle = css`
  ${headerCss}
  .title {
    margin-bottom: 8px;

    @media ${mediaQueries.small.only} {
      align-items: start;
    }
  }

  .pictogram {
    @media ${mediaQueries.small.only} {
      display: none;
    }
  }

  .pictogramQpass {
    display: none;

    @media ${mediaQueries.small.only} {
      display: grid;
      grid-area: 2 / 1 / 4 / 2;
    }
  }
`;