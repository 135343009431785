import { useEffect, useState } from 'react';
import { useProperty, useTranslation } from '@eti/providers';
import { css, resetButtonStyle } from '@eti/styles';
import { Inline, Stack } from '@etg/wings';

const linksWrapperStyles = css`
  display: flex;
  flex-direction: row;
  height: 44px;
  justify-content: flex-start;
  margin-top: 35px;
  padding-inline-start: 9px;
`;

const linkClass = (image: string | null) => css`
  background-image: url(${image});
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  ${resetButtonStyle};
  width: auto;

  &:first-child {
    margin-inline-end: 32px;
  }
`;

const badgeStyle = css`
  height: 100%;
  width: auto;
`;

const fetchStoreBadge = async (badgeType: string) => {
  try {
    const badge = await import(`@eti/assets/logos/store-badges/${badgeType}-badge.svg`);
    return badge.default;
  } catch {
    return null;
  }
};

const openStoreUrl = (url: string) => {
  window.open(url, '_blank');
};

const ReceiptMobileAppBannerButtons = () => {
  const { p } = useProperty();
  const { t } = useTranslation();
  const appStoreLink: string = p('Order.DownloadApp.AppStoreLink');
  const playStoreLink: string = p('Order.DownloadApp.PlayStoreLink');

  const [badges, setBadges] = useState({
    googlePlayBadge: null,
    appStoreBadge: null,
  });

  useEffect(() => {
    const loadImages = async () => {
      const googlePlayImage = await fetchStoreBadge('googleplay');
      const appstoreImage = await fetchStoreBadge('appstore');

      setBadges({
        googlePlayBadge: googlePlayImage,
        appStoreBadge: appstoreImage,
      });
    };

    loadImages();
  }, []);

  return (
    <Stack data-testid="orderPage-mobileApp-banner-buttons">
      <Inline>{t('Order.DownloadApp.Text')}</Inline>
      <div className={linksWrapperStyles}>
        <button
          className={linkClass(badges.appStoreBadge)}
          data-testid="orderPage-mobileApp-banner-appStore-button"
          onClick={() => openStoreUrl(appStoreLink)}
          type="button"
        >
          {badges.appStoreBadge && (
            <img alt="app-store-badge" className={badgeStyle} src={badges.appStoreBadge} />
          )}
        </button>
        <button
          className={linkClass(badges.googlePlayBadge)}
          data-testid="orderPage-mobileApp-banner-googlePlay-button"
          onClick={() => openStoreUrl(playStoreLink)}
          type="button"
        >
          {badges.googlePlayBadge && (
            <img alt="google-play-badge" className={badgeStyle} src={badges.googlePlayBadge} />
          )}
        </button>
      </div>
    </Stack>
  );
};

export default ReceiptMobileAppBannerButtons;
