export const handleWrongOrderNumberInputs = (input: string) => {
  const only10Numbers = new RegExp(`^\\d{10}$`);
  let val = input.replace(/\s/g, '');
  const indices: number[] = [];
  for (let i = 0; i < val.length; i += 1) {
    if (val[i] === '-') {
      indices.push(i);
    }
  }
  if (indices.length > 2 || [4, 8].some((index) => !indices.includes(index))) {
    val = val.replace(/-/g, '');
  }
  if (only10Numbers.test(val)) {
    return [val.slice(0, 4), '-', val.slice(4, 7), '-', val.slice(7)].join('');
  }
  return input;
};
