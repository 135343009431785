// For more information check https://www.w3.org/International/questions/qa-bidi-unicode-controls
const LRM = '\u{200e}';
const LRE = '\u{202a}';
const PDF = '\u{202c}';
const NBSP = '\u{00A0}';
export default {
  LRM,
  LRE,
  NBSP,
  PDF
};