import { useEffect } from 'react';

// CAUTION: To properly use this hook the function that will be passed to "onLoad" should
// be declared using "useCallback". If this is not done then in every render of the
// component that calls "useScript" the "onLoad" function will be redeclared. This means
// that the dependency array of the "useEffect" is pretty much useless and the "onLoad"
// function will be called in every render of the component.
export const useScript = (id, src, onLoad) => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!document.getElementById(id)) {
      const script = document.createElement('script');

      script.id = id;
      script.src = src;
      script.async = true;
      script.defer = true;

      if (onLoad) {
        script.addEventListener('load', onLoad);
      }

      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    }

    if (onLoad) {
      onLoad();
    }
  }, [id, src, onLoad]);
};
