import { date as dateUtils } from '@eti/utils';
export const getInputMaskConfig = function (constraint, dateFormat) {
  if (dateFormat === void 0) {
    dateFormat = 'yyyy-mm-dd';
  }
  const format = dateFormat.toLowerCase();
  const pipe = dateUtils.createAutoCorrectedDatePipe(format, constraint);
  const mask = format.split('').map(char => char !== '-' ? /\d/ : char);
  return {
    mask,
    pipe
  };
};