import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { useValidation } from '@eti/providers';
import { hasError } from '../utils/location';
import LocationDropdown from './LocationDropdown';

const LocationField = ({
  name,
  onBlur,
  onSwitchDirection,
  validationRuleName,
  ...locationFieldProps
}) => {
  const validate = useValidation(validationRuleName);
  return (
    <Field
      component={(fieldProps) => (
        <LocationDropdown
          onSwitchDirection={onSwitchDirection}
          {...fieldProps}
          isError={hasError(fieldProps.meta)}
          {...fieldProps.input}
          value={fieldProps.input.value === '' ? {} : fieldProps.input.value}
        />
      )}
      name={name}
      onBlur={onBlur}
      props={{ ...locationFieldProps, name }}
      validate={validate}
    />
  );
};

LocationField.propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onSwitchDirection: PropTypes.func,
  validationRuleName: PropTypes.string,
};

export default LocationField;
