const scrollToElementBottom = (elementBottomPosition, marginBottom = 20) => {
  const scrollTopPosition =
    elementBottomPosition - window.innerHeight + window.scrollY + marginBottom;
  const isScrollBehaviorSupported = 'scrollBehavior' in document.documentElement.style;

  if (isScrollBehaviorSupported) {
    window.scrollTo({
      top: scrollTopPosition,
      behavior: 'smooth',
    });
  } else {
    window.scrollTo(0, scrollTopPosition);
  }
};

export default scrollToElementBottom;
