import { css } from '@eti/styles';
export const rowIconWrapperStyles = css`
  padding: 8px;
  position: relative;
  text-align: center;
  width: 36px;

  @media (min-width: 48em) {
    padding: 12px;
    width: 44px;
  }
`;
export const dataCellStyles = css`
  font-size: 0.625rem;
  min-height: 0.625rem;

  html[dir='rtl'] & {
    text-align: right;
  }

  @media (min-width: 48em) {
    font-size: 0.75rem;
  }

  @media (min-width: 60.625em) {
    font-size: 0.875rem;
  }

  & span {
    color: #666;

    &::after {
      content: ' ';
    }
  }
`;