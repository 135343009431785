import { createValidator } from 'revalidate';
import { constants, traveler as TravelerUtils } from '@eti/utils';
import { SAME_NAME_AS_ANOTHER_TRAVELER } from '../constants/defaultErrorMessages';
const {
  TRAVELER_LAST_NAME,
  TRAVELER_FIRST_NAME,
  TRAVELER_FORM_SECTION_NAME
} = constants.travelerInputs;
const {
  getTravelerFormSectionName
} = TravelerUtils;
const getFormattedName = name => name && name.trim().toLowerCase();
const isUniqueTraveler = (travelerId, hideWarningMessage) => createValidator(message => (value, allValues) => {
  if (!value || !allValues) {
    return null;
  }
  const currentTravelerSectionName = getTravelerFormSectionName(travelerId);
  const currentTravelerSection = allValues[currentTravelerSectionName];
  const currentTravelerFirstName = getFormattedName(currentTravelerSection[`${TRAVELER_FIRST_NAME}-${travelerId}`]);
  const currentTravelerLastName = getFormattedName(currentTravelerSection[`${TRAVELER_LAST_NAME}-${travelerId}`]);
  const duplicatedTravelersList = Object.keys(allValues).filter(section => {
    const traveler = allValues[section];
    if (section.includes(TRAVELER_FORM_SECTION_NAME) && section !== currentTravelerSectionName) {
      const id = section.replace(`${TRAVELER_FORM_SECTION_NAME}-`, '');
      const firstName = getFormattedName(traveler[`${TRAVELER_FIRST_NAME}-${id}`]);
      const lastName = getFormattedName(traveler[`${TRAVELER_LAST_NAME}-${id}`]);
      if (firstName && lastName && firstName === currentTravelerFirstName && lastName === currentTravelerLastName) {
        return true;
      }
    }
    return false;
  });
  if (!allValues || duplicatedTravelersList.length > 0) {
    return hideWarningMessage ? ' ' : message || SAME_NAME_AS_ANOTHER_TRAVELER;
  }
  return null;
}, SAME_NAME_AS_ANOTHER_TRAVELER);
export default isUniqueTraveler;