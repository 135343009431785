export const getTripSegments = bound => bound.segments.filter(segment => segment.__typename === 'TripSegment');
const getTripSegmentsFromBounds = bounds => {
  return bounds.flatMap(bound => getTripSegments(bound));
};
export const isCarrierCodesPresentInAllSegments = function (carrierCodes, bounds) {
  if (carrierCodes === void 0) {
    carrierCodes = [];
  }
  const lowerCaseCarrierCodes = carrierCodes.map(carrier => carrier.toLowerCase());
  const tripSegments = getTripSegmentsFromBounds(bounds) || [];
  return tripSegments.every(segment => {
    var _segment$ticketingCar, _segment$ticketingCar2, _segment$marketingCar, _segment$marketingCar2;
    const ticketingCarrierCode = (_segment$ticketingCar = segment === null || segment === void 0 || (_segment$ticketingCar2 = segment.ticketingCarrier) === null || _segment$ticketingCar2 === void 0 || (_segment$ticketingCar2 = _segment$ticketingCar2.code) === null || _segment$ticketingCar2 === void 0 ? void 0 : _segment$ticketingCar2.toLowerCase()) !== null && _segment$ticketingCar !== void 0 ? _segment$ticketingCar : '';
    const marketingCarrierCode = (_segment$marketingCar = (_segment$marketingCar2 = segment.marketingCarrier) === null || _segment$marketingCar2 === void 0 || (_segment$marketingCar2 = _segment$marketingCar2.code) === null || _segment$marketingCar2 === void 0 ? void 0 : _segment$marketingCar2.toLowerCase()) !== null && _segment$marketingCar !== void 0 ? _segment$marketingCar : '';
    return lowerCaseCarrierCodes.includes(ticketingCarrierCode) || lowerCaseCarrierCodes.includes(marketingCarrierCode);
  });
};