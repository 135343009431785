import { Inline, vars } from '@etg/wings';
import { css, cx, resetButtonStyle } from '@eti/styles';
import { routes } from '../../../constants/routesAndApisConstants';

const listStyles = css`
  background-color: #fff;
  border: 1px solid ${vars.colors.divider};
  border-radius: 3px;
  flex-direction: column;
  left: 50%;
  list-style: none;
  margin-top: 2px;
  position: absolute;
  transform: translateX(-50%);
  width: max-content;
  z-index: 1;
`;

const listItemStyles = css`
  border-bottom: 1px solid ${vars.colors.divider};
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
`;

const linkStyles = css`
  color: ${vars.colors.text};
  cursor: pointer;
  font-size: 0.875rem;
  padding: 16px;
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: rgb(0 0 0 / 10%);
    transition: 0.3s;
  }
`;

const inlineStyles = css`
  ${resetButtonStyle};
`;

const aStyles = css`
  display: inline-block;
`;

interface MenuItem {
  id: string;
  name: string;
  path: string;
  target: string;
}

interface DropdownMenuProps {
  menu: MenuItem[];
}
const DropdownMenu = ({ menu }: DropdownMenuProps) => {
  const renderMenuItem = ({ id, name, path, target }: MenuItem) => {
    if (Object.values(routes).some((r) => path.endsWith(r))) {
      return (
        <li className={listItemStyles} key={id}>
          <Inline
            as="a"
            className={cx(inlineStyles, linkStyles)}
            data-testid={`menu-link-${name}`}
            href={path}
            target={target}
          >
            {name}
          </Inline>
        </li>
      );
    }

    return (
      <li className={listItemStyles} key={id}>
        <a
          className={cx(linkStyles, aStyles)}
          data-testid={`menu-link-${name}`}
          href={path}
          rel="noreferrer"
          target={target}
        >
          {name}
        </a>
      </li>
    );
  };

  return (
    <ul className={listStyles} data-testid="main-navigation-menu">
      {menu.map(({ id, name, path, target }) => {
        return renderMenuItem({ id, name, path, target });
      })}
    </ul>
  );
};

export default DropdownMenu;
