import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import formatDate from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import toDate from 'date-fns/toDate';
import getYear from 'date-fns/getYear';
import addYears from 'date-fns/addYears';
export { default as createAutoCorrectedDatePipe } from './createAutoCorrectedDatePipe';
export const getLazyLocale = async options => {
  let locale;

  // TODO: check related jira https://jira.etraveli.net/browse/WEB-3016
  const getLocale = languageCode => {
    switch (languageCode) {
      case 'gr':
        return 'el';
      case 'no':
        return 'nb';
      case 'zh':
        return 'zh-CN';
      case 'ar':
        return 'ar-SA';
      default:
        return languageCode;
    }
  };
  if (options.locale && options.locale !== 'en') {
    try {
      const tempLocale = getLocale(options.locale);
      locale = (await import(`date-fns/locale/${tempLocale}/index.js`)).default;
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
    }
  }
  return locale;
};
export const createFormatter = async function (options) {
  if (options === void 0) {
    options = {};
  }
  const locale = await getLazyLocale(options);
  const formatter = {
    format: (date, string, opts) => formatDate(date, string, {
      ...opts,
      locale
    })
  };
  global.etiDateFormatter = formatter;
  return formatter;
};
const isValidDate = date => !Number.isNaN(date.getTime());
export const convertToDate = dateValue => typeof dateValue === 'string' ? parseISO(dateValue) : toDate(dateValue);
export const format = function (dateValue) {
  const date = convertToDate(dateValue);
  if (!isValidDate(date)) {
    return 'Invalid Date';
  }
  for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    rest[_key - 1] = arguments[_key];
  }
  return global.etiDateFormatter ? global.etiDateFormatter.format(date, ...rest) : formatDate(date, ...rest);
};
export const durationInDays = (fromDate, untilDate) => differenceInCalendarDays(untilDate, fromDate);
export const getExpiryDateConstraints = function (baseDate, passportExpiryMaxFutureYears) {
  if (passportExpiryMaxFutureYears === void 0) {
    passportExpiryMaxFutureYears = 10;
  }
  return {
    minYear: getYear(baseDate),
    maxYear: getYear(addYears(baseDate, passportExpiryMaxFutureYears)),
    minMonth: 0
  };
};
export const getBirthDateConstraints = baseDate => ({
  minYear: 1900,
  maxYear: getYear(baseDate),
  minMonth: 1
});