import { CANCELLATION_INSURANCE_COVER_GENIUS } from '../products';
import { getPrice, getSelected, getGraphQlSelection } from '../single-choice/utils/selection';
import CancellationInsuranceCoverGenius from './components/CancellationInsuranceCoverGenius';
const {
  name: formSection
} = CANCELLATION_INSURANCE_COVER_GENIUS;
export const cancellationInsuranceCoverGenius = {
  formSection,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  ...CANCELLATION_INSURANCE_COVER_GENIUS
};
export default CancellationInsuranceCoverGenius;