import { useRef } from 'react';
import { breakpoints, Input, Stack, vars } from '@etg/wings';
import { css } from '@eti/styles';
import { connect, MapStateToProps } from 'react-redux';
import { FormSection, formValueSelector } from 'redux-form';
import { useTranslation } from '@eti/providers';
import { SEARCH } from '../../../../constants/formNamesConstants';
import { PASSENGERS_FORM_SECTION } from '../../constants/formFields';
import { Passengers as PassengersType, PassengerConstraints } from '../../models';
import { usePassengers } from '../../utils/usePassengers';
import PassengersToggle from './PassengersToggle';
import AdultPassengers from './AdultPassengers';
import ChildPassengers from './ChildPassengers';
import InfantPassengers from './InfantPassengers';
import ChildrenAges from './ChildrenAges';

const wrapperStyles = css`
  flex: 1;
  min-width: 0;
`;

const passengersWrapperStyles = css`
  margin-inline: -16px;

  @media (min-width: ${breakpoints._768}) {
    margin: 0;
  }
`;

const agesStyles = css`
  background-color: #f0f0f0;
  border-top: 1px solid ${vars.colors.divider};
  padding: 8px 16px;
`;

interface PassengersProps {
  constraints?: PassengerConstraints;
}

interface ConnectedProps extends PassengersProps {
  passengers: PassengersType;
}

const mapStateToProps: MapStateToProps<ConnectedProps, PassengersProps, any> = (state) => ({
  passengers: formValueSelector(SEARCH)(state, PASSENGERS_FORM_SECTION) || {},
});

const Passengers = ({ constraints, passengers }: ConnectedProps) => {
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { label } = usePassengers(passengers);

  const labelId = 'search-passengers-label';

  const onLabelClick = () => {
    buttonRef.current?.focus();
  };

  return (
    <FormSection name={PASSENGERS_FORM_SECTION}>
      <Stack className={wrapperStyles} spacing={4}>
        <Input.Label id={labelId} onClick={onLabelClick}>
          {t('Searchform.Passengers.Label')}
        </Input.Label>
        <PassengersToggle
          isDisabled={constraints?.isDisabled}
          label={label}
          labelledBy={labelId}
          ref={buttonRef}
          title={t('Searchform.Passengers.Label')}
        >
          <div className={passengersWrapperStyles}>
            <AdultPassengers constraints={constraints} selectedPassengers={passengers} />
            <ChildPassengers constraints={constraints} selectedPassengers={passengers} />
            <InfantPassengers selectedPassengers={passengers} />
            {passengers.children > 0 && (
              <>
                <div className={agesStyles}>
                  {t('Responsive.Air.SearchFormPage.PassengersPane.ChildAgeInfo')}
                </div>
                {Array.from({ length: passengers.children }).map((_, index) => (
                  <ChildrenAges
                    index={index}
                    key={`child-${index}`}
                    selectedPassengers={passengers}
                  />
                ))}
              </>
            )}
          </div>
        </PassengersToggle>
      </Stack>
    </FormSection>
  );
};

export default connect(mapStateToProps)(Passengers);
