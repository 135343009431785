import { getPrice, getSelected, getGraphQlSelection } from '../single-choice/utils/selection';
import { PLATINUM_SERVICE } from '../products';
import Platinum from './components/Platinum';
import getIcon from './utils/icon';
const formSection = PLATINUM_SERVICE.name;
export const platinum = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  ...PLATINUM_SERVICE
};
export default Platinum;