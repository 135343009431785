import { createValidator } from 'revalidate';
import { inRange } from 'lodash/number';
import { INVALID_MONTH } from '../constants/defaultErrorMessages';
const isMonthInRangeAndNotExpired = month => {
  const currentMonth = new Date().getMonth() + 1;
  return inRange(parseInt(month, 10), 1, 13) && currentMonth <= month;
};
const isMonthOk = month => !month ? false : isMonthInRangeAndNotExpired(month);
const isValidExpiryMonth = createValidator(message => month => {
  if (month && !isMonthOk(month) || month === 0) {
    return message || INVALID_MONTH;
  }
  return null;
}, INVALID_MONTH);
export default isValidExpiryMonth;