import { endpoints } from '../../../constants/routesAndApisConstants';

const fetchRpcAction = async (payload) => {
  try {
    const response = await fetch(endpoints.MOBILE_TRAVEL_PLAN, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(payload),
    });
    if (!response.ok) {
      throw Error(response.statusMessage);
    }
    return await response.json();
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchRpcAction;
