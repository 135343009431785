// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { breakpoints, Button, Dialog, Inline } from '@etg/wings';
import { DangerousHtmlContent, LoadingDots } from '@eti/components';
import { css } from '@eti/styles';
import DebugButton from './DebugButton';

const loadingDotsContainerStyles = css`
  position: absolute;
`;

const buttonTitleStyles = (isLoading) => css`
  ${isLoading && 'opacity: 0.2;'}
`;

const accessibilityReviewInfoText =
  'Check your browser console to see output of issues regarding accessibility. You should remove all output types but "info" to keep it readable. \n aXe will run every time you load/reload a page and whenever you hit this button again. \n Please see <a href="https://www.npmjs.com/package/@axe-core/react" target="_blank">https://www.npmjs.com/package/@axe-core/react</a> for more information. \n This message will self-destruct and only be shown once per browser session.';
const accessibilityReviewNotActiveText =
  '<p style="margin-bottom: 4px">You need to activate <code>@axe-core/react</code> by restarting the dev-server with:</p> <p style="font-family: monospace; color: #fff; background: #111; padding: 8px;">REVIEW_ACCESSIBILITY=true npm run start -- client</p>';

const review = async () => {
  const axe = (await import('@axe-core/react')).default;
  axe(React, ReactDOM, 1000);
};

const DebugAccessibilityReview = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAccessibilityReviewActive, setIsAccessibilityReviewActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    if (!isLoading) {
      setIsLoading(true);
      if (process.env.REVIEW_ACCESSIBILITY === 'true') {
        setIsAccessibilityReviewActive(true);
        if (!window.sessionStorage.getItem('hasSeenAccessibilityReviewInfo')) {
          setIsDialogOpen(true);
          window.sessionStorage.setItem('hasSeenAccessibilityReviewInfo', 'true');
        }
        await review();
      } else {
        setIsDialogOpen(true);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const dismissDialog = () => setIsDialogOpen(false);

  return (
    <>
      <DebugButton onClick={handleClick}>
        <span className={buttonTitleStyles(isLoading)}>Accessibility review</span>
        {isLoading && <LoadingDots containerStyle={loadingDotsContainerStyles} />}
      </DebugButton>
      <Dialog
        footer={
          <Inline align="end" collapseBelow={breakpoints._560} spacing={8}>
            <Button form="accessibilityReview" onClick={dismissDialog} variant="primary">
              Alright!
            </Button>
          </Inline>
        }
        isOpen={isDialogOpen}
        onDismiss={dismissDialog}
        title={`Accessibility review ${!isAccessibilityReviewActive ? '- not activated' : ''}`}
      >
        <DangerousHtmlContent
          content={
            isAccessibilityReviewActive
              ? accessibilityReviewInfoText
              : accessibilityReviewNotActiveText
          }
        />
      </Dialog>
    </>
  );
};

export default DebugAccessibilityReview;
