import { createLoadableComponent } from '../../infrastructure/code-splitting/utils/LoadableComponentFactory';

export const PaymentPage = createLoadableComponent(
  () =>
    import(
      /* webpackPrefetch: true */
      './containers/PaymentPageGraphqlContainer'
    ),
);

export const PaymentLinkPage = createLoadableComponent(
  () => import('./containers/PaymentLinkPageGraphqlContainer'),
);
