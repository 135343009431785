import { getSelected, getGraphQlSelection } from './utils/utils';
import ProductBundle from './containers/ProductBundleContainer';
import { FORM_SECTION } from './utils/constants';
const name = FORM_SECTION;
const id = 'ancillary-bundle';
export const ancillaryBundle = {
  formSection: name,
  getGraphQlSelection,
  getSelected,
  id,
  name
};
export default ProductBundle;