/* eslint-disable no-console,global-require,import/no-extraneous-dependencies */
import '@eti/validation';
import '@eti/travelers';
import '@eti/debug';
import '@eti/utils';
import './common/debug/components/DebugSiteProperties';
import './widgets/search-form/containers/SearchFormContainer';

import ReactDOM from 'react-dom';
import App from './main/components/App';
import suppressConsoleWarnings from './utils/suppressConsoleWarnings';

(async () => {
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
})();

if (process.env.NODE_ENV === 'development') {
  suppressConsoleWarnings();
}

ReactDOM.render(<App />, document.getElementById('root'));
