export const CABIN_CLASS = 'cabinClass';
export const CARRIER_CODES = 'carrierCodes';
export const DEPARTURE_DATE = 'departureDate';
export const DEPARTURE_TIME_OF_DAY = 'departureTimeOfDay';
export const DESTINATION = 'destination';
export const DIRECT_FLIGHT = 'directFlight';
export const MULTI_BOUND = 'multiBound';
export const ONE_DAY_STOPOVER = 'oneDayStopover';
export const ONE_NIGHT_STOPOVER = 'oneNightStopover';
export const ORIGIN = 'origin';
export const PASSENGERS = 'passengers';
export const RETURN_DATE = 'returnDate';
export const RETURN_TIME_OF_DAY = 'returnTimeOfDay';
export const SHORT_STOPOVER = 'shortStopover';
export const SINGLE_BOUND = 'singleBound';
export const STOPOVER_ENABLED = 'stopoverEnabled';
export const STOPOVER_TYPES = 'stopoverTypes';
export const TRIP_TYPE = 'tripType';
export const TWO_NIGHTS_STOPOVER = 'twoNightsStopover';
export default {
  CABIN_CLASS,
  CARRIER_CODES,
  DEPARTURE_DATE,
  DEPARTURE_TIME_OF_DAY,
  DESTINATION,
  DIRECT_FLIGHT,
  MULTI_BOUND,
  ONE_DAY_STOPOVER,
  ONE_NIGHT_STOPOVER,
  ORIGIN,
  PASSENGERS,
  RETURN_DATE,
  RETURN_TIME_OF_DAY,
  SHORT_STOPOVER,
  SINGLE_BOUND,
  STOPOVER_ENABLED,
  TRIP_TYPE,
  TWO_NIGHTS_STOPOVER,
};
