import { vars } from '@etg/wings';
import { css, etiColors } from '@eti/styles';
export const sliderStyles = css`
  height: 37px;
  margin: 13px 15px 0;
  position: relative;
`;
export const railStyle = css`
  background-color: ${etiColors.grayScale[6]};
  border-radius: 5px;
  cursor: pointer;
  height: 4px;
  width: 100%;
`;
export const handleStyle = (percent, disabled) => css`
  background-color: ${disabled ? etiColors.grayScale[5] : etiColors.white};
  border: 1px solid ${etiColors.grayScale[7]};
  border-radius: 50%;
  color: #333;
  cursor: pointer;
  height: 28px;
  left: ${percent}%;
  margin-left: -15px;
  margin-top: -17px;
  position: absolute;
  text-align: center;
  width: 28px;
  z-index: 2;
`;
export const trackStyles = (_brand, source, target, disabled) => css`
  background-color: ${disabled ? etiColors.grayScale[9] : vars.colors.inputs.main};
  border-radius: 5px;
  cursor: pointer;
  height: 4px;
  left: ${source.percent}%;
  position: absolute;
  top: 0;
  width: ${target.percent - source.percent}%;
  z-index: 1;
`;
export const valueLabelStyles = position => css`
  color: ${etiColors.grayScale[10]};
  font-size: 0.875rem;
  ${position === 'left' ? 'left: -15px' : 'right: -15px'};
  position: absolute;
  top: 22px;
`;