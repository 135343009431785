import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { date } from '@eti/utils';
import { gql } from '@eti/schema-types';
import Main from '../components/Main';

export const contextContainer = gql(/* GraphQL */ `
  query CurrentUserForLayout {
    siteContext {
      agencyAccount {
        code
        name
      }
      language {
        id
        code
        locale
        name
      }
      brand {
        id
        code
        name
      }
      dateFormat {
        date
        datetime
        dayDdmmmTime
        dayDdmmm
        time
        day
        dayYear
        dayTime
        weekday
        shortWeekday
        shortWeekdayYear
        shortWeekdayFullYear
        weekdayYear
        monthYear
        longMonthYear
        timeWeekdayYear
        shortDayShortMonth
        dayName
      }
      market {
        code
        name
        id
        phonePrefixes {
          id
          prefix
        }
        states {
          value: code
          label: name
        }
      }
      language {
        code
        id
      }
      entryType
      partner
      siteCurrency {
        code
        exponent
      }
      siteName
      productionWeb
    }
    localization {
      availableLanguages {
        code
        id
        locale
        name
      }
    }
    siteInformation {
      footer {
        legal
        paymentProviders {
          id
          url {
            href
          }
        }
        siteFlags {
          id
          url {
            href
          }
        }
        trustedPartners {
          id
          url {
            href
          }
        }
        menus {
          mainMenuItems {
            name
            path
          }
        }
      }
      header {
        mainMenuItems {
          menuType
          path
          name
          subMenu {
            path
            name
            target
          }
        }
      }
    }
  }
`);

const MainContainer = (ownProps) => {
  const [isDateFormatterInitialized, setDateFormatterInitialized] = useState(false);
  const { data, error, loading } = useQuery(contextContainer, {
    onCompleted: async () => {
      // TODO: Investigate and refactor according to this issue https://jira.etraveli.net/browse/WEB-3732
      if (!loading) {
        await date.createFormatter({ locale: data.siteContext.language.code });
        setDateFormatterInitialized(true);
      }
    },
    onError: () => {
      throw new Error(error);
    },
  });

  if (loading && !isDateFormatterInitialized) {
    return null;
  }

  if (data) {
    const props = {
      ...ownProps,
      siteContext: data.siteContext,
      localization: data.localization,
      siteInformation: data.siteInformation,
      loading: data.loading,
    };

    return <Main {...props} />;
  }

  return null;
};

export default MainContainer;
