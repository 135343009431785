import { createValidator } from 'revalidate';
const invalidRyanEmailPattern = /_@./;
const defaultMessage = "Invalid email; '_' before '@' is not allowed for Ryan Air.";
const isValidRyanAirEmail = marketingCarriers => createValidator(message => value => {
  const hasRyanAsMarketingCarrier = marketingCarriers.some(_ref => {
    let {
      code
    } = _ref;
    return code === 'FR';
  });
  if (!hasRyanAsMarketingCarrier) {
    return null;
  }
  return value && invalidRyanEmailPattern.test(value.trim()) ? message || defaultMessage : null;
}, defaultMessage);
export default isValidRyanAirEmail;