import { useEffect } from 'react';
import Cookies from 'js-cookie';
import isEqual from 'lodash/isEqual';
import { SESSION_TRACKING_ENTRY_ID } from '../constants/cookiesConstants';
export const entryExistsInDataLayer = entry => {
  const {
    dataLayer
  } = window;
  return dataLayer && dataLayer.some(dataLayerEntry => isEqual(dataLayerEntry, entry));
};
export const keyExistsInDataLayer = key => {
  const {
    dataLayer
  } = window;
  return dataLayer && dataLayer.some(dataLayerEntry => Object.keys(dataLayerEntry).includes(key));
};
export const pushEntryToDataLayer = function (entry, allowDuplicate) {
  if (allowDuplicate === void 0) {
    allowDuplicate = true;
  }
  const {
    dataLayer
  } = window;
  if (dataLayer) {
    if (allowDuplicate || !entryExistsInDataLayer(entry)) {
      dataLayer.push(entry);
    }
  }
};
export const pushToDataLayer = function () {
  for (var _len = arguments.length, entries = new Array(_len), _key = 0; _key < _len; _key++) {
    entries[_key] = arguments[_key];
  }
  entries.forEach(pushEntryToDataLayer);
};
export const getSiteContextProperties = function (_temp) {
  let {
    market,
    entryType,
    partner,
    siteCurrency: currency,
    siteName
  } = _temp === void 0 ? {} : _temp;
  const siteCountry = market === null || market === void 0 ? void 0 : market.code;
  const siteCurrency = currency === null || currency === void 0 ? void 0 : currency.code;
  return {
    siteName,
    siteCountry,
    entryType,
    partner,
    siteCurrency
  };
};
export const getSiteInitialData = (siteContext, storeSessionTrackingEntryInDataLayer) => {
  const sessionTrackingEntryId = Cookies.get(SESSION_TRACKING_ENTRY_ID);
  const siteInitialData = getSiteContextProperties(siteContext);
  if (sessionTrackingEntryId && storeSessionTrackingEntryInDataLayer) {
    siteInitialData.ibeSteId = sessionTrackingEntryId;
  }
  return siteInitialData;
};
export const useGADataLayerEffect = function () {
  for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    args[_key2] = arguments[_key2];
  }
  useEffect(() => {
    pushToDataLayer(...args);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

/* eslint-disable no-underscore-dangle */
export const logMouseFlowTag = tag => {
  window._mfq = window._mfq || [];
  window._mfq.push(['tag', tag]);
};
export const useLogMouseFlowTagEffect = function (tag, condition) {
  if (condition === void 0) {
    condition = true;
  }
  useEffect(() => {
    if (condition) {
      logMouseFlowTag(tag);
    }
  }, [condition, tag]);
};