// TODO: Validate combination of adults/children/infants (https://jira.etraveli.net/browse/CA-213)
export const CHILD_AGE_DEFAULT_VALUE = 7;
export const MAX_PASSENGERS = 9;
export const MAX_CHILD_AGE = 17;
export const MIN_CHILD_AGE = 2;
export const MAX_INFANT_AGE = 1;
export const MIN_INFANT_AGE = 0;
export const MIN_ADULTS = 1;
export const MIN_CHILDREN = 0;
export const MIN_INFANTS = 0;

export default {
  CHILD_AGE_DEFAULT_VALUE,
  MAX_PASSENGERS,
  MAX_CHILD_AGE,
  MIN_CHILD_AGE,
  MAX_INFANT_AGE,
  MIN_INFANT_AGE,
  MIN_ADULTS,
  MIN_CHILDREN,
  MIN_INFANTS,
};
