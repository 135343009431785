import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { analytics } from '@eti/utils';
import { css, etiColors, mediaQueries } from '@eti/styles';
import { useTranslation } from '@eti/providers';
import { PENDING_PROVIDER_BOOKING } from '../../../constants/orderInfoConstants';
import Loading from '../../../common/loading/components/Loading';
import useSelfServiceRebooking from '../../../common/self-service-rebooking/useSelfServiceRebooking';
import usePendingProviderBooking from '../../../common/pending-provider-booking/usePendingProviderBooking';
import SubPage from './SubPage';
import PendingProviderBookingPage from './PendingProviderBookingPage';

const rebookingStyles = css`
  color: ${etiColors.black};
  padding: 0 30px;
  text-align: center;

  & section:first-child {
    background-size: contain;
    margin-top: 15px;
    padding: 90px 0;
  }

  @media ${mediaQueries.medium.up} {
    padding: 0 22%;

    & section:first-child {
      padding: 160px 0;
    }
  }

  @media ${mediaQueries.large.up} {
    & section:first-child {
      margin-top: 35px;
      padding: 160px 0;
    }

    & p {
      font-size: 1rem;
    }
  }
`;

const getText = (title, description) =>
  `<h2 data-testid="processingOrderPage-title">${title}</h2><div data-testid="processingOrderPage-description">${description}</div>`;

const ProcessingOrderPage = ({ processingOrderMessage }) => {
  const { t } = useTranslation();
  const {
    loading: loadingPendingProviderBooking,
    isPendingProviderBooking,
    pendingProviderBookingData,
  } = usePendingProviderBooking();
  const {
    loading: loadingSelfServiceRebooking,
    isSelfServiceRebooking,
    selfServiceRebookingTravelers,
  } = useSelfServiceRebooking();

  useEffect(() => {
    const hasPendingProviderCookie = Cookies.get(PENDING_PROVIDER_BOOKING) === 'true';

    if (!hasPendingProviderCookie) {
      analytics.pushEntryToDataLayer({ event: 'pendingProvider' });
      Cookies.set(PENDING_PROVIDER_BOOKING, true, { expires: 1, secure: true });
    }
  }, []);

  if (loadingSelfServiceRebooking || loadingPendingProviderBooking) {
    return <Loading />;
  }

  if (isSelfServiceRebooking) {
    const title = t(
      'PendingProvider.Page.Rebooking.Header',
      selfServiceRebookingTravelers.find((traveler) => traveler.ageType === 'ADT').firstName,
    );

    return (
      <SubPage
        className={rebookingStyles}
        image="pending-provider"
        isSvg
        pageTitle={t('PageTitle.rf.payment.processing-order.action')}
        text={getText(title, t('PendingProvider.Page.Rebooking.Content'))}
      />
    );
  }

  if (isPendingProviderBooking) {
    return (
      <PendingProviderBookingPage
        data={pendingProviderBookingData}
        processingOrderMessage={processingOrderMessage}
      />
    );
  }

  return (
    <SubPage
      className={rebookingStyles}
      image="pending-provider"
      isSvg
      pageTitle={t('PageTitle.rf.payment.processing-order.action')}
      text={getText(
        t('PaymentState.Notification.PaymentValidationInProgress.Subject'),
        t('PaymentState.Notification.PaymentValidationInProgress.Body'),
      )}
    />
  );
};

ProcessingOrderPage.propTypes = {
  processingOrderMessage: PropTypes.string,
};

export default ProcessingOrderPage;
