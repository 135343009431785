import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from '@eti/providers';
import { css, cx, keyframes } from '@eti/styles';

const getLogoWrapperHeight = (id: string | string[]) => {
  switch (id) {
    case 'affirm':
      return css`
        height: 20px;

        @media (min-width: 400px) {
          height: 26px;
        }
      `;
    case 'alipaycn':
      return css`
        height: 20px;

        @media (min-width: 400px) {
          height: 24px;
        }
      `;
    case 'alipayhk':
      return css`
        height: 20px;
      `;
    case 'applepay':
      return css`
        height: 18px;

        @media (min-width: 400px) {
          height: 24px;
        }
      `;
    case 'bancontact':
    case 'ideal':
      return css`
        height: 26px;

        @media (min-width: 400px) {
          height: 34px;
        }
      `;
    case 'googlepay':
    case 'onlinebankingpl':
      return css`
        height: 32px;

        @media (min-width: 400px) {
          height: 36px;
        }
      `;
    case 'paypal':
      return css`
        height: 16px;

        @media (min-width: 400px) {
          height: 22px;
        }
      `;
    case 'upi':
      return css`
        height: 24px;
      `;
    case 'sofort':
      return css`
        height: 28px;
      `;
    default:
      return css`
        height: 24px;

        @media (min-width: 400px) {
          height: 32px;
        }
      `;
  }
};

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

const logoWrapperStyles = (id: any) => css`
  animation: ${fadeIn} 0.75s ease;
  display: flex;
  margin-left: auto;
  ${id === 'bank' ? 'fill: #666' : null};

  [dir='rtl'] & {
    margin-left: 0;
    margin-right: auto;
  }
`;

const getLogoStyles = (isCreditCards: boolean) => css`
  height: 100%;
  margin-left: 6px;
  ${isCreditCards ? 'border-radius: 2px' : null};
  width: auto;

  [dir='rtl'] & {
    margin-left: 0;
    margin-right: 6px;
  }
`;

const creditCardQuantityStyles = css`
  align-items: center;
  background-color: #f8f8f8;
  display: flex;
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0 8px;
  white-space: nowrap;
`;

const PaymentMethodLogo = ({ id }: { id: string | string[] }) => {
  const { t } = useTranslation();
  const [logotype, setLogotype] = useState<ReactElement | null>(null);
  const cardQuantityLabel = t('Payment.Method.Card.CardQuantity.Label');

  useEffect(() => {
    let isUnmounted = false;
    const isCreditCardCollection = Array.isArray(id);
    const logoWrapperHeightStyles = getLogoWrapperHeight(id);
    const logoStyles = getLogoStyles(isCreditCardCollection);
    const dataTestId = `paymentMethodLogo-${isCreditCardCollection ? 'card' : id}`;
    const loadLogotype = async () => {
      try {
        const logotypes = await Promise.all(
          (isCreditCardCollection ? id : [id]).map((logoId): Promise<typeof import('*.svg')> => {
            if (isCreditCardCollection) {
              return import(
                /* webpackMode: "lazy-once" */ `@eti/assets/logos/credit-cards/${logoId}.svg`
              );
            }
            return import(
              /* webpackMode: "lazy-once" */ `@eti/assets/logos/payment-methods/${logoId.toLowerCase()}.svg`
            );
          }),
        );

        if (!isUnmounted) {
          setLogotype(
            <div
              className={cx(logoWrapperStyles(id), logoWrapperHeightStyles)}
              data-testid={dataTestId}
              key={1}
            >
              {logotypes.map(({ ReactComponent, default: filename }) => (
                <ReactComponent className={logoStyles} key={filename} />
              ))}
              {isCreditCardCollection && (
                <div className={cx(logoStyles, creditCardQuantityStyles)}>{cardQuantityLabel}</div>
              )}
            </div>,
          );
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(error);
      }
    };

    loadLogotype();
    return () => {
      isUnmounted = true;
    };
  }, [id, cardQuantityLabel]);

  return logotype;
};

export default PaymentMethodLogo;
