import { travelerForm } from '@eti/travelers';
import { string, getTravelerInitials } from '@eti/utils';
const getTravelerFirstName = (form, state, travelerIndex) => travelerForm.getSelectedFirstName(form)(state, travelerIndex);
const getTravelerLastName = (form, state, travelerIndex) => travelerForm.getSelectedLastName(form)(state, travelerIndex);
export const getTravelerNames = (form, state, travelers) => travelers === null || travelers === void 0 ? void 0 : travelers.reduce((names, traveler, index) => {
  const firstName = getTravelerFirstName(form, state, index);
  const lastName = getTravelerLastName(form, state, index);
  return firstName && lastName ? [...names, {
    travelerId: traveler.id,
    fullName: `${firstName} ${lastName}`,
    initials: getTravelerInitials(firstName, lastName),
    firstName,
    lastName
  }] : names;
}, []);
export const getTravelerName = function (travelerId, travelerNames, defaultTravelerLabel, travelerIndex) {
  var _travelerNames;
  if (travelerNames === void 0) {
    travelerNames = [];
  }
  const travelerWithName = (_travelerNames = travelerNames) === null || _travelerNames === void 0 ? void 0 : _travelerNames.find(traveler => (traveler === null || traveler === void 0 ? void 0 : traveler.travelerId) === travelerId);
  return travelerWithName ? travelerWithName.fullName : string.insertArgument(defaultTravelerLabel, travelerIndex);
};