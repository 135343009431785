import PropTypes from 'prop-types';
import { css } from '@eti/styles';

const buttonStyles = css`
  align-items: center;
  background-color: #307be8;
  border: 0;
  border-radius: 3px;
  box-shadow:
    rgb(49 49 93 / 3%) 0 0 0 0.5px,
    rgb(49 49 93 / 10%) 0 2px 5px 0,
    rgb(0 0 0 / 8%) 0 1px 2px 0;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: inherit;
  justify-content: center;
  letter-spacing: 1px;
  padding: 10px 12px;
  text-transform: uppercase;

  &:active {
    background-color: #286fd6;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  html[dir='rtl'] & {
    flex-direction: row-reverse;
  }
`;

const DebugButton = ({ onClick, ...props }) => (
  <button className={buttonStyles} onClick={onClick} {...props} type="button" />
);

DebugButton.propTypes = { onClick: PropTypes.func };

export default DebugButton;
