export const ONE_WAY = 'ONE_WAY';
export const RETURN = 'RETURN';
export const MULTI_STOP = 'MULTI_STOP';
export const OPEN_JAW_SINGLE_ORIGIN = 'OPEN_JAW_SINGLE_ORIGIN';
export const OPEN_JAW_SINGLE_DESTINATION = 'OPEN_JAW_SINGLE_DESTINATION';
export const OPEN_JAW_DOUBLE = 'OPEN_JAW_DOUBLE';

export const tripTypes = {
  RETURN,
  ONE_WAY,
  MULTI_STOP,
  OPEN_JAW_SINGLE_ORIGIN,
  OPEN_JAW_SINGLE_DESTINATION,
  OPEN_JAW_DOUBLE,
} as const;

type KeysOfTripTypes = keyof typeof tripTypes;
type ValuesOfTripTypes = (typeof tripTypes)[KeysOfTripTypes];
export const extraMultiStopTripTypes: Array<ValuesOfTripTypes> = [
  OPEN_JAW_SINGLE_ORIGIN,
  OPEN_JAW_SINGLE_DESTINATION,
  OPEN_JAW_DOUBLE,
];
