import PropTypes from 'prop-types';
import { css, etiColors } from '@eti/styles';

const wrapperStyles = (type) => css`
  overflow: hidden;
  padding-left: ${type === 'CITY' ? '0' : '1rem'};
  padding-right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;

  [dir='rtl'] & {
    padding-left: 0;
    padding-right: ${type === 'CITY' ? '0' : '1rem'};
  }
`;

const countryStyles = css`
  color: ${etiColors.grayScale[9]};

  div[data-dropdownstate='selected'] & {
    color: #fff;
  }
`;

const LocationDropdownOption = ({ searchLocation, allAirportsText, hasMultipleAirports }) => {
  const state = searchLocation.state ? `, ${searchLocation.state}` : ``;
  const allAirports =
    searchLocation.type === 'CITY' && hasMultipleAirports ? ` (${allAirportsText})` : ``;

  return (
    <div
      className={wrapperStyles(searchLocation.type)}
      data-testid={`searchForm-LocationDropdownOption-${searchLocation.iataCode}`}
    >
      <span>{`${searchLocation.name}${allAirports}`}</span>
      <span className={countryStyles}>{`, ${searchLocation.country}${state}`}</span>
    </div>
  );
};

LocationDropdownOption.propTypes = {
  allAirportsText: PropTypes.string,
  hasMultipleAirports: PropTypes.bool,
  searchLocation: PropTypes.shape({
    country: PropTypes.string,
    iataCode: PropTypes.string,
    name: PropTypes.string,
    state: PropTypes.string,
    type: PropTypes.oneOf(['CITY', 'AIRPORT']),
  }),
};

export default LocationDropdownOption;
