import { useSiteContext } from '@eti/providers';
import { constants, date } from '@eti/utils';
import { getLocalizedDuration } from '../../../../../widgets/trip-details/utils/format';

const { unicodeControlCharacters } = constants;
const { LRE, PDF } = unicodeControlCharacters;

export const roundDown = (value, interval) => {
  return value - (value % interval);
};

export const roundUp = (value, interval) => {
  const roundedValue = value - (value % interval);
  return roundedValue === value ? value : roundedValue + interval;
};

export const resetAllFilters = (selectedFilters) =>
  Object.keys(selectedFilters).reduce((acc, key) => ({ ...acc, [key]: null }), {});

export const useDurationFormatter = () => {
  const { language, timeFormat } = useSiteContext();
  const formatDurationValue = (value) =>
    `${LRE}${getLocalizedDuration(value, language.code, timeFormat)}${PDF}`;
  return { formatDurationValue };
};

const endOfDay = 24 * 60 * 60 * 1000;
export const formatDateTimeValue = (dateTime) => (value) => {
  const dt = value === endOfDay ? new Date(value - 1) : new Date(value);
  const validTimeStamp = dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000;
  return date.format(validTimeStamp, dateTime);
};
