export const DIRECTION = 'DIRECTION';
export const systemColors = {
  Amadeus: '#0089c1',
  AmadeusNDC: '#FF69B4',
  Sabre: '#c50030',
  Travelport: '#7fc12d',
  Travelfusion: '#e5be00',
  Pyton: '#952fc1',
  Tripstack: '#cc5933',
  Farelogix: '#0909c1',
  IbeDummy: '#00a468',
  IbeGDSDummy: '#00b0b7'
};
export default DIRECTION;