import PropTypes from 'prop-types';
import {
  Button,
  Columns,
  Column,
  Heading,
  Inline,
  Stack,
  Notice,
  breakpoints,
  vars,
} from '@etg/wings';
import { useDateFormat, useTranslation } from '@eti/providers';
import { css, cx } from '@eti/styles';
import { TripType } from '@eti/types';
import { date as dateUtil, price as priceUtil } from '@eti/utils';
import { ArrowsLeftRight, ArrowRight, CalendarBlank, Clock, User } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import {
  MULTI_STOP,
  ONE_WAY,
  OPEN_JAW_DOUBLE,
  OPEN_JAW_SINGLE_DESTINATION,
  OPEN_JAW_SINGLE_ORIGIN,
} from '../../../constants/tripTypesConstants';

const wrapperStyles = css`
  padding: 16px;
`;

const noticeWrapperStyles = css`
  background-color: ${vars.colors.signal.caution.tint};
  padding: 8px 16px;
`;

const contentStyles = css`
  font-size: 0.875rem;
`;

const actionButtonStyles = css`
  width: 100%;
`;

const noticeIconStyles = css`
  flex: 0 0 auto;
`;

const oneWayIconStyles = css`
  [dir='rtl'] & {
    transform: rotateY(180deg);
  }
`;

const tripDatesStyles = css`
  display: flex;
  gap: 0.25rem;
`;

const priceWrapperStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const multiCityTripTypes = [
  MULTI_STOP,
  OPEN_JAW_SINGLE_ORIGIN,
  OPEN_JAW_SINGLE_DESTINATION,
  OPEN_JAW_DOUBLE,
];
const tripTypesWithOneWayArrow = [ONE_WAY, ...multiCityTripTypes];

const TripReminderContents = ({ onToggleTripContents, purchaseFlowStep, tripData }) => {
  const { t } = useTranslation();
  const { shortWeekday } = useDateFormat();
  const departureDate = dateUtil.format(tripData.date.departure, shortWeekday);
  const returnDate = tripData.date?.return
    ? dateUtil.format(tripData.date.return, shortWeekday)
    : null;

  return (
    <div className={wrapperStyles}>
      <Stack spacing={24}>
        <Stack spacing={16}>
          <Stack spacing={8}>
            <Inline alignY="center" data-testid="tripReminder-originAndDestination" spacing={8}>
              <Heading level={2}>{tripData.origin}</Heading>
              {tripTypesWithOneWayArrow.includes(tripData.type) ? (
                <ArrowRight
                  className={oneWayIconStyles}
                  data-testid="tripReminder-icon-oneWay"
                  size={24}
                  weight="light"
                />
              ) : (
                <ArrowsLeftRight data-testid="tripReminder-icon-return" size={24} weight="light" />
              )}
              {multiCityTripTypes.includes(tripData.type) && (
                <>
                  <Heading data-testid="tripReminder-multipleStops" level={2}>
                    …
                  </Heading>
                  <ArrowRight className={oneWayIconStyles} size={24} weight="light" />
                </>
              )}
              <Heading level={2}>{tripData.destination}</Heading>
            </Inline>
            <Inline
              className={contentStyles}
              spacing={{ [breakpoints._0]: 8, [breakpoints._360]: 16 }}
            >
              <Inline spacing={8}>
                <User size={20} weight="light" />
                <span data-testid="tripReminder-travelerSummary">{tripData.travelers}</span>
              </Inline>
              <Inline spacing={8}>
                <CalendarBlank size={20} weight="light" />
                <span className={tripDatesStyles} data-testid="tripReminder-tripDates">
                  {multiCityTripTypes.includes(tripData.type) ? (
                    t('TripReminder.MultipleDates.Label')
                  ) : (
                    <>
                      {departureDate}
                      {returnDate && (
                        <>
                          <span>-</span>
                          {returnDate}
                        </>
                      )}
                    </>
                  )}
                </span>
              </Inline>
            </Inline>
          </Stack>
          {tripData.totalPrice ? (
            <dl className={cx(priceWrapperStyles, contentStyles)}>
              <dt>{t('TripReminder.TotalPrice.Label')}</dt>
              <dd>
                <strong data-testid="tripReminder-totalPrice">
                  {priceUtil.formatPrice(tripData.totalPrice)}
                </strong>
              </dd>
            </dl>
          ) : null}
          <div className={noticeWrapperStyles}>
            <Notice
              icon={
                <Clock
                  className={noticeIconStyles}
                  color={vars.colors.signal.caution.light}
                  size={20}
                  weight="fill"
                />
              }
              variant="caution"
            >
              {t('TripReminder.UrgencyNotice.Label')}
            </Notice>
          </div>
        </Stack>
        <Columns spacing={16}>
          <Column width={{ [breakpoints._0]: '4/4', [breakpoints._560]: '2/4' }}>
            <Button
              className={actionButtonStyles}
              data-testid="tripReminder-closeReminderButton"
              onClick={onToggleTripContents}
            >
              {t('TripReminder.Buttons.Cancel.Label')}
            </Button>
          </Column>
          <Column width={{ [breakpoints._0]: '4/4', [breakpoints._560]: '2/4' }}>
            <Button
              as={Link}
              className={actionButtonStyles}
              data-testid="tripReminder-proceedButton"
              to={purchaseFlowStep}
              variant="primary"
            >
              {t('TripReminder.Buttons.Proceed.Label')}
            </Button>
          </Column>
        </Columns>
      </Stack>
    </div>
  );
};

TripReminderContents.propTypes = {
  onToggleTripContents: PropTypes.func.isRequired,
  purchaseFlowStep: PropTypes.string.isRequired,
  tripData: PropTypes.shape({
    date: PropTypes.shape({
      departure: PropTypes.instanceOf(Date),
      return: PropTypes.instanceOf(Date),
    }),
    destination: PropTypes.string,
    origin: PropTypes.string,
    totalPrice: PropTypes.number,
    travelers: PropTypes.string,
    type: TripType,
  }),
};

export default TripReminderContents;
