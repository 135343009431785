import { createValidator } from 'revalidate';
import { inRange } from 'lodash/number';
import { INVALID_EXPIRE_YEAR } from '../constants/defaultErrorMessages';
const YEARS_UNTIL_CARD_EXPIRES = 20;
export const convertToFullYear = year => `20${year}`;
const isExpireYearOk = year => {
  if (String(year).length === 2) {
    const currentYear = new Date().getFullYear();
    return inRange(parseInt(convertToFullYear(year), 10), currentYear, currentYear + YEARS_UNTIL_CARD_EXPIRES);
  }
  return false;
};
const isValidExpiryYear = createValidator(message => year => {
  if (year && !isExpireYearOk(year)) {
    return message || INVALID_EXPIRE_YEAR;
  }
  return null;
}, INVALID_EXPIRE_YEAR);
export default isValidExpiryYear;