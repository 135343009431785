import { connect, MapStateToProps, MapDispatchToProps, MergeProps } from 'react-redux';
import { formValueSelector, change as changeFormValue, FormStateMap } from 'redux-form';
import { TripType } from '@eti/schema-types';
import DatesWithClickOutside, { type DatesProps } from '../components/dates/Dates';
import {
  RETURN_DATE,
  SINGLE_BOUND,
  MULTI_BOUND,
  DEPARTURE_DATE,
  ORIGIN,
  DESTINATION,
} from '../constants/formFieldNames';

type SearchBound = { departureDate: string };
interface ConnectedProps {
  departureDate: string;
  returnDate: string;
  previousBoundDepartureDate: string;
  bounds: SearchBound[];
}

interface OwnProps extends DatesProps {
  form: string;
  boundIndex?: number;
  numberOfBounds: number;
}

const getFieldName = (fieldName: string, selectedTripType?: TripType, index: number = 0) =>
  selectedTripType !== TripType.MultiStop
    ? `${SINGLE_BOUND}.${fieldName}`
    : `${MULTI_BOUND}[${index}].${fieldName}`;

const mapStateToProps: MapStateToProps<ConnectedProps, OwnProps, { form: FormStateMap }> = (
  state,
  { departureDateName, form, boundIndex = 0, selectedTripType },
) => {
  const originFieldName = getFieldName(`${ORIGIN}.iataCode`, selectedTripType, boundIndex);
  const destinationFieldName = getFieldName(
    `${DESTINATION}.iataCode`,
    selectedTripType,
    boundIndex,
  );
  return {
    departureDate: formValueSelector(form)(state, departureDateName),
    returnDate: formValueSelector(form)(state, `${SINGLE_BOUND}.${RETURN_DATE}`),
    previousBoundDepartureDate: formValueSelector(form)(
      state,
      `${MULTI_BOUND}[${boundIndex - 1}].${DEPARTURE_DATE}`,
    ),
    bounds: formValueSelector(form)(state, `${MULTI_BOUND}`),
    origin: formValueSelector(form)(state, originFieldName),
    destination: formValueSelector(form)(state, destinationFieldName),
  };
};

type ConnectedDispatch = {
  setDepartureDate: (day?: string) => void;
  setReturnDate: (day?: string) => void;
  setNextBoundsDates: (day: string, bounds: SearchBound[]) => void;
};

const mapDispatchToProps: MapDispatchToProps<ConnectedDispatch, OwnProps> = (
  dispatch,
  { form, departureDateName, numberOfBounds, boundIndex = 0 },
) => ({
  setDepartureDate: (day = '') => {
    dispatch(changeFormValue(form, departureDateName, day));
  },
  setReturnDate: (day = '') => {
    dispatch(changeFormValue(form, `${SINGLE_BOUND}.${RETURN_DATE}`, day));
  },
  setNextBoundsDates: (day, bounds) => {
    bounds.slice(boundIndex + 1, numberOfBounds).forEach((bound, index) => {
      if (bound && bound.departureDate < day) {
        dispatch(
          changeFormValue(form, `${MULTI_BOUND}[${boundIndex + 1 + index}].${DEPARTURE_DATE}`, day),
        );
      }
    });
  },
});

interface ConnectedMergeProps extends DatesProps {
  setNextBoundsDates: (date: string) => void;
}

const mergeProps: MergeProps<ConnectedProps, ConnectedDispatch, OwnProps, ConnectedMergeProps> = (
  state,
  actions,
  props,
) => ({
  ...state,
  ...actions,
  ...props,
  setNextBoundsDates: (day) => actions.setNextBoundsDates(day, state.bounds),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DatesWithClickOutside);
