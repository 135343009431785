import { useSiteContext, useTranslation } from '@eti/providers';
import { price as priceUtil } from '@eti/utils';
import * as allProducts from '../products';
const {
  BAGGAGE_SERVICE,
  BANKRUPTCY_INSURANCE_COVER_GENIUS,
  CANCELLATION_INSURANCE_COVER_GENIUS,
  COMPREHENSIVE_INSURANCE_COVER_GENIUS,
  TRAVEL_INSURANCE_COVER_GENIUS,
  CANCEL_24_HOURS
} = allProducts;
const {
  formatPrice
} = priceUtil;
export const generateMuxProductKey = products => {
  var _products$map$join;
  return (_products$map$join = products.map(p => p.id).join('-')) !== null && _products$map$join !== void 0 ? _products$map$join : '';
};
export const getEmptyOptionId = identifierKey => `emptyOption-${identifierKey}`;
export const getPrice = sellSpecification => {
  if (!sellSpecification) {
    return 0;
  }
  if ('sellPriceTravelers' in sellSpecification) {
    var _sellSpecification$se;
    return (_sellSpecification$se = sellSpecification.sellPriceTravelers) === null || _sellSpecification$se === void 0 ? void 0 : _sellSpecification$se.reduce((accumulatedTotalPrice, currentTraveler) => {
      var _currentTraveler$pric;
      return accumulatedTotalPrice + ((currentTraveler === null || currentTraveler === void 0 || (_currentTraveler$pric = currentTraveler.price) === null || _currentTraveler$pric === void 0 || (_currentTraveler$pric = _currentTraveler$pric.price) === null || _currentTraveler$pric === void 0 ? void 0 : _currentTraveler$pric.value) || 0);
    }, 0);
  }
  if ('price' in sellSpecification) {
    var _sellSpecification$pr;
    return sellSpecification === null || sellSpecification === void 0 || (_sellSpecification$pr = sellSpecification.price) === null || _sellSpecification$pr === void 0 || (_sellSpecification$pr = _sellSpecification$pr.price) === null || _sellSpecification$pr === void 0 ? void 0 : _sellSpecification$pr.value;
  }
  return 0;
};
export const getSelectionPerTravelerId = sellSpecification => {
  return sellSpecification && 'sellPriceTravelers' in sellSpecification ? sellSpecification.sellPriceTravelers.map(traveler => traveler === null || traveler === void 0 ? void 0 : traveler.travelerId).filter(Boolean) : null;
};
const getCoverGeniusTranslationText = (product, t, codeName, isInUSMarket) => {
  const ipidPolicyLink = product !== null && product !== void 0 && product.productSpecificTexts && 'ipid' in product.productSpecificTexts ? product === null || product === void 0 ? void 0 : product.productSpecificTexts.ipid.url : '';
  const termsUrl = product !== null && product !== void 0 && product.productSpecificTexts && 'termsUrl' in product.productSpecificTexts ? product.productSpecificTexts.termsUrl : '';
  const disclaimer = product !== null && product !== void 0 && product.productSpecificTexts && 'disclaimer' in product.productSpecificTexts ? product.productSpecificTexts.disclaimer : '';
  const ipidPolicyHtml = ipidPolicyLink && `<a href=${ipidPolicyLink} target="_blank">${t('coverGeniusIPIDPolicyLinkText')}</a><br/>` || '';
  const termsUrlHtml = termsUrl && `<a href=${termsUrl} target="_blank">${t('coverGeniusPolicyLinkText')}</a><br/>` || '';
  const disclaimerHtml = disclaimer && `<br/><p> ${disclaimer} </p>`;
  const descriptionDisclaimerHtml = isInUSMarket && disclaimerHtml || '';
  const readmoreDisclaimerHtml = !isInUSMarket && disclaimerHtml || '';
  const productReadMoreTermAndIpidLinks = !isInUSMarket ? `${ipidPolicyHtml}${termsUrlHtml}` : '';
  const productDescriptionTermAndIpidLinks = isInUSMarket ? `${ipidPolicyHtml}${termsUrlHtml}` : '';
  const productTitle = t(`${codeName}Title`);
  const productDescription = `${t(`${codeName}AbstractText`)}${descriptionDisclaimerHtml}${productDescriptionTermAndIpidLinks}`;
  const readMoreTextkey = t(`${codeName}AdditionalInformation`);
  const productReadMore = `${readMoreTextkey}${productReadMoreTermAndIpidLinks}${readmoreDisclaimerHtml}`;
  return {
    productTitle,
    productDescription,
    productReadMore
  };
};
export const useGetData = products => {
  const {
    t
  } = useTranslation();
  const {
    market
  } = useSiteContext();
  const isInUSMarket = market.code === 'US';
  return products === null || products === void 0 ? void 0 : products.map(product => {
    const code = Object.values(allProducts).find(allProduct => {
      var _allProduct$id;
      return (allProduct === null || allProduct === void 0 || (_allProduct$id = allProduct.id) === null || _allProduct$id === void 0 ? void 0 : _allProduct$id.toString()) === (product === null || product === void 0 ? void 0 : product.id);
    });
    const codeName = (code === null || code === void 0 ? void 0 : code.name) || '';
    const dataWithTranslations = (productTitle, productDescription, productReadMore) => {
      var _product$sellSpecific;
      return {
        productTitle: t(productTitle),
        productDescription: t(productDescription) || t(`${codeName}AdditionalSalesText`),
        productReadMore: t(productReadMore),
        productPrice: formatPrice(getPrice((_product$sellSpecific = product.sellSpecification) !== null && _product$sellSpecific !== void 0 ? _product$sellSpecific : null)),
        productId: product === null || product === void 0 ? void 0 : product.id.toString()
      };
    };
    switch (product === null || product === void 0 ? void 0 : product.id) {
      case CANCEL_24_HOURS.id.toString():
        return dataWithTranslations('cancelTwentyFourHoursTitle', 'cancelTwentyFourHoursAbstract', 'cancelTwentyFourHoursAdditionalSalesText');
      case BAGGAGE_SERVICE.id.toString():
        return {
          ...dataWithTranslations('baggageServiceTitle', 'baggageServiceAbstract', 'baggageServiceReadMoreModalContent')
        };
      case BANKRUPTCY_INSURANCE_COVER_GENIUS.id.toString():
      case CANCELLATION_INSURANCE_COVER_GENIUS.id.toString():
      case COMPREHENSIVE_INSURANCE_COVER_GENIUS.id.toString():
      case TRAVEL_INSURANCE_COVER_GENIUS.id.toString():
        return {
          ...dataWithTranslations(),
          ...getCoverGeniusTranslationText(product, t, codeName, isInUSMarket)
        };
      default:
        {
          return dataWithTranslations(`${codeName}Title`, `${codeName}Abstract`, `${codeName}ReadMoreModalContent`);
        }
    }
  });
};