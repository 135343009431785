import { formValueSelector } from 'redux-form';
import minBy from 'lodash/minBy';
import sumBy from 'lodash/sumBy';
export const getSelected = (name, form) => state => {
  var _formData$value;
  const formData = formValueSelector(form)(state, name);
  const value = formData === null || formData === void 0 || (_formData$value = formData.value) === null || _formData$value === void 0 ? void 0 : _formData$value.value;
  return value === true || value === 'true';
};
export const getPrice = (name, form) => state => {
  var _formData$value$price, _formData$value2;
  const formData = formValueSelector(form)(state, name);
  return (_formData$value$price = formData === null || formData === void 0 || (_formData$value2 = formData.value) === null || _formData$value2 === void 0 ? void 0 : _formData$value2.priceRaw) !== null && _formData$value$price !== void 0 ? _formData$value$price : null;
};
export const getTravelers = (name, form) => state => {
  var _formData$travelers;
  const formData = formValueSelector(form)(state, name);
  return (_formData$travelers = formData === null || formData === void 0 ? void 0 : formData.travelers) !== null && _formData$travelers !== void 0 ? _formData$travelers : null;
};
export const getTravelerField = (id, name) => name && id ? `${name}.travelers.traveler-${id}` : null;
export const getTravelerChoice = (name, form, id) => state => {
  var _formData$choice;
  const travelerField = getTravelerField(id, name);
  const formData = formValueSelector(form)(state, travelerField);
  return (_formData$choice = formData === null || formData === void 0 ? void 0 : formData.choice) !== null && _formData$choice !== void 0 ? _formData$choice : {};
};
export const getSelectedTraveler = (name, form, id) => state => {
  var _formData$selected;
  const travelerField = getTravelerField(id, name);
  const formData = formValueSelector(form)(state, travelerField);
  return (_formData$selected = formData === null || formData === void 0 ? void 0 : formData.selected) !== null && _formData$selected !== void 0 ? _formData$selected : null;
};
export const getDisplayPrice = (name, form) => state => {
  var _formData$travelers2, _minBy;
  const formData = formValueSelector(form)(state, name);
  const travelers = Object.values((_formData$travelers2 = formData === null || formData === void 0 ? void 0 : formData.travelers) !== null && _formData$travelers2 !== void 0 ? _formData$travelers2 : {});
  const hasAnySelected = travelers.find(_ref => {
    let {
      selected
    } = _ref;
    return selected;
  });
  const total = sumBy(travelers, _ref2 => {
    var _choice$priceRaw;
    let {
      selected,
      choice
    } = _ref2;
    const price = (_choice$priceRaw = choice.priceRaw) !== null && _choice$priceRaw !== void 0 ? _choice$priceRaw : 0;
    return selected ? price : 0;
  });
  const minimum = ((_minBy = minBy(travelers.filter(_ref3 => {
    let {
      choice
    } = _ref3;
    return choice.priceRaw !== 0;
  }), _ref4 => {
    let {
      choice
    } = _ref4;
    return choice.priceRaw;
  })) === null || _minBy === void 0 ? void 0 : _minBy.choice.priceRaw) || null;
  return travelers.length && hasAnySelected ? total : minimum;
};
export const hasAnyTravelerSelected = (name, form) => state => {
  var _formData$travelers3;
  const formData = formValueSelector(form)(state, name);
  const travelers = Object.values((_formData$travelers3 = formData === null || formData === void 0 ? void 0 : formData.travelers) !== null && _formData$travelers3 !== void 0 ? _formData$travelers3 : []);
  return travelers.some(_ref5 => {
    let {
      selected
    } = _ref5;
    return selected;
  });
};
export const getTravelersWithChoice = (name, form) => state => {
  var _formData$travelers4;
  const formData = formValueSelector(form)(state, name);
  const travelers = Object.values((_formData$travelers4 = formData === null || formData === void 0 ? void 0 : formData.travelers) !== null && _formData$travelers4 !== void 0 ? _formData$travelers4 : {});
  return travelers.filter(traveler => {
    var _traveler$choice$valu, _traveler$choice, _traveler$choice$pric, _traveler$choice2;
    const choiceValue = (_traveler$choice$valu = traveler === null || traveler === void 0 || (_traveler$choice = traveler.choice) === null || _traveler$choice === void 0 ? void 0 : _traveler$choice.value) !== null && _traveler$choice$valu !== void 0 ? _traveler$choice$valu : false;
    const choicePrice = (_traveler$choice$pric = traveler === null || traveler === void 0 || (_traveler$choice2 = traveler.choice) === null || _traveler$choice2 === void 0 ? void 0 : _traveler$choice2.priceRaw) !== null && _traveler$choice$pric !== void 0 ? _traveler$choice$pric : 0;
    return choiceValue && choicePrice > 0;
  }).map(traveler => {
    var _traveler$choice3;
    return traveler === null || traveler === void 0 || (_traveler$choice3 = traveler.choice) === null || _traveler$choice3 === void 0 ? void 0 : _traveler$choice3.id;
  });
};
export const getPreselectedTravelerChoices = (travelers, nationalities, preselectedChoice) => {
  const travelerIds = travelers.map(traveler => traveler.id);
  const choice = nationalities.find(nationality => nationality.id === preselectedChoice);
  if (!choice) {
    return {};
  }
  const {
    pricePerTravelers,
    descriptions,
    name
  } = choice;
  return travelerIds.reduce((acc, travelerId) => {
    var _travelerPrice$price$, _travelerPrice$price;
    const travelerPrice = pricePerTravelers.find(pricePerTraveler => pricePerTraveler.travelerId === travelerId);
    return {
      ...acc,
      [`traveler-${travelerId}`]: {
        choice: {
          id: travelerId,
          value: preselectedChoice,
          label: name,
          priceRaw: (_travelerPrice$price$ = travelerPrice === null || travelerPrice === void 0 || (_travelerPrice$price = travelerPrice.price) === null || _travelerPrice$price === void 0 || (_travelerPrice$price = _travelerPrice$price.price) === null || _travelerPrice$price === void 0 ? void 0 : _travelerPrice$price.value) !== null && _travelerPrice$price$ !== void 0 ? _travelerPrice$price$ : 0,
          descriptions
        }
      }
    };
  }, {});
};