import { css } from '@emotion/css';
const resetButtonStyle = css`
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-family: inherit;
  outline: none;
  padding: 0;
`;
export default resetButtonStyle;