import { useEffect, useState } from 'react';
import { AssetType } from './constants';
export const useAsset = function (type, id, subfolder) {
  if (id === void 0) {
    id = 'fallback';
  }
  if (subfolder === void 0) {
    subfolder = 'default';
  }
  const [asset, setAsset] = useState({
    ReactComponent: null,
    default: null
  });
  useEffect(() => {
    let isUnmounted = false;
    const load = async () => {
      try {
        const getLazyLoadedAsset = () => {
          switch (type) {
            case AssetType.Pictogram:
              return import(`@eti/assets/pictograms/${subfolder}/${id}.svg`);
            case AssetType.Illustration:
              return import(`@eti/assets/illustrations/${subfolder}/${id}.svg`);
            default:
              return import(`@eti/assets/icons/${id}.svg`);
          }
        };
        const lazyLoadedAsset = await getLazyLoadedAsset();
        if (!isUnmounted) {
          setAsset(lazyLoadedAsset);
        }
      } catch (error) {
        console.warn(error); // eslint-disable-line no-console
      }
    };
    load();
    return () => {
      isUnmounted = true;
    };
  }, [id, subfolder, type]);
  return asset;
};