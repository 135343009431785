import { formValueSelector } from 'redux-form';
export const getTotalPrice = (form, name) => state => Object.values(formValueSelector(form)(state, name) || {}).reduce((totalPrice, _ref) => {
  let {
    priceRaw
  } = _ref;
  return totalPrice + (priceRaw || 0);
}, 0);
export const getTotalMarkup = (form, name) => state => Object.values(formValueSelector(form)(state, name) || {}).reduce((totalMarkup, _ref2) => {
  let {
    markup
  } = _ref2;
  if (typeof totalMarkup !== 'number') {
    return markup;
  }
  if (typeof markup !== 'number') {
    return totalMarkup;
  }
  return totalMarkup + markup;
}, null);
export const getPriceDivided = (price, divider) => {
  if (divider > 0 && Number.isInteger(divider)) {
    return divider > 1 ? Math.ceil(price / divider) : price;
  }
  return null;
};