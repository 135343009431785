import format from 'date-fns/format';
import { Formatters } from 'react-day-picker';

export type Locale = {
  code?: string;
  formatDistance?: (...args: Array<any>) => any;
  formatRelative?: (...args: Array<any>) => any;
  localize?: {
    ordinalNumber: (...args: Array<any>) => any;
    era: (...args: Array<any>) => any;
    quarter: (...args: Array<any>) => any;
    month: (...args: Array<any>) => any;
    day: (...args: Array<any>) => any;
    dayPeriod: (...args: Array<any>) => any;
  };
  formatLong?: {
    date: (...args: Array<any>) => any;
    time: (...args: Array<any>) => any;
    dateTime: (...args: Array<any>) => any;
  };
  match?: {
    ordinalNumber: (...args: Array<any>) => any;
    era: (...args: Array<any>) => any;
    quarter: (...args: Array<any>) => any;
    month: (...args: Array<any>) => any;
    day: (...args: Array<any>) => any;
    dayPeriod: (...args: Array<any>) => any;
  };
  options?: {
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  };
};

const customLocales: Record<string, string> = {
  gr_GR: 'el',
  in_ID: 'id',
  no_NO: 'nb',
};
const defaultLanguageLocales: Record<string, string> = {
  fa: 'fa-IR',
  zh: 'zh-CN',
};

export const getLocale = async (locale: string) => {
  const formattedLocale = locale.replace('_', '-');
  const [language] = formattedLocale.split('-');

  const overrideLocale = customLocales[locale] ?? defaultLanguageLocales[language];
  let resolvedLocale;

  try {
    const fallBackLocale = overrideLocale ?? formattedLocale;
    resolvedLocale = await import(`date-fns/locale/${fallBackLocale}/index.js`);
  } catch {
    const fallBackLanguage = overrideLocale ?? language;
    try {
      resolvedLocale = await import(`date-fns/locale/${fallBackLanguage}/index.js`);
    } catch {
      resolvedLocale = await import(`date-fns/locale/en-GB/index.js`);
    }
  }
  return resolvedLocale.default;
};

const getDateFormatByLocale = (localeCode?: string) => {
  switch (localeCode) {
    case 'et':
    case 'pt-BR': {
      return 'cccccc';
    }
    case 'vi': {
      return 'ccccc';
    }
    default: {
      return 'eee';
    }
  }
};

export const formatWeekdayName: Formatters['formatWeekdayName'] = (date, options) => {
  const dateFormat = getDateFormatByLocale(options?.locale?.code);

  return format(date, dateFormat, options);
};
