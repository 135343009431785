import { useRef, useState, useLayoutEffect } from 'react';
import throttle from 'lodash/throttle';
const DOWN = 'DOWN';
const UP = 'UP';
export default function useScroll(_temp) {
  let {
    selector = document,
    maxScreenWidth,
    throttleTime = 250,
    useCaptionOnScrollListener = false
  } = _temp === void 0 ? {} : _temp;
  const previousScrollPosition = useRef(window.pageYOffset);
  const [direction, setDirection] = useState(null);
  const [pageYOffset, setPageYOffset] = useState(previousScrollPosition);
  const handleScroll = throttle(() => {
    const currentScrollPosition = window.pageYOffset;
    setPageYOffset(currentScrollPosition);
    if (currentScrollPosition === previousScrollPosition.current) {
      return;
    }
    if (currentScrollPosition > previousScrollPosition.current) {
      setDirection(DOWN);
    } else {
      setDirection(UP);
    }
    previousScrollPosition.current = currentScrollPosition;
  }, throttleTime);
  useLayoutEffect(() => {
    if (maxScreenWidth) {
      if (window.innerWidth <= maxScreenWidth) {
        selector.addEventListener('scroll', handleScroll, useCaptionOnScrollListener);
      }
    } else {
      selector.addEventListener('scroll', handleScroll, useCaptionOnScrollListener);
    }
    return () => {
      selector.removeEventListener('scroll', handleScroll, useCaptionOnScrollListener);
    };
  }, [handleScroll, selector, maxScreenWidth, useCaptionOnScrollListener]);
  return {
    direction,
    pageYOffset,
    isScrollingDown: direction === DOWN,
    isScrollingUp: direction === UP
  };
}