import { connect } from 'react-redux';
import { getMealState } from '../utils/units';
import Meal from '../components/Meal';
import { getTravelerNames } from '../../common/utils/travelerNames';
const mapStateToProps = (state, _ref) => {
  var _getMealState;
  let {
    form,
    order
  } = _ref;
  return {
    mealState: (_getMealState = getMealState(form, state)) !== null && _getMealState !== void 0 ? _getMealState : [],
    travelerNames: getTravelerNames(form, state, order === null || order === void 0 ? void 0 : order.travelers)
  };
};
export default connect(mapStateToProps)(Meal);