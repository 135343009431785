/* eslint-disable react/prop-types */
import { components } from 'react-select';
import { AirplaneLanding, AirplaneTakeoff } from '@phosphor-icons/react';
import { ORIGIN } from '../constants/formFieldNames';
import { iconWrapperStyles } from '../components/styles/locationDropdown';

export const getNoOptionsMessage =
  (searchTermTooSmallText, noResultsFoundText) =>
  ({ inputValue }) =>
    inputValue.length < 2 ? searchTermTooSmallText : noResultsFoundText;

export const ValueContainer =
  (name) =>
  ({ children, ...rest }) => (
    <components.ValueContainer {...rest}>
      <div className={iconWrapperStyles}>
        {name.includes(ORIGIN) && (
          <AirplaneTakeoff data-testid="originIcon" size={18} weight="light" />
        )}
        {!name.includes(ORIGIN) && (
          <AirplaneLanding data-testid="destinationIcon" size={18} weight="light" />
        )}
      </div>
      {children}
    </components.ValueContainer>
  );
