import { breakpoints, Card, Heading, Inline, LinkButton, Stack, vars } from '@etg/wings';
import { ContentWrapper } from '@eti/components';
import { DevBar, Collection } from '@eti/debug';
import {
  useTranslation,
  useSiteContext,
  useProperty,
  useDirection,
  useSearch,
} from '@eti/providers';
import { css } from '@eti/styles';
import { useHistory } from 'react-router-dom';
import { CaretLeft } from '@phosphor-icons/react';
import { DebugCommonComponents } from '../../../common/debug/components';
import { SearchForm } from '../../../widgets/search-form';
import { getSearchFormDataFrom } from '../../../widgets/search-form/utils/initialValues';
import { routes } from '../../../constants/routesAndApisConstants';
import { isDev } from '../../../common/debug/utils';

const pageNotFoundWrapperStyles = css`
  background: ${vars.colors.pageBackdrop};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const containerStyles = css`
  margin-block: 24px;

  @media (min-width: ${breakpoints._768}) {
    margin-block: 32px;
    max-width: 950px;
  }

  @media (min-width: ${breakpoints._970}) {
    margin-block: 40px;
    padding: 0;
  }
`;

const linkWrapperStyles = css`
  background-color: #fff;
  height: 60px;
`;

const linkButtonStyles = css`
  align-items: center;
  display: flex;
  gap: 16px;
`;

const headingStyles = css`
  margin-bottom: 48px;
  max-width: 760px;
  text-align: center;
`;

const PageNotFound = () => {
  const history = useHistory();
  const { language } = useSiteContext();
  const { t } = useTranslation();
  const { p } = useProperty();
  const { isRTL } = useDirection();
  const { currentSearchData } = useSearch();

  const search = getSearchFormDataFrom(currentSearchData);
  const errorPageHomeUrl = p('IBEClient.ErrorMessageLink.Url');

  const handleSearch = (searchParameters) => {
    history.push(routes.RESULT, { searchVariables: searchParameters });
  };

  return (
    <div className={pageNotFoundWrapperStyles}>
      <ContentWrapper className={containerStyles}>
        <Stack align="center">
          <Heading data-testid="page-not-found-title" level={1}>
            {t('StatusPage.PageNotFound.Title')}
          </Heading>
          <Heading className={headingStyles} level={2}>
            {t('StatusPage.PageNotFound.Message')}
          </Heading>
        </Stack>
        <Card>
          <SearchForm language={language.code} onSearch={handleSearch} search={search} />
        </Card>
      </ContentWrapper>
      <Inline align="center" alignY="center" className={linkWrapperStyles} noWrap>
        <LinkButton
          className={linkButtonStyles}
          data-testid="start-page-link"
          onClick={() => history.push(errorPageHomeUrl)}
        >
          <CaretLeft mirrored={isRTL} size={20} weight="light" />
          {t('StatusPage.LinkLabel.Home')}
        </LinkButton>
      </Inline>
      {isDev && (
        <DevBar>
          <Collection>
            <DebugCommonComponents />
          </Collection>
        </DevBar>
      )}
    </div>
  );
};

export default PageNotFound;
