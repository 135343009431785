import { gql } from '@eti/schema-types';

export const CONTINUE_MAKE_ORDER = gql(/* GraphQL */ `
  mutation ContinueMakeOrder {
    continueMakeOrder {
      type
      message
    }
  }
`);
