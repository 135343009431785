import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const ClosedDownBrandPage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      pageTitle={t('PageTitle.rf.closed-down-brand.action')}
      textKey="ClosedDownBrand.HtmlContent"
    />
  );
};
export default ClosedDownBrandPage;
