import { COLORS } from './constants';
import { getIcon } from './icon';
const getStyles = notNeutral => `
  height: auto;
  ${notNeutral && 'margin-top: -1%'};
  transform: scaleX(-1);
  width: ${notNeutral ? '63%' : '100%'};
`;
const configuration = {
  colors: [COLORS.blue, COLORS.white],
  getStyles,
  id: 'share'
};
export default type => getIcon({
  ...configuration,
  type
});