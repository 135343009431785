import { useSiteContext } from '@eti/providers';
import { analytics } from '@eti/utils';
import { CartData } from '../models';
import { formatPrice, getAirProduct, getBundleProducts } from '../utils';
import { PRODUCT_CATEGORIES } from '../constants/productCategories';
import { getExtraProducts } from './useEcomPurchaseDataLayerEffect';

export const usePendingProviderDataLayerEffect = (processingOrderMessage: string) => {
  const { dataLayer, sessionStorage } = window;
  const { siteCurrency } = useSiteContext();
  const cartDataJson = sessionStorage.getItem('cartData');

  if (!cartDataJson || !dataLayer) {
    return;
  }
  const cartData: CartData = JSON.parse(cartDataJson);
  const getPaymentProduct = (
    itemId: number,
    itemName: string,
    price: number,
    currencyExponent: number,
  ) => {
    return {
      item_id: itemId,
      item_name: itemName,
      item_category: PRODUCT_CATEGORIES.PAYMENT,
      price: formatPrice(currencyExponent, price),
      quantity: 1,
    };
  };

  const discountAmount = formatPrice(siteCurrency?.exponent, cartData?.discountAmount);
  const items = [
    getAirProduct(cartData.travelerPrices, siteCurrency.exponent),
    ...getBundleProducts(cartData.bundles, siteCurrency.exponent),
    ...getExtraProducts(cartData.products, siteCurrency.exponent),
    getPaymentProduct(
      Number(cartData?.selectedPaymentMethod?.id),
      cartData?.selectedPaymentMethod?.code,
      cartData?.selectedPaymentMethod?.price?.value,
      siteCurrency?.exponent,
    ),
  ].map((item, index) => ({ ...item, index }));
  const totalValue = items.reduce((acc, item) => acc + item.price, 0) - discountAmount;
  const pendingProviderEvent = {
    event: 'pending_provider',
    ecommerce: {
      coupon: cartData.coupon ? 'Coupon' : '',
      currency: siteCurrency?.code,
      discount: discountAmount,
      items,
      pending_provider_reason: processingOrderMessage,
      transaction_id: cartData?.transactionId,
      value: totalValue,
    },
  };

  analytics.pushEntryToDataLayer({ ecommerce: null }, false);
  analytics.pushEntryToDataLayer(pendingProviderEvent, false);

  sessionStorage.removeItem('discountCodeAmount');
  sessionStorage.removeItem('cartData');
};
