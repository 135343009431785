import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const TravelConditionsExternalPage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      image="cookies"
      pageTitle={t('PageTitle.rf.travel-conditions-external.action')}
      textKey="TravelConditionsExternal.HtmlContent"
    />
  );
};

export default TravelConditionsExternalPage;
