import { price as priceUtils } from '@eti/utils';
import { getTravelerName } from '../../common/utils/travelerNames';
import { MEAL } from '../../products';
import { isPrimaryFlow } from '../../common/utils/primaryFlow';
export const NO_OPTION = 'NO_OPTION';
export const sortTravelers = travelers => {
  const canSortTravelers = travelers.some(traveler => traveler.travelerId || traveler.travelerType);
  if (canSortTravelers) {
    return travelers.sort((traveler1, traveler2) => {
      var _traveler1$travelerTy;
      return traveler2.travelerType && ((_traveler1$travelerTy = traveler1.travelerType) === null || _traveler1$travelerTy === void 0 ? void 0 : _traveler1$travelerTy.localeCompare(traveler2.travelerType)) || Number(traveler1 === null || traveler1 === void 0 ? void 0 : traveler1.travelerId) - Number(traveler2 === null || traveler2 === void 0 ? void 0 : traveler2.travelerId);
    });
  }
  return travelers;
};
const isOrderTypePostbooking = order => {
  return 'availableExtraProducts' in order;
};
export const getSellSpecification = order => {
  if (!isPrimaryFlow() && isOrderTypePostbooking(order)) {
    var _order$availableExtra;
    return (_order$availableExtra = order.availableExtraProducts.find(product => String(product === null || product === void 0 ? void 0 : product.id) === String(MEAL.id))) === null || _order$availableExtra === void 0 ? void 0 : _order$availableExtra.sellSpecification;
  }
  return !isOrderTypePostbooking(order) ? order.sellSpecification : undefined;
};
export const getMinMealPrice = options => {
  if (!options.length) {
    return null;
  }
  const minPrice = options.filter(_ref => {
    let {
      priceRaw
    } = _ref;
    return Boolean(priceRaw);
  }).reduce((totalMinPrice, _ref2) => {
    let {
      priceRaw
    } = _ref2;
    return totalMinPrice ? Math.min(priceRaw || 0, totalMinPrice) : priceRaw || 0;
  }, 0);
  return minPrice;
};
export const getTotalPrice = options => {
  if (!options || (options === null || options === void 0 ? void 0 : options.length) === 0) {
    return null;
  }
  const price = options.filter(_ref3 => {
    let {
      priceRaw
    } = _ref3;
    return Boolean(priceRaw);
  }).reduce((totalPrice, _ref4) => {
    let {
      priceRaw
    } = _ref4;
    return totalPrice + (priceRaw || 0);
  }, 0);
  return price;
};
const structuredMealPreferences = _ref5 => {
  let {
    sellSpecification,
    travelerId,
    price,
    noMealText
  } = _ref5;
  const availableMealChoices = sellSpecification.availableChoices.map(choice => {
    var _priceUtils$getPriceF;
    return {
      price: (priceUtils === null || priceUtils === void 0 || (_priceUtils$getPriceF = priceUtils.getPriceFormatter()) === null || _priceUtils$getPriceF === void 0 ? void 0 : _priceUtils$getPriceF.formatPrice(price)) || '',
      priceRaw: price,
      label: (choice === null || choice === void 0 ? void 0 : choice.name) || '',
      value: (choice === null || choice === void 0 ? void 0 : choice.id) || '',
      choiceId: (choice === null || choice === void 0 ? void 0 : choice.id) || '',
      travelerId
    };
  });
  if ((availableMealChoices === null || availableMealChoices === void 0 ? void 0 : availableMealChoices.length) === 0) {
    return [];
  }
  return [{
    label: noMealText || 'No thanks',
    value: NO_OPTION,
    travelerId
  }, ...availableMealChoices];
};
const isSellSpecificationTravelerChoiceNew = sellSpecification => {
  return sellSpecification.__typename === 'SellSpecificationTravelerChoiceNew';
};
export const getTravelerMealPreferenceList = (order, travelerNames, defaultTravelerLabel, noMealText) => {
  const sellSpecification = getSellSpecification(order);
  if (!sellSpecification || !isSellSpecificationTravelerChoiceNew(sellSpecification)) {
    return [];
  }
  return sellSpecification.pricePerTravelers.map((priceSpecTraveler, index) => {
    var _priceSpecTraveler$pr;
    const foundTraveler = order.travelers.find(_ref6 => {
      let {
        id
      } = _ref6;
      return id === (priceSpecTraveler === null || priceSpecTraveler === void 0 ? void 0 : priceSpecTraveler.travelerId);
    });
    const price = priceSpecTraveler === null || priceSpecTraveler === void 0 || (_priceSpecTraveler$pr = priceSpecTraveler.price) === null || _priceSpecTraveler$pr === void 0 || (_priceSpecTraveler$pr = _priceSpecTraveler$pr.price) === null || _priceSpecTraveler$pr === void 0 ? void 0 : _priceSpecTraveler$pr.value;
    const travelerName = (foundTraveler === null || foundTraveler === void 0 ? void 0 : foundTraveler.fullName) || getTravelerName(foundTraveler === null || foundTraveler === void 0 ? void 0 : foundTraveler.id, travelerNames, defaultTravelerLabel, index + 1);
    return {
      mealPreferences: structuredMealPreferences({
        noMealText,
        sellSpecification,
        price,
        travelerId: priceSpecTraveler === null || priceSpecTraveler === void 0 ? void 0 : priceSpecTraveler.travelerId
      }),
      name: travelerName,
      travelerId: priceSpecTraveler === null || priceSpecTraveler === void 0 ? void 0 : priceSpecTraveler.travelerId,
      travelerType: foundTraveler === null || foundTraveler === void 0 ? void 0 : foundTraveler.ageType
    };
  });
};
export const onMealChange = (selectedMealValue, mealState, currentTravelerId) => {
  const existingMealOptionIndex = mealState.findIndex(mealOption => (mealOption === null || mealOption === void 0 ? void 0 : mealOption.travelerId) === currentTravelerId);
  const updatedMealState = existingMealOptionIndex !== -1 ? mealState.map((mealStateOption, index) => index === existingMealOptionIndex ? selectedMealValue : mealStateOption) : [...mealState, selectedMealValue];
  return updatedMealState;
};