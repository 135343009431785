import { useState, useEffect } from 'react';
import fetchRpcAction from '../utils/fetchRpcAction';

const useFlightStatus = () => {
  const [status, setStatus] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    let intervalId;
    let isMounted = true;
    setIsLoading(true);
    (async () => {
      try {
        intervalId = setInterval(async () => {
          const { model } = await fetchRpcAction({ action: 'flights' });
          setStatus(model);
        }, 60000);

        const { model } = await fetchRpcAction({ action: 'flights' });
        if (isMounted) {
          setStatus(model);
        }
      } catch (error) {
        if (isMounted) {
          setHasError(true);
        }
      }
      if (isMounted) {
        setIsLoading(false);
      }
    })();
    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, []);
  return { status, isLoading, hasError, isLoggedIn: Boolean(!isLoading && status) };
};

export default useFlightStatus;
