import { useSiteContext } from '@eti/providers';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { SELECT_LANGUAGE_MUTATION } from '../containers/mutations';

export const useLanguage = () => {
  const { language: selectedLanguage, availableLanguages = [] } = useSiteContext();

  const [selectLanguage] = useMutation(SELECT_LANGUAGE_MUTATION, {
    onCompleted: () => {
      window.location.reload();
    },
  });

  const [currentLanguage, setCurrentLanguage] = useState(
    JSON.parse(sessionStorage.getItem('currentLanguage')) ?? selectedLanguage,
  );

  const lessThanTwoAvailableLanguages = availableLanguages.length < 2;

  const changeLanguage = async (event) => {
    const languageChosen = availableLanguages.find(
      (language) => language.id.toString() === event.target.value,
    );
    setCurrentLanguage(languageChosen);
    const result = await selectLanguage({
      variables: {
        id: languageChosen.id,
      },
    });
    if (result.data.selectLanguage.success) {
      sessionStorage.setItem('currentLanguage', JSON.stringify(languageChosen));
      const currentURL = new URL(window.location.href);
      if (currentURL.search.includes('customerLanguage')) {
        const { searchParams } = currentURL;
        searchParams.set('customerLanguage', languageChosen.code);
        window.location.href = currentURL.href;
      }
    }
  };

  return {
    availableLanguages,
    currentLanguage,
    lessThanTwoAvailableLanguages,
    changeLanguage,
  };
};
