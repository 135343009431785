import { getPrice, getSelected, getGraphQlSelection } from '../single-choice/utils/selection';
import { CO2_COMPENSATION } from '../products';
import Co2Compensation from './components/Co2Compensation';
import getIcon from './utils/icon';
const formSection = CO2_COMPENSATION.name;
export const co2Compensation = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  ...CO2_COMPENSATION
};
export default Co2Compensation;