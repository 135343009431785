import { gql } from '@eti/schema-types';

export const GET_SETTINGS = gql(/* GraphQL */ `
  query GetSettings {
    settings {
      regions {
        availableLocales {
          code
          name
          type
          directionality
          availableCurrencies {
            code
            name
          }
          region {
            code
            name
          }
        }
        code
        name
      }
      selectedRegion {
        code
        name
        availableLocales {
          code
          name
          type
          directionality
          availableCurrencies {
            code
            name
          }
          region {
            code
            name
          }
        }
      }
      selectedLocale {
        code
        directionality
        name
        region {
          code
          name
        }
      }
      selectedCurrency {
        code
        name
      }
    }
  }
`);
