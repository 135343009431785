const PRIMARY = 'primary';
const SECONDARY = 'secondary';
const INFORMATION = 'info';
const WARNING = 'warning';
const CONFIRMATION = 'confirmation';
const CRITICAL = 'critical';
const GAIN_TRUST = 'gainTrust';
const TEXT = 'text';
export default {
  PRIMARY,
  SECONDARY,
  INFORMATION,
  WARNING,
  CONFIRMATION,
  CRITICAL,
  GAIN_TRUST,
  TEXT
};