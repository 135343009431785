import { formValueSelector } from 'redux-form';
export const getSelected = (name, form) => state => {
  const formData = formValueSelector(form)(state, name);
  return formData ? formData.value : null;
};
export const getSelectedForTraveler = (name, form, travelerIndex) => state => {
  const formData = formValueSelector(form)(state, name);
  return formData && formData.value ? formData.value.find(item => item.id === travelerIndex) : null;
};
export const getNumberOfUnits = (name, form) => state => {
  const selected = getSelected(name, form)(state);
  return selected ? selected.filter(travelerOption => !travelerOption.exclude).length : 0;
};
export const getPrice = (name, form) => state => {
  const selection = formValueSelector(form)(state, name);
  return selection && Array.isArray(selection.value) ? selection.value.reduce((totalPrice, _ref) => {
    let {
      priceRaw
    } = _ref;
    return totalPrice + (priceRaw || 0);
  }, 0) : 0;
};