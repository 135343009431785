import { getPrice, getSelected, getGraphQlSelection } from '../single-choice/utils/selection';
import { CANCELLATION_OUTSIDE_EU } from '../products';
import getIcon from '../common/utils/icon/cancelation-icon';
import CancellationOutsideEu from './components/CancellationOutsideEu';
const formSection = CANCELLATION_OUTSIDE_EU.name;
export const cancellationOutsideEu = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'traveler'),
  ...CANCELLATION_OUTSIDE_EU
};
export default CancellationOutsideEu;