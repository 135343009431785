const openAppStoreLink = (appStoreLink, playStoreLink) => {
  const {
    userAgent
  } = navigator;
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    window.open(appStoreLink, '_self');
    return true;
  }
  if (/android/i.test(userAgent)) {
    window.open(playStoreLink, '_self');
    return true;
  }
  return false;
};
export default openAppStoreLink;