import { change as changeFormValue, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import SeatMapContent from '../components/SeatMapContent';
import { getSelected, getPrice, getUpdatedSeatMap, processSeatSelection } from '../../utils/selection';
import { SEATING_PREFERENCE_VALUES } from '../../utils/constants';
const mapStateToProps = (state, _ref) => {
  let {
    bounds,
    form
  } = _ref;
  const getSelectedFunction = getSelected('seatMap', form)(state);
  const {
    price,
    markup
  } = getPrice('seatMap', form)(state);
  const selectedSeats = formValueSelector(form)(state, 'seatMap') || {};
  return {
    getSelected: activeSegment => Object.values(getSelectedFunction(activeSegment)),
    totalMarkup: markup,
    totalPrice: price,
    selectedSeats,
    bounds
  };
};
const mapDispatchToProps = (dispatch, _ref2) => {
  let {
    form,
    moveToExtraProductsPage,
    travelers
  } = _ref2;
  return {
    onChange: (boundSegmentIndex, travelerId, column, row, seat, price, markup, seatPreference) => {
      const traveler = travelers.find(t => t.id === travelerId);
      const getSeatPreference = sp => sp && sp !== SEATING_PREFERENCE_VALUES.NO_OPTION ? {
        seatPreference: sp
      } : {};
      const segment = {
        ...traveler,
        column,
        price,
        row,
        value: seat,
        markup,
        id: travelerId,
        ...getSeatPreference(seatPreference)
      };
      dispatch(changeFormValue(form, 'seatMap.value.value', 'true'));
      dispatch(changeFormValue(form, `seatMap.${boundSegmentIndex}.traveler${travelerId}`, segment));
    },
    onRemove: (boundSegmentIndex, travelerId, seatSelection) => {
      const seatMapData = getUpdatedSeatMap(boundSegmentIndex, travelerId, seatSelection);
      dispatch(changeFormValue(form, `seatMap`, seatMapData));
    },
    onSeatConfirmation: seatSelection => () => {
      const selectedSeats = processSeatSelection(seatSelection);
      if (selectedSeats.length === 0) {
        dispatch(changeFormValue(form, 'seatMap.value.value', 'false'));
      }
      moveToExtraProductsPage();
    },
    onOptOut() {
      dispatch(changeFormValue(form, 'seatMap.value.value', 'false'));
      moveToExtraProductsPage();
    }
  };
};
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSeatConfirmation: dispatchProps.onSeatConfirmation(stateProps.selectedSeats)
});
export const SeatMapContentContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(SeatMapContent);
export default SeatMapContentContainer;