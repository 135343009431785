import { formValueSelector } from 'redux-form';
import { SIMPLE_VISA } from '../../products';
const {
  name: formSection
} = SIMPLE_VISA;
const getGraphQlSelection = _ref => {
  var _formData$value;
  let {
    form,
    state
  } = _ref;
  const formData = formValueSelector(form)(state, formSection);
  if (!(formData !== null && formData !== void 0 && (_formData$value = formData.value) !== null && _formData$value !== void 0 && _formData$value.value) || !formData.travelers) {
    return null;
  }
  const selectionTravelerChoice = Object.values(formData.travelers).filter(_ref2 => {
    let {
      selected
    } = _ref2;
    return selected;
  }).map(_ref3 => {
    let {
      choice
    } = _ref3;
    return {
      choiceId: choice.value,
      travelerId: choice.id
    };
  });
  if (selectionTravelerChoice.length === 0) {
    return null;
  }
  return {
    productId: String(SIMPLE_VISA.id),
    selectionTravelerChoice
  };
};
export default getGraphQlSelection;