import { createValidator } from 'revalidate';
import { inRange } from 'lodash/number';
import { INVALID_EXPIRY_DATE } from '../constants/defaultErrorMessages';
import isValidExpiryYear, { convertToFullYear } from './isValidExpiryYear';
import isValidExpiryMonth from './isValidExpiryMonth';
const enteredExpiryDates = expiry => typeof expiry === 'string' && expiry.length > 0 ? expiry.match(/(\d{1,2})/g) : [];
const isNonEmptyArray = arr => Array.isArray(arr) && arr.length > 0;
const getExpiryMonth = expiryArr => isNonEmptyArray(expiryArr) ? expiryArr[0] : '';
const getExpiryYear = expiryArr => isNonEmptyArray(expiryArr) ? expiryArr[1] : '';
const isFutureYear = expiryDates => convertToFullYear(getExpiryYear(expiryDates)) > new Date().getFullYear();
const isValidExpiryDate = createValidator(function (message) {
  if (message === void 0) {
    message = INVALID_EXPIRY_DATE;
  }
  return expiry => {
    const expiryDates = enteredExpiryDates(expiry);
    const isValidYear = isValidExpiryYear(message)(getExpiryYear(expiryDates)) === null;
    const isValidMonth = isValidExpiryMonth(message)(getExpiryMonth(expiryDates)) === null;
    if (expiryDates.length === 1) {
      return message;
    }
    if (isValidYear && isFutureYear(expiryDates)) {
      const month = getExpiryMonth(expiryDates);
      const isMonthInRangeBetween1to12 = inRange(parseInt(month, 10), 1, 13);
      if (isMonthInRangeBetween1to12) {
        return null;
      }
      return message;
    }
    return isValidMonth && isValidYear ? null : message;
  };
}, INVALID_EXPIRY_DATE);
export default isValidExpiryDate;