import { useState } from 'react';
import fetchRpcAction from '../utils/fetchRpcAction';

const useLogin = () => {
  const [isFail, setIsFail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const login = (email, orderNumber, onSuccess) => {
    let isMounted = true;
    setIsLoading(true);
    (async () => {
      try {
        const { model: isLoggedIn } = await fetchRpcAction({ action: 'login', email, orderNumber });
        if (isMounted) {
          setIsFail(!isLoggedIn);
          if (isLoggedIn) {
            onSuccess();
          }
        }
      } catch (error) {
        if (isMounted) {
          setHasError(true);
        }
      }
      if (isMounted) {
        setIsLoading(false);
      }
    })();
    return () => {
      isMounted = false;
    };
  };

  return { login, isFail, isLoading, hasError };
};

export default useLogin;
