import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import TravelInsurance from '../components/TravelInsurance';
import { persistTaxId } from '../utils/utils';
import { TAX_ID_INPUT } from '../utils/constants';
const mapStateToProps = (state, _ref) => {
  let {
    form,
    name
  } = _ref;
  const taxId = formValueSelector(form)(state, `${name}.${TAX_ID_INPUT}`);
  const selectedValueRaw = formValueSelector(form)(state, `${name}.value.value`);
  const isSelectedValue = selectedValueRaw && JSON.parse(selectedValueRaw);
  const recoverSessionTaxId = () => persistTaxId(taxId);
  return {
    recoverSessionTaxId,
    isSelectedValue
  };
};
export default connect(mapStateToProps)(TravelInsurance);