import { gql } from '@eti/schema-types';

export const FETCH_SEARCH_LOCATIONS_QUERY = gql(/* GraphQL */ `
  query searchLocations($searchTerm: String) {
    searchLocations(searchTerm: $searchTerm) {
      city
      cityCode
      continent
      continentCode
      country
      countryCode
      iataCode
      id
      multipleAirports
      name
      state
      type
    }
  }
`);
