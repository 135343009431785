/* eslint-disable react/prop-types */
// TODO: WEB-2030 Move WithValidationProvider to common folder.
import { useProperty, useTranslation, ValidationProvider } from '@eti/providers';
import { validationRules } from '../utils/validationRules';

export const withValidationProvider =
  (WrappedComponent) =>
  ({ ...props }) => {
    const { p } = useProperty();
    const { t } = useTranslation();
    const textRules = {
      required: t('Validation.Required'),
      mail: t('Validation.Mail.Required'),
      orderNumber: t('Validation.OrderNumber.Invalid'),
    };
    const orderNumberMaxLength = p('IbeClient.OrderNumber.MaxCharacters');

    return (
      <ValidationProvider rules={validationRules(textRules, orderNumberMaxLength)}>
        <WrappedComponent {...props} />
      </ValidationProvider>
    );
  };
