export const CONSENT = 'consent';
export const CONTACT_US = 'contact-us';
export const CONTACT_US_V2 = 'contact-us-v2';
export const LOGIN = 'login';
export const PAYMENT = 'payment';
export const PRODUCTS = 'products';
export const RESULT = 'result';
export const SEARCH = 'search';
export const TRAVELER_CONTACT = 'traveler-contact-section';
export const TRAVELER_DETAILS = 'traveler-details';
export const VOID = 'void';
export const YOUR_SELECTED_TRIP = 'your-selected-trip';
export const CHANGE_TRIP = 'change-trip';

export default {
  CONSENT,
  CONTACT_US,
  CONTACT_US_V2,
  LOGIN,
  PAYMENT,
  PRODUCTS,
  RESULT,
  SEARCH,
  TRAVELER_CONTACT,
  TRAVELER_DETAILS,
  VOID,
  YOUR_SELECTED_TRIP,
} as const;
