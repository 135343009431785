/* eslint-disable promise/no-promise-in-callback */
import { Component } from 'react';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import StackTrace from 'stacktrace-js';
import ErrorPage from '../../../pages/status-pages/components/ErrorPage';
import { isDev } from '../../../common/debug/utils';

const isLogFunctionAvailable = () => window.etiJsLog && isFunction(window.etiJsLog.log);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    if (!isDev && isLogFunctionAvailable()) {
      StackTrace.fromError(error)
        .then((stack) => {
          const trace = stack[0];
          const traceParts = trace.fileName.split('/');
          const component = traceParts[traceParts.length - 1].split('.')[0];

          window.etiJsLog.log(
            `[REACT] ${error.toString()}`,
            window.location.pathname,
            trace.lineNumber,
            component,
          );
        })
        .catch((err) => {
          console.error(`StackTrace Error: ${err}`); // eslint-disable-line no-console
          window.etiJsLog.log(
            `StackTrace failed with: ${err} while processing Error: ${error} with stack: ${error.stack}`,
            window.location.pathname,
          );
        });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorPage /> : children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ErrorBoundary;
