import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const Experience = () => {
  const { t } = useTranslation();

  return (
    <SubPage pageTitle={t('PageTitle.rf.experience.action')} textKey="Experience.HtmlContent" />
  );
};

export default Experience;
