export const quickSortType = {
  RECOMMENDED: 'RECOMMENDATION',
  CAMPAIGN: 'CARRIER_PROMO',
  CHEAPEST: 'CHEAP_TRIP',
  SHORTEST: 'SHORT_TRIP',
  BEST_VALUE: 'BEST_VALUE',
  LONG_STAY: 'LONG_STAY',
} as const;

export const quickSortPricesType = {
  RECOMMENDATION: 'recommendation',
  CARRIER_PROMO: 'carrierPromo',
  CHEAP_TRIP: 'cheapTrip',
  SHORT_TRIP: 'shortTrip',
  BEST_VALUE: 'bestValue',
  LONG_STAY: 'longStay',
} as const;

export const quickSortDropdownMapping = {
  Recommendation: 'RECOMMENDATION',
  Promotion: 'CARRIER_PROMO',
  Cheapest: 'CHEAP_TRIP',
  Shorter: 'SHORT_TRIP',
  Best: 'BEST_VALUE',
  Long: 'LONG_STAY',
} as const;
