import { useState } from 'react';
import { useProperty } from '@eti/providers';
import TripReminderGraphqlContainer from './TripReminderGraphqlContainer';

const TripReminderContainer = () => {
  const { p } = useProperty();
  const [showReminder, setShowReminder] = useState(true);

  const shouldShowReminder =
    p('IbeClient.TripReminder.Enabled') &&
    showReminder &&
    Boolean(!JSON.parse(sessionStorage.getItem('hideTripReminder')));

  const handleDiscardReminder = () => {
    sessionStorage.setItem('hideTripReminder', '1');
    setShowReminder(false);
  };

  if (shouldShowReminder) {
    return <TripReminderGraphqlContainer onDiscardReminder={handleDiscardReminder} />;
  }
  return null;
};

export default TripReminderContainer;
