import PropTypes from 'prop-types';
import { Button, breakpoints } from '@etg/wings';
import { css } from '@eti/styles';
import { Plus } from '@phosphor-icons/react';
import { useTranslation } from '@eti/providers';

const buttonStyles = css`
  width: 100%;

  @media (min-width: ${breakpoints._768}) {
    width: fit-content;
  }
`;

const AddBoundButton = ({ onAdd }) => {
  const { t } = useTranslation();
  const handleClick = () => onAdd();

  return (
    <Button
      className={buttonStyles}
      data-testid="searchForm-multiStop-addTrip-button"
      iconPrefix={<Plus />}
      onClick={handleClick}
      size="small"
    >
      {t('Air.Search.MultiBound.AddBoundButton.Text')}
    </Button>
  );
};

AddBoundButton.propTypes = {
  onAdd: PropTypes.func,
};

export default AddBoundButton;
