/* stylelint-disable value-list-comma-newline-after */
import { css } from '@eti/styles';
export const buttonStyles = css`
  align-items: center;
  background-color: #307be8;
  border: 0;
  border-radius: 3px;
  box-shadow:
    rgb(49 49 93 / 3%) 0 0 0 0.5px,
    rgb(49 49 93 / 10%) 0 2px 5px 0,
    rgb(0 0 0 / 8%) 0 1px 2px 0;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica', 'Arial', sans-serif;
  letter-spacing: 1px;
  margin: auto;
  padding: 10px 12px;
  text-transform: uppercase;

  &:active {
    background-color: #286fd6;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  html[dir='rtl'] & {
    flex-direction: row-reverse;
  }
`;
export default buttonStyles;