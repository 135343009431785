import { getPrice, getSelected, getGraphQlSelection } from '../single-choice/utils/selection';
import { TRAVEL_DOCS_POST } from '../products';
import TravelDocsPost from './components/TravelDocsPost';
import getIcon from './utils/icon';
const formSection = TRAVEL_DOCS_POST.name;
export const travelDocsPost = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  ...TRAVEL_DOCS_POST
};
export default TravelDocsPost;