const defaultBrowserName = userAgent => {
  if (userAgent.lastIndexOf(' ') + 1 >= userAgent.lastIndexOf('/')) {
    return null;
  }
  const nameOffset = userAgent.lastIndexOf(' ') + 1;
  const versionOffset = userAgent.lastIndexOf('/');
  const browserName = userAgent.substring(nameOffset, versionOffset);
  return browserName.toLowerCase() !== browserName.toUpperCase() ? browserName : navigator.appName;
};
const userBrowserName = () => {
  const {
    userAgent
  } = navigator;
  switch (true) {
    case userAgent.indexOf('Edge') !== -1:
    case userAgent.indexOf('Edg') !== -1:
      return 'Edge';
    case userAgent.indexOf('MSIE') !== -1:
      return 'Internet Explorer';
    case userAgent.indexOf('Opera') !== -1:
    case userAgent.indexOf('OPR') !== -1:
      return 'Opera';
    case userAgent.indexOf('Chrome') !== -1:
      return 'Chrome';
    case userAgent.indexOf('Firefox') !== -1:
      return 'Firefox';
    case userAgent.indexOf('Safari') !== -1:
      return 'Safari';
    default:
      return defaultBrowserName(userAgent);
  }
};
export default userBrowserName;