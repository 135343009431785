export const AGENCY_FORM_SECTION = 'agencyInformation' as const;
export const CONTACT_INFO_FORM_SECTION = 'contactInformation' as const;

export const agencyFields = {
  NAME: 'name',
  OFFICE_ID: 'officeId',
  AGENT_FIRST_NAME: 'agentFirstName',
  AGENT_LAST_NAME: 'agentLastName',
  AGENT_EMAIL: 'agentEmail',
  AGENT_PHONE: 'agentPhone',
  AGENT_PHONE_COUNTRY_ID: 'agentPhoneCountryId',
} as const;

export const contactFields = {
  CONTACT_TYPE: 'contactType',
  ARRANGER_FIRST_NAME: 'arrangerFirstName',
  ARRANGER_LAST_NAME: 'arrangerLastName',
  EMAIL: 'email',
  EMAIL_CONFIRM: 'emailConfirm',
  EMERGENCY_LAST_NAME: 'emergencyLastName',
  EMERGENCY_PHONE: 'emergencyPhone',
  EMERGENCY_PHONE_COUNTRY_ID: 'emergencyPhoneCountryId',
  NEWSLETTER: 'newsletter',
  PHONE: 'phone',
  PHONE_COUNTRY_ID: 'phoneCountryId',
} as const;

export const contactTypes = {
  ARRANGER: 'arranger',
  TRAVELER: 'traveler',
} as const;

export const fieldRestrictions = {
  MAX_CHARS: 50,
  MAX_CHARS_LAST_NAME: 40,
} as const;
