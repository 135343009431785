import { COLORS } from '../../common/utils/icon/constants';
import { getIcon } from '../../common/utils/icon/icon';
const getStyles = notNeutral => `
  height: auto;
  width: ${notNeutral ? '63%' : '100%'};
`;
const configuration = {
  colors: [COLORS.blue, COLORS.white],
  getStyles,
  id: 'seat-class'
};
export default type => getIcon({
  ...configuration,
  type
});