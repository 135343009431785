import { getTravelerName } from '../../common/utils/travelerNames';
const getTravelerInfo = (travelers, travelerNames, defaultTravelerLabel) => (travelerId, index) => {
  const {
    ageType,
    name
  } = travelers.find(_ref => {
    let {
      id
    } = _ref;
    return id === travelerId;
  });
  return {
    ageType,
    name: name || getTravelerName(travelerId, travelerNames, defaultTravelerLabel, index + 1)
  };
};
export const getOptions = _ref2 => {
  let {
    id,
    order = {},
    travelerNames,
    defaultTravelerLabel
  } = _ref2;
  const {
    availableExtraProducts,
    sellSpecification,
    travelers
  } = order;
  const travelerInfoFor = getTravelerInfo(travelers, travelerNames, defaultTravelerLabel);
  if (travelers) {
    if (availableExtraProducts) {
      const product = availableExtraProducts.find(availableProduct => availableProduct.id === id);
      if (!product) {
        return [];
      }
      return product.sellSpecification.sellPriceTravelers.map((_ref3, index) => {
        let {
          travelerId,
          price
        } = _ref3;
        return {
          id: travelerId,
          priceRaw: price.price.value,
          ...travelerInfoFor(travelerId, index)
        };
      });
    }
    if (sellSpecification) {
      return sellSpecification.sellPriceTravelers.map((_ref4, index) => {
        let {
          price,
          travelerId
        } = _ref4;
        return {
          id: travelerId,
          priceRaw: price.price.value,
          ...travelerInfoFor(travelerId, index)
        };
      });
    }
  }
  return [];
};