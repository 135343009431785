import { analytics, date as dateUtil } from '@eti/utils';
import uniq from 'lodash/uniq';
import { useSiteContext } from '@eti/providers';
import minBy from 'lodash/minBy';
import sumBy from 'lodash/sumBy';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getAgesOfChildren, getAgesOfInfants } from '../../utils/format';
import { VIRTUAL_INTERLINING } from '../../constants/tripCharacteristics';
import { routes } from '../../constants/routesAndApisConstants';
import { RESPONSIVE_PAGEVIEW_DETAILS } from '../../constants/dataLayerEventsConstants';

export const getPageTypeDetails = (pathname) => {
  const routeName =
    pathname &&
    Object.entries(routes).find((route) => route[1] === pathname.replace('/rf', ''))?.[0];

  return (
    RESPONSIVE_PAGEVIEW_DETAILS[routeName] || {
      page_type: 'Other',
      page_type_details: 'Other',
    }
  );
};

export const getResponsivePageViewEvent = (pathname, pageType, pageTypeDetailed, pageLocation) => {
  const pageTypeDetails = getPageTypeDetails(pathname);
  const referrer = sessionStorage.getItem('documentReferrer') || document.referrer;
  sessionStorage.setItem('documentReferrer', window.location.href);
  return {
    event: 'responsive_pageview',
    responsive_url: pathname || window.location.pathname,
    page_location: pageLocation || window.location.href,
    page_title: document.title,
    page_type: pageType || pageTypeDetails.page_type,
    page_type_detailed: pageTypeDetailed || pageTypeDetails.page_type_details,
    page_referrer: referrer,
    scenarios: window?.spa_variables?.scenarios ?? [],
  };
};
export const usePageViewDataLayerEvent = (dataLayerPathname) => {
  const { pathname } = useLocation();

  const responsiveUrl = dataLayerPathname || pathname;

  useEffect(() => {
    setTimeout(
      () => analytics.pushEntryToDataLayer(getResponsivePageViewEvent(responsiveUrl)),
      2000,
    );
  }, [responsiveUrl]);
};

export const useSearchValuesToDataLayerEffect = (currentSearchData) => {
  useEffect(() => {
    if (currentSearchData?.routes) {
      const firstRoute = currentSearchData.routes[0];
      const departureDate = currentSearchData.date.departure;
      const returnDate = currentSearchData.date.return;

      analytics.pushEntryToDataLayer(
        {
          event: 'searchEvent',
          childrenAges: currentSearchData.travelers.childAges,
          city: firstRoute.destinationCity,
          cityCode: firstRoute.destinationCityCode,
          continent: firstRoute.destinationContinent,
          continentCode: firstRoute.destinationContinentCode,
          country: firstRoute.destinationCountry,
          destination: firstRoute.destinationName,
          destinationCountry: firstRoute.destinationCountry,
          destinationCountryCode: firstRoute.destinationCountryCode,
          nrOfAdults: currentSearchData.travelers.adults,
          nrOfChildren: getAgesOfChildren(currentSearchData.travelers.childAges).length,
          nrOfInfants: getAgesOfInfants(currentSearchData.travelers.childAges).length,
          origin: firstRoute.originName,
          originCity: firstRoute.originCity,
          originCityCode: firstRoute.originCityCode,
          originCountry: firstRoute.originCountry,
          originCountryCode: firstRoute.originCountryCode,
          originContinent: firstRoute.originContinent,
          originContinentCode: firstRoute.originContinentCode,
          startDate: departureDate,
          startDateDay: dateUtil.format(departureDate, 'dd'),
          startDateMonth: dateUtil.format(departureDate, 'MM'),
          startDateYear: dateUtil.format(departureDate, 'yyyy'),
          startDateYearMonth: dateUtil.format(departureDate, 'yyyy-MM'),
          startDay: dateUtil.format(departureDate, 'iii'),
          endDate: returnDate,
          endDateDay: dateUtil.format(returnDate, 'dd'),
          endDateMonth: dateUtil.format(returnDate, 'MM'),
          endDateYear: dateUtil.format(returnDate, 'yyyy'),
          endDateYearMonth: dateUtil.format(returnDate, 'yyyy-MM'),
          endDay: dateUtil.format(returnDate, 'iii'),
        },
        false,
      );
    }
  }, [currentSearchData]);
};

const tripSegmentsFilter = (segment) => segment.__typename === 'TripSegment';

const getAirportCodes = (bounds) => {
  const tripSegmentsOnFirstBound = bounds[0].segments.filter(tripSegmentsFilter);
  const { origin } = tripSegmentsOnFirstBound[0];
  const { destination } = tripSegmentsOnFirstBound[tripSegmentsOnFirstBound.length - 1];

  const originAirportCode = origin.isAirport ? origin.code : null;
  const destinationAirportCode = destination.isAirport ? destination.code : null;

  return { originAirportCode, destinationAirportCode };
};

export const useSelectedTripToDataLayerEffect = (selectedTrip) => {
  useEffect(() => {
    if (selectedTrip) {
      const allTripSegments = selectedTrip.bounds.flatMap((bound) =>
        bound.segments.filter(tripSegmentsFilter),
      );
      const uniqueMarketingCarrierCodes = uniq(
        allTripSegments.map((segment) => segment.marketingCarrier.code),
      );
      const uniqueOperatingCarrierCodes = uniq(
        allTripSegments.map((segment) => segment.operatingCarrier.code),
      );
      const uniqueMarketingCarrierNames = uniq(
        allTripSegments.map((segment) => segment.marketingCarrier.name),
      );
      const uniqueTicketingCarrierCodes = uniq(
        allTripSegments.reduce((acc, segment) => {
          const ticketingCarrierCode = segment.ticketingCarrier?.code ?? null;
          return ticketingCarrierCode ? [...acc, ticketingCarrierCode] : acc;
        }, []),
      );
      const cabinClassNames = selectedTrip.bounds
        .flatMap((bound) => bound.segments.filter((segment) => segment.cabinClassName))
        .map((tripSegment) => tripSegment.cabinClassName);

      const { originAirportCode, destinationAirportCode } = getAirportCodes(selectedTrip.bounds);

      if (
        selectedTrip.tripCharacteristics &&
        selectedTrip.tripCharacteristics.includes(VIRTUAL_INTERLINING)
      ) {
        analytics.pushEntryToDataLayer({ event: 'VITrip' }, false);
      }

      analytics.pushEntryToDataLayer(
        {
          event: 'selectedTripEvent',
          cabinClass: cabinClassNames,
          airBoundXMarketingCarrier: uniqueMarketingCarrierCodes,
          airBoundXOperatingCarrier: uniqueOperatingCarrierCodes,
          airBoundXTicketingCarrier: uniqueTicketingCarrierCodes,
          carrier: uniqueMarketingCarrierNames,
          carrierCode: uniqueMarketingCarrierCodes,
          destinationAirportCode,
          originAirportCode,
        },
        false,
      );
    }
  }, [selectedTrip]);
};

export const useTravelerPricesToDataLayerEffect = (
  travelerPrices = [],
  paymentMethodPrices = [],
) => {
  const siteContext = useSiteContext();

  useEffect(() => {
    if (siteContext && travelerPrices.length > 0) {
      const totalPrice =
        sumBy(travelerPrices, 'price.price.value') / 10 ** siteContext.siteCurrency.exponent;

      analytics.pushEntryToDataLayer({
        event: 'priceEvent',
        price: totalPrice,
      });

      if (siteContext.partner && !analytics.keyExistsInDataLayer('priceSeen')) {
        analytics.pushEntryToDataLayer(
          {
            event: 'priceSeenEvent',
            priceSeen: totalPrice,
            priceSeenPaymentMethod: minBy(paymentMethodPrices, 'price.value')?.type ?? null,
          },
          false,
        );
      }
    }
  }, [travelerPrices, paymentMethodPrices, siteContext]);
};
