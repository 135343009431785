import { getTravelerName } from '../../common/utils/travelerNames';
export const getTravelers = function (order, travelerNames, travelerLabel, nationalities) {
  if (travelerNames === void 0) {
    travelerNames = [];
  }
  if (nationalities === void 0) {
    nationalities = [];
  }
  const travelers = order === null || order === void 0 ? void 0 : order.travelers;
  const acsendingAlpabetical = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };
  const nationalitiesCopy = [...nationalities];
  nationalitiesCopy.sort(acsendingAlpabetical);
  return travelers ? travelers.map(function (_temp, index) {
    let {
      ageType,
      id,
      name
    } = _temp === void 0 ? {} : _temp;
    return {
      ageType,
      id,
      name: name || getTravelerName(id, travelerNames, travelerLabel, index + 1),
      nationalities: nationalitiesCopy.reduce(function (acc, _temp2) {
        var _pricePerTraveler$pri, _pricePerTraveler$pri2;
        let {
          id: value,
          name: label,
          descriptions,
          pricePerTravelers
        } = _temp2 === void 0 ? {} : _temp2;
        const pricePerTraveler = pricePerTravelers.find(_ref => {
          let {
            travelerId
          } = _ref;
          return travelerId === id;
        });
        return [...acc, {
          id,
          value,
          label,
          priceRaw: (_pricePerTraveler$pri = pricePerTraveler === null || pricePerTraveler === void 0 || (_pricePerTraveler$pri2 = pricePerTraveler.price) === null || _pricePerTraveler$pri2 === void 0 || (_pricePerTraveler$pri2 = _pricePerTraveler$pri2.price) === null || _pricePerTraveler$pri2 === void 0 ? void 0 : _pricePerTraveler$pri2.value) !== null && _pricePerTraveler$pri !== void 0 ? _pricePerTraveler$pri : 0,
          descriptions
        }];
      }, [])
    };
  }) : [];
};
export const getSelection = (name, id, form, productItemMainId) => {
  var _form$name$value$valu, _form$name, _form$name$travelers, _form$name2;
  const isSelected = (_form$name$value$valu = (_form$name = form[name]) === null || _form$name === void 0 || (_form$name = _form$name.value) === null || _form$name === void 0 ? void 0 : _form$name.value) !== null && _form$name$value$valu !== void 0 ? _form$name$value$valu : false;
  if (!isSelected) {
    return undefined;
  }
  const travelers = (_form$name$travelers = (_form$name2 = form[name]) === null || _form$name2 === void 0 ? void 0 : _form$name2.travelers) !== null && _form$name$travelers !== void 0 ? _form$name$travelers : {};
  const travelerChoice = Object.values(travelers).reduce(function (acc, _temp3) {
    let {
      choice,
      selected
    } = _temp3 === void 0 ? {} : _temp3;
    return selected && Number(choice.value) ? [...acc, {
      choiceId: Number(choice.value),
      travelerId: Number(choice.id)
    }] : acc;
  }, []);
  return travelerChoice.length > 0 ? {
    productId: id,
    productItemMainId,
    selectionItem: {
      travelerChoice
    }
  } : undefined;
};
export const getPreselectedTravelerChoices = (travelers, nationalities, preselectedChoice) => {
  const travelerIds = travelers.map(traveler => traveler.id);
  const choice = nationalities.find(nationality => nationality.id === preselectedChoice);
  if (!choice) {
    return {};
  }
  const {
    pricePerTravelers,
    descriptions,
    name
  } = choice;
  return travelerIds.reduce((acc, travelerId) => {
    var _travelerPrice$price$, _travelerPrice$price;
    const travelerPrice = pricePerTravelers.find(pricePerTraveler => pricePerTraveler.travelerId === travelerId);
    return {
      ...acc,
      [`traveler-${travelerId}`]: {
        choice: {
          id: travelerId,
          value: preselectedChoice,
          label: name,
          priceRaw: (_travelerPrice$price$ = travelerPrice === null || travelerPrice === void 0 || (_travelerPrice$price = travelerPrice.price) === null || _travelerPrice$price === void 0 || (_travelerPrice$price = _travelerPrice$price.price) === null || _travelerPrice$price === void 0 ? void 0 : _travelerPrice$price.value) !== null && _travelerPrice$price$ !== void 0 ? _travelerPrice$price$ : 0,
          descriptions
        }
      }
    };
  }, {});
};