import { connect } from 'react-redux';
import BookableSeat from '../components/rows/BookableSeat';
import { getNameInitials } from '../utils/getNameInitials';
import { getSelected } from '../utils/selection';
const selected = function (seat, selectedSeats) {
  if (selectedSeats === void 0) {
    selectedSeats = [];
  }
  return selectedSeats.some(s => s.value === seat);
};
const selectedTraveler = function (seat, selectedSeats) {
  if (selectedSeats === void 0) {
    selectedSeats = [];
  }
  return selectedSeats.find(s => s.value === seat);
};
const mapStateToProps = (state, _ref) => {
  var _selectedTraveler, _selectedTraveler2;
  let {
    activeTraveler,
    activeSegment,
    form,
    seat
  } = _ref;
  const selectedSeats = Object.values(getSelected('seatMap', form)(state)(activeSegment));
  const isSelected = selected(seat, selectedSeats);
  const selectedTravelerName = isSelected ? (_selectedTraveler = selectedTraveler(seat, selectedSeats)) === null || _selectedTraveler === void 0 ? void 0 : _selectedTraveler.name : null;
  const selectedSeatInitials = selectedTravelerName ? getNameInitials(selectedTravelerName) : null;
  const isActive = isSelected ? ((_selectedTraveler2 = selectedTraveler(seat, selectedSeats)) === null || _selectedTraveler2 === void 0 ? void 0 : _selectedTraveler2.id) === activeTraveler : false;
  return {
    isSelected,
    selectedSeatInitials,
    isActive
  };
};
export default connect(mapStateToProps)(BookableSeat);