import { TravelerPrice } from '../../../pages/traveler-details/models';
import { getTravelerPricesTotal } from '../../../utils/travelers';
import { PRODUCT_CATEGORIES } from './constants/productCategories';
import { AIR } from './constants/mainProducts';
import { Bundles } from './models';

export const formatPrice = (exponent: number, price = 0) => price / 10 ** exponent;

export const getAirProduct = (travelerPrices: TravelerPrice[], currencyExponent: number) => {
  const airPrice = formatPrice(currencyExponent, getTravelerPricesTotal(travelerPrices));
  return {
    item_id: AIR.id,
    item_name: AIR.name,
    price: airPrice,
    item_category: PRODUCT_CATEGORIES.MAIN,
    quantity: 1,
  };
};

export const getBundleProducts = (bundles: Bundles, currencyExponent: number) => {
  if (!bundles?.length) {
    return [];
  }
  return bundles.map((bundle) => {
    return {
      item_id: Number(bundle.selectedOption),
      item_name: bundle.title,
      item_category: PRODUCT_CATEGORIES.EXTRA,
      price: formatPrice(currencyExponent, bundle.price),
      quantity: 1,
    };
  });
};
