import { vars } from '@etg/wings';
import { css, etiColors, mediaQueries } from '@eti/styles';
export const productWrapperStyles = upsellHighlight => css`
  background-color: ${etiColors.white};
  ${upsellHighlight && `border: 2px solid ${vars.colors.promotional.dark}`};
  border-radius: ${vars.card.borderRadius};
  font-size: 0.875rem;
  padding: 0;
  word-break: break-word;
`;
export const standardGrid = css`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 3fr 7fr;
  grid-template-rows: repeat(4, auto);
  padding: 16px;

  .illustration {
    grid-area: 1 / 1 / 5 / 2;
  }

  .header {
    grid-area: 1 / 2 / 2 / 3;
  }

  .body {
    grid-area: 2 / 2 / 3 / 3;
  }

  .readMore {
    grid-area: 3 / 2 / 4 / 3;
  }

  .cta {
    grid-area: 4 / 2 / 5 / 3;
  }

  .children {
    display: contents;
  }

  @media ${mediaQueries.small.only} {
    grid-column-gap: 0;
    grid-template-columns: 0 1fr;
  }
`;