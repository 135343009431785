import { SeatPreferenceSelectionInput } from '@eti/schema-types';
import { SEATING_PREFERENCE_OPTIONS, SEATING_PREFERENCE_VALUES } from './constants';
export const getOptions = type => {
  const SeatingComboOptions = {
    seatingPreferenceOptions: [{
      id: 'SeatingPreference_id_option_1',
      label: 'Product.SeatMap.Segment.Unavailable.SeatingLight.AisleSeat',
      value: SeatPreferenceSelectionInput.Aisle
    }, {
      id: 'SeatingPreference_id_option_2',
      label: 'Product.SeatMap.Segment.Unavailable.SeatingLight.WindowSeat',
      value: SeatPreferenceSelectionInput.Window
    }, {
      id: 'SeatingPreference_id_option_3',
      label: 'Product.SeatMap.Segment.Unavailable.SeatingLight.AnySeat',
      value: SEATING_PREFERENCE_VALUES.NO_OPTION
    }],
    seatingBesideOptions: [{
      id: 'SeatingBeside_id_option_1',
      label: 'Product.SeatMap.Segment.Unavailable.SeatingBeside.TryToSeatUsTogether',
      value: SeatPreferenceSelectionInput.Beside
    }, {
      id: 'SeatingBeside_id_option_2',
      label: 'Product.SeatMap.Segment.Unavailable.SeatingBeside.RequestAnySeatAvailable',
      value: SEATING_PREFERENCE_VALUES.NO_OPTION
    }]
  };
  if (type === SEATING_PREFERENCE_OPTIONS.SELL_SEATING_PREFERENCE) {
    return SeatingComboOptions.seatingPreferenceOptions;
  }
  return SeatingComboOptions.seatingBesideOptions;
};