import { css } from '@eti/styles';
import { breakpoints } from '@etg/wings';
import { useScript } from '../../common/authentication/custom-hooks/scripting';

const blindadoStyle = css`
  align-self: center;

  @media (min-width: ${breakpoints._560}) {
    align-self: flex-start;
  }
`;
const Blindado = () => {
  useScript('blindado', '//cdn.siteblindado.com/aw.js');

  return (
    <div className={blindadoStyle} id="armored_website">
      <param id="aw_preload" value="true" />
      <param id="aw_use_cdn" value="true" />
    </div>
  );
};

export default Blindado;
