import { constants } from '@eti/utils';
const {
  ADULT,
  CHILD,
  INFANT
} = constants.ageTypes;
export const getAgeTypeLabel = (ageType, adultLabel, childLabel, infantLabel) => {
  switch (ageType) {
    case ADULT:
      return adultLabel;
    case CHILD:
      return childLabel;
    case INFANT:
      return infantLabel;
    default:
      return adultLabel;
  }
};