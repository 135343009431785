import { getPriceDivided } from '../../../utils/price';
export const getPricePerBound = _ref => {
  let {
    numberOfBounds,
    priceRaw,
    showPricePerBound
  } = _ref;
  const price = showPricePerBound ? getPriceDivided(priceRaw, numberOfBounds) : priceRaw;
  if (!price) {
    return 0;
  }
  return price;
};
export const getMarkupPerBound = _ref2 => {
  let {
    markup,
    numberOfBounds,
    showPricePerBound
  } = _ref2;
  const markupPrice = showPricePerBound ? getPriceDivided(markup, numberOfBounds) : markup;
  if (!markupPrice) {
    return 0;
  }
  return markupPrice;
};