export enum RebookEligibilityType {
  CHANGE_BY_CUSTOMER = 'change_trip_yourself',
  CHANGE_BY_ETG = 'change_trip_yourself_no_price_review',
  CHANGE_BY_ETG_IF_POSSIBLE = 'change_trip_with_us',
  CHANGE_BY_AIRLINE = 'change_trip_with_airline',
  CHANGE_NOT_AVAILABLE = 'change_not_available',
  CHANGE_UNKNOWN = 'change_trip_unknown',
  CHANGE_PNR_WITH_ETG = 'change_this_pnr_with_us',
  CHANGE_FLEXIBLE_TICKET = 'change_flexible_ticket_trip',
  CHANGE_FLEXIBLE_TICKET_YOURSELF = 'change_trip_yourself_flexible_ticket_trip',
  CHANGE_SHORT_DEPARTURE = 'change_short_departure_trip',
  CHANGE_NOT_CONFIRMED = 'change_not_confirmed_trip',
  CHANGE_EXCHANGED_BY_AIRLINE = 'change_exchanged_by_airline',
  CHANGE_NOT_OPEN_TICKET = 'change_not_open_ticket',
  CHANGE_BY_CUSTOMER_ANCILLARIES = 'change_trip_yourself_ancillaries',
  CHANGE_BY_ETG_ANCILLARIES = 'change_trip_yourself_ancillaries_no_price_review',
}
