import { useEffect, useRef, useState } from 'react';
import { PIX_START_TIME } from '../../payment/payment-methods/pix/constants/pixConstants';

const getTimeLeft = (startTime: number) => {
  const diff = startTime - Math.round(new Date().getTime() / 1000);
  const secondsLeft = diff + 15 * 60;

  return secondsLeft > 0 ? secondsLeft : 0;
};

const getInitialTimeLeft = () => {
  const pixStartTime = sessionStorage.getItem(PIX_START_TIME);
  if (pixStartTime) {
    return getTimeLeft(Number(pixStartTime));
  }

  const currentTime = Math.round(new Date().getTime() / 1000);
  sessionStorage.setItem(PIX_START_TIME, `${currentTime}`);
  return getTimeLeft(currentTime);
};

const resetStartTime = () => {
  sessionStorage.removeItem(PIX_START_TIME);
};

export const useCountdown = () => {
  const [timeLeft, setTimeLeft] = useState(getInitialTimeLeft);
  const startTimeStamp = Number(sessionStorage.getItem(PIX_START_TIME));
  const intervalRef = useRef<number>();
  const isExpired = timeLeft < 1;

  useEffect(() => {
    if (!isExpired) {
      intervalRef.current = window.setInterval(() => {
        setTimeLeft(() => {
          return getTimeLeft(startTimeStamp);
        });
      }, 1000);
    }

    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
      }
    };
  }, [isExpired, startTimeStamp, timeLeft]);

  const formattedTimeLeft = `${Math.floor(timeLeft / 60)}m ${timeLeft % 60}s`;

  return {
    isExpired,
    formattedTimeLeft,
    resetStartTime,
  };
};
