const VALID_HEX = /^#?([0-9A-F]{3}){1,2}$/i;
export const hexToRgba = function (hex, alpha) {
  if (alpha === void 0) {
    alpha = 1;
  }
  if (!VALID_HEX.test(hex)) {
    return hex;
  }
  const rgb = hex.match(hex.length <= 4 ? /\w/g : /\w\w/g).map(x => parseInt(x.length < 2 ? `${x}${x}` : x, 16));
  const hasAllValues = rgb.every(Number.isInteger);
  if (hasAllValues) {
    const [r, g, b] = rgb;
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return hex;
};