import Media from 'react-media';
import { Inline, breakpoints } from '@etg/wings';
import { Checkbox } from '@eti/form';
import { useTranslation } from '@eti/providers';
import { css } from '@eti/styles';
import { DIRECT_FLIGHT } from '../constants/formFieldNames';

const labelStyles = css`
  cursor: pointer;
  font-size: 0.875rem;
`;

const DirectFlight = () => {
  const { t } = useTranslation();
  return (
    <Media query={`(min-width: ${breakpoints._768})`}>
      {(matches) => (
        <Inline align={matches ? 'start' : 'center'} alignY="center" noWrap spacing={8}>
          <Checkbox
            data-testid={`${DIRECT_FLIGHT}-input`}
            id="directFlightCheckbox"
            name={DIRECT_FLIGHT}
          />
          <label className={labelStyles} htmlFor="directFlightCheckbox">
            {t('Air.Search.DirectFlightsOnly')}
          </label>
        </Inline>
      )}
    </Media>
  );
};

export default DirectFlight;
