const QR_CODE_URL = 'qrCodeUrl';
const QR_CODE_DATA = 'qrCodeData';
const PAYMENT_TRANSACTION_ID = 'pti';
const PAYMENT_TRANSACTION_ID_KEY = 'paymentTransactionId';
const PIX_START_TIME = 'pix-start-time';
const PIX_PAYMENT_URL = 'pix-payment-url';
const PIX_REDIRECT_URL_KEY = 'redirectUrl';
const PIX_REDIRECT_URL = 'pix-redirect-url';

export {
  QR_CODE_DATA,
  QR_CODE_URL,
  PIX_PAYMENT_URL,
  PIX_REDIRECT_URL_KEY,
  PIX_START_TIME,
  PAYMENT_TRANSACTION_ID,
  PAYMENT_TRANSACTION_ID_KEY,
  PIX_REDIRECT_URL,
};
