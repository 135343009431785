import { createValidator } from 'revalidate';
import { THE_FIELD_HAS_NOT_THE_SAME_VALUE } from '../constants/defaultErrorMessages';
import { getFieldValue } from '../utils/utils';
const matchesFieldIfSelected = (otherField, selectedField) => createValidator(message => (value, allValues) => {
  const otherValue = getFieldValue(allValues, otherField);
  const isSelected = getFieldValue(allValues, selectedField);
  if (!value || !isSelected || !otherValue) {
    return null;
  }
  if (value !== otherValue) {
    return message || THE_FIELD_HAS_NOT_THE_SAME_VALUE;
  }
  return null;
}, THE_FIELD_HAS_NOT_THE_SAME_VALUE);
export default matchesFieldIfSelected;