import PropTypes from 'prop-types';
import { Stack } from '@etg/wings';
import { connect } from 'react-redux';
import { arrayPush, arrayRemove } from 'redux-form';
import { MULTI_BOUND } from '../constants/formFieldNames';
import { MAX_BOUNDS } from '../constants/multistop';
import AddBoundButton from './AddBoundButton';
import BoundHeader from './BoundHeader';
import Bound from './Bound';

const getKey = (index) => `bound-${index}`;

const Bounds = ({
  fields,
  form,
  languageCode,
  dateFormat,
  numberOfBounds,
  daysFromTodayForValidDepartureDate,
  onAdd,
  onLoadSearchResults,
  onRemove,
  selectedTripType,
}) => {
  const fieldsWithIds = fields.map((field, index) => ({ field, id: index }));
  const hasRemoveButtons = fieldsWithIds.length > 2;

  return (
    <>
      {fieldsWithIds.map((_, index) => (
        <Stack key={getKey(index)}>
          <BoundHeader boundIndex={index} onRemove={onRemove} />
          <Bound
            boundIndex={index}
            dateFormat={dateFormat}
            daysFromTodayForValidDepartureDate={daysFromTodayForValidDepartureDate}
            form={form}
            hasRemoveButtons={hasRemoveButtons}
            index={index}
            languageCode={languageCode}
            numberOfBounds={numberOfBounds}
            onLoadSearchResults={onLoadSearchResults}
            onRemove={onRemove}
            selectedTripType={selectedTripType}
          />
        </Stack>
      ))}
      {fields.length < MAX_BOUNDS && <AddBoundButton onAdd={onAdd} />}
    </>
  );
};

Bounds.propTypes = {
  dateFormat: PropTypes.string,
  daysFromTodayForValidDepartureDate: PropTypes.number,
  fields: PropTypes.instanceOf(Object),
  form: PropTypes.string,
  languageCode: PropTypes.string,
  numberOfBounds: PropTypes.number,
  onAdd: PropTypes.func,
  onLoadSearchResults: PropTypes.func,
  onRemove: PropTypes.func,
  selectedTripType: PropTypes.string,
};

const mapDispatchToProps = (dispatch, { form }) => ({
  onAdd: () => {
    dispatch(arrayPush(form, MULTI_BOUND, {}));
  },
  onRemove: (index) => {
    dispatch(arrayRemove(form, MULTI_BOUND, index));
  },
});

export default connect(null, mapDispatchToProps)(Bounds);
