import { composeValidators, isRequired, isValidEmail, isValidOrderNumber } from '@eti/validation';
import { MAIL, ORDER_NUMBER } from './constants';

export const validationRules = (
  { required: message, mail: mailMessage, orderNumber: orderNumberMessage },
  orderNumberMaxLength,
) => ({
  [MAIL]: composeValidators(isRequired({ message }), isValidEmail({ mailMessage }))(MAIL),
  [ORDER_NUMBER]: composeValidators(
    isRequired({ message }),
    isValidOrderNumber(orderNumberMaxLength)({ orderNumberMessage }),
  )(ORDER_NUMBER),
});
