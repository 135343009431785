import { useTranslation } from '@eti/providers';
import { Passengers } from '../models';

export const usePassengers = (passengers: Passengers) => {
  const { t } = useTranslation();
  const labels = [];
  const { adults, children, infants } = passengers;

  const adultsTextKey =
    adults > 1 ? 'Air.Search.NumberOfAdultsPlural' : 'Air.Search.NumberOfAdultsSingular';
  labels.push(t(adultsTextKey, adults));

  if (children > 0) {
    const childrenTextKey =
      children > 1 ? 'Air.Search.NumberOfChildrenPlural' : 'Air.Search.NumberOfChildrenSingular';
    labels.push(t(childrenTextKey, children));
  }

  if (infants > 0) {
    const infantsTextKey =
      infants > 1 ? 'Air.Search.NumberOfInfantsPlural' : 'Air.Search.NumberOfInfantsSingular';
    labels.push(t(infantsTextKey, infants));
  }

  return { label: labels.join(', ') };
};
