import { getPrice, getSelected, getGraphQlSelection } from '../single-choice/utils/selection';
import { ONLINE_CHECK_IN } from '../products';
import OnlineCheckIn from './components/OnlineCheckIn';
import getIcon from './utils/icon';
const formSection = ONLINE_CHECK_IN.name;
export const onlineCheckIn = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  ...ONLINE_CHECK_IN
};
export default OnlineCheckIn;