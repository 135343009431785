const cutAndTrimString = (str, maxLength) => {
  let allowedLength = maxLength;
  const trimmedString = str.trim();
  if (allowedLength > trimmedString.length) {
    allowedLength = trimmedString.length;
  }
  return trimmedString.slice(0, allowedLength).trim();
};
export const getFullName = (firstName, lastName, maxLength) => {
  const modifiedLastName = cutAndTrimString(lastName, maxLength - 2);
  let firstNameMaxLength = maxLength - modifiedLastName.length - 1;
  if (firstNameMaxLength > firstName.length) {
    firstNameMaxLength = firstName.length;
  }
  const modifiedFirstName = cutAndTrimString(firstName, firstNameMaxLength);
  return `${modifiedFirstName} ${modifiedLastName}`;
};
export const getTravelerInitials = (firstName, lastName) => {
  let initials = '';
  if (firstName && lastName) {
    initials = (firstName.trim().charAt(0) + lastName.trim().charAt(0)).toUpperCase();
  }
  return initials;
};