import { css, etiColors, mediaQueries } from '@eti/styles';
export const priceWrapperStyles = css`
  width: 100%;
`;
export const priceLabelStyles = css`
  color: ${etiColors.grayScale[10]};
  font-size: 0.875rem;
  margin-inline-start: auto;
  margin-top: auto;
`;
export const priceStyles = css`
  font-size: 1rem;
  font-weight: 700;
  margin-inline-start: auto;
`;
export const animationProps = isMobile => ({
  animate: {
    height: 'auto',
    opacity: 1
  },
  transition: {
    type: 'tween',
    duration: isMobile ? 0.2 : 0
  }
});
export const standardGrid = css`
  column-gap: 8px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(3, auto);

  @media ${mediaQueries.medium.up} {
    justify-content: end;
  }

  .priceLabel {
    grid-area: 2 / 1 / 3 / 2;

    @media ${mediaQueries.small.only} {
      grid-area: 1 / 2 / 2 / 3;
    }
  }

  .price {
    grid-area: 3 / 1 / 4 / 2;

    @media ${mediaQueries.small.only} {
      grid-area: 2 / 2 / 3 / 3;
      margin-bottom: 8px;
    }
  }

  .selection {
    grid-area: 1 / 2 / 2 / 3;
    margin-bottom: 8px;

    @media ${mediaQueries.small.only} {
      grid-area: 2 / 1 / 3 / 2;
      margin-bottom: 0;
    }
  }

  .buttonWrapper {
    grid-area: 2 / 2 / 4 / 3;

    @media ${mediaQueries.small.only} {
      grid-area: 3 / 1 / 4 / 3;
    }
  }

  .children {
    display: contents;
  }

  .validation {
    grid-area: 5 / 2 / 6 / 3;
    margin-top: 8px;

    @media ${mediaQueries.small.only} {
      grid-area: 4 / 1 / 5 / 3;
    }
  }
`;