import { formValueSelector } from 'redux-form';
import { getTotalMarkup, getTotalPrice } from '../price';
export const getMinPrice = function (travelers) {
  if (travelers === void 0) {
    travelers = [];
  }
  return travelers.reduce((minPrice, _ref) => {
    let {
      price
    } = _ref;
    if (!price || !Number.isFinite(price)) {
      return minPrice;
    }
    return minPrice ? Math.min(price, minPrice) : price;
  }, null);
};
export const getPrice = (name, form) => state => {
  const selection = formValueSelector(form)(state, name);
  if (selection && selection !== null && selection !== void 0 && selection.value && Array.isArray(selection.value)) {
    return selection.value.reduce((totalPrice, _ref2) => {
      let {
        priceRaw = 0
      } = _ref2;
      return totalPrice + priceRaw;
    }, 0);
  }
  return getTotalPrice(form, name)(state);
};
export const getMarkup = (name, form) => state => getTotalMarkup(form, name)(state);