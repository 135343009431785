import PropTypes from 'prop-types';
import { useProperty, useTranslation } from '@eti/providers';
import { Button } from '@etg/wings';
import { css, mediaQueries } from '@eti/styles';
import DirectFlight from './DirectFlight';

const buttonWrapperStyles = (isMultiStop) => css`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 22px;

  @media ${mediaQueries.medium.up} {
    flex-direction: column;
    margin: ${isMultiStop ? '22px 0 0 0' : '22px 0 0 24px'};

    html[dir='rtl'] & {
      margin: ${isMultiStop ? '22px 0 0 0' : '22px 24px 0 0'};
    }
  }
`;

const directFlightWrapperStyles = css`
  margin-bottom: 24px;
  margin-top: 0;

  @media ${mediaQueries.medium.up} {
    margin-bottom: 0;
    margin-top: 60px;
  }

  & > :first-child {
    justify-content: start;
    margin-top: 0;
  }
`;

const buttonStyles = css`
  width: 100%;

  @media ${mediaQueries.large.up} {
    min-width: 180px;
  }
`;

const SubmitButton = ({ isDatePassengerFieldsOpen, isMultiStop }) => {
  const { t } = useTranslation();
  const { p } = useProperty();
  const showDirectFlight =
    !p('SearchForm.ShowExtendedForm.Enabled') &&
    !isMultiStop &&
    isDatePassengerFieldsOpen &&
    !p('Searchform.Stopover.Enabled');

  return (
    <div className={buttonWrapperStyles(isMultiStop)}>
      <Button
        className={buttonStyles}
        data-testid="searchForm-searchFlights-button"
        type="submit"
        variant="primary"
      >
        {t('Button.Text.Search.Air')}
      </Button>
      {showDirectFlight && (
        <div className={directFlightWrapperStyles}>
          <DirectFlight />
        </div>
      )}
    </div>
  );
};

SubmitButton.propTypes = {
  isDatePassengerFieldsOpen: PropTypes.bool,
  isMultiStop: PropTypes.bool,
};

export default SubmitButton;
