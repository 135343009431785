import { useEffect } from 'react';

export const useScrollTop = (hasChanged?: boolean) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [hasChanged]);
};

export const ScrollToTop = ({ hasChanged }: { hasChanged?: boolean }) => {
  useScrollTop(hasChanged);
  return null;
};
