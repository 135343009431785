export const INPUT_MUST_BE_ALPHABETIC = 'Input must be alphabetic';
export const SAME_NAME_AS_ANOTHER_TRAVELER = 'Same name as another traveler';
export const INVALID_ADULT_BIRTH_DATE = 'Invalid date of birth for adult';
export const INVALID_CARD_NUMBER = 'Invalid card number';
export const INVALID_CHILD_BIRTH_DATE = 'Invalid date of birth for child';
export const INVALID_CVC = 'Invalid cvc';
export const INVALID_DATE_OF_BIRTH = 'Invalid date of birth';
export const INVALID_EXPIRE_YEAR = 'Invalid expire year';
export const INVALID_EXPIRY_DATE = 'Invalid expiry date';
export const INVALID_EMAIL_ADDRESS = 'Invalid email address';
export const INVALID_INFANT_BIRTH_DATE = 'Invalid date of birth for infant';
export const INVALID_MONTH = 'Invalid month';
export const INVALID_PHONE_NUMBER = 'Invalid phone number';
export const INVALID_TAX_ID = 'Invalid tax ID';
export const INVALID_ZIPCODE = 'Invalid Zipcode';
export const ORIGIN_SAME_AS_DESTINATION = 'Origin same as destination';
export const TERMS_AND_CONDITIONS_NOT_ACCEPTED = 'Terms & conditions not accepted';
export const THE_FIELD_HAS_THE_SAME_VALUE = 'The field has the same value';
export const THE_FIELD_HAS_NOT_THE_SAME_VALUE = 'The field does not have the same value';
export const INVALID_CHARACTER = 'One of the characters is not allowed';
export const INVALID_ORDER_NUMBER = 'Invalid order number';
export const INVALID_UPI_COLLECT_ID = 'Invalid UPI ID';