import { css, mediaQueries } from '@eti/styles';
export const pictogramStyles = url => css`
  background-image: url(${url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 2rem;
  margin-inline-end: 8px;
  margin-top: 2px;
  width: 2rem;
`;
export const standardGrid = css`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(2, auto);

  .upsell {
    grid-area: 1 / 1 / 2 / 3;
    margin-bottom: 8px;
  }

  .title {
    grid-area: 2 / 1 / 3 / 3;

    @media ${mediaQueries.small.only} {
      grid-area: 2 / 2 / 3 / 3;
    }
  }

  .children {
    display: contents;
  }

  .pictogram {
    display: none;

    @media ${mediaQueries.small.only} {
      display: grid;
      grid-area: 2 / 1 / 3 / 2;
    }
  }
`;
export const headerWrapperStyles = css`
  padding-inline-start: 0;
`;
export const titleStyles = css`
  align-items: center;
  color: #000;
  font-weight: 700;

  @media ${mediaQueries.medium.up} {
    font-size: 1.375rem;
  }
`;