import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const ImpressumPage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      image="cookies"
      pageTitle={t('PageTitle.rf.impressum.action')}
      textKey="Impressum.HtmlContent"
    />
  );
};

export default ImpressumPage;
