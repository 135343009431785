import { ReactNode, useState } from 'react';
import { SelectBox } from '@etg/wings';
import { capitalize } from '../../utils';

type Option = {
  label: string;
  value: string;
};

interface SelectBoxSelectionProps {
  'data-testid': string;
  defaultValue: string | string[];
  formatSelectedOption?: (arg: Option | Option[]) => ReactNode;
  isMultiple?: boolean;
  label: string;
  name: string;
  options: Option[];
  placeholder: string;
}

const SelectBoxSelection = ({
  'data-testid': dataTestId,
  defaultValue,
  formatSelectedOption,
  isMultiple,
  label,
  name,
  options,
  placeholder,
}: SelectBoxSelectionProps) => {
  const [selectedOption, setSelectedOption] = useState(() => {
    const storedValue = sessionStorage.getItem(`mocked${capitalize(name)}`);

    if (!storedValue) {
      return defaultValue;
    }
    const isMultipleValues = (storedValues: string) =>
      storedValues.length > 2 && storedValues.includes('[');
    const getOption = (value: string) => options.find((option) => option.value === value);

    if (isMultipleValues(storedValue)) {
      return storedValue
        .replace(/([[\]])+/g, '')
        .split(':')
        .reduce((acc: string[], value) => {
          const currentOption = getOption(value);
          if (currentOption) {
            acc.push(currentOption.value);
          }
          return acc;
        }, []);
    }

    const currentOption = getOption(storedValue);
    return currentOption ? currentOption.value : defaultValue;
  });

  const handleSelectedOption = (option: Option | string[] | Option[]) => {
    if (Array.isArray(option)) {
      setSelectedOption(option.map((o) => (typeof o === 'string' ? o : o.value)));
    } else {
      setSelectedOption(option.value);
    }
  };

  return (
    <SelectBox
      data-testid={dataTestId}
      formatSelectedOption={formatSelectedOption}
      isMultiple={isMultiple}
      label={label}
      name={name}
      onChange={handleSelectedOption}
      options={options}
      placeholder={placeholder}
      value={selectedOption}
    />
  );
};

export default SelectBoxSelection;
