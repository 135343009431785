import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const PrivacyPolicyExternalPage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      image="cookies"
      pageTitle={t('PageTitle.rf.privacy-policy-external.action')}
      textKey="PrivacyPolicyExternal.HtmlContent"
    />
  );
};

export default PrivacyPolicyExternalPage;
