import PropTypes from 'prop-types';
import { Button, Card, Heading, Inline, Stack, breakpoints } from '@etg/wings';
import { ContentWrapper, Panel } from '@eti/components';
import { css, cx, mediaQueries } from '@eti/styles';

const cardStyles = css`
  @media ${mediaQueries.large.up} {
    max-width: 630px;
    min-width: 420px;
  }
`;

const wrapperStyles = css`
  height: 100%;
`;

const paddingStyles = css`
  padding: 100px 0;
`;

const StatusPage = ({ backgroundImage, links = [], title, message }) => {
  return (
    <Panel
      backgroundImage={backgroundImage}
      className={wrapperStyles}
      defaultBackgroundImage="error"
    >
      <ContentWrapper className={wrapperStyles}>
        <Inline align="center" alignY="center" className={cx(paddingStyles, wrapperStyles)}>
          <Card
            className={cardStyles}
            padding={{ [breakpoints._0]: 16, [breakpoints._768]: 32, [breakpoints._1024]: 40 }}
          >
            <Stack align="center" spacing={24}>
              <Stack align="center" spacing={8}>
                <Heading level={1}>{title}</Heading>
                <div>{message}</div>
              </Stack>
              <Inline collapseBelow={breakpoints._1024} spacing={16}>
                {links.map(({ href, label }) => (
                  <Button as="a" href={href} key={href}>
                    {label}
                  </Button>
                ))}
              </Inline>
            </Stack>
          </Card>
        </Inline>
      </ContentWrapper>
    </Panel>
  );
};

StatusPage.propTypes = {
  backgroundImage: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  message: PropTypes.string,
  title: PropTypes.string,
};

export default StatusPage;
