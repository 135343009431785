import * as customEventConstants from '../constants/customEventConstants';

type CustomEventConstantsType = (typeof customEventConstants)[keyof typeof customEventConstants];

function subscribe<T extends (...args: any) => any>(
  eventName: CustomEventConstantsType,
  listener: T,
): void {
  document.addEventListener(eventName, listener);
}

function unsubscribe<T extends (...args: any) => any>(
  eventName: CustomEventConstantsType,
  listener: T,
): void {
  document.removeEventListener(eventName, listener);
}

function publish<T>(eventName: CustomEventConstantsType, data: T): void {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

export { publish, subscribe, unsubscribe };
