const getConfigFromSessionStorage = () => {
  const storedValues = JSON.parse(sessionStorage.getItem('debugTextResolver'));
  const alwaysUseKeys = storedValues?.alwaysUseKeys ?? false;
  const useKeyWhenTextNotFound = storedValues?.useKeyWhenTextNotFound ?? false;
  const warnNotFoundKeys = storedValues?.warnNotFoundKeys ?? false;
  const useKeyWhenTranslationNotFound = useKeyWhenTextNotFound;
  const warnWhenTranslationNotFound = warnNotFoundKeys;
  return {
    alwaysUseKeys,
    useKeyWhenTextNotFound,
    warnNotFoundKeys,
    useKeyWhenTranslationNotFound,
    warnWhenTranslationNotFound,
  };
};

export const generateTextResolverConfig = (debugEnabled) => ({
  ...(debugEnabled && getConfigFromSessionStorage()),
});
