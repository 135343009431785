import { useEffect, useState } from 'react';
import { constants } from '@eti/utils';
import { AgeType } from '@eti/schema-types';
import { getTravelersPrimaryFlow } from './CheckInBaggageUtil';
export const useSecondBaggage = _ref => {
  let {
    travelerNumber,
    orderSecondBag
  } = _ref;
  const [secondBaggageData, setSecondBaggageData] = useState({
    secondBagTraveler: {
      ageType: AgeType.Adt,
      price: 0,
      productPreSelection: '',
      markup: 0,
      value: false,
      id: ''
    },
    weightOfSecondBag: 0,
    piecesOfSecondBag: 0,
    weightUnitOfSecondBag: '',
    secondBagTravelers: []
  });
  useEffect(() => {
    var _orderSecondBag$sellS, _orderSecondBag$sellS2;
    const secondBagTravelers = getTravelersPrimaryFlow(orderSecondBag);
    const travelersWithoutInfantsSecondBag = secondBagTravelers === null || secondBagTravelers === void 0 ? void 0 : secondBagTravelers.filter(trav => trav.ageType !== constants.ageTypes.INFANT);
    const secondBagTraveler = travelersWithoutInfantsSecondBag && travelersWithoutInfantsSecondBag[travelerNumber];
    const {
      maxWeight: weightOfSecondBag = 0,
      numberOfUnits: piecesOfSecondBag = 0,
      weightUnit: weightUnitOfSecondBag = ''
    } = (_orderSecondBag$sellS = orderSecondBag === null || orderSecondBag === void 0 || (_orderSecondBag$sellS2 = orderSecondBag.sellSpecification) === null || _orderSecondBag$sellS2 === void 0 || (_orderSecondBag$sellS2 = _orderSecondBag$sellS2.sellPriceBaggage) === null || _orderSecondBag$sellS2 === void 0 ? void 0 : _orderSecondBag$sellS2[0]) !== null && _orderSecondBag$sellS !== void 0 ? _orderSecondBag$sellS : {};
    setSecondBaggageData({
      secondBagTraveler,
      weightOfSecondBag,
      piecesOfSecondBag,
      weightUnitOfSecondBag,
      secondBagTravelers: travelersWithoutInfantsSecondBag
    });
  }, [travelerNumber, orderSecondBag]);
  return secondBaggageData;
};