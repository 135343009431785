const FLIGHT = 0;
const PASSENGER = 1;
const PRODUCT = 2;
const REFERRER = 3;
const SITE = 4;
const USER = 5;
export const snowplowContextIndexes = {
  FLIGHT,
  PASSENGER,
  PRODUCT,
  SITE,
  USER,
  REFERRER
};