import { DirectionSwitchButton } from '@eti/debug';
import { routes } from '../../../constants/routesAndApisConstants';
import DebugAccessibilityReview from './DebugAccessibilityReview';
import DebugTexts from './DebugTexts';
import DebugSiteProperties from './DebugSiteProperties';
import DebugThemeSelect from './DebugThemeSelect';
import DebugOrderDetailPage from './DebugOrderDetailPage';

const DebugCommonComponents = () => {
  const isOrderDetailsPage = window.location.pathname.includes(routes.ORDER_DETAILS);

  return (
    <>
      <DirectionSwitchButton />
      <DebugTexts />
      <DebugSiteProperties />
      {isOrderDetailsPage && <DebugOrderDetailPage />}
      <DebugAccessibilityReview />
      <DebugThemeSelect />
    </>
  );
};

export default DebugCommonComponents;
