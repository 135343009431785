import formFieldNames from '../constants/formFieldNames';
import passengers from '../constants/passengers';
import {
  getCabinClass,
  getDirectFlight,
  getMultiBound,
  getPassengers,
  getSingleBound,
  getTripType,
} from './options';

export const searchForm = {
  formFieldNames,
  getCabinClass,
  getDirectFlight,
  getMultiBound,
  getPassengers,
  getSingleBound,
  getTripType,
  ...passengers,
};
