import { lazy } from 'react';
import { AllDataTypes } from './ContentfulTypes';

const ContentfulComponents = ({ block }: { block: AllDataTypes }) => {
  const { __typename } = block;

  try {
    const Component = lazy(() => import(`./${__typename}`));
    return <Component data={block} />;
  } catch {
    return null;
  }
};
export default ContentfulComponents;
