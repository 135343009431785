import PropTypes from 'prop-types';
import { Input, Stack } from '@etg/wings';
import { useTranslation } from '@eti/providers';

const TextInput = ({
  autoCapitalize = 'off',
  autoCorrect = 'off',
  'data-testid': dataTestId,
  errors,
  isRequired,
  label,
  register,
  spellCheck = 'false',
}) => {
  const { nonDebugT } = useTranslation();
  const { name } = register;
  const { [name]: error } = errors;
  const errorId = `${name}-error`;

  return (
    <Stack spacing={4}>
      <Input.Label htmlFor={name}>
        {isRequired ? nonDebugT('Input.Field.Required', label) : label}
      </Input.Label>
      <Input
        autoCapitalize={autoCapitalize}
        autoCorrect={autoCorrect}
        data-testid={dataTestId}
        errorId={errorId}
        hasError={Boolean(error)}
        id={name}
        spellCheck={spellCheck}
        {...register}
      />
      <Input.Meta error={error?.message} errorId={errorId} hasError={Boolean(error)} />
    </Stack>
  );
};

TextInput.propTypes = {
  autoCapitalize: PropTypes.string,
  autoCorrect: PropTypes.string,
  'data-testid': PropTypes.string,
  errors: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  register: PropTypes.object,
  spellCheck: PropTypes.string,
};

export default TextInput;
