import { cabinBaggage, checkInBaggage } from '../../../../products';
const getCheckedBaggageDetails = checkedBaggageSummary => {
  const {
    tripSummaries
  } = checkedBaggageSummary;
  const {
    baggageDetails
  } = tripSummaries === null || tripSummaries === void 0 ? void 0 : tripSummaries[0];
  return baggageDetails;
};
const getCabinBaggageDetails = cabinBaggageTripSummary => {
  const {
    cabinBaggageTravelerDetails
  } = cabinBaggageTripSummary;
  const {
    cabinBaggageDetails
  } = cabinBaggageTravelerDetails === null || cabinBaggageTravelerDetails === void 0 ? void 0 : cabinBaggageTravelerDetails[0];
  return cabinBaggageDetails;
};
export const getIncludedBaggageSummary = (id, cabinBaggageTripSummary, checkedBaggageSummary) => {
  let hasBaggageDetails = null;
  switch (id) {
    case checkInBaggage.id:
      hasBaggageDetails = checkedBaggageSummary === null || checkedBaggageSummary === void 0 ? void 0 : checkedBaggageSummary.tripSummaries.some(traveler => traveler.baggageStatus === 'INCLUDED_CHECKED_BAGGAGE');
      return hasBaggageDetails ? getCheckedBaggageDetails(checkedBaggageSummary) : null;
    case cabinBaggage.id:
      hasBaggageDetails = cabinBaggageTripSummary === null || cabinBaggageTripSummary === void 0 ? void 0 : cabinBaggageTripSummary.cabinBaggageTravelerDetails.some(traveler => traveler.cabinBaggageStatus === 'INCLUDED_CABIN_BAGGAGE');
      return hasBaggageDetails ? getCabinBaggageDetails(cabinBaggageTripSummary) : null;
    default:
      return null;
  }
};
export const isProductAvailable = (id, isCheckedBaggageAvailableOrIncluded, isCabinBaggageAvailableOrIncluded) => {
  switch (id) {
    case checkInBaggage.id:
      return isCheckedBaggageAvailableOrIncluded;
    case cabinBaggage.id:
      return isCabinBaggageAvailableOrIncluded;
    default:
      return null;
  }
};