import { gql } from '@eti/schema-types';

export const TRIP_REMINDER_QUERY = gql(/* GraphQL */ `
  query getTripReminderInfo {
    cart {
      price {
        markup {
          value
        }
        price {
          value
        }
        vat {
          value
        }
      }
      trips {
        bounds {
          segments {
            ... on TripSegment {
              departuredAt
              destination {
                cityName
              }
              origin {
                cityName
              }
            }
          }
        }
        travelers {
          ageType
          id
        }
        travelerPrices {
          id
          price {
            markup {
              value
            }
            price {
              value
            }
            vat {
              value
            }
          }
          taxesAndFees {
            category
            taxes {
              code
              title
              amount {
                value
              }
            }
          }
          travelerId
        }
        type
      }
    }
    selectedTrip {
      bounds {
        segments {
          ... on TripSegment {
            departuredAt
            destination {
              cityName
            }
            origin {
              cityName
            }
          }
        }
      }
      travelers {
        ageType
        id
      }
      travelerPrices {
        id
        price {
          markup {
            value
          }
          price {
            value
          }
          vat {
            value
          }
        }
        taxesAndFees {
          category
          taxes {
            code
            title
            amount {
              value
            }
          }
        }
        travelerId
      }
      type
    }
  }
`);
