import { date as dateUtil, string as stringUtil } from '@eti/utils';
import isPlainObj from 'is-plain-obj';
import isFunction from 'lodash/isFunction';
import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import humanizeDuration from 'humanize-duration';
import isEmpty from 'lodash/isEmpty';
import { getStopoverType } from '../../../utils/format';
import { getAgeTypeLabelForQuantity } from '../../../utils/travelers';
import {
  CHANGE_OF_AIRPORT,
  OVERNIGHT_STAY,
  SELF_TRANSFER,
  STOP,
} from '../../../constants/eventSegmentTypes';
import {
  AIR_EQUIPMENT_CODE,
  ALL,
  NONE,
  SOME,
  TRAIN_EQUIPMENT_CODE,
} from '../../../constants/transportationMeansConstants';
import { RETURN } from '../../../constants/tripTypesConstants';

const outBoundTitle = 'TripDetails.OutBoundTitle';
const inBoundTitle = 'TripDetails.InBoundTitle';
const multiStopBoundTitle = 'TripDetails.MultiStopBoundTitle';
const isArrayLength = (input) => Array.isArray(input) && input.length > 0;

export const getBoundTitle = (type, isMultiStop) => {
  if (isMultiStop) {
    return multiStopBoundTitle;
  }
  return type === 'inBound' ? inBoundTitle : outBoundTitle;
};

export const getBoundDate = (segments, format = 'EEEE d MMM') =>
  isArrayLength(segments) && dateUtil.format(segments[0].departuredAt, format);

export const getTitle = (outBound, inBound) =>
  isPlainObj(outBound) &&
  isArrayLength(outBound.segments) && {
    originCity: outBound.segments[0].origin.cityName,
    destinationCity:
      isPlainObj(inBound) && isArrayLength(inBound.segments)
        ? inBound.segments[0].origin.cityName
        : outBound.segments[outBound.segments.length - 1].destination.cityName,
  };

export const getDate = (outBound, inBound) =>
  isPlainObj(outBound) &&
  isArrayLength(outBound.segments) && {
    departureDate: outBound.segments[0].departuredAt,
    returnDate:
      isPlainObj(inBound) && isArrayLength(inBound.segments)
        ? inBound.segments[0].departuredAt
        : outBound.segments[outBound.segments.length - 1].arrivedAt,
  };

export const getLocalizedDuration = (
  duration,
  language = 'en',
  timeFormat,
  units = ['h', 'm', 's'],
  round = false,
  delimiter = ' ',
  spacer = '',
) => {
  const opts = {
    language,
    units,
    delimiter,
    spacer,
    languages: {
      [language]: {
        d: () => timeFormat?.day || 'days',
        h: () => timeFormat?.hour || 'h',
        m: () => timeFormat?.minute || 'min',
        s: () => timeFormat?.second || 's',
      },
    },
    round,
  };
  return humanizeDuration(duration, opts);
};

export const getBoundType = (index) => (index === 0 ? 'outBound' : 'inBound');

export const getBoundAsSegment = (
  segments = [],
  boundNumber,
  boundIncludedCheckedBaggage,
  boundIncludedCabinBaggage,
) => {
  if (!segments || !segments.length) {
    return null;
  }

  const isSelfTransfer = segments
    .filter((segment) => segment.__typename === 'EventSegment')
    .map((segment) => segment.types?.some((type) => type.type === 'SELF_TRANSFER'))
    .includes(true);

  const stopoverType = getStopoverType(segments);

  return {
    ...segments[0],
    arrivedAt: segments[segments.length - 1].arrivedAt,
    destination: segments[segments.length - 1].destination,
    isSelfTransfer,
    stopoverType,
    segmentId: `${boundNumber}_0-${segments.length - 1}`,
    stops: segments.filter((segment) => segment.__typename === 'EventSegment').length,
    duration: segments.reduce(
      (acc, { duration }) => (duration && !Number.isNaN(duration) ? acc + duration : acc),
      0,
    ),
    boundIncludedCabinBaggage,
    boundIncludedCheckedBaggage,
  };
};

export const getNumberOfTravelers = (travelers = []) =>
  travelers.reduce(
    (acc, traveler) => ({
      ...acc,
      [traveler.ageType]: acc[traveler.ageType] + 1,
    }),
    {
      ADT: 0,
      CNN: 0,
      INF: 0,
    },
  );

export const formatDate = (value, pattern) => (value ? dateUtil.format(value, pattern) : '');

export const resolveTripBounds = (bounds = [], tripType) => {
  if (!tripType || isEmpty(bounds)) {
    return [];
  }
  const transformBounds = [...bounds];
  const result = [];

  if (tripType === RETURN) {
    transformBounds.pop();
  }

  transformBounds.forEach((bound) => {
    const firstSegment = bound.segments[0];
    const lastSegment = bound.segments[bound.segments.length - 1];
    result.push({
      origin: `${firstSegment.origin.cityCode} ${firstSegment.origin.cityName}`,
      destination: `${lastSegment.destination.cityCode} ${lastSegment.destination.cityName}`,
    });
  });

  return result;
};

export const resolveTripDates = (bounds = []) => {
  if (isEmpty(bounds) || isEmpty(bounds[0].segments)) {
    return {};
  }

  const firstBound = bounds[0];
  const lastBound = bounds[bounds.length - 1];
  const lastSegmentIndex = lastBound.segments.length - 1;

  const departureDate = formatDate(firstBound.segments[0].departuredAt, 'd MMM');
  const arrivalDate = formatDate(lastBound.segments[lastSegmentIndex].arrivedAt, 'd MMM');
  return { departureDate, arrivalDate };
};

const getFormattedStringForAgeType = (t, ageType, quantity) => {
  const formattedStringForAgeType = stringUtil.insertArgument(
    t(getAgeTypeLabelForQuantity(ageType, quantity)),
    quantity,
  );
  return formattedStringForAgeType || `${quantity} ${ageType}`;
};

export const generateTotalTravelersDesktopText = (t, travelers) =>
  isFunction(t)
    ? Object.entries(getNumberOfTravelers(travelers))
        .filter(([, num]) => num > 0)
        .map(([ageType, quantity]) => `${getFormattedStringForAgeType(t, ageType, quantity)}`)
        .join(', ')
    : '';

export const getSegmentsFromBounds = (bounds = []) =>
  bounds
    .flatMap((bound) =>
      bound.segments.map((segment) => ({
        ...segment,
      })),
    )
    .map((segment, index) => ({
      ...segment,
      segmentIndex: index,
    }));

const isEventTypeIncluded = (segment, eventType) =>
  segment.types && segment.types.map((t) => t.type).includes(eventType);

export const getOvernightStayCities = (bounds) => {
  const segmentsWithIndexes = getSegmentsFromBounds(bounds);

  const tripSegmentWithOvernightStayIndexes = segmentsWithIndexes
    .filter((segment) => isEventTypeIncluded(segment, OVERNIGHT_STAY))
    .map((segment) => segment.segmentIndex - 1);

  return uniq(
    segmentsWithIndexes
      .filter((segment) => tripSegmentWithOvernightStayIndexes.includes(segment.segmentIndex))
      .map((segment) => segment.destination.cityName),
  );
};

const isEventSegment = (segment) => segment.__typename === 'EventSegment';

export const getChangeOfAirportParameters = (bounds) => {
  const segments = getSegmentsFromBounds(bounds);

  const segmentWithChangeOfAirport = segments.find((segment) =>
    isEventTypeIncluded(segment, CHANGE_OF_AIRPORT),
  );

  return segmentWithChangeOfAirport
    ? {
        fromAirport: segments[segmentWithChangeOfAirport.segmentIndex - 1].destination.name,
        toAirport: segments[segmentWithChangeOfAirport.segmentIndex + 1].origin.name,
      }
    : null;
};

const transformEventSegmentTypes = (types, uniqueEventSegmentTypes) =>
  types.map((type) => {
    const legendNumber =
      type === STOP || type === SELF_TRANSFER
        ? {}
        : { legendNumber: uniqueEventSegmentTypes.indexOf(type) + 1 };
    return {
      type,
      ...legendNumber,
    };
  });

const generateLegendNumbers = (uniqueEventSegmentTypes, bound) =>
  (bound.segments || []).map((segment) => ({
    ...segment,
    types: isEventSegment(segment)
      ? transformEventSegmentTypes(segment.types, uniqueEventSegmentTypes)
      : [],
  }));

export const getEventSegmentsList = (bounds = []) => {
  const segments = bounds.flatMap((bound) => bound.segments || []);
  return segments.filter(isEventSegment).map((segment) => segment.types);
};

export const addLegendNumberToSegmentEvents = (bounds = []) => {
  const flatEventSegmentTypes = flatten(getEventSegmentsList(bounds));
  if (flatEventSegmentTypes.length === 0) {
    return bounds;
  }

  const uniqueEventSegmentTypes = uniq(flatEventSegmentTypes).filter(
    (type) => type !== STOP && type !== SELF_TRANSFER,
  );

  return bounds.map((bound) => ({
    ...bound,
    ...{ segments: generateLegendNumbers(uniqueEventSegmentTypes, bound) },
  }));
};

export const getBaggageWeightText = (
  { pieces, weight, weightUnit } = {},
  secondBaggageDetails,
  isRTL,
) => {
  const { maxWeight: weightSecondBag, quantity: piecesSecondBag } = secondBaggageDetails || {};
  if (!weight && !weightUnit) {
    return '';
  }

  const weightText = !isRTL ? `${weight}${weightUnit}` : `${weightUnit}${weight}`;
  const piecesText = !isRTL ? ` ${pieces} × ${weightText}` : ` ${weightText} × ${pieces}`;

  const piecesTextDifferentWeight = !isRTL
    ? ` ${pieces} × ${weight}${weightUnit} +  ${piecesSecondBag} × ${weightSecondBag}${weightUnit} `
    : `${weightUnit}  ${weightSecondBag} × ${piecesSecondBag} + ${weightUnit}  ${weight} × ${pieces} `;

  const piecesTextSameWeight = !isRTL
    ? `${piecesSecondBag + pieces} × ${weightText}`
    : `${weightText} × ${piecesSecondBag + pieces}`;

  const combinedPiecesText =
    weight !== weightSecondBag && weightSecondBag !== undefined
      ? piecesTextDifferentWeight
      : piecesTextSameWeight;

  const baggagePiecesDescription = weightSecondBag ? combinedPiecesText : piecesText;
  return pieces ? baggagePiecesDescription : ` ${weightText}`;
};

export const boundIncludesTrainSegments = (segments = []) => {
  const boundEquipmentCodes = segments.map((segment) => segment.equipmentCode);

  if (
    boundEquipmentCodes.includes(AIR_EQUIPMENT_CODE) &&
    boundEquipmentCodes.includes(TRAIN_EQUIPMENT_CODE)
  ) {
    return SOME;
  }

  if (boundEquipmentCodes.includes(TRAIN_EQUIPMENT_CODE)) {
    return ALL;
  }

  return NONE;
};

export const tripIncludesTrainSegments = (bounds = []) => {
  const boundsTrainStatuses = bounds.map((bound) => boundIncludesTrainSegments(bound.segments));

  const tripIncludesTotallyTrainBound = boundsTrainStatuses.includes(ALL);
  const tripIncludesPartiallyTrainBound = boundsTrainStatuses.includes(SOME);
  const tripIncludesTotallyFlightBound = boundsTrainStatuses.includes(NONE);

  if (
    tripIncludesTotallyTrainBound &&
    !tripIncludesPartiallyTrainBound &&
    !tripIncludesTotallyFlightBound
  ) {
    return ALL;
  }

  if (
    tripIncludesTotallyFlightBound &&
    !tripIncludesPartiallyTrainBound &&
    !tripIncludesTotallyTrainBound
  ) {
    return NONE;
  }

  return SOME;
};

export const getTimeBreakdown = (flightDuration = '') => {
  const flightDurationSplit = flightDuration.split('h');
  const extractHour = flightDurationSplit[0].replace(/h/g, '');
  const minutes = Number(
    flightDurationSplit[`${flightDurationSplit.length === 1 ? 0 : 1}`]
      .replace(/min/g, '')
      .replace(/\s/g, ''),
  );
  const totalHour = Number(extractHour);
  const days = Math.floor(totalHour / 24);
  const remainder = totalHour % 24;
  const hours = Math.floor(remainder);
  return { days, hours, minutes };
};
