import { useEffect, useState } from 'react';
import { constants } from '@eti/utils';
import { AgeType } from '@eti/schema-types';
import { getTravelersPrimaryFlow, bundleBaggageDetails, getPreselected, checkedBaggagePurchasablePiecesWeight } from './CheckInBaggageUtil';
export const useCheckedBag = _ref => {
  let {
    travelerNumber,
    order,
    bundleData,
    includedBaggage
  } = _ref;
  const [checkedBagData, setCheckedBagData] = useState({
    checkedBagNumberOfPieces: 0,
    checkedBagWeight: 0,
    checkedBagWeightUnit: '',
    isInfant: false,
    isFirstTraveler: false,
    includedCheckedBaggageInTrip: null,
    traveler: {
      ageType: AgeType.Adt,
      price: 0,
      markup: 0,
      value: false,
      id: ''
    },
    travelers: [],
    isCheckedBagPreselected: false
  });
  useEffect(() => {
    const travelers = getTravelersPrimaryFlow(order);
    const traveler = (travelers === null || travelers === void 0 ? void 0 : travelers[travelerNumber]) || {};
    const isInfant = traveler.ageType === constants.ageTypes.INFANT;
    const isFirstTraveler = travelerNumber === 0;
    const {
      pieces: checkedBagNumberOfPieces = 0,
      weight: checkedBagWeight = 0,
      weightUnit: checkedBagWeightUnit = ''
    } = checkedBaggagePurchasablePiecesWeight(order.sellSpecification);
    const includedCheckedBaggageInTrip = includedBaggage || bundleBaggageDetails(bundleData);
    const isCheckedBagPreselected = getPreselected(travelers);
    setCheckedBagData({
      checkedBagNumberOfPieces,
      checkedBagWeight,
      checkedBagWeightUnit,
      isInfant,
      isFirstTraveler,
      includedCheckedBaggageInTrip,
      traveler,
      travelers,
      isCheckedBagPreselected
    });
  }, [travelerNumber, order, bundleData, includedBaggage]);
  return checkedBagData;
};