import { formValueSelector, getFormSyncErrors } from 'redux-form';
export const getSelected = (name, form) => state => {
  var _formData$value$value, _formData$value;
  const formData = formValueSelector(form)(state, name);
  return (_formData$value$value = formData === null || formData === void 0 || (_formData$value = formData.value) === null || _formData$value === void 0 ? void 0 : _formData$value.value) !== null && _formData$value$value !== void 0 ? _formData$value$value : null;
};
export const getPrice = (name, form) => state => {
  var _formData$value$price, _formData$value2;
  const formData = formValueSelector(form)(state, name);
  return (_formData$value$price = formData === null || formData === void 0 || (_formData$value2 = formData.value) === null || _formData$value2 === void 0 ? void 0 : _formData$value2.priceRaw) !== null && _formData$value$price !== void 0 ? _formData$value$price : null;
};
export const getProvince = (name, form) => state => {
  var _formData$province;
  const formData = formValueSelector(form)(state, name);
  return (_formData$province = formData === null || formData === void 0 ? void 0 : formData.province) !== null && _formData$province !== void 0 ? _formData$province : null;
};
export const getTravelers = (name, form) => state => {
  var _formData$travelers;
  const formData = formValueSelector(form)(state, name);
  return (_formData$travelers = formData === null || formData === void 0 ? void 0 : formData.travelers) !== null && _formData$travelers !== void 0 ? _formData$travelers : null;
};
export const getTravelerField = (id, name) => name && id ? `${name}.travelers.traveler-${id}` : null;
export const getValidDateOfBirth = (name, form, id) => state => {
  var _errors$name$traveler, _errors$name;
  const errors = getFormSyncErrors(form)(state);
  const {
    dateOfBirth
  } = (_errors$name$traveler = errors === null || errors === void 0 || (_errors$name = errors[name]) === null || _errors$name === void 0 || (_errors$name = _errors$name.travelers) === null || _errors$name === void 0 ? void 0 : _errors$name[`traveler-${id}`]) !== null && _errors$name$traveler !== void 0 ? _errors$name$traveler : {};
  return Boolean(!dateOfBirth);
};
export const getTravelerDateOfBirth = (name, form, id) => state => {
  var _formData$dateOfBirth;
  const travelerField = getTravelerField(id, name);
  const formData = formValueSelector(form)(state, travelerField);
  return (_formData$dateOfBirth = formData === null || formData === void 0 ? void 0 : formData.dateOfBirth) !== null && _formData$dateOfBirth !== void 0 ? _formData$dateOfBirth : null;
};
export const getSelectedTraveler = (name, form, id) => state => {
  var _formData$selected;
  const travelerField = getTravelerField(id, name);
  const formData = formValueSelector(form)(state, travelerField);
  return (_formData$selected = formData === null || formData === void 0 ? void 0 : formData.selected) !== null && _formData$selected !== void 0 ? _formData$selected : null;
};
export const getTravelerPrice = (name, form, id) => state => {
  const travelerField = getTravelerField(id, name);
  const formData = formValueSelector(form)(state, travelerField);
  return formData === null || formData === void 0 ? void 0 : formData.priceRaw;
};
export const getIsFetchingPrice = (name, form, id) => state => {
  var _formData$isFetchingP;
  const travelerField = getTravelerField(id, name);
  const formData = formValueSelector(form)(state, travelerField);
  return (_formData$isFetchingP = formData === null || formData === void 0 ? void 0 : formData.isFetchingPrice) !== null && _formData$isFetchingP !== void 0 ? _formData$isFetchingP : false;
};
export const getIsRefetchingPrice = (name, form, id) => state => {
  var _formData$isRefetchin;
  const travelerField = getTravelerField(id, name);
  const formData = formValueSelector(form)(state, travelerField);
  return (_formData$isRefetchin = formData === null || formData === void 0 ? void 0 : formData.isRefetchingPrice) !== null && _formData$isRefetchin !== void 0 ? _formData$isRefetchin : false;
};
export const getTotalTravelersPrice = (name, form) => state => {
  var _formData$travelers2;
  const formData = formValueSelector(form)(state, name);
  const travelers = Object.values((_formData$travelers2 = formData === null || formData === void 0 ? void 0 : formData.travelers) !== null && _formData$travelers2 !== void 0 ? _formData$travelers2 : {});
  const hasAnySelected = travelers.find(_ref => {
    let {
      selected
    } = _ref;
    return selected;
  });
  return travelers.length && hasAnySelected ? travelers.reduce((total, traveler) => {
    const {
      selected,
      priceRaw
    } = traveler;
    if (selected && Number.isInteger(priceRaw)) {
      return total + priceRaw;
    }
    return total;
  }, 0) : null;
};
export const getTravelersPriceError = (name, form) => state => {
  var _formData$travelers3;
  const formData = formValueSelector(form)(state, name);
  const travelers = Object.values((_formData$travelers3 = formData === null || formData === void 0 ? void 0 : formData.travelers) !== null && _formData$travelers3 !== void 0 ? _formData$travelers3 : {});
  return travelers.length ? travelers.some(_ref2 => {
    let {
      priceError
    } = _ref2;
    return priceError;
  }) : null;
};
export const getValidTravelers = (name, form) => state => {
  var _formData$travelers4;
  const formData = formValueSelector(form)(state, name);
  const travelers = Object.values((_formData$travelers4 = formData === null || formData === void 0 ? void 0 : formData.travelers) !== null && _formData$travelers4 !== void 0 ? _formData$travelers4 : {});
  if (travelers.length) {
    const errors = getFormSyncErrors(form)(state);
    const hasDateOfBirthErrors = travelers.some(function (_temp) {
      var _errors$name2;
      let {
        id
      } = _temp === void 0 ? {} : _temp;
      return errors === null || errors === void 0 || (_errors$name2 = errors[name]) === null || _errors$name2 === void 0 || (_errors$name2 = _errors$name2.travelers) === null || _errors$name2 === void 0 ? void 0 : _errors$name2[`traveler-${id}`];
    });
    return !hasDateOfBirthErrors;
  }
  return false;
};