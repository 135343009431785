import { css, etiColors, mediaQueries } from '@eti/styles';

export const iconWrapperStyles = css`
  display: flex;
  margin-right: 8px;

  [dir='rtl'] & {
    margin-left: 8px;
    margin-right: 0;
    transform: rotateY(180deg);
  }

  svg {
    vertical-align: top;
  }
`;

const getControlBorderColor = (hasError, isFocused) => {
  if (isFocused) {
    return etiColors.info.solid;
  }

  if (hasError) {
    return etiColors.critical.solid;
  }

  return etiColors.grayScale[7];
};

const getControlStyles =
  (hasError, _isRequired, customControlStyles = {}) =>
  (_controlStyles, { isFocused }) => {
    const {
      [`@media ${mediaQueries.small.only}`]: smallOnlyStyles,
      [`@media ${mediaQueries.medium.only}`]: mediumOnlyStyles,
      ...restSizesStyles
    } = customControlStyles;

    return {
      borderColor: getControlBorderColor(hasError, isFocused),
      padding: '2px 0.5rem',
      ...restSizesStyles,
    };
  };

const getContainerStyles = (containerStyles) => ({
  ...containerStyles,
  width: '100%',
});

const getMenuStyles = (menuStyles) => ({
  ...menuStyles,
  marginBottom: '0.125rem',
  marginTop: 0,
});

const getPlaceholderStyles = (placeholderStyles) => ({
  ...placeholderStyles,
  color: '#a7a7a7',
  left: '2.175rem',
  '[dir="rtl"] &': {
    left: 0,
    right: '2.175rem',
  },
});

const getSingleValueStyles = (singleValueStyles) => ({
  ...singleValueStyles,
  left: '2.175rem',
  '[dir="rtl"] &': {
    left: 0,
    right: '2.175rem',
  },
});

export const getStyles = (hasError, isRequired, customStyles = {}) => ({
  control: getControlStyles(hasError, isRequired, customStyles.control),
  container: getContainerStyles,
  menu: getMenuStyles,
  placeholder: getPlaceholderStyles,
  singleValue: getSingleValueStyles,
});
