import { useState } from 'react';
import { useDidUpdateEffect } from '../custom-hooks/useDidUpdateEffect';
import { getProductNameForTracking, TRACKING_PROPERTIES, useProductSnowplowTracking } from '../custom-hooks/useProductSnowplowTracking';
const useBaggageTracker = (productName, numberOfTravelers, currentTravelerId, shouldApplySelectionToAll) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const {
    sendAddToCartEvent,
    sendSelectEvent
  } = useProductSnowplowTracking(productName);
  useDidUpdateEffect(() => {
    const isFirstTraveler = Number(currentTravelerId) === 0;
    if (!isFirstTraveler || numberOfTravelers <= 1) {
      return;
    }
    if (isFirstTraveler && numberOfTravelers > 1) {
      if (selectedValue) {
        sendAddToCartEvent({
          addToCart: shouldApplySelectionToAll,
          value: 'Same baggage option'
        });
      } else {
        sendSelectEvent({
          isSelected: shouldApplySelectionToAll,
          label: `Same ${getProductNameForTracking(productName)} option`
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldApplySelectionToAll]);
  const addToCartEventOnChange = selectionValue => {
    const isSelected = selectionValue !== 'false';
    setSelectedValue(isSelected);
    sendAddToCartEvent({
      addToCart: isSelected,
      ...(selectionValue === 'combined' && {
        value: 'Baggage x 2'
      })
    });
  };
  const addToCartModalEvent = value => {
    sendAddToCartEvent({
      addToCart: value,
      property: TRACKING_PROPERTIES.VALIDATION_POP_UP
    });
  };
  return {
    addToCartEventOnChange,
    addToCartModalEvent
  };
};
export default useBaggageTracker;