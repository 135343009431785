import userDeviceType from './isOfType';

const userOSType = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const source = userDeviceType();

  if (source === 'Desktop') {
    if (/Windows NT 10.0/i.test(userAgent)) {
      return 'Windows 10';
    }
    if (/Windows NT 6.3/i.test(userAgent)) {
      return 'Windows 8.1';
    }
    if (/Windows NT 6.2/i.test(userAgent)) {
      return 'Windows 8';
    }
    if (/Windows NT 6.1/i.test(userAgent)) {
      return 'Windows 7';
    }
    if (/Windows NT 6.0/i.test(userAgent)) {
      return 'Windows Vista';
    }
    if (/Windows NT 5.1/i.test(userAgent)) {
      return 'Windows XP';
    }
    if (/Windows NT 5.0/i.test(userAgent)) {
      return 'Windows 2000';
    }
    if (/X11/i.test(userAgent)) {
      return 'UNIX';
    }
    if (/Linux/i.test(userAgent)) {
      return 'Linux';
    }
    if (/Mac/i.test(userAgent)) {
      return 'Mac/iOS';
    }
  } else {
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }
  }

  return 'unknown';
};

export default userOSType;
