import { css, mediaQueries } from '@eti/styles';
export const wrapperStyles = css`
  padding: 24px;
`;
export const standardGrid = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  .canvas {
    grid-area: 1 / 1 / 2 / 2;
    [dir='rtl'] & {
      transform: rotateY(180deg);
    }
  }

  .children {
    display: contents;
  }

  @media ${mediaQueries.small.only} {
    display: none;
  }
`;
export const canvasStyles = (illustrationUrl, illustrationHeight) => css`
  background: url(${illustrationUrl});
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  height: min(100%, ${illustrationHeight.toString()}px);
  width: 100%;
`;