import { generateMuxProductKey, getEmptyOptionId } from './utils';
import MutuallyExclusive from './containers/MutuallyExclusiveContainer';
const name = 'mutuallyExclusive';
export const mutuallyExclusive = {
  formSection: name,
  name,
  id: name,
  generateMuxProductKey,
  getEmptyOptionId
};
export default MutuallyExclusive;