import { createValidator } from 'revalidate';
import { ORIGIN_SAME_AS_DESTINATION } from '../constants/defaultErrorMessages';
const isValidLocation = (originFieldName, destinationFieldName) => createValidator(message => (_value, allValues, _props, name) => {
  const [boundType, fieldName] = name.split('.');
  const getLocation = field => {
    var _boundTypeData$field$, _boundTypeData$field;
    const boundTypeData = boundType.split('[').map(str => str.replace(']', '')).reduce((acc, part) => acc === null || acc === void 0 ? void 0 : acc[part], allValues);
    return (_boundTypeData$field$ = boundTypeData === null || boundTypeData === void 0 || (_boundTypeData$field = boundTypeData[field]) === null || _boundTypeData$field === void 0 ? void 0 : _boundTypeData$field.iataCode) !== null && _boundTypeData$field$ !== void 0 ? _boundTypeData$field$ : null;
  };
  const primaryLocation = getLocation(fieldName);
  if (!primaryLocation) {
    return 'the field is required';
  }
  const secondaryLocationFieldName = [originFieldName, destinationFieldName].find(val => val !== fieldName);
  const secondaryLocation = getLocation(secondaryLocationFieldName);
  if (primaryLocation === secondaryLocation) {
    return message || ORIGIN_SAME_AS_DESTINATION;
  }
  return null;
}, ORIGIN_SAME_AS_DESTINATION);
export default isValidLocation;