import { SeatPreferenceSelectionInput } from '@eti/schema-types';
const checkRowCharacteristics = (row, characteristic) => row && row.characteristics && row.characteristics.indexOf(characteristic) !== -1 || false;
export const isExitRow = row => checkRowCharacteristics(row, 'EXIT_ROW');
export const isEmptyRow = row => checkRowCharacteristics(row, 'EMPTY_ROW');
export const isRowOverWing = row => checkRowCharacteristics(row, 'OVER_WING_ROW');
const isBetweenAisles = (prev, curr) => prev.type === SeatPreferenceSelectionInput.Aisle && curr.type === SeatPreferenceSelectionInput.Aisle;
const emptyDetailsAisle = {
  columnName: null,
  type: 'EMPTY'
};
export const addAislesToColumnDetails = details => details.reduce((prev, detail, index) => {
  if (index > 0 && isBetweenAisles(details[index - 1], detail)) {
    prev.push(emptyDetailsAisle);
  }
  prev.push(detail);
  return prev;
}, []);
const addEmptySeatForMissingSeatInRow = (rowNumber, columnName) => ({
  column: columnName,
  isBookable: false,
  mainCharacteristic: null,
  row: rowNumber,
  seat: String(rowNumber + columnName),
  type: 'EMPTY'
});
const newAisle = _ref => {
  let {
    rowNumber
  } = _ref;
  return {
    column: null,
    row: rowNumber,
    type: SeatPreferenceSelectionInput.Aisle
  };
};
const newRowItem = (_ref2, _ref3) => {
  let {
    sellSeatMapSeats,
    rowNumber
  } = _ref2;
  let {
    columnName
  } = _ref3;
  const seatByColumnName = sellSeatMapSeats.find(seat => seat.column === columnName);
  return seatByColumnName || addEmptySeatForMissingSeatInRow(rowNumber, columnName);
};
export const addAislesToRows = (cabinRows, columnDetails) => {
  if (cabinRows && columnDetails) {
    return cabinRows.map(row => {
      const newRowSeats = columnDetails.reduce((prev, column) => {
        if (column.type === 'EMPTY') {
          prev.push(newAisle(row));
        } else {
          prev.push(newRowItem(row, column));
        }
        return prev;
      }, []);
      return {
        ...row,
        sellSeatMapSeats: newRowSeats
      };
    });
  }
  return cabinRows || null;
};
const addExplicitEmptyRow = row => {
  const emptyRowLayout = (row.sellSeatMapSeats || []).map((seat, index, arr) => {
    if (index === 0 || index === arr.length - 1) {
      return {
        column: seat === null || seat === void 0 ? void 0 : seat.column,
        type: 'EXIT_ARROW'
      };
    }
    return {
      column: seat === null || seat === void 0 ? void 0 : seat.column
    };
  });
  return {
    characteristics: ['EMPTY_ROW', ...(isRowOverWing(row) ? ['OVER_WING_ROW'] : [])],
    rowNumber: null,
    sellSeatMapSeats: emptyRowLayout
  };
};
export const addExitRows = cabinRows => cabinRows ? cabinRows.reduce((prev, row) => checkRowCharacteristics(row, 'EXIT_ROW') ? [...prev, addExplicitEmptyRow(row), row] : [...prev, row], []) : null;