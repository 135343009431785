const CHANGE_OF_AIRPORT = 'CHANGE_OF_AIRPORT';
const ONE_DAY_STOPOVER = 'ONE_DAY_STOPOVER';
const ONE_NIGHT_STOPOVER = 'ONE_NIGHT_STOPOVER';
const OVERNIGHT_STAY = 'OVERNIGHT_STAY';
const SELF_TRANSFER = 'SELF_TRANSFER';
const SHORT_STOPOVER = 'SHORT_STOPOVER';
const STOP = 'STOP';
const TRANSFER_TO_AIRPORT = 'TRANSFER_TO_AIRPORT';
const TRANSFER_TO_BUS = 'TRANSFER_TO_BUS';
const TRANSFER_TO_TRAIN = 'TRANSFER_TO_TRAIN';
const TWO_NIGHTS_STOPOVER = 'TWO_NIGHTS_STOPOVER';

export {
  CHANGE_OF_AIRPORT,
  ONE_DAY_STOPOVER,
  ONE_NIGHT_STOPOVER,
  OVERNIGHT_STAY,
  SELF_TRANSFER,
  SHORT_STOPOVER,
  STOP,
  TRANSFER_TO_AIRPORT,
  TRANSFER_TO_BUS,
  TRANSFER_TO_TRAIN,
  TWO_NIGHTS_STOPOVER,
};
