import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import { CabinClass } from '@eti/schema-types';
export const transformFilters = selectedFilters => Object.entries(selectedFilters).filter(_ref => {
  let [, value] = _ref;
  return value != null;
}).map(_ref2 => {
  let [key, value] = _ref2;
  if (isArray(value)) {
    return {
      code: key,
      value: value.join(',')
    };
  }
  if (isNumber(value.min) && isNumber(value.max)) {
    return {
      code: key,
      value: `${value.min},${value.max}`
    };
  }
  return {
    code: key,
    value: value.toString()
  };
});
export const createSearchVariablesFromContextData = searchData => {
  var _searchData$travelers, _searchData$travelers2;
  if (!searchData) {
    return null;
  }
  return !isEmpty(searchData === null || searchData === void 0 ? void 0 : searchData.origin) && searchData !== null && searchData !== void 0 && (_searchData$travelers = searchData.travelers) !== null && _searchData$travelers !== void 0 && _searchData$travelers.adults && searchData !== null && searchData !== void 0 && searchData.routes ? {
    adults: searchData.travelers.adults,
    cabinClass: (searchData === null || searchData === void 0 ? void 0 : searchData.cabinClass) || CabinClass.Economy,
    carrierCodes: searchData.carrierCodes,
    childAges: (_searchData$travelers2 = searchData.travelers) === null || _searchData$travelers2 === void 0 ? void 0 : _searchData$travelers2.childAges,
    direct: searchData.shouldOnlyShowDirectFlights,
    filters: (searchData === null || searchData === void 0 ? void 0 : searchData.filters) && transformFilters(searchData.filters),
    routes: searchData.routes.map(route => ({
      origin: route.originCode,
      destination: route.destinationCode,
      departureDate: route.departureDate
    })),
    sortTypeCode: searchData.selectedSortType,
    validWithVoucher: searchData.shouldOnlyShowFlightsValidWithVoucher
  } : null;
};