import numeral from 'numeral';
import { unicodeControlCharacters as ucc } from '../constants';
export const convert = pattern => pattern.replace(/(#+[(#,.]*#+)/g, match => `[${match.replace(/#/g, '0')}]`);
const getPattern = (pattern, number) => {
  const patterns = pattern.split(';');
  return patterns[number < 0 && patterns.length > 1 ? 1 : 0];
};
export const createPriceFormatter = function (_temp) {
  let {
    currencySymbol = '',
    decimalSymbol = '.',
    groupingSeparator = ',',
    pattern = '0,0.00¤',
    currencyExponent = 0
  } = _temp === void 0 ? {} : _temp;
  return {
    formatPrice(number) {
      let formattedPrice = numeral(number / 10 ** currencyExponent).format(convert(getPattern(pattern, number).replace('¤', '$'))).replace('$', currencySymbol);
      const decimalPosition = formattedPrice.lastIndexOf('.');
      formattedPrice = formattedPrice.replace(/,/g, groupingSeparator);
      formattedPrice = formattedPrice.slice(0, decimalPosition) + formattedPrice.slice(decimalPosition).replace('.', decimalSymbol);
      formattedPrice = formattedPrice.replace(/ /g, ucc.NBSP);
      return ucc.LRE + formattedPrice + ucc.PDF;
    }
  };
};