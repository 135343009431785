import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { vars } from '@etg/wings';
import { LoadingDots } from '@eti/components';
import { css, cx } from '@eti/styles';

const getContainerStyles = css`
  align-items: center;
  color: ${vars.colors.links.default};
  display: flex;
  font-size: 2.5rem;
  height: 100%;
  justify-content: center;
`;
const pageClass = css`
  .etiMainContent {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

const Loading = ({ dataTestId, className }) => {
  useEffect(() => {
    document.body.classList.add(pageClass);
    return () => {
      document.body.classList.remove(pageClass);
    };
  });

  return <LoadingDots containerStyle={cx(getContainerStyles, className)} dataTestId={dataTestId} />;
};

Loading.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default Loading;
