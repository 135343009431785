import { useCallback, useState } from 'react';
const _withPromise = function (callBack, timeout) {
  if (timeout === void 0) {
    timeout = 0;
  }
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        callBack();
        resolve();
      } catch (error) {
        reject(error);
      }
    }, timeout);
  });
};

/**
 * Custom Hook
 * @param {RefType} ref Specify HTML Element to scroll to
 * @param scrollOption
 * @returns {UseScrollType<T>} {scrollVertical, scrollHorizontal, setConfigOptions, setReferenceElement}
 * @description Utility Hook which helps to scroll to a particular HTML element in the page, making it visible within the viewport.
 * @summary
 * This is useful for smoothly navigating users to specific sections or content on a long webpage.
 * useScrollTo hook can be initialised with a HTMLElement reference to target and apply the scroll on it.
 * If ref is not passed then as a fallback it will automatically target to the global window object
 */
const useScrollTo = (ref, scrollOption) => {
  const [referenceElement, setReferenceElement] = useState(ref);
  const [configOptions, setScrollOptions] = useState(scrollOption);
  const scroll = useCallback((behavior, top, left) => {
    var _referenceElement$cur;
    const reference = (_referenceElement$cur = referenceElement === null || referenceElement === void 0 ? void 0 : referenceElement.current) !== null && _referenceElement$cur !== void 0 ? _referenceElement$cur : window;
    const scrollOptions = {
      top,
      left,
      behavior: behavior !== null && behavior !== void 0 ? behavior : 'smooth'
    };
    return _withPromise(() => {
      var _reference$scrollTo;
      return reference === null || reference === void 0 || (_reference$scrollTo = reference.scrollTo) === null || _reference$scrollTo === void 0 ? void 0 : _reference$scrollTo.call(reference, scrollOptions);
    }, configOptions === null || configOptions === void 0 ? void 0 : configOptions.scrollTimeout);
  }, [configOptions === null || configOptions === void 0 ? void 0 : configOptions.scrollTimeout, referenceElement]);

  /**
   * scrollVertical
   * @param {ScrollBehavior} behavior Specify the scroll behaviour. 'smooth' | 'auto'
   * @param {number} top Specify the pixel to scroll
   * @returns {Promise<void>} Promise of void for any callbacks
   */
  const scrollVertical = useCallback((behavior, top) => {
    var _ref, _referenceElement$cur2;
    const topPosition = (_ref = top !== null && top !== void 0 ? top : referenceElement === null || referenceElement === void 0 || (_referenceElement$cur2 = referenceElement.current) === null || _referenceElement$cur2 === void 0 ? void 0 : _referenceElement$cur2.scrollHeight) !== null && _ref !== void 0 ? _ref : document.body.scrollHeight;
    return scroll(behavior, topPosition);
  }, [referenceElement, scroll]);

  /**
   * scrollHorizontal
   * @param {ScrollBehavior} behavior Specify the scroll behaviour. 'smooth' | 'auto'
   * @param {number} left Specify the pixel to scroll
   * @returns {Promise<void>} Promise of void for any callbacks
   */
  const scrollHorizontal = useCallback((behavior, left) => {
    var _ref2, _referenceElement$cur3;
    const leftPosition = (_ref2 = left !== null && left !== void 0 ? left : referenceElement === null || referenceElement === void 0 || (_referenceElement$cur3 = referenceElement.current) === null || _referenceElement$cur3 === void 0 ? void 0 : _referenceElement$cur3.scrollWidth) !== null && _ref2 !== void 0 ? _ref2 : document.body.scrollWidth;
    return scroll(behavior, undefined, leftPosition);
  }, [referenceElement, scroll]);
  return {
    scroll,
    scrollVertical,
    scrollHorizontal,
    setScrollOptions,
    setReferenceElement
  };
};
export { useScrollTo };