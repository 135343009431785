import getIcon from '../common/utils/icon';
import { getPrice, getSelected, getGraphQlSelection } from '../../single-choice/utils/selection';
import { TRAVEL_INSURANCE_OUTSIDE_EU } from '../../products';
import TravelInsuranceOutsideEu from './components/TravelInsuranceOutsideEu';
const formSection = TRAVEL_INSURANCE_OUTSIDE_EU.name;
export const travelInsuranceOutsideEu = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'traveler'),
  ...TRAVEL_INSURANCE_OUTSIDE_EU
};
export default TravelInsuranceOutsideEu;