import TravelerForm from './components/TravelerForm';
import getTravelerFormSectionName from './utils/travelerFormName';
import { getSelected as getSelectedGender } from './utils/gender';
import { getSelected as getSelectedFirstName } from './utils/firstName';
import { getSelected as getSelectedFrequentFlyerCard } from './utils/frequentFlyerCardNumber';
import { getSelected as getSelectedFrequentFlyerCardType } from './utils/frequentFlyerCardType';
import { getSelected as getSelectedLastName } from './utils/lastName';
import { getSelected as getSelectedDateOfBirth } from './utils/dateOfBirth';
import { getSelected as getSelectedTravelExpiryDate } from './utils/travelDocumentExpiryDate';
import { getSelected as getSelectedTravelDocumentNumber } from './utils/travelDocumentNumber';
import * as fieldNames from './utils/constants';
export const travelerForm = {
  ...fieldNames,
  getTravelerFormSectionName,
  getSelectedFirstName,
  getSelectedFrequentFlyerCard,
  getSelectedFrequentFlyerCardType,
  getSelectedDateOfBirth,
  getSelectedGender,
  getSelectedLastName,
  getSelectedTravelExpiryDate,
  getSelectedTravelDocumentNumber
};
export default TravelerForm;