import { useQuery } from '@apollo/client';
import { gql } from '@eti/schema-types';

export const SELF_SERVICE_REBOOKING_QUERY = gql(/* GraphQL */ `
  query getSelfServiceRebooking {
    selfServiceRebooking {
      criteria
      travelers {
        id
        ageType
        birthDate
        firstName
        lastName
        gender
      }
      validatingCarriers
    }
  }
`);

const useSelfServiceRebooking = () => {
  const { data, loading } = useQuery(SELF_SERVICE_REBOOKING_QUERY);

  const selfServiceRebookingValidatingCarriers =
    data?.selfServiceRebooking?.validatingCarriers || [];
  const selfServiceRebookingCriteria = data?.selfServiceRebooking?.criteria || [];
  const selfServiceRebookingTravelers = data?.selfServiceRebooking?.travelers || [];
  const isSelfServiceRebooking = selfServiceRebookingTravelers.length > 0;

  return {
    loading,
    isSelfServiceRebooking,
    selfServiceRebookingCriteria,
    selfServiceRebookingTravelers,
    selfServiceRebookingValidatingCarriers,
  };
};

export default useSelfServiceRebooking;
