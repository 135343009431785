import { useMutation, useQuery } from '@apollo/client';
import { SetSettingsResult } from '@eti/schema-types';
import { useEffect } from 'react';
import { GET_SETTINGS } from '../queries';
import { SET_SETTINGS } from '../mutations';
import Loading from '../../loading/components/Loading';
import Settings from './Settings';

type OptionType = {
  value: string;
  label: string;
};
const SettingsGraphqlContainer = ({ iconOnly = false }: { iconOnly?: boolean }) => {
  useEffect(() => {
    const url = new URL(window.location.href);
    url.searchParams.delete('customerLanguage');
    window.history.replaceState(null, '', url);
  }, []);

  const { data, loading } = useQuery(GET_SETTINGS);
  const [setSettings] = useMutation(SET_SETTINGS);
  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  const handleOnSubmit = async (
    selectedLocaleOption: OptionType,
    selectedRegionOption: OptionType,
    selectedCurrencyOption: OptionType,
  ) => {
    try {
      const { data: setData } = await setSettings({
        variables: {
          localeCode: selectedLocaleOption.value,
          regionCode: selectedRegionOption.value,
          currencyCode: selectedCurrencyOption?.value,
        },
      });

      if (setData?.setSettings?.result === SetSettingsResult.Success) {
        window.location.reload();
      } else if (setData?.setSettings?.redirectUrl) {
        window.location.assign(setData.setSettings.redirectUrl);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return <Settings iconOnly={iconOnly} onSubmit={handleOnSubmit} settings={data?.settings} />;
};

export default SettingsGraphqlContainer;
