import { useTranslation } from '@eti/providers';
import ErrorDialog from './ErrorDialog';

interface ErrorModalProps {
  onClick: () => void;
}

const PaymentTimeoutModal = ({ onClick }: ErrorModalProps) => {
  const { t } = useTranslation();

  return (
    <ErrorDialog
      content={t('Payment.PaymentTimeout.Description')}
      data-testid="payment-timeout-dialog"
      isOpen
      onPrimaryAction={onClick}
      pictogramId="session-timeout"
      primaryButtonLabel={t('Payment.PaymentTimeout.Continue.Button')}
      title={t('Payment.PaymentTimeout.Title')}
    />
  );
};

export default PaymentTimeoutModal;
