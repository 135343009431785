import { createValidator } from 'revalidate';
import { THE_FIELD_HAS_NOT_THE_SAME_VALUE } from '../constants/defaultErrorMessages';
import { getFieldValue } from '../utils/utils';
const matchesField = otherField => createValidator(message => (value, allValues) => {
  const otherValue = getFieldValue(allValues, otherField);
  if (!value) {
    return null;
  }
  if (!allValues || otherValue && value !== otherValue) {
    return message || THE_FIELD_HAS_NOT_THE_SAME_VALUE;
  }
  return null;
}, THE_FIELD_HAS_NOT_THE_SAME_VALUE);
export default matchesField;