import { useTranslation } from '@eti/providers';
import { passengerFields } from '../../constants/formFields';
import { MAX_CHILD_AGE, MIN_CHILD_AGE } from '../../constants/passengers';
import { Passengers } from '../../models';
import PassengerRow from './PassengerRow';

interface AgeSelectionProps {
  index: number;
  selectedPassengers: Passengers;
}

const ChildrenAges = ({ index, selectedPassengers }: AgeSelectionProps) => {
  const { t } = useTranslation();

  const formatValueForStore = (newValue: number) => {
    const newChildAges = [...selectedPassengers.childrenAges];

    if (newValue < MIN_CHILD_AGE) {
      newChildAges[index] = MAX_CHILD_AGE;
    } else if (newValue > MAX_CHILD_AGE) {
      newChildAges[index] = MIN_CHILD_AGE;
    } else {
      newChildAges[index] = newValue;
    }

    return newChildAges;
  };

  const formatValueFromStore = (storedSelection: number[]) => {
    return storedSelection[index];
  };

  return (
    <PassengerRow
      format={formatValueFromStore}
      label={t('Air.Search.ChildAge', index + 1)}
      name={passengerFields.CHILDREN_AGES}
      normalize={formatValueForStore}
      passengerIndex={index + 1}
    />
  );
};

export default ChildrenAges;
