import { ElementType } from 'react';
import ErrorBoundary from '../components/ErrorBoundary';

const WithErrorBoundary = (WrappedComponent: ElementType) => (props: any) => (
  <ErrorBoundary>
    <WrappedComponent {...props} />
  </ErrorBoundary>
);

export default WithErrorBoundary;
