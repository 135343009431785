import { formValueSelector } from 'redux-form';
import { SECOND_BAGGAGE, CHECK_IN_BAGGAGE } from '../products';
const checkinBaggageFormSection = CHECK_IN_BAGGAGE.name;
const secondBagFormSection = SECOND_BAGGAGE.name;
const getTravelersSelection = _ref => {
  let {
    state,
    form,
    formSection,
    valueWhenSelected
  } = _ref;
  const formData = formValueSelector(form)(state, formSection);
  const selectionTravelerNumberOfUnits = Object.values(formData || {}).filter(_ref2 => {
    let {
      value
    } = _ref2;
    return value === valueWhenSelected;
  }).map(_ref3 => {
    let {
      id
    } = _ref3;
    return {
      travelerId: String(id),
      numberOfUnits: 1
    };
  });
  if (selectionTravelerNumberOfUnits.length === 0) {
    return null;
  }
  return selectionTravelerNumberOfUnits;
};
const getGraphQlSelection = _ref4 => {
  let {
    state,
    form
  } = _ref4;
  const secondBagWhenFirstIncluded = getTravelersSelection({
    state,
    form,
    formSection: secondBagFormSection,
    valueWhenSelected: true
  });
  const secondBagWhenFirstPurchasable = getTravelersSelection({
    state,
    form,
    formSection: checkinBaggageFormSection,
    valueWhenSelected: 'combined'
  });
  if (!secondBagWhenFirstIncluded && !secondBagWhenFirstPurchasable) {
    return null;
  }
  return {
    productId: String(SECOND_BAGGAGE.id),
    selectionTravelerNumberOfUnits: secondBagWhenFirstIncluded || secondBagWhenFirstPurchasable
  };
};
export default getGraphQlSelection;