export const AIRLINES = 'AIRLINES';
export const STOPOVER_ENABLED = 'STOPOVER_ENABLED';
export const CHECKED_BAGGAGE_INCLUDED = 'CHECKED_BAGGAGE_INCLUDED';
export const EXCLUDE_SELF_TRANSFER = 'EXCLUDE_SELF_TRANSFER';
export const NUMBER_OF_STOPS = 'MAX_STOPS';
export const ONE_DAY_STOPOVER = 'ONE_DAY_STOPOVER';
export const ONE_NIGHT_STOPOVER = 'ONE_NIGHT_STOPOVER';
export const PRICE = 'PRICE';
export const SHORT_STOPOVER = 'SHORT_STOPOVER';
export const STOPOVER_TYPES = 'STOPOVER_TYPES';
export const TRAVEL_TIME = 'TRAVEL_TIME';
export const TRIP_0_ARRIVAL_TIME = 'TRIP_0_ARRIVAL_TIME';
export const TRIP_0_DEPARTURE_TIME = 'TRIP_0_DEPARTURE_TIME';
export const TRIP_1_ARRIVAL_TIME = 'TRIP_1_ARRIVAL_TIME';
export const TRIP_1_DEPARTURE_TIME = 'TRIP_1_DEPARTURE_TIME';
export const TRIP_2_ARRIVAL_TIME = 'TRIP_2_ARRIVAL_TIME';
export const TRIP_2_DEPARTURE_TIME = 'TRIP_2_DEPARTURE_TIME';
export const TRIP_3_ARRIVAL_TIME = 'TRIP_3_ARRIVAL_TIME';
export const TRIP_3_DEPARTURE_TIME = 'TRIP_3_DEPARTURE_TIME';
export const TRIP_4_ARRIVAL_TIME = 'TRIP_4_ARRIVAL_TIME';
export const TRIP_4_DEPARTURE_TIME = 'TRIP_4_DEPARTURE_TIME';
export const TWO_NIGHTS_STOPOVER = 'TWO_NIGHTS_STOPOVER';
