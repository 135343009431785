import { useState, useEffect, useCallback } from 'react';
const EXTRA_MARGIN = 20;

/**
 * A custom hook that determines whether a specified element is fully visible within the viewport.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Object} params.options - Options for the IntersectionObserver.
 * @param {number} [params.options.threshold=1.0] - The percentage of the target's visibility required to trigger the observer callback.
 * @param {string} [params.options.rootMargin='0px'] - Margin around the root element.
 * @param {React.RefObject<HTMLElement>} params.elementRef - A ref to the element to observe.
 *
 * @returns {boolean} - Returns `true` if the element is fully visible within the viewport, otherwise `false`.
 *
 * @example
 * const MyComponent = () => {
 *   const ref = useRef(null);
 *   const isVisible = useElementInViewport({
 *     options: { threshold: 0.5 }, 50% in view port
 *     elementRef: ref
 *   });
 *
 *   return (
 *     <div>
 *       <div ref={ref}>Observe me</div>
 *       <p>{isVisible ? 'Element is in viewport!' : 'Element is not in viewport'}</p>
 *     </div>
 *   );
 * };
 */
export const useElementInViewport = _ref => {
  let {
    options,
    elementRef
  } = _ref;
  const [isFullyVisible, setIsFullyVisible] = useState(false);
  const [isAtTop, setIsAtTop] = useState(false);

  // To attach IntersectionObserver on the products once the scrolling is start and the position is less than 200px
  // Reason behind adding this condition is, when we go to extra products section on TD page on click of continue
  // button page scroll to top and due to which all the product go through view port once and which shouldn't happen.
  const handleScroll = useCallback(() => {
    if (window.scrollY < 200) {
      setIsAtTop(true);
    }
  }, []);
  useEffect(() => {
    var _options$threshold, _options$rootMargin;
    const element = elementRef === null || elementRef === void 0 ? void 0 : elementRef.current;
    if (!isAtTop || !element) {
      return undefined;
    }
    const viewportHeight = window.innerHeight;
    const elementHeight = element.getBoundingClientRect().height;

    // Calculate dynamically rootMargin when the height of the target element is more
    // than viewport height and add extra 10px to top & bottom
    const calculatedRootMargin = elementHeight > viewportHeight ? `${(elementHeight - viewportHeight) / 2 + EXTRA_MARGIN}px` : '0px';
    const observerOptions = {
      threshold: (_options$threshold = options === null || options === void 0 ? void 0 : options.threshold) !== null && _options$threshold !== void 0 ? _options$threshold : 1.0,
      rootMargin: (_options$rootMargin = options === null || options === void 0 ? void 0 : options.rootMargin) !== null && _options$rootMargin !== void 0 ? _options$rootMargin : calculatedRootMargin
    };
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsFullyVisible(entry.intersectionRatio >= observerOptions.threshold);
          observer.unobserve(element);
          observer.disconnect();
        } else {
          setIsFullyVisible(false);
        }
      });
    }, observerOptions);
    observer.observe(element);
    return () => {
      observer.unobserve(element);
      observer.disconnect();
    };
  }, [options, elementRef, isAtTop]);

  //  To add scroll event listener so that we start observing the elements once the
  //  page scroll to top on travel-details extra products section.
  useEffect(() => {
    if (isAtTop) {
      window.removeEventListener('scroll', handleScroll);
    } else {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, isAtTop]);
  return isFullyVisible;
};