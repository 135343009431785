import { useProperty, useTranslation } from '@eti/providers';
import { css, etiColors, mediaQueries } from '@eti/styles';
import { device } from '@eti/utils';
import { Helmet } from 'react-helmet';
import Loading from '../../../common/loading/components/Loading';
import ReceiptMobileAppBanner from '../../receipt/components/ReceiptMobileAppBanner';

const bannerContainerStyles = css`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const mobileAppBannerStyle = css`
  border-bottom: 1px dotted ${etiColors.grayScale[8]};
  margin: 0 auto;
  padding: 16px 10px;

  @media ${mediaQueries.medium.up} {
    max-width: 750px;
    padding: 16px 15px;
  }

  @media ${mediaQueries.large.up} {
    max-width: 970px;
  }

  @media ${mediaQueries.xlarge.up} {
    max-width: 1170px;
  }
`;

const MobileAppDownloadPage = () => {
  const { p } = useProperty();
  const { t } = useTranslation();

  const appStoreLink = p('Order.DownloadApp.AppStoreLink');
  const playStoreLink = p('Order.DownloadApp.PlayStoreLink');
  const isMobileDevice = device.openAppStoreLink(appStoreLink, playStoreLink);

  return (
    <>
      <Helmet title={t('PageTitle.rf.mobile-app-download.action')} />
      {isMobileDevice ? (
        <Loading />
      ) : (
        <div className={bannerContainerStyles}>
          <ReceiptMobileAppBanner className={mobileAppBannerStyle} />
        </div>
      )}
    </>
  );
};

export default MobileAppDownloadPage;
