import { connect } from 'react-redux';
import { hasSubmitFailed, formValueSelector } from 'redux-form';
import { getErrors } from '../../common/utils/validation';
import SeatMapProduct from '../components/SeatMapProduct';
import { getPrice, getSelection, isSelected } from '../utils/selection';
const mapStateToProps = (state, _ref) => {
  let {
    form
  } = _ref;
  const isSeatMapSelected = isSelected('seatMap', form)(state);
  const isSeatMapFilled = Boolean(formValueSelector(form)(state, `seatMap.value.value`));
  return {
    errors: hasSubmitFailed(form)(state) ? getErrors(form, 'seatMap')(state) : [],
    isSelected: isSeatMapSelected,
    isSeatMapFilled,
    priceRaw: isSeatMapSelected ? getPrice('seatMap', form)(state).price : 0,
    selectedSeats: getSelection('seatMap', form)(state)
  };
};
const mapDispatchToProps = (_dispatch, _ref2) => {
  let {
    setIsSeatMapModalOpen
  } = _ref2;
  return {
    onChangeSelection() {
      setIsSeatMapModalOpen(true);
    }
  };
};
export const seatMapProductContainer = connect(mapStateToProps, mapDispatchToProps);
export default seatMapProductContainer(SeatMapProduct);