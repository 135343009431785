export const PASSENGERS_FORM_SECTION = 'passengers' as const;

export const CABIN_CLASS = 'cabinClass' as const;

export const passengerFields = {
  ADULTS: 'adults',
  CHILDREN: 'children',
  CHILDREN_AGES: 'childrenAges',
  INFANTS: 'infants',
  INFANTS_AGES: 'infantsAges',
} as const;
