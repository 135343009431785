import { css, mediaQueries } from '@eti/styles';
import { standardGrid } from '../../../common/main-layout/styles';
export const cabinBaggagePostbookingStyles = () => {
  const cabinBaggageProductLayoutStyle = readMoreContent => css`
    ${standardGrid}

    .cta {
      grid-area: ${readMoreContent ? '4 / 2 / 5 / 3' : '3 / 2 / 5 / 3'};
    }
  `;
  const cabinBaggageBodyGridLayoutStyle = css`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);

    .description {
      grid-area: 1 / 1 / 2 / 4;
      margin-bottom: 16px;
    }

    .additionalInformation {
      grid-area: 2 / 1 / 3 / 4;
      margin-bottom: 16px;
    }

    .promotionalText {
      grid-area: 5 / 1 / 5 / 4;
      margin-top: 16px;
    }
  `;
  const cabinBaggageCtaGridLayoutStyle = css`
    column-gap: 8px;
    display: grid;
    grid-template-columns: 2fr auto;
    grid-template-rows: repeat(3, auto);

    @media ${mediaQueries.medium.up} {
      justify-content: unset;
    }

    .priceLabel {
      grid-area: 4 / 1 / 4 / 2;

      @media ${mediaQueries.small.only} {
        grid-area: 4 / 1 / 5 / 4;
      }
    }

    .price {
      grid-area: 5 / 1 / 5 / 2;
      max-height: 18px;

      @media ${mediaQueries.small.only} {
        grid-area: 5 / 3 / 6 / 4;
      }
    }
  `;
  const weightDescriptionStyles = css`
    grid-area: 3 / 1 / 4 / 4;
    margin-bottom: 8px;

    @media ${mediaQueries.small.only} {
      margin-bottom: 4px;
    }
  `;
  const dimensionGridStyle = css`
    grid-area: 4 / 1 / 5 / 4;
  `;
  const connectedSelectBoxStyles = css`
    display: grid;
    grid-area: 2 / 2 / 6 / 4;

    label {
      margin-inline-start: 0;
    }

    @media ${mediaQueries.small.only} {
      grid-area: 3 / 1 / 4 / 4;
    }

    @media ${mediaQueries.medium.up} {
      width: 320px;
    }

    @media ${mediaQueries.large.up} {
      width: 272px;
    }

    @media ${mediaQueries.xlarge.up} {
      width: 336px;
    }
  `;
  const selectBoxOptionLabelWrapperStyles = css`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
  `;
  const selectBoxOptionLabelTextStyle = css`
    white-space: nowrap;
  `;
  return {
    cabinBaggageProductLayoutStyle,
    cabinBaggageBodyGridLayoutStyle,
    cabinBaggageCtaGridLayoutStyle,
    weightDescriptionStyles,
    dimensionGridStyle,
    connectedSelectBoxStyles,
    selectBoxOptionLabelWrapperStyles,
    selectBoxOptionLabelTextStyle
  };
};