import { abtests } from '@eti/utils';
import { useLocation } from 'react-router-dom';
import { getRouteNameByPathName } from '../../../constants/routesAndApisConstants';

export const withAbTests = (WrappedComponent) => (props) => {
  const location = useLocation();
  abtests.executeAbTestCallbacks(getRouteNameByPathName(location.pathname));
  return <WrappedComponent {...props} />;
};

export default withAbTests;
