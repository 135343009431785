import { formValueSelector } from 'redux-form';
import { MEAL } from '../../products';
import { NO_OPTION } from './mealUtil';
const formSection = MEAL.name;
const getGraphQlSelection = _ref => {
  let {
    state,
    form
  } = _ref;
  const formData = formValueSelector(form)(state, formSection);
  if (!formData) {
    return null;
  }
  const selected = formData.filter(_ref2 => {
    let {
      value
    } = _ref2;
    return String(value) !== NO_OPTION;
  });
  if (!selected || !selected.length) {
    return null;
  }
  return {
    productId: String(MEAL.id),
    selectionTravelerChoice: selected.map(value => ({
      travelerId: Number(value.travelerId),
      choiceId: Number(value.choiceId)
    }))
  };
};
export default getGraphQlSelection;