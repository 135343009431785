import { change as changeFormValue } from 'redux-form';
import { travelerForm } from '@eti/travelers';
import { date, constants } from '@eti/utils';
import { LOGIN, PRODUCTS } from '../../../constants/formNamesConstants';
import {
  ADDRESS_FORM_SECTION,
  addressFields,
  nameFields,
  PAYMENT_CONDITIONS,
} from '../../../pages/payment/constants/formFields';
import {
  cardFields,
  CARD_FORM_SECTION,
} from '../../../pages/payment/payment-methods/card/constants/formFields';
import {
  CONTACT_INFO_FORM_SECTION,
  contactFields,
} from '../../../pages/traveler-details/constants/formFields';

const { ADULT, CHILD, INFANT } = constants.ageTypes;
const {
  EMAIL,
  EMAIL_CONFIRM,
  EMERGENCY_LAST_NAME,
  EMERGENCY_PHONE,
  EMERGENCY_PHONE_COUNTRY_ID,
  PHONE,
  PHONE_COUNTRY_ID,
} = contactFields;

const travelersMock = [
  {
    ageType: ADULT,
    id: '0',
    price: 19800,
    markup: 9900,
  },
  {
    ageType: ADULT,
    id: '1',
    price: 19800,
    markup: 9900,
  },
  {
    ageType: CHILD,
    id: '2',
    price: 19800,
    markup: 9900,
  },
  {
    ageType: INFANT,
    id: '3',
    markup: 9900,
  },
];

export const isDev = process.env.NODE_ENV === 'development';

export const fillExtraProductsForm = (dispatch) => {
  dispatch(
    changeFormValue(PRODUCTS, 'checkInBaggage', {
      'traveler-0': {
        value: true,
        id: 0,
        priceRaw: 17741,
      },
      'traveler-1': {
        value: true,
        id: 1,
        priceRaw: 29289,
      },
      'traveler-2': {
        value: true,
        id: 2,
        priceRaw: 12972,
      },
      'traveler-3': {
        id: '3',
        price: 198,
        value: false,
      },
      'checkInBaggage-toggleTravelers': true,
    }),
  );
  dispatch(
    changeFormValue(PRODUCTS, 'onlineCheckIn', { value: { priceRaw: 39534, value: false } }),
  );
  dispatch(
    changeFormValue(PRODUCTS, 'travelInsuranceInsideEu', {
      value: { priceRaw: 79014, value: false },
    }),
  );
  dispatch(
    changeFormValue(PRODUCTS, 'seatingBesides', { value: { priceRaw: 13724, value: false } }),
  );
  dispatch(
    changeFormValue(PRODUCTS, 'cancellationInsideEu', { value: { priceRaw: 94204, value: false } }),
  );
  dispatch(changeFormValue(PRODUCTS, 'simpleVisa', { value: { value: false } }));
  dispatch(
    changeFormValue(PRODUCTS, 'cancellationGuarantee', {
      value: { priceRaw: 66687, value: false },
    }),
  );
};

export const fillContactForm = (change) => {
  change(`${CONTACT_INFO_FORM_SECTION}.${EMAIL}`, 'devtest@etraveli.com');
  change(`${CONTACT_INFO_FORM_SECTION}.${EMAIL_CONFIRM}`, 'devtest@etraveli.com');
  change(`${CONTACT_INFO_FORM_SECTION}.${PHONE_COUNTRY_ID}`, 4);
  change(`${CONTACT_INFO_FORM_SECTION}.${PHONE}`, '1234567123');
  change(`${CONTACT_INFO_FORM_SECTION}.${EMERGENCY_PHONE}`, '1234567124');
  change(`${CONTACT_INFO_FORM_SECTION}.${EMERGENCY_PHONE_COUNTRY_ID}`, 3);
  change(`${CONTACT_INFO_FORM_SECTION}.${EMERGENCY_LAST_NAME}`, 'Kula');
};

const fetchTravelersIBE = async () => {
  const response = await fetch(`/rpc.debug.travellers.query.do.action`);
  const travelersData = await response.json();

  travelersData.model.travelers.sort(
    (traveler1, traveler2) => traveler1.dateOfBirth.year - traveler2.dateOfBirth.year,
  );

  return travelersData.model.travelers.map(({ gender, firstName, lastName, dateOfBirth }) => ({
    gender: gender.toLowerCase(),
    firstName,
    lastName,
    dateOfBirth: date.format(
      new Date(dateOfBirth.year, dateOfBirth.month, dateOfBirth.day),
      'yyyy-MM-dd',
    ),
  }));
};

const fetchTravelersClient = async (travelers) => {
  const ageTypes = travelers.map((traveler) => traveler.ageType).join('.');
  const response = await fetch(`/rpc.debug.fill-form.traveler-details.action?ageTypes=${ageTypes}`);
  const { travelers: travelersData } = await response.json();
  return travelersData;
};

const fillProducts = (change, muxProduct = {}) => {
  change('onlineCheckIn', { value: { priceRaw: 39534, value: false } });
  change('airHelp', { value: { priceRaw: 39534, value: false } });
  change('flexibleTicket', { value: [{ price: '0 SEK', priceRaw: 0, value: 'NONE' }] });
  if (
    document.querySelector(
      '[data-testid^="traveler-details-extra-product-"] div[data-testid="seatMap-container"]',
    )
  ) {
    change('seatMap', { value: { priceRaw: 0, value: false } });
  }
  change('ancillaryBundle', { value: { id: '1', price: 0, value: 'BASIC' } });
  change('cancellationInsideEu', { value: { priceRaw: 94204, value: false } });
  change('cancellationOutsideEu', { value: { priceRaw: 94204, value: false } });
  change('meal', [
    { travelerId: '0', label: 'No thanks', code: 'NO_OPTION', value: 'NO_OPTION' },
    { travelerId: '1', label: 'No thanks', code: 'NO_OPTION', value: 'NO_OPTION' },
    { travelerId: '2', label: 'No thanks', code: 'NO_OPTION', value: 'NO_OPTION' },
  ]);
  change('travelInsuranceInsideEu', { value: { priceRaw: 94204, value: false } });
  change('travelInsuranceOutsideEu', { value: { priceRaw: 94204, value: false } });
  change('baggageService', { value: { priceRaw: 94204, value: false } });
  change('sms', { value: { priceRaw: 94204, value: false } });
  change('mobileTravelPlan', { value: { priceRaw: 94204, value: false } });
  change('allInclusiveProtection', { value: { value: false } });
  change('tripCancellationProtection', { value: { value: false } });
  change('simpleVisa', { value: { value: false } });
  change('cancellationGuarantee', { value: { priceRaw: 66687, value: false } });
  change('cancelForAnyReason', { value: { value: false } });
  change('bankruptcyInsuranceCoverGenius', { value: { value: false } });
  change('cancellationInsuranceCoverGenius', { value: { value: false } });
  change('travelInsuranceCoverGenius', { value: { value: false } });
  change('comprehensiveInsuranceCoverGenius', { value: { value: false } });
  change('cancelTwentyFourHours', { value: { value: false } });
  change('fastTrack', { value: { value: false } });
  change('platinum', { value: { value: false } });

  if (muxProduct && Object.keys(muxProduct)?.length !== 0) {
    change('mutuallyExclusive', { ...muxProduct });
  }
};

export const fillTravelersForm = async (change, travelers, muxProduct = {}) => {
  const travelersData = isDev ? await fetchTravelersClient(travelers) : await fetchTravelersIBE();
  const futureYear = new Date().getFullYear() + 1;

  travelersData.forEach(({ gender, firstName, lastName, dateOfBirth }, index) => {
    const key = `${travelerForm.TRAVELER_FORM_SECTION_NAME}-${index}`;

    change(`${key}.${travelerForm.GENDER}-${index}`, gender);
    change(`${key}.${travelerForm.FIRST_NAME}-${index}`, firstName);
    change(`${key}.${travelerForm.LAST_NAME}-${index}`, lastName);
    change(`${key}.${travelerForm.DATE_OF_BIRTH}-${index}`, dateOfBirth);
    change(`${key}.${travelerForm.TRAVEL_DOCUMENT_ISSUING_COUNTRY}`, {
      code: 'SE',
      label: 'Sweden',
      value: '2',
    });
    change(`${key}.${travelerForm.TRAVEL_DOCUMENT_NUMBER}-${index}`, '123456789');
    change(`${key}.${travelerForm.TRAVEL_DOCUMENT_EXPIRY_DATE}-${index}`, `${futureYear}-05-12`);
  });

  const defaultShowProductsPerTraveler =
    sessionStorage.getItem('mockedShowProductsPerTraveler') || 'yes';

  if (defaultShowProductsPerTraveler === 'yes') {
    travelersMock.forEach((traveler, i) => {
      if (traveler.ageType !== INFANT) {
        change(`cabinBaggage.cabinBaggage-traveler-${i}`, {
          value: 'false',
        });
        change(`checkInBaggage.checkInBaggage-traveler-${i}`, {
          value: 'false',
          id: `${i}`,
        });
      }
    });
  } else {
    travelersMock.forEach((traveler, i) => {
      if (traveler.ageType !== INFANT) {
        change(`checkInBaggage.traveler-${i}`, {
          id: traveler.id,
          markup: traveler.markup,
          numberOfPieces: 1,
          priceRaw: traveler.price,
          value: true,
          weight: 100,
          weightUnit: 'kg',
        });
      }
    });
    change('checkInBaggage.checkInBaggage-toggleTravelers', { value: false });
    change('cabinBaggage', { value: { priceRaw: 0, value: false } });
  }
  fillProducts(change, muxProduct);
};

export const fillExtraServicesPageForm = (change) => {
  fillProducts(change);
};

export const fillPaymentForm = async (dispatch, change) => {
  const response = await fetch('/rpc.debug.fill-form.payment.action');
  const {
    firstName,
    lastName,
    cardNumber,
    cardTypeId,
    expiryDate,
    cvc,
    street,
    houseNumber,
    zipCode,
    city,
    country,
  } = await response.json();

  dispatch(change(`${ADDRESS_FORM_SECTION}.${nameFields.FIRST_NAME}`, firstName));
  dispatch(change(`${ADDRESS_FORM_SECTION}.${nameFields.LAST_NAME}`, lastName));
  dispatch(change(`${CARD_FORM_SECTION}.${cardFields.CARD_NUMBER}`, cardNumber));
  dispatch(change(`${CARD_FORM_SECTION}.${cardFields.CARD_TYPE_ID}`, cardTypeId));
  dispatch(change(`${CARD_FORM_SECTION}.${cardFields.EXPIRE_MONTH_YEAR}`, expiryDate));
  dispatch(change(`${CARD_FORM_SECTION}.${cardFields.CVC}`, cvc));
  dispatch(change(`${CARD_FORM_SECTION}.${nameFields.FIRST_NAME}`, firstName));
  dispatch(change(`${CARD_FORM_SECTION}.${nameFields.LAST_NAME}`, lastName));
  dispatch(change(`${ADDRESS_FORM_SECTION}.${addressFields.STREET}`, street));
  dispatch(change(`${ADDRESS_FORM_SECTION}.${addressFields.HOUSE_NUMBER}`, houseNumber));
  dispatch(change(`${ADDRESS_FORM_SECTION}.${addressFields.ZIP_CODE}`, zipCode));
  dispatch(change(`${ADDRESS_FORM_SECTION}.${addressFields.CITY}`, city));
  dispatch(change(`${ADDRESS_FORM_SECTION}.${addressFields.COUNTRY}`, country));
  dispatch(change(`${PAYMENT_CONDITIONS}`, true));
};

export const fillLoginForm = (dispatch) => {
  dispatch(changeFormValue(LOGIN, 'email', 'devtest@etraveligroup.com'));
  dispatch(changeFormValue(LOGIN, 'orderNumber', 'DEV001'));
};

export const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
