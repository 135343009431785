import payment from 'payment';
import { createValidator } from 'revalidate';
import { string as stringUtils } from '@eti/utils';
import { INVALID_CARD_NUMBER } from '../constants/defaultErrorMessages';
const isValidationDisabled = (validationDisabledCards, cardType) => validationDisabledCards.some(disabledCard => disabledCard.toUpperCase() === (cardType === null || cardType === void 0 ? void 0 : cardType.toUpperCase()));
export const isCardNumberOk = function (number, validationDisabledCards) {
  if (validationDisabledCards === void 0) {
    validationDisabledCards = [];
  }
  if (!number) {
    return number;
  }
  const cardType = payment.fns.cardType(number);
  const unknownCardType = cardType == null;
  const validationDisabled = unknownCardType || isValidationDisabled(validationDisabledCards, cardType);
  return validationDisabled || payment.fns.validateCardNumber(number);
};
const isValidCardNumber = validationDisabledCards => createValidator(message => cardNumber => {
  const rawNumber = stringUtils.stripSpaces(cardNumber);
  if (!isCardNumberOk(rawNumber, validationDisabledCards)) {
    return message || INVALID_CARD_NUMBER;
  }
  return null;
}, INVALID_CARD_NUMBER);
export default isValidCardNumber;