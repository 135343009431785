export default (function (texts, translate) {
  if (translate === void 0) {
    translate = t => t;
  }
  if (!texts) {
    return {};
  }
  return Object.entries(texts).reduce((acc, _ref) => {
    let [k, v] = _ref;
    return {
      ...acc,
      [k]: translate(v)
    };
  }, {});
});