import PropTypes from 'prop-types';
import { Input, Stack } from '@etg/wings';
import { css } from '@eti/styles';

const wrapperStyles = css`
  flex: 1;
`;

const InputLabel = ({ children, id, label }) => {
  return (
    <Stack className={wrapperStyles} spacing={4}>
      <Input.Label htmlFor={id}>{label}</Input.Label>
      {children}
    </Stack>
  );
};

InputLabel.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.string,
};

export default InputLabel;
