import { ChangeEvent, useState } from 'react';
import { Inline, Radio, RadioGroup, Stack } from '@etg/wings';
import { css } from '@eti/styles';
import { capitalize } from '../../utils';

const radioLegendStyles = css`
  color: #666;
  font-size: 0.75rem;
`;

interface RadioSelectionProps {
  className?: string;
  defaultValue: string;
  id: string;
  label: string;
  options?: {
    label: string;
    value: string;
  }[];
}

const RadioSelection = ({
  className,
  defaultValue,
  label,
  id,
  options = [],
}: RadioSelectionProps) => {
  const [selectedValue, setSelectedValue] = useState(
    () => sessionStorage.getItem(`mocked${capitalize(id)}`) || defaultValue,
  );
  const yesOptionId = `${id}Yes`;
  const noOptionId = `${id}No`;
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.target.value);
  };

  return (
    <RadioGroup
      className={className}
      name={id}
      onChange={handleChange}
      selectedValue={selectedValue}
    >
      <Stack spacing={8}>
        <legend className={radioLegendStyles}>{label}</legend>
        <Inline spacing={16}>
          {options.length > 0 ? (
            options.map((option) => {
              const optionId = `${option.value}-id`;
              return (
                <Radio.Label htmlFor={optionId} key={optionId}>
                  <Inline spacing={8}>
                    <Radio id={optionId} value={option.value} />
                    {option.label}
                  </Inline>
                </Radio.Label>
              );
            })
          ) : (
            <>
              <Radio.Label htmlFor={yesOptionId}>
                <Inline spacing={8}>
                  <Radio id={yesOptionId} value="yes" />
                  Yes
                </Inline>
              </Radio.Label>
              <Radio.Label htmlFor={noOptionId}>
                <Inline>
                  <Radio id={noOptionId} value="no" />
                  No
                </Inline>
              </Radio.Label>
            </>
          )}
        </Inline>
      </Stack>
    </RadioGroup>
  );
};

export default RadioSelection;
