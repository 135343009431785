export const getSeatMapSegmentText = function (activeSegment, seatMapSegmentTexts) {
  if (seatMapSegmentTexts === void 0) {
    seatMapSegmentTexts = [];
  }
  const item = seatMapSegmentTexts.find(x => activeSegment === `${x.boundIndex}-${x.segmentIndex}`);
  if (item && item.text) {
    const {
      text
    } = item;
    return text.length ? text : null;
  }
  return null;
};