import { useCallback, useState } from 'react';
import { publish } from '../../utils/customEvent';

export const useToggle = (initState = false, eventName = '', publishEvent = false) => {
  const [isOpen, setIsOpen] = useState(initState);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleToggle = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
    if (publishEvent) {
      publish(eventName, { isOpen });
    }
  };

  return { isOpen, handleOpen, handleClose, handleToggle };
};
