import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Heart, Warning, X } from '@phosphor-icons/react';
import isFunction from 'lodash/isFunction';
import throttle from 'lodash/throttle';
import { css, etiColors, resetButtonStyle } from '@eti/styles';
import {
  ContentWrapper as EtiContentWrapper,
  DangerousHtmlContent,
  variants,
} from '@eti/components';

const getIcon = (variant) => {
  if (variant === variants.GAIN_TRUST) {
    return <Heart color={etiColors.info.solid} size={20} weight="fill" />;
  }
  return <Warning color={etiColors.warning.solid} size={20} weight="thin" />;
};

const notificationWrapperStyles = (variant) => css`
  background-color: ${variant === variants.GAIN_TRUST ? etiColors.white : etiColors.warning.light};
  position: relative;
  z-index: 10;
`;

const contentWrapperStyles = css`
  display: flex;
  padding: 16px 32px 16px 16px;
  position: relative;

  [dir='rtl'] & {
    padding: 16px 16px 16px 32px;
  }
`;

const contentStyles = css`
  display: flex;
  flex-direction: column;
  line-height: 21px;

  h2 {
    font-size: 1rem;
    font-weight: 600;
  }

  div {
    font-size: 0.875rem;
  }
`;

const iconStyles = css`
  margin-right: 12px;

  html[dir='rtl'] & {
    margin-left: 12px;
    margin-right: 0;
  }
`;

const closeNotificationButtonStyles = css`
  display: flex;
  ${resetButtonStyle};
  padding: 5px;
  position: absolute;
  right: 3px;
  top: 3px;

  [dir='rtl'] & {
    left: 3px;
    right: unset;
  }
`;

const TopHeaderNotification = ({
  closeButtonLabel,
  content,
  id,
  title,
  setNotificationHeight,
  variant,
}) => {
  const [isVisible, setIsVisible] = useState(!sessionStorage.getItem(`hide-${id}`));
  const notificationRef = useRef(null);

  const handleCloseCallback = () => {
    setIsVisible(false);
    sessionStorage.setItem(`hide-${id}`, 'true');
    if (isFunction(setNotificationHeight)) {
      setNotificationHeight(0);
    }
  };

  const handleSetNotificationHeight = useCallback(() => {
    const { current } = notificationRef || {};
    if (isFunction(setNotificationHeight) && current) {
      setNotificationHeight(current.getBoundingClientRect().height);
    }
  }, [setNotificationHeight]);

  useEffect(() => {
    const handleResize = throttle(() => {
      handleSetNotificationHeight();
    }, 300);
    window.addEventListener('resize', handleResize, true);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleSetNotificationHeight]);

  useEffect(() => {
    const timeout = setTimeout(handleSetNotificationHeight, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [handleSetNotificationHeight]);

  return isVisible && content ? (
    <div className={notificationWrapperStyles(variant)} data-testid={id} ref={notificationRef}>
      <EtiContentWrapper>
        <div className={contentWrapperStyles}>
          <div className={iconStyles}>{getIcon(variant)}</div>
          <div className={contentStyles}>
            <h2>{title}</h2>
            <DangerousHtmlContent content={content} />
          </div>
          <button
            aria-label={closeButtonLabel}
            className={closeNotificationButtonStyles}
            data-testid={`${id}-close-button`}
            onClick={handleCloseCallback}
            type="button"
          >
            <X color={etiColors.black} size={24} weight="thin" />
          </button>
        </div>
      </EtiContentWrapper>
    </div>
  ) : null;
};

TopHeaderNotification.propTypes = {
  closeButtonLabel: PropTypes.string,
  content: PropTypes.string,
  id: PropTypes.string,
  setNotificationHeight: PropTypes.func,
  title: PropTypes.string,
  variant: PropTypes.oneOf([variants.WARNING, variants.GAIN_TRUST]),
};

export default TopHeaderNotification;
