import { date } from '@eti/utils';

export const formatGetDate = (pattern) => (value) => (value ? date.format(value, pattern) : '');

// We need to provide a function that sets the data to redux field even if it is not does anything,
// since we do provide a function to format the data
const formatSet = () => undefined;

export const formatSetDate = formatSet;

export const formatSetPassengers = formatSet;
