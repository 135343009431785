import { getPrice, getSelected, getGraphQlSelection } from '../single-choice/utils/selection';
import { FAST_TRACK } from '../products';
import FastTrack from './components/FastTrack';
const formSection = FAST_TRACK.name;
export const fastTrack = {
  formSection,
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  ...FAST_TRACK
};
export default FastTrack;