import { css } from '@eti/styles';
import { resolveColorByIconIndexAndType, isNeutral, createColorChart } from './color';
import { COLORS } from './constants';
const insertIf = (condition, element) => {
  if (condition) {
    return element;
  }
  return null;
};
const baseStyles = css`
  height: 100%;
  width: 100%;
`;
export const getCircleBackground = color => ({
  className: baseStyles,
  color,
  id: 'circle'
});
export const getIcon = _ref => {
  let {
    colors,
    getStyles,
    type,
    id,
    useJaggedBackground = false
  } = _ref;
  const notNeutral = !isNeutral(type);
  const iconColors = createColorChart(...colors);
  const icon = {
    className: css`
      ${getStyles(notNeutral)};
    `,
    color: notNeutral ? resolveColorByIconIndexAndType(1, type, iconColors) : COLORS.neutral,
    id
  };
  const circle = {
    className: baseStyles,
    color: resolveColorByIconIndexAndType(0, type, colors),
    id: useJaggedBackground ? 'star-circle' : 'circle'
  };
  const insertIfOutput = insertIf(notNeutral, circle);
  if (insertIfOutput) {
    return [insertIfOutput, icon];
  }
  return [icon];
};