import { formValueSelector } from 'redux-form';
export const getGraphQlSelection = (productId, formSection) => _ref => {
  var _formData$value$filte, _formData$value;
  let {
    form,
    state
  } = _ref;
  const formData = formValueSelector(form)(state, formSection) || {};
  const cardSelection = ((_formData$value$filte = (_formData$value = formData.value) === null || _formData$value === void 0 ? void 0 : _formData$value.filter(_ref2 => {
    let {
      exclude
    } = _ref2;
    return !exclude;
  })) !== null && _formData$value$filte !== void 0 ? _formData$value$filte : []).map(_ref3 => {
    let {
      value
    } = _ref3;
    return {
      travelerId: Number(value)
    };
  });
  const perTravelerSelection = Object.values(formData).filter(_ref4 => {
    let {
      value
    } = _ref4;
    return value === 'true';
  }).map(_ref5 => {
    let {
      id
    } = _ref5;
    return {
      travelerId: String(id)
    };
  });
  if (!cardSelection.length && !perTravelerSelection.length) {
    return null;
  }
  return {
    productId,
    selectionCabinBaggage: cardSelection.length ? cardSelection : perTravelerSelection
  };
};