import externalStore from 'store2';
import emitter from './emitter';
const store = externalStore.namespace('DEBUG');
export const set = (key, data) => {
  emitter.emit(key, data);
  store.set(key, data);
};
export const get = key => store.get(key);
export const getAll = () => store.getAll();
export const clear = () => store.clear();
export const remove = key => store.remove(key);