import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const TermsAfterBookingPage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      image="cookies"
      pageTitle={t('PageTitle.rf.terms-after-booking.action')}
      textKey="TermsAfterBooking.HtmlContent"
    />
  );
};
export default TermsAfterBookingPage;
