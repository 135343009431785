import { formValueSelector } from 'redux-form';
import { FLEXIBLE_TICKET } from '../../products';
const formSection = FLEXIBLE_TICKET.name;
const getGraphQlSelection = _ref => {
  var _formData$value;
  let {
    state,
    form
  } = _ref;
  const formData = formValueSelector(form)(state, formSection);
  const selectionTraveler = (formData === null || formData === void 0 || (_formData$value = formData.value) === null || _formData$value === void 0 ? void 0 : _formData$value.flatMap(_ref2 => {
    let {
      value
    } = _ref2;
    const parsedValue = Number(value);
    if (Number.isNaN(parsedValue)) {
      return [];
    }
    return value;
  })) || [];
  if (selectionTraveler.length === 0) {
    return null;
  }
  return {
    productId: String(FLEXIBLE_TICKET.id),
    selectionTraveler
  };
};
export default getGraphQlSelection;