import { BANKRUPTCY_INSURANCE_COVER_GENIUS } from '../products';
import { getPrice, getSelected, getGraphQlSelection } from '../single-choice/utils/selection';
import BankruptcyInsuranceCoverGenius from './components/BankruptcyInsuranceCoverGenius';
const {
  name: formSection
} = BANKRUPTCY_INSURANCE_COVER_GENIUS;
export const bankruptcyInsuranceCoverGenius = {
  formSection,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  ...BANKRUPTCY_INSURANCE_COVER_GENIUS
};
export default BankruptcyInsuranceCoverGenius;