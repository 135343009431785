import loadable from '@loadable/component';
import { compose } from 'redux';
import Loading from '../../../common/loading/components/Loading';

export const loadWithTimeout = (ms) => (promise) => {
  let timeout;
  return Promise.race([
    promise,
    new Promise((_, reject) => {
      timeout = setTimeout(
        () => reject(new Error(`Failed to load chunked component due to timeout`)),
        ms,
      );
    }),
  ]).then(
    (component) => {
      clearTimeout(timeout);
      return component;
    },
    (err) => {
      clearTimeout(timeout);
      throw err;
    },
  );
};

const delayLoading = (ms) => async (promise) => {
  await new Promise((res) => setTimeout(res, ms));
  return promise;
};

export const DELAY = 200;
export const TIMEOUT = 60 * 1000;

export const createLoadableComponent = (loader) => {
  return loadable(
    () => {
      return compose(loadWithTimeout(TIMEOUT), delayLoading(DELAY))(loader());
    },
    {
      fallback: <Loading />,
    },
  );
};
