import { useEffect } from 'react';
import { analytics } from '@eti/utils';
import { useProperty, useSnowplowTracking, useTranslation } from '@eti/providers';
import { getResponsivePageViewEvent } from '../../../common/data-layer/dataLayer';
import StatusPage from './StatusPage';

const ErrorPage = () => {
  const { t } = useTranslation();
  const { p } = useProperty();

  const { isSnowplowTrackingEnabled, sendTrackEvent } = useSnowplowTracking();
  analytics.useGADataLayerEffect(getResponsivePageViewEvent('/rf/error', 'Error', 'Error'));

  useEffect(() => {
    if (isSnowplowTrackingEnabled) {
      sendTrackEvent({ category: 'Error', action: 'Seen', label: 'Page' });
    }
  }, [isSnowplowTrackingEnabled, sendTrackEvent]);

  const errorPageHomeUrl = p('IBEClient.ErrorMessageLink.Url');
  return (
    <StatusPage
      links={
        errorPageHomeUrl && [
          {
            label: t('StatusPage.LinkLabel.Home'),
            href: errorPageHomeUrl,
          },
        ]
      }
      message={t('StatusPage.Error.Message')}
      title={t('StatusPage.Error.Title')}
    />
  );
};

export default ErrorPage;
