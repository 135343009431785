import { css } from '@emotion/css';
import { etiColors, mediaQueries } from '..';
const headingSizes = {
  1: [1.5, 1.875],
  2: [1.25, 1.5],
  3: [1.125, 1.375],
  4: [1, 1.125],
  5: [0.875, 1]
};
const headingMargins = {
  1: [0.5, 0.625],
  2: [0.1875, 0.5],
  3: [0.1875, 0.5],
  4: [0.1875, 0.5],
  5: [0, 0.3125]
};
const getMarginStyles = (withMargin, size) => withMargin ? css`
        margin-bottom: ${headingMargins[size][0]}rem;

        @media (min-width: 62em) {
          margin-bottom: ${headingMargins[size][1]}rem;
        }
      ` : null;
export const getHeadingStyles = (size, withMargin) => css`
  font-size: ${headingSizes[size][0]}rem;
  font-weight: ${size === 5 ? '700' : '400'};
  line-height: 1.3;
  ${getMarginStyles(withMargin, size)};

  @media (min-width: 62em) {
    font-size: ${headingSizes[size][1]}rem;
  }
`;
export const getTextStyles = () => css`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4;

  @media ${mediaQueries.large.up} {
    font-size: 1rem;
  }

  ul {
    margin-bottom: 0.25em;

    @media ${mediaQueries.large.up} {
      margin-bottom: 0.5em;
    }
  }

  & p,
  & li {
    margin-bottom: 0.25em;

    @media ${mediaQueries.large.up} {
      margin-bottom: 0.5em;
    }
  }

  & h1 {
    ${getHeadingStyles(1, true)};
  }

  & h2 {
    ${getHeadingStyles(2, true)};
  }

  & h3 {
    ${getHeadingStyles(3, true)};
  }

  & h4 {
    ${getHeadingStyles(4, true)};
  }

  & h5 {
    ${getHeadingStyles(5, true)};
  }

  & li {
    padding-left: 0.625em;
    position: relative;

    [dir='rtl'] & {
      padding-left: 0;
      padding-right: 0.625em;
    }

    &::before {
      background-color: ${etiColors.black};
      border-radius: 50%;
      content: '';
      height: 4px;
      left: 0;
      margin-right: 0.625em;
      position: absolute;
      top: 0.525em;
      width: 4px;

      [dir='rtl'] & {
        left: auto;
        margin-left: 0.625em;
        margin-right: 0;
        right: 0;
      }

      @media ${mediaQueries.large.up} {
        top: 0.615em;
      }
    }
  }

  & li:not(:last-child) {
    margin-bottom: 0.25em;

    @media ${mediaQueries.large.up} {
      margin-bottom: 0.5em;
    }
  }

  & *:last-child {
    margin-bottom: 0;
  }
`;