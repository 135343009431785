const setupAsyncInit = (clientID: string, onComplete: () => void) => {
  window.fbAsyncInit = () => {
    window.FB.init({
      appId: clientID,
      cookie: true,
      xfbml: true,
      version: 'v19.0',
    });

    if (onComplete) {
      onComplete();
    }
  };
};

const createSdkScriptTag = (languageCode: string, marketCode: string) => {
  const script = document.createElement('script');

  script.id = 'facebook-jssdk';
  script.src = `https://connect.facebook.net/${languageCode}_${marketCode}/sdk.js`;
  script.async = true;
  script.defer = true;

  document.head.appendChild(script);
};

export const asyncInit = (
  clientID: string,
  languageCode: string,
  marketCode: string,
  onComplete: () => void,
) => {
  if (!window.FB) {
    setupAsyncInit(clientID, onComplete);
    createSdkScriptTag(languageCode, marketCode);
  } else if (onComplete) {
    onComplete();
  }
};
