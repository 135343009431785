export const RESULT_SEARCH_NOT_POSSIBLE = { event: 'resultUnableToSearchMissingArguments' };
export const CAN_NOT_SELECT_TRIP = { event: 'canNotSelectTrip' };
export const TRAVELER_DETAILS_NO_TRIP = { event: 'travelerDetailsNoTrip' };
export const TRAVELER_DETAILS_NO_TRIP_NO_CART = { event: 'travelerDetailsNoTripNoCart' };
export const PAYMENT_PENDING = { event: 'paymentPendingPaymentMessageShown' };
export const PAYMENT_NO_RESERVATIONS_IN_CART = {
  event: 'paymentNoReservationsInCartMessageShown',
};

export const RESPONSIVE_PAGEVIEW_DETAILS = {
  HOME: { page_type: 'Home', page_type_details: 'General' },
  START: { page_type: 'Home', page_type_details: 'General' },
  RESULT: { page_type: 'Result', page_type_details: 'Result' },
  YOUR_SELECTED_TRIP: {
    page_type: 'Result - modify trip',
    page_type_details: 'Your selected trip',
  },
  NEW_BRAND_REDIRECTION: { page_type: 'New brand', page_type_details: '' },
  META_ENTRY: { page_type: 'Meta', page_type_details: 'Meta entry' },
  TRAVELER_DETAILS: { page_type: 'Traveler details', page_type_details: 'Traveler details' },
  PAYMENT: { page_type: 'Payment', page_type_details: 'Intent' },
  ORDER: { page_type: 'Order', page_type_details: 'Order' },

  POSTBOOKING_AUTHENTICATION: { page_type: 'Login', page_type_details: 'Postbooking' },
  POSTBOOKING_PRODUCTS: { page_type: 'Postbooking', page_type_details: 'Products' },

  PROCESSING_ORDER: { page_type: 'Order', page_type_details: 'Processing order' },
  PROCESSING_PIX: { page_type: 'Payment', page_type_details: 'Processing PIX' },
  PAYMENT_LINK: { page_type: 'Postbooking', page_type_details: 'Payment link' },
  PAYMENT_CASCADING_TRANSITION: { page_type: 'Payment', page_type_details: 'Transition' },

  ANCILLARY_STORE: { page_type: 'Postbooking', page_type_details: 'Products' },
  RECEIPT_PDF: { page_type: 'Order', page_type_details: 'Receipt PDF' },
  CORONA: { page_type: 'Information', page_type_details: 'Corona' },
  START_PAGE_IFRAME: { page_type: 'Home', page_type_details: 'Iframe' },
  DESTINATION: { page_type: 'Home', page_type_details: 'Destination' },

  ORDER_AUTHENTICATION: { page_type: 'Login', page_type_details: 'Order - login' },
  ORDER_LIST: { page_type: 'Order Details', page_type_details: 'Order List' },
  ORDER_DETAILS: { page_type: 'Order Details', page_type_details: 'Order Details' },

  ORDER_DETAILS_MANAGE_TRIP: { page_type: 'Order Details', page_type_details: 'Manage Trip' },
  ORDER_DETAILS_EXTRA_PRODUCTS: { page_type: 'Order Details', page_type_details: 'Extra Products' },
  ORDER_DETAILS_CONTACT_US: { page_type: 'Order Details', page_type_details: 'Contact Us' },
  ORDER_DETAILS_CHECK_IN: { page_type: 'Order Details', page_type_details: 'Check In' },
  ORDER_DETAILS_TRAVEL_INFORMATION: {
    page_type: 'Order Details',
    page_type_details: 'Travel Information',
  },
  CHANGE_TRIP: { page_type: 'Order Details', page_type_details: 'Change Trip' },

  ABOUT_US: { page_type: 'Information', page_type_details: 'About us' },
  COOKIES: { page_type: 'Information', page_type_details: 'Cookies' },
  TERMS_AFTER_BOOKING: { page_type: 'Information', page_type_details: 'Terms After Booking' },
  REFUNDS: { page_type: 'Information', page_type_details: 'Refunds' },
  TRUSTED_CHOICE: { page_type: 'Information', page_type_details: 'Trusted Choice' },
  IMPRESSUM: { page_type: 'Other', page_type_details: 'Other' },
  MOBILE_APP_DOWNLOAD: { page_type: 'Download', page_type_details: 'Download app' },
  PACKAGE_TRAVEL_INFO: {
    page_type: 'Information',
    page_type_details: 'Package Travel Information',
  },
  PRICE_DROP_PROTECTION: { page_type: 'Information', page_type_details: 'Price Drop Protection' },
  TRAVEL_INSURANCE: { page_type: 'Information', page_type_details: 'Travel Insurance' },
  SELF_TRANSFER: { page_type: 'Information', page_type_details: 'Self Transfer' },
  CANCELLATION_PROTECTION: {
    page_type: 'Information',
    page_type_details: 'Cancellation protection',
  },
  CONNECTION_GUARANTEE: { page_type: 'Information', page_type_details: 'Connection Guarantee' },
  BAGGAGE_INSURANCE: { page_type: 'Information', page_type_details: 'Baggage Insurance' },
  CLOSED_DOWN_BRAND: { page_type: 'Information', page_type_details: 'Closed Brands' },
  DISPUTE_RESOLUTION_PROCEDURE: { page_type: 'Other', page_type_details: 'Other' },

  CONTACT: { page_type: 'Information', page_type_details: 'Contact us' },
  CONTACT_BCOM_BUSINESS: {
    page_type: 'Information',
    page_type_details: 'Contact us bcom business',
  },

  TRAVEL_CONDITIONS: { page_type: 'Information', page_type_details: 'Travel Conditions' },
  TRAVEL_CONDITIONS_BCOM_BUSINESS: { page_type: 'Information', page_type_details: 'Other' },
  TRAVEL_CONDITIONS_EXTERNAL: { page_type: 'Information', page_type_details: 'Other' },
  CMS_CONTENT: { page_type: 'Other', page_type_details: 'Other' },
  PRIVACY_POLICY: { page_type: 'Information', page_type_details: 'Privacy Policy' },
  PRIVACY_POLICY_EXTERNAL: {
    page_type: 'Information',
    page_type_details: 'Privacy Policy External',
  },

  MOBILE_TRAVEL_PLAN_LOGIN: { page_type: 'Login', page_type_details: 'MTP' },
  MOBILE_TRAVEL_PLAN_FLIGHT_STATUS: { page_type: 'MTP', page_type_details: 'Flight status' },
  MOBILE_TRAVEL_PLAN: { page_type: 'MTP', page_type_details: 'Overview' },
};
