import { MEAL } from '../products';
import MealContainer from './containers/MealContainer';
import getIcon from './utils/icon';
import { getPrice } from './utils/price';
import { getNumberOfUnits, getSelected } from './utils/units';
import getGraphQlSelection from './utils/getGraphQlSelection';
const formSection = MEAL.name;
export const meal = {
  formSection,
  getGraphQlSelection,
  getIcon,
  getNumberOfUnits,
  getPrice,
  getSelected,
  ...MEAL
};
export default MealContainer;