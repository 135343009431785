export const VISA_TRANSIT = 'VISA_TRANSIT';
export const VISA_TOURISM = 'VISA_TOURISM';
export const VISA_UNKNOWN = 'VISA_UNKNOWN';
export const VISA_NOT_NEEDED = 'VISA_NOT_NEEDED';
export const VISA_NOT_AVAILABLE = 'VISA_NOT_AVAILABLE';
export const visaTypes = {
  VISA_TRANSIT,
  VISA_TOURISM,
  VISA_UNKNOWN,
  VISA_NOT_NEEDED,
  VISA_NOT_AVAILABLE
};