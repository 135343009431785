import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const BcomBusinessTravelConditionsPage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      pageTitle={t('PageTitle.rf.bcombusiness.travel-conditions.action')}
      textKey="TravelConditions.BcomBusiness.Text"
    />
  );
};

export default BcomBusinessTravelConditionsPage;
