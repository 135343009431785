export const convertToLocalDateString = (dateString: string) => {
  const forceTimeResetSuffix = 'T00:00:00';
  return `${dateString}${forceTimeResetSuffix}`;
};

export const convertDateStringOrTimestamp = (dateStringOrTimestamp: string | Date) => {
  return typeof dateStringOrTimestamp === 'string'
    ? convertToLocalDateString(dateStringOrTimestamp.substring(0, 10))
    : dateStringOrTimestamp;
};
