import { css, etiColors } from '@eti/styles';
export const defaultStyles = css`
  appearance: none;
  background-color: ${etiColors.white};
  border: 1px solid ${etiColors.grayScale[7]};
  border-radius: 0.1875rem;
  color: ${etiColors.black};
  font-family: inherit;
  font-size: 1rem;
  padding: 0 1rem;

  &::placeholder {
    color: #a7a7a7;
  }
`;
export const isRequiredStyles = css`
  background-color: ${etiColors.white};
`;
export const errorStyles = css`
  border-color: ${etiColors.critical.solid};
`;
export const labelStyles = css`
  align-items: flex-end;
  color: ${etiColors.black};
  display: flex;
  font-size: 0.875rem;
  height: 38px;
  margin-left: 0.5rem;
  margin-top: 0.3125rem;

  [dir='rtl'] & {
    margin-left: 0;
    margin-right: 0.5rem;
  }
`;