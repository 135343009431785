import { SelectBox } from '@etg/wings';
import { useTranslation } from '@eti/providers';
import { Option } from '../models';

interface CurrencySelectProps {
  value: Option;
  onChange: (option: Option) => void;
  options: Option[];
}

const CurrencySelect = ({ value, onChange, options }: CurrencySelectProps) => {
  const { t } = useTranslation();
  const label = t('Settings.Currency.Label');

  return (
    <SelectBox
      data-testid="settings-currency-dropdown"
      label={label}
      name="currencySelect"
      onChange={onChange}
      options={options}
      placeholder={label}
      value={value}
    />
  );
};

export default CurrencySelect;
