import { createValidator } from 'revalidate';
import { INVALID_EMAIL_ADDRESS } from '../constants/defaultErrorMessages';
export const convertListOfStringsToRegularExpressions = function (listOfStrings) {
  if (listOfStrings === void 0) {
    listOfStrings = [];
  }
  return listOfStrings.filter(domain => domain).map(domain => new RegExp(domain));
};
export const isEmailBlocked = (email, domains) => {
  return domains.some(domain => domain.test(email));
};
const isBlockedEmail = function (blockedEmailDomains) {
  if (blockedEmailDomains === void 0) {
    blockedEmailDomains = [];
  }
  const domains = convertListOfStringsToRegularExpressions(blockedEmailDomains);
  return createValidator(message => value => {
    if (isEmailBlocked(value, domains)) {
      return message || INVALID_EMAIL_ADDRESS;
    }
    return undefined;
  }, INVALID_EMAIL_ADDRESS);
};
export default isBlockedEmail;