import { gql } from '@eti/schema-types';

export const SET_SETTINGS = gql(/* GraphQL */ `
  mutation SetSettings($localeCode: String!, $regionCode: String, $currencyCode: String) {
    setSettings(localeCode: $localeCode, regionCode: $regionCode, currencyCode: $currencyCode) {
      result
      redirectUrl
    }
  }
`);
