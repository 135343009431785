import createValidator from 'revalidate/lib/createValidator';
import differenceInYears from 'date-fns/differenceInYears';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import { date } from '@eti/utils';
import { INVALID_ADULT_BIRTH_DATE } from '../constants/defaultErrorMessages';
const isValidAdultBirthDate = function (inBoundDate, format, adultMinimumAge) {
  if (format === void 0) {
    format = 'yyyy-MM-dd';
  }
  if (adultMinimumAge === void 0) {
    adultMinimumAge = 18;
  }
  return createValidator(message => input => {
    const birthDate = parse(input, format, new Date());
    const parsedInBoundDate = date.convertToDate(inBoundDate);
    const validInBoundDate = isValid(parsedInBoundDate);
    const validBirthDate = isValid(birthDate);
    if (!validInBoundDate || !input) {
      return null;
    }
    if (!validBirthDate) {
      return message || INVALID_ADULT_BIRTH_DATE;
    }
    return differenceInYears(parsedInBoundDate, birthDate) >= adultMinimumAge ? null : message || INVALID_ADULT_BIRTH_DATE;
  }, INVALID_ADULT_BIRTH_DATE);
};
export default isValidAdultBirthDate;