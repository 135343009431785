import { price } from '@eti/utils';
import { ThemeProvider } from '@etg/wings';
import { Provider } from 'react-redux';
import { AuthApolloProvider } from '@eti/authentication';
import {
  DirectionProvider,
  DIRECTIONS,
  PropertiesProvider,
  SearchProvider,
  TranslationProvider,
} from '@eti/providers';
import { BrowserRouter as Router } from 'react-router-dom';
import { LazyMotion, domMax } from 'framer-motion';
import { generateTextResolverConfig } from '../utils/textResolverConfigUtil';
import { createStore } from '../utils/storeUtil';
import MainContainer from '../containers/MainContainer';
import ErrorBoundary from '../../infrastructure/error-boundaries/components/ErrorBoundary';
import getBrandCode from '../../utils/brandCode';
import { setInitialSearchContextData } from '../utils/searchProviderDataUtil';

// TODO: Pass correct format to `PriceFormatter`
price.setPriceFormatter(price.createPriceFormatter(window.spa_variables.priceFormat));

const App = () => {
  const { debugEnabled, properties, texts, isRTL, searchParameters, siteContext } =
    window.spa_variables;
  const store = createStore({}, debugEnabled);
  const translationsConfigOptions = generateTextResolverConfig(debugEnabled);
  const direction = isRTL ? DIRECTIONS.RTL : DIRECTIONS.LTR;
  const brandCode = getBrandCode({ brand: siteContext.brand, partner: siteContext.partner });
  const searchData = setInitialSearchContextData(
    searchParameters,
    properties['Result.QuickSort.Order'],
  );

  return (
    <Router basename={window.spa_variables.basePath}>
      <LazyMotion features={domMax} strict>
        <TranslationProvider options={translationsConfigOptions} translations={texts}>
          <DirectionProvider direction={direction}>
            <AuthApolloProvider brandCode={brandCode}>
              <ThemeProvider brand={brandCode}>
                <SearchProvider searchData={searchData}>
                  <Provider store={store}>
                    <PropertiesProvider properties={properties}>
                      <ErrorBoundary>
                        <MainContainer />
                      </ErrorBoundary>
                    </PropertiesProvider>
                  </Provider>
                </SearchProvider>
              </ThemeProvider>
            </AuthApolloProvider>
          </DirectionProvider>
        </TranslationProvider>
      </LazyMotion>
    </Router>
  );
};

export default App;
