import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const AncillaryStorePage = () => {
  const { t } = useTranslation();

  return (
    <SubPage pageTitle={t('PageTitle.rf.ancillarystore.action')} textKey="AncillaryStore.Text" />
  );
};

export default AncillaryStorePage;
