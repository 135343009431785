export const insertArgument = function (text) {
  if (text === '') {
    return '';
  }
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }
  return text ? args.reduce((formatted, arg, i) => formatted.split(`{${i}}`).join(arg), text) : null;
};
export const sliceStart = (str, i) => {
  const index = i > str.length ? str.length : i;
  return typeof index === 'number' ? str.slice(Math.abs(index), str.length) : str;
};
export const sliceEnd = (str, i) => {
  const index = i > str.length ? str.length : i;
  return typeof index === 'number' ? str.slice(0, -Math.abs(index)) : str;
};
export const removeFirst = str => sliceStart(str, 1);
export const removeLast = str => sliceEnd(str, 1);
export const getStart = (str, i) => {
  const index = i > str.length ? str.length : i;
  return typeof index === 'number' ? str.slice(0, index) : str;
};
export const getEnd = (str, i) => {
  const index = i > str.length ? str.length : i;
  return typeof index === 'number' ? str.slice(str.length - index) : str;
};
export const firstToUpperCase = str => {
  if (typeof str !== 'string') {
    throw new TypeError(`Expected a \`string\`, got \`${typeof str}\``);
  }
  return str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
};
export const stripSpaces = str => str ? str.replace(/\s/g, '') : null;