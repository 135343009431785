import { Button, vars } from '@etg/wings';
import { useSiteContext } from '@eti/providers';
import { css, cx } from '@eti/styles';
import { constants } from '@eti/utils';
import { Globe } from '@phosphor-icons/react';

const getSettingsButtonStyles = (iconOnly?: boolean) => css`
  border-color: ${vars.colors.inputs.border};
  height: 40px;
  padding-inline: 8px;
  padding-inline-end: ${iconOnly ? '8px' : '11px'};
  width: fit-content;
`;

const labelStyles = css`
  font-size: 0.875rem;
  font-weight: 400;
`;

const settingButtonBrandedStyles = (brandCode: string) => {
  const { brands } = constants;

  switch (brandCode) {
    case brands.GOTOGATE:
    case brands.FLIGHTNETWORK:
      return css`
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff;
        white-space: nowrap;

        &:hover {
          background-color: #fff;
          border-color: #000;
          color: #000;
        }
      `;
    case brands.MYTRIP: {
      return css`
        background-color: #fff;
        border: 1px solid #000;
        color: #000;
        white-space: nowrap;

        &:hover {
          background-color: #000;
          border-color: #fff;
          color: #fff;
        }
      `;
    }
    case brands.ADR: {
      return css`
        border-color: #000;
        color: #000;

        &:hover {
          background-color: #f7ba88;
          border-color: #000;
          color: #000;
        }
      `;
    }
    default:
      return css`
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 3px;
        color: #000;
        white-space: nowrap;

        &:hover {
          background-color: #000;
          border-color: #fff;
          color: #fff;
        }
      `;
  }
};

interface SettingsButtonProps {
  children: string;
  onClick: () => void;
  iconOnly?: boolean;
}

const SettingsButton = ({ children, onClick, iconOnly }: SettingsButtonProps) => {
  const {
    brand: { code: brandCode },
  } = useSiteContext();
  return (
    <Button
      className={cx(getSettingsButtonStyles(iconOnly), settingButtonBrandedStyles(brandCode))}
      data-testid="settings-selection-button"
      onClick={onClick}
      type="button"
      variant="secondary"
    >
      <>
        <Globe data-testid="language-globe" size={28} weight="light" />
        {!iconOnly && (
          <span className={labelStyles} data-testid="language-label">
            {children}
          </span>
        )}
      </>
    </Button>
  );
};

export default SettingsButton;
