import { gql } from '@eti/schema-types';

export const GET_PRICES_PER_DAY = gql(/* GraphQL */ `
  # Fetch best prices per day for 13 months in batches of 99 days at a time.
  query getBestPricesPerDay(
    $destination: String!
    $numberOfDays: Int!
    $origin: String!
    $startDate1: String!
    $startDate2: String!
    $startDate3: String!
    $startDate4: String!
    $startDate5: String!
    $startDate6: String!
    $startDate7: String!
  ) {
    from0To57Days: bestPricesPerDay(
      destination: $destination
      origin: $origin
      numberOfDays: $numberOfDays
      startDate: $startDate1
    ) {
      bestPricesPerDay {
        date
        priceCategory
      }
    }
    from57To114Days: bestPricesPerDay(
      destination: $destination
      origin: $origin
      numberOfDays: $numberOfDays
      startDate: $startDate2
    ) {
      bestPricesPerDay {
        date
        priceCategory
      }
    }
    from114To171Days: bestPricesPerDay(
      destination: $destination
      origin: $origin
      numberOfDays: $numberOfDays
      startDate: $startDate3
    ) {
      bestPricesPerDay {
        date
        priceCategory
      }
    }
    from171To228Days: bestPricesPerDay(
      destination: $destination
      origin: $origin
      numberOfDays: $numberOfDays
      startDate: $startDate4
    ) {
      bestPricesPerDay {
        date
        priceCategory
      }
    }
    from228To285Days: bestPricesPerDay(
      destination: $destination
      origin: $origin
      numberOfDays: $numberOfDays
      startDate: $startDate5
    ) {
      bestPricesPerDay {
        date
        priceCategory
      }
    }
    from285To342Days: bestPricesPerDay(
      destination: $destination
      origin: $origin
      numberOfDays: $numberOfDays
      startDate: $startDate6
    ) {
      bestPricesPerDay {
        date
        priceCategory
      }
    }
    from342To399Days: bestPricesPerDay(
      destination: $destination
      origin: $origin
      numberOfDays: $numberOfDays
      startDate: $startDate7
    ) {
      bestPricesPerDay {
        date
        priceCategory
      }
    }
  }
`);
