import { connect } from 'react-redux';
import { formValueSelector, change as changeFormValue } from 'redux-form';
import { constants } from '@eti/utils';
import { getTravelersPrimaryFlow } from '../utils/CheckInBaggageUtil';
import { CHECK_IN_BAGGAGE } from '../../products';
import CheckInBaggagePrimaryFlow from '../components/CheckInBaggagePrimaryFlow';
const productName = CHECK_IN_BAGGAGE.name;
export const setChoiceValueForTraveler = (form, value, dispatch, order, travelerId) => {
  var _order$sellSpecificat, _price$markup, _price$price;
  const baggageSpecification = ((_order$sellSpecificat = order.sellSpecification) === null || _order$sellSpecificat === void 0 ? void 0 : _order$sellSpecificat.sellPriceBaggage) || [];
  const {
    ancillaryCarrierPriceBreakdowns = [],
    price,
    maxWeight,
    weightUnit,
    numberOfUnits
  } = (baggageSpecification === null || baggageSpecification === void 0 ? void 0 : baggageSpecification.find(currTraveler => String(currTraveler === null || currTraveler === void 0 ? void 0 : currTraveler.travelerId) === String(travelerId))) || {};
  dispatch(changeFormValue(form, `${productName}.${productName}-traveler-${travelerId}`, {
    value: value !== null && value !== void 0 ? value : null,
    id: travelerId,
    ...(value === 'true' && {
      ...(ancillaryCarrierPriceBreakdowns && ancillaryCarrierPriceBreakdowns.length > 0 && {
        ancillaryCarrierPriceBreakdowns
      }),
      markup: (price === null || price === void 0 || (_price$markup = price.markup) === null || _price$markup === void 0 ? void 0 : _price$markup.value) || null,
      priceRaw: (price === null || price === void 0 || (_price$price = price.price) === null || _price$price === void 0 ? void 0 : _price$price.value) || 0,
      numberOfPieces: numberOfUnits,
      weight: maxWeight !== null && maxWeight !== void 0 ? maxWeight : null,
      weightUnit: weightUnit !== null && weightUnit !== void 0 ? weightUnit : null
    })
  }));
};
export const setCombinedChoiceValueForTraveler = (form, secondBagTraveler, value, dispatch, order, travelerId, orderSecondBag) => {
  var _order$sellSpecificat2, _orderSecondBag$sellS, _price$price2, _price$markup2;
  const {
    markup: secondbagmarkup = 0,
    price: secondbagprice = 0
  } = secondBagTraveler || {};
  const formattedValue = value === 'combined';
  const baggageSpecificationFirstBag = ((_order$sellSpecificat2 = order.sellSpecification) === null || _order$sellSpecificat2 === void 0 || (_order$sellSpecificat2 = _order$sellSpecificat2.sellPriceBaggage) === null || _order$sellSpecificat2 === void 0 ? void 0 : _order$sellSpecificat2[0]) || [];
  const {
    maxWeight: weightOfFirstBag,
    numberOfUnits: piecesOfFirstBag,
    weightUnit: weightUnitOfFirstBag,
    price
  } = baggageSpecificationFirstBag;
  const baggageSpecificationSecondBag = ((_orderSecondBag$sellS = orderSecondBag.sellSpecification) === null || _orderSecondBag$sellS === void 0 || (_orderSecondBag$sellS = _orderSecondBag$sellS.sellPriceBaggage) === null || _orderSecondBag$sellS === void 0 ? void 0 : _orderSecondBag$sellS[0]) || [];
  const {
    maxWeight: weightOfSecondBag,
    numberOfUnits: piecesOfSecondBag,
    weightUnit: weightUnitOfSecondBag
  } = baggageSpecificationSecondBag;
  const firstBagPrice = price === null || price === void 0 || (_price$price2 = price.price) === null || _price$price2 === void 0 ? void 0 : _price$price2.value;
  const firstBagMarkup = price === null || price === void 0 || (_price$markup2 = price.markup) === null || _price$markup2 === void 0 ? void 0 : _price$markup2.value;
  dispatch(changeFormValue(form, `${productName}.${productName}-traveler-${travelerId}`, {
    value: value !== null && value !== void 0 ? value : null,
    id: travelerId,
    ...(formattedValue && {
      markup: (secondbagmarkup !== null && secondbagmarkup !== void 0 ? secondbagmarkup : 0) + (firstBagMarkup !== null && firstBagMarkup !== void 0 ? firstBagMarkup : 0),
      priceRaw: (secondbagprice !== null && secondbagprice !== void 0 ? secondbagprice : 0) + (firstBagPrice !== null && firstBagPrice !== void 0 ? firstBagPrice : 0),
      numberOfPieces: weightOfFirstBag === weightOfSecondBag ? (piecesOfFirstBag || 0) + (piecesOfSecondBag || 0) : piecesOfFirstBag,
      piecesOfSecondBag,
      weight: weightOfFirstBag,
      weightOfSecondBag,
      weightUnit: weightUnitOfFirstBag,
      weightUnitOfSecondBag
    })
  }));
};
const getFilteredSecondBagTravelers = orderSecondBag => {
  const secondBagTravelers = getTravelersPrimaryFlow(orderSecondBag);
  return (secondBagTravelers === null || secondBagTravelers === void 0 ? void 0 : secondBagTravelers.filter(trav => trav.ageType !== constants.ageTypes.INFANT)) || [];
};
export const onChange = (dispatch, _ref) => {
  let {
    form,
    order,
    orderSecondBag
  } = _ref;
  return (selectionValue, selectedTravelerId, shouldApplySelectionToAll) => {
    const secondBagTravelers = getFilteredSecondBagTravelers(orderSecondBag);
    const selectedSecondBagTraveler = secondBagTravelers.find(_ref2 => {
      let {
        id
      } = _ref2;
      return id === selectedTravelerId;
    });
    const triggerChange = val => {
      var _order$travelers;
      const isFirstTraveler = selectedTravelerId === ((_order$travelers = order.travelers) === null || _order$travelers === void 0 || (_order$travelers = _order$travelers[0]) === null || _order$travelers === void 0 ? void 0 : _order$travelers.id);
      const applySelectionToAll = isFirstTraveler && shouldApplySelectionToAll;
      const handleCombinedChoice = () => {
        if (setCombinedChoiceValueForTraveler) {
          if (applySelectionToAll) {
            var _order$travelers2;
            (_order$travelers2 = order.travelers) === null || _order$travelers2 === void 0 || _order$travelers2.forEach(_ref3 => {
              let {
                ageType,
                id
              } = _ref3;
              if (ageType === constants.ageTypes.INFANT) {
                return;
              }
              const currentSecondBagTraveler = secondBagTravelers.find(secondBagTrav => secondBagTrav.id === id);
              setCombinedChoiceValueForTraveler(form, currentSecondBagTraveler, val, dispatch, order, id, orderSecondBag);
            });
          } else {
            dispatch(changeFormValue(form, `${productName}.shouldApplySelectionToAll`, false));
            setCombinedChoiceValueForTraveler(form, selectedSecondBagTraveler, val, dispatch, order, selectedTravelerId, orderSecondBag);
          }
        }
      };
      const handleIndividualChoice = () => {
        if (setChoiceValueForTraveler) {
          if (applySelectionToAll) {
            var _order$travelers3;
            (_order$travelers3 = order.travelers) === null || _order$travelers3 === void 0 || _order$travelers3.forEach(trav => {
              if (trav.ageType === constants.ageTypes.INFANT) {
                return;
              }
              setChoiceValueForTraveler(form, val, dispatch, order, trav.id);
            });
          } else {
            dispatch(changeFormValue(form, `${productName}.shouldApplySelectionToAll`, false));
            setChoiceValueForTraveler(form, val, dispatch, order, selectedTravelerId);
          }
        }
      };
      if (val === 'combined') {
        handleCombinedChoice();
      } else {
        handleIndividualChoice();
      }
    };
    triggerChange(selectionValue);
  };
};
export const onChangeAll = (dispatch, _ref4) => {
  let {
    form,
    order,
    orderSecondBag
  } = _ref4;
  return (checkboxValue, firstTravelerSelectionValue) => {
    var _order$travelers4;
    const secondBagTravelers = getFilteredSecondBagTravelers(orderSecondBag);
    dispatch(changeFormValue(form, `${productName}.shouldApplySelectionToAll`, checkboxValue));
    (_order$travelers4 = order.travelers) === null || _order$travelers4 === void 0 || _order$travelers4.slice(1).forEach(_ref5 => {
      let {
        ageType,
        id
      } = _ref5;
      if (ageType === constants.ageTypes.INFANT) {
        return;
      }
      const secondBagTraveler = secondBagTravelers.find(secondBagTrav => secondBagTrav.id === id);
      if (firstTravelerSelectionValue === 'combined') {
        if (setCombinedChoiceValueForTraveler) {
          setCombinedChoiceValueForTraveler(form, secondBagTraveler, checkboxValue ? firstTravelerSelectionValue : null, dispatch, order, id, orderSecondBag);
        }
      } else if (setChoiceValueForTraveler) {
        setChoiceValueForTraveler(form, checkboxValue ? String(firstTravelerSelectionValue) : null, dispatch, order, id);
      }
    });
  };
};
const mapStateToProps = (state, _ref6) => {
  var _formValueSelector;
  let {
    form,
    travelerNumber
  } = _ref6;
  const shouldApplySelectionToAll = formValueSelector(form)(state, `${productName}.shouldApplySelectionToAll`);
  const firstTravelerSelectionValue = (_formValueSelector = formValueSelector(form)(state, `${productName}.${productName}-traveler-0`)) === null || _formValueSelector === void 0 ? void 0 : _formValueSelector.value;
  return {
    shouldApplySelectionToAll: shouldApplySelectionToAll !== null && shouldApplySelectionToAll !== void 0 ? shouldApplySelectionToAll : true,
    productName,
    isFirstTraveler: travelerNumber === 0,
    firstTravelerSelectionValue
  };
};
const mapDispatchToProps = (dispatch, _ref7) => {
  let {
    form,
    order,
    orderSecondBag
  } = _ref7;
  return {
    onChange: onChange(dispatch, {
      form,
      order,
      orderSecondBag
    }),
    onChangeAll: onChangeAll(dispatch, {
      form,
      order,
      orderSecondBag
    })
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckInBaggagePrimaryFlow);