import { createValidator, composeValidators, isAlphaNumeric, isRequired, isNumeric } from '@eti/validation';
import { ALPHA_NUMERIC_VALIDATION_MESSAGE, INVALID_DOCUMENT_VALIDATION_MESSAGE, INVALID_PASSPORT_VALIDATION_MESSAGE, REQUIRED_DOCUMENT_VALIDATION_MESSAGE, REQUIRED_PASSPORT_VALIDATION_MESSAGE, RUSSIAN_INTERNATIONAL_PASSPORT_NUMBER_INPUT, RUSSIAN_PASSPORT_NUMBER_INPUT, BIRTH_CERTIFICATE_NUMBER_INPUT, MILITARY_ID_NUMBER_INPUT, INTERNATIONAL_PASSPORT_NUMBER_INPUT, FOREIGN_PASSPORT_NUMBER_INPUT, FOREIGN_DOCUMENT_NUMBER_INPUT, REQUIRED_CPF_VALIDATION_MESSAGE, INVALID_CPF_VALIDATION_MESSAGE, CPF } from './constants';
const isValidInternationalRussianPassportNumber = composeValidators(isRequired({
  message: REQUIRED_PASSPORT_VALIDATION_MESSAGE
}), isNumeric({
  message: INVALID_PASSPORT_VALIDATION_MESSAGE
}), createValidator(message => value => {
  if (!/^\d{9}$/.test(value)) {
    return message;
  }
  return undefined;
}, INVALID_PASSPORT_VALIDATION_MESSAGE));
const isValidRussianPassportNumber = composeValidators(isRequired({
  message: REQUIRED_PASSPORT_VALIDATION_MESSAGE
}), isNumeric({
  message: INVALID_PASSPORT_VALIDATION_MESSAGE
}), createValidator(message => value => {
  if (!/^\d{10}$/.test(value)) {
    return message;
  }
  return undefined;
}, INVALID_PASSPORT_VALIDATION_MESSAGE));
const isValidCPFNumber = composeValidators(isRequired({
  message: REQUIRED_CPF_VALIDATION_MESSAGE
}), isNumeric({
  message: INVALID_CPF_VALIDATION_MESSAGE
}), createValidator(message => value => {
  if (!/^\d{11}$/.test(value)) {
    return message;
  }
  return undefined;
}, INVALID_CPF_VALIDATION_MESSAGE));
const isValidBirthCertificateNumber = composeValidators(isRequired({
  message: REQUIRED_DOCUMENT_VALIDATION_MESSAGE
}), isAlphaNumeric({
  message: ALPHA_NUMERIC_VALIDATION_MESSAGE
}), createValidator(message => value => {
  if (!/^.{6,14}$/.test(value)) {
    return message;
  }
  return undefined;
}, INVALID_DOCUMENT_VALIDATION_MESSAGE));
const isValidMilitaryId = composeValidators(isRequired({
  message: REQUIRED_DOCUMENT_VALIDATION_MESSAGE
}), isAlphaNumeric({
  message: ALPHA_NUMERIC_VALIDATION_MESSAGE
}), createValidator(message => value => {
  if (!/^.{8,14}$/.test(value)) {
    return message;
  }
  return undefined;
}, INVALID_DOCUMENT_VALIDATION_MESSAGE));
const isValidInternationalPassport = composeValidators(isRequired({
  message: REQUIRED_PASSPORT_VALIDATION_MESSAGE
}), isAlphaNumeric({
  message: ALPHA_NUMERIC_VALIDATION_MESSAGE
}), createValidator(message => value => {
  if (!/[a-zA-Z0-9]{8}/.test(value)) {
    return message;
  }
  return undefined;
}, INVALID_PASSPORT_VALIDATION_MESSAGE));
const isValidForeignDocument = composeValidators(isRequired({
  message: REQUIRED_DOCUMENT_VALIDATION_MESSAGE
}), isAlphaNumeric({
  message: ALPHA_NUMERIC_VALIDATION_MESSAGE
}), createValidator(message => value => {
  if (!/[0-9]/.test(value)) {
    return message;
  }
  return undefined;
}, INVALID_DOCUMENT_VALIDATION_MESSAGE));
const isValidForeignPassport = composeValidators(isRequired({
  message: REQUIRED_PASSPORT_VALIDATION_MESSAGE
}), isAlphaNumeric({
  message: ALPHA_NUMERIC_VALIDATION_MESSAGE
}), createValidator(message => value => {
  if (!/[a-zA-Z0-9]/.test(value)) {
    return message;
  }
  return undefined;
}, INVALID_PASSPORT_VALIDATION_MESSAGE));
export const rules = id => {
  return {
    [`${CPF}-${id}`]: isValidCPFNumber(`${CPF}-${id}`),
    [`${RUSSIAN_INTERNATIONAL_PASSPORT_NUMBER_INPUT}-${id}`]: isValidInternationalRussianPassportNumber(`${RUSSIAN_INTERNATIONAL_PASSPORT_NUMBER_INPUT}-${id}`),
    [`${RUSSIAN_PASSPORT_NUMBER_INPUT}-${id}`]: isValidRussianPassportNumber(`${RUSSIAN_PASSPORT_NUMBER_INPUT}-${id}`),
    [`${BIRTH_CERTIFICATE_NUMBER_INPUT}-${id}`]: isValidBirthCertificateNumber(`${BIRTH_CERTIFICATE_NUMBER_INPUT}-${id}`),
    [`${MILITARY_ID_NUMBER_INPUT}-${id}`]: isValidMilitaryId(`${MILITARY_ID_NUMBER_INPUT}-${id}`),
    [`${INTERNATIONAL_PASSPORT_NUMBER_INPUT}-${id}`]: isValidInternationalPassport(`${INTERNATIONAL_PASSPORT_NUMBER_INPUT}-${id}`),
    [`${FOREIGN_PASSPORT_NUMBER_INPUT}-${id}`]: isValidForeignPassport(`${FOREIGN_PASSPORT_NUMBER_INPUT}-${id}`),
    [`${FOREIGN_DOCUMENT_NUMBER_INPUT}-${id}`]: isValidForeignDocument(`${FOREIGN_DOCUMENT_NUMBER_INPUT}-${id}`)
  };
};