import { useTranslation } from '@eti/providers';
import { css, etiColors } from '@eti/styles';
import SubPage from './SubPage';

const styles = () => css`
  .travelterms {
    margin-top: 1rem;
  }

  @media print {
    * {
      background-color: transparent;
      box-shadow: none;
      text-shadow: none;
    }

    body {
      background-color: #fff;
      color: #000;
      font-family: 'Arial', 'Helvetica', sans-serif;
      line-height: 17px;
      margin: 0;
      width: 100%;
    }

    p,
    li {
      font-size: 0.75rem;
    }
    p {
      margin-bottom: 0.5rem;
    }
    li {
      margin: 0;
      padding: 0;
    }

    h2 {
      font-size: 0.875rem;
      margin: 0;
      padding: 0;
    }

    header,
    footer,
    aside,
    nav,
    form,
    iframe,
    .image-panel,
    #debug-link,
    .sub-page-print-button,
    button,
    svg,
    image {
      display: none;
    }

    a {
      color: ${etiColors.info.focus};
    }

    ${'' /*  Don't show links that are fragment identifiers, */}
    ${'' /* or use the `javascript:` pseudo protocol */}
    a[href^='#']::after,
    a[href^='javascript:']::after {
      content: '';
    }
    .travelterms {
      margin-top: 0;

      [dir='rtl'] & {
        margin-right: 1rem;
      }
    }

    .travelterms ol {
      margin-bottom: 7px;
      margin-top: 7px;
    }
    .travelterms ol > li {
      font-weight: 700;
    }

    .travelterms ol ol > li {
      display: block;
      font-weight: 400;
      padding-bottom: 5px;
    }
    .travelterms span.traveltermsText {
      display: block;
      font-weight: 400;
      margin-bottom: 20px;
    }
  }
`;

const TravelConditionsPage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      className={styles()}
      image="travel-conditions"
      pageTitle={t('PageTitle.rf.travel-conditions.action')}
      shouldShowPrintButton
      textKey="TravelConditions.Text"
    />
  );
};

export default TravelConditionsPage;
