import { AIR_HELP, ALL_INCLUSIVE_PROTECTION, BAGGAGE_INSURANCE_COMBO_COVER_GENIUS, BAGGAGE_INSURANCE_COVER_GENIUS, BAGGAGE_INSURANCE_GADGET_COVER_GENIUS, BAGGAGE_SERVICE, BANKRUPTCY_INSURANCE_COVER_GENIUS, CABIN_BAGGAGE, CANCEL_FOR_ANY_REASON, CANCELLATION_GUARANTEE, CANCELLATION_INSIDE_EU, CANCELLATION_OUTSIDE_EU, CANCELLATION_INSURANCE_COVER_GENIUS, CHECK_IN_BAGGAGE, COMPREHENSIVE_INSURANCE_COVER_GENIUS, CANCEL_24_HOURS, CO2_COMPENSATION, FLEXIBLE_TICKET, MEAL, MOBILE_TRAVEL_PLAN, ONLINE_CHECK_IN, PLATINUM_SERVICE, SEATING_BESIDES, SEATING_LIGHT, SEAT_MAP, SERVICE_PACKAGE, SERVICE_PACKAGE_NO_TEXT, SIMPLE_VISA, SMS, TRAVEL_INSURANCE_COVER_GENIUS, TRAVEL_INSURANCE_INSIDE_EU, TRAVEL_INSURANCE_OUTSIDE_EU, TRIP_CANCELLATION_PROTECTION, FAST_TRACK } from '../../../products';
import { PERSONAL_ITEM } from '../../components/baggage-per-traveler/utils/constants';
export const getProductPictogramId = (productName, isRTL) => {
  const productPictogramMap = {
    [AIR_HELP.name]: 'air-help',
    [ALL_INCLUSIVE_PROTECTION.name]: isRTL ? 'insurance-comprehensive-RTL' : 'insurance-comprehensive',
    [BAGGAGE_INSURANCE_COMBO_COVER_GENIUS.name]: 'baggage-insurance',
    [BAGGAGE_INSURANCE_COVER_GENIUS.name]: 'baggage-insurance',
    [BAGGAGE_INSURANCE_GADGET_COVER_GENIUS.name]: 'baggage-insurance',
    [BAGGAGE_SERVICE.name]: 'baggage-insurance',
    [BANKRUPTCY_INSURANCE_COVER_GENIUS.name]: isRTL ? 'bankruptcy-insurance-RTL' : 'bankruptcy-insurance',
    [CABIN_BAGGAGE.name]: 'cabin-baggage-new',
    [CANCEL_FOR_ANY_REASON.name]: 'trip-cancel-for-any-reason',
    [CANCELLATION_GUARANTEE.name]: 'cancellation-guarantee',
    [CANCELLATION_INSIDE_EU.name]: 'cancellation-insurance-medical',
    [CANCELLATION_INSURANCE_COVER_GENIUS.name]: 'cancellation-insurance-medical',
    [CANCELLATION_OUTSIDE_EU.name]: 'cancellation-insurance-medical',
    [CHECK_IN_BAGGAGE.name]: 'checked-baggage-new',
    [COMPREHENSIVE_INSURANCE_COVER_GENIUS.name]: isRTL ? 'insurance-comprehensive-RTL' : 'insurance-comprehensive',
    [CANCEL_24_HOURS.name]: 'fallback',
    [CO2_COMPENSATION.name]: 'co2-compensation',
    [FLEXIBLE_TICKET.name]: 'flexible-ticket',
    [MEAL.name]: 'special-meal',
    [MOBILE_TRAVEL_PLAN.name]: 'mobile-travel-plan',
    [ONLINE_CHECK_IN.name]: 'check-in',
    [PLATINUM_SERVICE.name]: 'platinum',
    [SEATING_BESIDES.name]: 'seating',
    [SEATING_LIGHT.name]: 'seating',
    [SEAT_MAP.name]: 'seating',
    [SERVICE_PACKAGE.name]: 'support-bundle',
    [SERVICE_PACKAGE_NO_TEXT.name]: 'support-bundle',
    [SIMPLE_VISA.name]: 'simple-visa',
    [SMS.name]: 'booking-sms',
    [TRAVEL_INSURANCE_COVER_GENIUS.name]: 'global-medical-protection',
    [TRAVEL_INSURANCE_INSIDE_EU.name]: isRTL ? 'insurance-comprehensive-RTL' : 'insurance-comprehensive',
    [TRAVEL_INSURANCE_OUTSIDE_EU.name]: isRTL ? 'insurance-comprehensive-RTL' : 'insurance-comprehensive',
    [TRIP_CANCELLATION_PROTECTION.name]: 'trip-cancel-for-any-reason',
    [PERSONAL_ITEM.name]: 'personal-item',
    [FAST_TRACK.name]: 'fast-track'
  };
  return productPictogramMap[productName] || 'fallback';
};
export const getProductIllustrationId = (productName, isRTL) => {
  const productIllustrationMap = {
    [AIR_HELP.name]: 'air-help',
    [ALL_INCLUSIVE_PROTECTION.name]: isRTL ? 'insurance-comprehensive-RTL' : 'insurance-comprehensive',
    [BAGGAGE_SERVICE.name]: 'baggage-insurance',
    [BANKRUPTCY_INSURANCE_COVER_GENIUS.name]: isRTL ? 'bankruptcy-insurance-RTL' : 'bankruptcy-insurance',
    [CABIN_BAGGAGE.name]: 'cabin-baggage',
    [CANCEL_FOR_ANY_REASON.name]: isRTL ? 'trip-cancel-for-any-reason-RTL' : 'trip-cancel-for-any-reason',
    [CANCELLATION_GUARANTEE.name]: isRTL ? 'cancellation-guarantee-RTL' : 'cancellation-guarantee',
    [CANCELLATION_INSIDE_EU.name]: isRTL ? 'cancellation-insurance-medical-RTL' : 'cancellation-insurance-medical',
    [CANCELLATION_INSURANCE_COVER_GENIUS.name]: isRTL ? 'cancellation-insurance-medical-RTL' : 'cancellation-insurance-medical',
    [CANCELLATION_OUTSIDE_EU.name]: isRTL ? 'cancellation-insurance-medical-RTL' : 'cancellation-insurance-medical',
    [CHECK_IN_BAGGAGE.name]: 'checked-baggage',
    [COMPREHENSIVE_INSURANCE_COVER_GENIUS.name]: isRTL ? 'insurance-comprehensive-RTL' : 'insurance-comprehensive',
    [CANCEL_24_HOURS.name]: 'fallback',
    [CO2_COMPENSATION.name]: 'co2-compensation',
    [FLEXIBLE_TICKET.name]: 'flexible-ticket',
    [MEAL.name]: 'special-meal',
    [MOBILE_TRAVEL_PLAN.name]: 'mobile-travel-plan',
    [ONLINE_CHECK_IN.name]: 'check-in',
    [PLATINUM_SERVICE.name]: isRTL ? 'platinum-RTL' : 'platinum',
    [SEATING_BESIDES.name]: 'seating',
    [SEAT_MAP.name]: 'seating',
    [SERVICE_PACKAGE.name]: 'support-bundle',
    [SIMPLE_VISA.name]: 'simple-visa',
    [SMS.name]: 'booking-sms',
    [TRAVEL_INSURANCE_COVER_GENIUS.name]: 'global-medical-protection',
    [TRAVEL_INSURANCE_INSIDE_EU.name]: isRTL ? 'insurance-comprehensive-RTL' : 'insurance-comprehensive',
    [TRAVEL_INSURANCE_OUTSIDE_EU.name]: isRTL ? 'insurance-comprehensive-RTL' : 'insurance-comprehensive',
    [TRIP_CANCELLATION_PROTECTION.name]: isRTL ? 'trip-cancel-for-any-reason-RTL' : 'trip-cancel-for-any-reason',
    [PERSONAL_ITEM.name]: 'personal-item',
    [FAST_TRACK.name]: 'fast-track'
  };
  return productIllustrationMap[productName] || 'fallback';
};