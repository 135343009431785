import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const TravelInsurancePage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      image="cookies"
      pageTitle={t('PageTitle.rf.travel-insurance.action')}
      textKey="TravelInsurance.HtmlContent"
    />
  );
};

export default TravelInsurancePage;
