import PropTypes from 'prop-types';
import { location as locationPropTypes } from '../../trip-details/propTypes';

export const search = PropTypes.shape({
  cabinClass: PropTypes.string,
  destination: PropTypes.shape({
    ...locationPropTypes,
    arrivedAt: PropTypes.objectOf(Date),
    departuredAt: PropTypes.objectOf(Date),
  }),
  hasDirectFlight: PropTypes.bool,
  multiBound: PropTypes.arrayOf(PropTypes.shape),
  origin: PropTypes.shape({
    ...locationPropTypes,
    arrivedAt: PropTypes.objectOf(Date),
    departuredAt: PropTypes.oneOfType([PropTypes.objectOf(Date), PropTypes.string]),
  }),
  passengers: PropTypes.shape({
    adults: PropTypes.number,
    children: PropTypes.number,
    childrenAges: PropTypes.arrayOf(PropTypes.number),
    infantAges: PropTypes.arrayOf(PropTypes.number),
    infants: PropTypes.number,
  }),
  singleBound: PropTypes.shape({
    departureDate: PropTypes.objectOf(Date),
    destination: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    origin: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    returnDate: PropTypes.objectOf(Date),
  }),
  tripType: PropTypes.string,
});
