import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@etg/wings';
import { ContentWrapper, DangerousHtmlContent } from '@eti/components';
import { useTranslation } from '@eti/providers';
import { css, cx, etiColors, mediaQueries } from '@eti/styles';
import { Info, X } from '@phosphor-icons/react';
import Cookies from 'js-cookie';

const textStyles = css`
  font-size: 0.875rem;
  margin-bottom: 0.75rem;

  @media (min-width: 48em) {
    margin-bottom: 0;
  }
`;

const titleStyle = css`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 0.25rem;

  @media ${mediaQueries.large.up} {
    font-size: 1.125rem;
  }
`;

const buttonStyle = css`
  align-self: center;
  margin: 0 auto;
  max-width: 100%;
  min-width: 10rem;

  @media ${mediaQueries.medium.up} {
    margin-left: 26px;
    min-width: 220px;

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 26px;
    }
  }
`;

const containerStyle = css`
  backdrop-filter: blur(2px);
  background-color: hsl(207deg 100% 96% / 90%);
  bottom: 0;
  color: ${etiColors.black};
  left: 0;
  position: fixed;
  right: 0;
  z-index: 999999;

  @media print {
    display: none;
  }
`;

const contentWrapperStyles = css`
  padding-bottom: 10px;
  padding-right: 32px;
  padding-top: 10px;
  position: relative;

  [dir='rtl'] & {
    padding-left: 32px;
    padding-right: 10px;
  }

  @media ${mediaQueries.medium.up} {
    display: flex;
    padding-bottom: 20px;
    padding-right: 40px;
    padding-top: 20px;

    [dir='rtl'] & {
      padding-left: 40px;
      padding-right: 15px;
    }
  }
`;

const messageContainerStyles = css`
  display: flex;
`;

const iconStyle = css`
  flex-shrink: 0;
  height: 26px;
  margin-right: 10px;
  width: 26px;

  [dir='rtl'] & {
    margin-left: 10px;
    margin-right: 0;
  }

  @media ${mediaQueries.large.up} {
    height: 30px;
    margin-right: 20px;
    width: 30px;

    [dir='rtl'] & {
      margin-left: 20px;
      margin-right: 0;
    }
  }
`;

const closeBannerButtonStyles = css`
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  padding: 8px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;

  [dir='rtl'] & {
    left: 0;
    right: auto;
  }

  & svg {
    fill: #333;
  }

  &:hover svg {
    fill: #262626;
  }

  &:focus {
    outline: 0;
  }
`;

export const CONSENT_COOKIE_NAME = 'ibe.acceptedCookie';

const shouldShowBanner = () => {
  if (typeof document === 'undefined') {
    return null;
  }
  return !Cookies.get(CONSENT_COOKIE_NAME);
};

const giveConsent = () => {
  Cookies.set(CONSENT_COOKIE_NAME, true, { expires: 180, secure: true });
};

const CookieBanner = ({ className, dataTestId }) => {
  const [showBanner, setShowBanner] = useState(shouldShowBanner);
  const { t } = useTranslation();

  const title = t('CookieBanner.Title');
  const text = t('CookieBanner.Text');
  const buttonLabel = t('CookieBanner.ButtonLabel');

  const handleAccept = () => {
    setShowBanner(false);
    giveConsent();
  };

  return showBanner ? (
    <div className={cx('etiCookieBanner', containerStyle, className)} data-testid={dataTestId}>
      <ContentWrapper className={contentWrapperStyles}>
        <button
          className={closeBannerButtonStyles}
          data-testid="cookieBanner-closeButton"
          onClick={handleAccept}
          type="button"
        >
          <X aria-hidden className="etiModal__closeIcon" size="24px" />
        </button>
        <div className={messageContainerStyles}>
          <Info className={iconStyle} color={etiColors.info.solid} id="info-circle" weight="fill" />
          <div>
            <h2 className={cx(titleStyle, 'cookieBanner__title')}>{title}</h2>
            <DangerousHtmlContent className={textStyles} content={text} />
          </div>
        </div>
        <Button
          className={buttonStyle}
          data-testid="cookieBanner-confirmButton"
          onClick={handleAccept}
          variant="primary"
        >
          {buttonLabel}
        </Button>
      </ContentWrapper>
    </div>
  ) : null;
};

CookieBanner.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default CookieBanner;
