import { useEffect } from 'react';
import { useProperty } from '@eti/providers';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line camelcase
declare const window: Window & { spa_variables: any };

const useQualtricsExitModal = (): void => {
  const { p } = useProperty();
  const { pathname } = useLocation();

  const qualtricsId: string = p('Qualtrics.ExitModal.Id');
  const isQualtricsEnabled: boolean = p('Qualtrics.ExitModal.Enabled');
  const qualtricsPages: string[] = p('Qualtrics.ExitModal.Pages');
  const isPageIncludedInQualtricsPages: boolean = qualtricsPages?.includes(
    Object.keys(window.spa_variables.routes)[
      Object.values(window.spa_variables.routes).indexOf(pathname)
    ],
  );

  useEffect(() => {
    const toggleQualtrics = (): void => {
      if (!isQualtricsEnabled) {
        return;
      }

      const script: HTMLScriptElement = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'qualtricsID';
      script.dataset.testid = 'qualtricsExitModal-script';

      const div: HTMLDivElement = document.createElement('div');
      div.id = qualtricsId;
      div.dataset.testid = 'qualtricsDivId';

      div.innerHTML = '<!--DO NOT REMOVE-CONTENTS PLACED HERE-->';
      script.innerHTML = `(function(){var g=function(e,h,f,g){
          this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
          this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
          this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
          this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};
          this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};
          try{(new g(100,"r","QSI_S_${qualtricsId}","https://${qualtricsId}-etraveli.siteintercept.qualtrics.com/SIE/?Q_ZID=${qualtricsId}")).start()}catch(i){}})();
      `;

      const isQualtricsScriptLoaded: boolean =
        document.querySelectorAll(`script[id=${script.id}]`).length > 0;

      if (isPageIncludedInQualtricsPages && !isQualtricsScriptLoaded) {
        document.body.append(script, div);
      }

      if (!isPageIncludedInQualtricsPages && isQualtricsScriptLoaded) {
        script.remove();
        div.remove();
      }
    };

    toggleQualtrics();
  }, [isQualtricsEnabled, isPageIncludedInQualtricsPages, qualtricsId]);
};

export { useQualtricsExitModal };
