import { useApolloClient } from '@apollo/client';
import { FETCH_SEARCH_LOCATIONS_QUERY } from '../containers/queries';

const notEmpty = <T extends Record<string, unknown>>(value: T | null | undefined): value is T =>
  Boolean(value);

export const useFetchSearchLocations = () => {
  const client = useApolloClient();

  const getSearchLocations = async (searchTerm: string) => {
    if (searchTerm && searchTerm.length > 1) {
      const { data } = await client.query({
        query: FETCH_SEARCH_LOCATIONS_QUERY,
        variables: { searchTerm },
      });
      return data?.searchLocations.filter(notEmpty) ?? [];
    }
    return [];
  };

  return { getSearchLocations };
};
