export const CARD_FORM_SECTION = 'creditCardForm' as const;

export const cardFields = {
  CARD_INSTALMENTS: 'instalments',
  CARD_HOLDER_FIRST_NAME: 'cardHolderFirstName',
  CARD_HOLDER_LAST_NAME: 'cardHolderLastName',
  CARD_NUMBER: 'cardnumber',
  CARD_TYPE_ID: 'cardTypeId',
  CARD_TYPE_NAME: 'cardTypeName',
  COMMERCIAL_CARD_TYPE_ID: 'commercialCardTypeId',
  CVC: 'cvc',
  EXPIRE_MONTH_YEAR: 'cc-exp',
  INICIS_SELECTED_CARD_TYPE: 'inicisSelectedCardType',
  INICIS_PERSONAL_CARD: 'inicisPersonalCard',
  INICIS_CORPORATE_CARD: 'inicisCorporateCard',
  SELECTED_CARD_SCHEMA: 'selectedSchemaId',
} as const;
