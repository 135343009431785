export const AIR_HELP = {
  id: 5102,
  name: 'airHelp'
};
export const ALL_INCLUSIVE_PROTECTION = {
  id: 278,
  name: 'allInclusiveProtection'
};
export const BAGGAGE_INSURANCE_COMBO_COVER_GENIUS = {
  id: 615,
  name: 'baggageInsuranceComboCoverGenius',
  iconId: 'luggage-gadget'
};
export const BAGGAGE_INSURANCE_COVER_GENIUS = {
  id: 612,
  name: 'baggageInsuranceCoverGenius',
  iconId: 'luggage-blank'
};
export const BAGGAGE_INSURANCE_GADGET_COVER_GENIUS = {
  id: 616,
  name: 'baggageInsuranceGadgetCoverGenius',
  iconId: 'gadget'
};
export const BAGGAGE_SERVICE = {
  id: 270,
  name: 'baggageService'
};
export const BANKRUPTCY_INSURANCE_COVER_GENIUS = {
  id: 621,
  name: 'bankruptcyInsuranceCoverGenius',
  iconId: 'bankruptcy-insurance'
};
export const CABIN_BAGGAGE = {
  id: 625,
  name: 'cabinBaggage'
};
export const CANCEL_24_HOURS = {
  id: 628,
  name: 'cancelTwentyFourHours'
};
export const CANCEL_FOR_ANY_REASON = {
  id: 627,
  name: 'cancelForAnyReason'
};
export const CANCELLATION_GUARANTEE = {
  id: 280,
  name: 'cancellationGuarantee'
};
export const CANCELLATION_INSIDE_EU = {
  id: 40,
  name: 'cancellationInsideEu'
};
export const CANCELLATION_INSURANCE_COVER_GENIUS = {
  id: 611,
  name: 'cancellationInsuranceCoverGenius',
  iconId: 'share'
};
export const CANCELLATION_OUTSIDE_EU = {
  id: 41,
  name: 'cancellationOutsideEu'
};
export const CHECK_IN_BAGGAGE = {
  id: 6,
  name: 'checkInBaggage'
};
export const CLIMATE_COMPENSATION = {
  id: 261,
  name: 'climateCompensation'
};
export const COMPREHENSIVE_INSURANCE_COVER_GENIUS = {
  id: 281,
  name: 'comprehensiveInsuranceCoverGenius',
  iconId: 'shield'
};
export const CONNECTION_GUARANTEE = {
  id: 274,
  name: 'connectionGuarantee'
};
export const CO2_COMPENSATION = {
  id: 629,
  name: 'co2Compensation'
};
export const FLEXIBLE_TICKET = {
  id: 65,
  name: 'flexibleTicket'
};
export const MEAL = {
  id: 260,
  name: 'meal'
};
export const MOBILE_TRAVEL_PLAN = {
  id: 83,
  name: 'mobileTravelPlan'
};
export const ONLINE_CHECK_IN = {
  id: 5003,
  name: 'onlineCheckIn'
};
export const SEAT_MAP = {
  id: 2003,
  name: 'seatMap'
};
export const SEATING_BESIDES = {
  id: 2000,
  name: 'seatingBesides'
};
export const SEATING_LIGHT = {
  id: 2001,
  name: 'seatingLight'
};
export const SERVICE_PACKAGE = {
  id: 62,
  name: 'servicePackage'
};
export const SERVICE_PACKAGE_NO_TEXT = {
  id: 64,
  name: 'servicePackageNoText'
};
export const SERVICE_PACKAGE_REQUEST = {
  id: 201,
  name: 'servicePackageRequest'
};
export const SIMPLE_VISA = {
  id: 279,
  name: 'simpleVisa'
};
export const SMS = {
  id: 2002,
  name: 'sms'
};
export const TRAVEL_DOCS_POST = {
  id: 63,
  name: 'travelDocsPost'
};
export const TRAVEL_INSURANCE_COVER_GENIUS = {
  id: 613,
  name: 'travelInsuranceCoverGenius',
  iconId: 'plane-bold'
};
export const TRAVEL_INSURANCE_INSIDE_EU = {
  id: 87,
  name: 'travelInsuranceInsideEu'
};
export const TRAVEL_INSURANCE_OUTSIDE_EU = {
  id: 86,
  name: 'travelInsuranceOutsideEu'
};
export const TRIP_CANCELLATION_PROTECTION = {
  id: 277,
  name: 'tripCancellationProtection'
};
export const SECOND_BAGGAGE = {
  id: 631,
  name: 'secondBaggagePerTraveler'
};
export const FAST_TRACK = {
  id: 632,
  name: 'fastTrack'
};
export const PLATINUM_SERVICE = {
  id: 5001,
  name: 'platinum'
};
export const QPASS = {
  id: 633,
  name: 'qPass'
};