import { useState, useEffect } from 'react';

const useClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isCopied) {
      timeoutId = setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isCopied]);

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopied(true);
      })
      .catch(() => {
        setIsCopied(false);
      });
  };

  const clipboardData: [boolean, (text: string) => void] = [isCopied, copyToClipboard];
  return clipboardData;
};

export default useClipboard;
