import PropTypes from 'prop-types';
import { BoundNew } from '@eti/types';

export const location = {
  code: PropTypes.string,
  name: PropTypes.string,
  cityCode: PropTypes.string,
  cityName: PropTypes.string,
  continentCode: PropTypes.string,
  countryCode: PropTypes.string,
  countryName: PropTypes.string,
};

export const bounds = PropTypes.arrayOf(BoundNew);

export const travelers = PropTypes.arrayOf(
  PropTypes.shape({
    ageType: PropTypes.string,
    birthDate: PropTypes.string,
    firstName: PropTypes.string,
    gender: PropTypes.string,
    id: PropTypes.string,
    lastName: PropTypes.string,
    name: PropTypes.string,
    passportNumber: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        quantity: PropTypes.number,
      }),
    ),
  }),
);

export const skeletonBounds = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    segments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }),
);
