import { etiColors } from '@eti/styles';
import merge from 'lodash/merge';
export const largeStyles = {
  '@media (min-width: 60.625em)': {
    fontSize: '1rem'
  }
};
export const getContainerStyles = function (width, customContainerStyles) {
  if (customContainerStyles === void 0) {
    customContainerStyles = {};
  }
  return containerStyles => merge({
    ...containerStyles,
    width
  }, customContainerStyles);
};
export const getDropdownIndicatorStyles = function (isDisabled, customDropdownIndicatorStyles) {
  if (customDropdownIndicatorStyles === void 0) {
    customDropdownIndicatorStyles = {};
  }
  return dropdownIndicatorStyles => {
    const {
      fill: customSvgFill
    } = customDropdownIndicatorStyles;
    return merge({
      ...dropdownIndicatorStyles,
      padding: '0',
      cursor: 'pointer',
      fill: isDisabled ? etiColors.grayScale[8] : customSvgFill || etiColors.grayScale[10]
    }, customDropdownIndicatorStyles);
  };
};
export const getValueContainerStyles = function (isDisabled, customValueContainerStyles) {
  if (customValueContainerStyles === void 0) {
    customValueContainerStyles = {};
  }
  return valueContainerStyles => merge({
    ...valueContainerStyles,
    height: '48px',
    padding: '0',
    fontSize: '1rem',
    color: isDisabled ? etiColors.grayScale[8] : etiColors.black
  }, customValueContainerStyles);
};
export const getSingleValueStyles = function (customSingleValueStyles) {
  if (customSingleValueStyles === void 0) {
    customSingleValueStyles = {};
  }
  return singleValueStyles => merge({
    ...singleValueStyles
  }, customSingleValueStyles);
};
export const getMenuStyles = function (customMenuStyles) {
  if (customMenuStyles === void 0) {
    customMenuStyles = {};
  }
  return menuStyles => merge({
    ...menuStyles,
    marginBottom: '2px',
    marginTop: '2px'
  }, customMenuStyles);
};
export const getPlaceHolderStyles = (_isDisabled, size, customPlaceholderStyles) => placeHolderStyles => merge({
  ...placeHolderStyles,
  marginLeft: 0,
  marginRight: 0,
  color: '#a7a7a7',
  width: '100%',
  ...(size === 'large' && largeStyles)
}, customPlaceholderStyles);