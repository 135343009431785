import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';
import { Inline, Radio, RadioGroup } from '@etg/wings';
import { useProperty, useSearch, useTranslation } from '@eti/providers';
import { connect } from 'react-redux';
import {
  Field as ReduxFormField,
  change as changeFormValue,
  formValueSelector,
  touch,
} from 'redux-form';
import { extraMultiStopTripTypes, tripTypes } from '../../../constants/tripTypesConstants';
import {
  DEPARTURE_DATE,
  DEPARTURE_TIME_OF_DAY,
  DESTINATION,
  DIRECT_FLIGHT,
  MULTI_BOUND,
  ORIGIN,
  RETURN_DATE,
  RETURN_TIME_OF_DAY,
  SINGLE_BOUND,
  TRIP_TYPE,
} from '../constants/formFieldNames';

const { MULTI_STOP, ONE_WAY, RETURN } = tripTypes;

const TripTypeSelectionComponent = ({ input }) => {
  const { t } = useTranslation();
  const { p } = useProperty();

  return (
    <RadioGroup name={TRIP_TYPE} onChange={input.onChange} selectedValue={input.value}>
      <Inline align="start" noWrap>
        <Radio.Label data-testid="searchForm-return-radio-label" htmlFor="returnOption">
          <Inline noWrap spacing={8}>
            <Radio data-testid="searchForm-return-radio" id="returnOption" value={RETURN} />
            <span>{t('Air.Search.Return')}</span>
          </Inline>
        </Radio.Label>
        <Radio.Label data-testid="searchForm-oneWay-radio-label" htmlFor="oneWayOption">
          <Inline noWrap spacing={8}>
            <Radio data-testid="searchForm-oneWay-radio" id="oneWayOption" value={ONE_WAY} />
            <span>{t('Air.Search.Oneway')}</span>
          </Inline>
        </Radio.Label>
        {p('IbeClient.MultiStop.Enabled') && (
          <Radio.Label data-testid="searchForm-multiStop-radio-label" htmlFor="multiStopOption">
            <Inline noWrap spacing={8}>
              <Radio
                data-testid="searchForm-multiStop-radio"
                id="multiStopOption"
                value={MULTI_STOP}
              />
              <span>{t('Air.Search.MultiBound')}</span>
            </Inline>
          </Radio.Label>
        )}
      </Inline>
    </RadioGroup>
  );
};

const TripTypes = ({ clearReturnDate, clearDirectFlight, onChange, selectedValue }) => {
  const { currentSearchData, setSearchData } = useSearch();

  const handleChange = useCallback(
    (event) => {
      const { value } = event.target;

      if (value === ONE_WAY) {
        clearReturnDate();
      }

      if (value === MULTI_STOP) {
        clearReturnDate();
        clearDirectFlight();
      }

      onChange(value);
    },
    [clearDirectFlight, clearReturnDate, onChange],
  );

  useEffect(() => {
    if (extraMultiStopTripTypes.includes(currentSearchData.tripType)) {
      setSearchData({
        ...currentSearchData,
        tripType: MULTI_STOP,
      });
    }
  }, [currentSearchData, setSearchData]);

  return (
    <ReduxFormField
      component={TripTypeSelectionComponent}
      name={TRIP_TYPE}
      onChange={handleChange}
      value={selectedValue}
    />
  );
};

Radio.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  label: PropTypes.string,
  testId: PropTypes.string,
  value: PropTypes.string,
};

TripTypeSelectionComponent.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
};

TripTypes.propTypes = {
  clearDirectFlight: PropTypes.func,
  clearReturnDate: PropTypes.func,
  onChange: PropTypes.func,
  selectedValue: PropTypes.string,
};

const mapStateToProps = (state, { form }) => ({
  selectedValue: formValueSelector(form)(state, TRIP_TYPE),
  singleBound: formValueSelector(form)(state, SINGLE_BOUND),
  multiBound: formValueSelector(form)(state, MULTI_BOUND),
});

const mapDispatchToProps = (dispatch, { form }) => ({
  clearReturnDate: () => {
    dispatch(changeFormValue(form, `${SINGLE_BOUND}.${RETURN_DATE}`, null));
    dispatch(changeFormValue(form, `${SINGLE_BOUND}.${RETURN_TIME_OF_DAY}`, null));
  },
  clearDirectFlight: () => {
    dispatch(changeFormValue(form, DIRECT_FLIGHT, false));
  },
  onChange: (singleBound, multiBound) => (value) => {
    const isEmptyBound = (bound) =>
      !bound || Object.keys(bound).length === 0 || (!bound[ORIGIN] && !bound[DESTINATION]);
    if (value === MULTI_STOP) {
      const isEveryBoundEmpty = multiBound.every((bound) => isEmptyBound(bound));

      if (isEveryBoundEmpty && !isEmptyBound(singleBound)) {
        [ORIGIN, DESTINATION, DEPARTURE_DATE, DEPARTURE_TIME_OF_DAY].forEach((key) => {
          if (singleBound[key]) {
            dispatch(changeFormValue(form, `${MULTI_BOUND}[0].${key}`, singleBound[key]));
            dispatch(touch(form, `${MULTI_BOUND}[0].${key}`));
          }
        });
      }
    } else if (isEmptyBound(singleBound)) {
      if (multiBound[0]?.origin?.name) {
        [ORIGIN, DESTINATION, DEPARTURE_DATE, DEPARTURE_TIME_OF_DAY].forEach((key) => {
          if (multiBound[0][key]) {
            dispatch(changeFormValue(form, `${SINGLE_BOUND}.${key}`, multiBound[0][key]));
            dispatch(touch(form, `${SINGLE_BOUND}.${key}`));
          }
        });
      }
    }
  },
});

const mergeProps = (state, actions, props) => ({
  ...state,
  ...actions,
  ...props,
  onChange: actions.onChange(state.singleBound, state.multiBound),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TripTypes);
