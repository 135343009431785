import PropTypes from 'prop-types';
import { LinkButton, Heading, breakpoints, Inline } from '@etg/wings';
import { css } from '@eti/styles';
import { string } from '@eti/utils';
import { useTranslation } from '@eti/providers';

const inlineStyles = css`
  justify-content: space-between;
`;

const removeTripButtonStyle = css`
  font-size: 0.875rem;

  @media (min-width: ${breakpoints._768}) {
    display: none;
  }
`;

const BoundHeader = ({ boundIndex, onRemove }) => {
  const { t } = useTranslation();

  const handleClick = () => onRemove(boundIndex);

  const isRemovableTrip = (i) => i > 1;

  return (
    <Inline className={inlineStyles}>
      <Heading data-testid="bound-header" level={3}>
        {string.insertArgument(t('Air.Search.MultiBound.BoundHeader.Text'), boundIndex + 1)}
      </Heading>
      {isRemovableTrip(boundIndex) && (
        <LinkButton
          className={removeTripButtonStyle}
          data-testid={`searchForm-removeTrip-${boundIndex}-button`}
          onClick={handleClick}
        >
          {t('Searchform.RemoveBound.Button.Text')}
        </LinkButton>
      )}
    </Inline>
  );
};

BoundHeader.propTypes = {
  boundIndex: PropTypes.number,
  onRemove: PropTypes.func,
};

export default BoundHeader;
