import { css, mediaQueries } from '@eti/styles';
import PropTypes from 'prop-types';
import { SelfServiceRebookingTraveler } from '@eti/types';
import { useTranslation } from '@eti/providers';

const wrapperStyles = css`
  display: flex;
  margin-left: 22px;

  [dir='rtl'] & {
    margin-left: 0;
    margin-right: 22px;
  }
`;

const criteriaStyles = css`
  flex: 1;
  font-size: 0.875rem;
  margin-right: 6px;

  @media ${mediaQueries.medium.up} {
    flex: 2;
  }

  [dir='rtl'] & {
    margin-left: 6px;
    margin-right: 0;
  }
`;

const travelersListStyles = css`
  list-style: inside;
  margin-left: 20px;
  text-indent: -20px;

  [dir='rtl'] & {
    margin-left: 0;
    margin-right: 20px;
  }
`;

const criteriaListStyles = css`
  list-style: inside;
  margin-left: 18px;
  text-indent: -18px;

  @media ${mediaQueries.medium.up} {
    column-count: 2;
  }

  [dir='rtl'] & {
    margin-left: 0;
    margin-right: 18px;
  }
`;

const travelersStyles = css`
  flex: 1;
  font-size: 0.875rem;
  margin-left: 6px;

  [dir='rtl'] & {
    margin-left: 0;
    margin-right: 6px;
  }
`;

const listItemContentStyles = css`
  left: -10px;
  position: relative;
  text-transform: capitalize;
`;

const titleStyles = css`
  font-size: 0.875rem;
  font-weight: 700;
`;

const ExtendedRebookingInfo = ({ rebookingData }) => {
  const { t } = useTranslation();

  const { criteria = [], travelers = [] } = rebookingData;

  return (
    <div className={wrapperStyles} data-testid="startPage-extendedRebookingInfo-wrapper">
      <div className={criteriaStyles}>
        <div className={titleStyles}>{t('SelfServiceRebooking.InformationBox.Criteria.Title')}</div>
        <ul className={criteriaListStyles}>
          {criteria.map((criterion) => (
            <li key={criterion}>
              <span
                className={listItemContentStyles}
                data-testid="startPage-extendedRebookingInfo-criterion"
              >
                {criterion}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className={travelersStyles}>
        <div className={titleStyles}>
          {t('SelfServiceRebooking.InformationBox.Passengers.Title')}
        </div>
        <ul className={travelersListStyles}>
          {travelers.map((traveler) => (
            <li key={traveler.id}>
              <span
                className={listItemContentStyles}
                data-testid="startPage-extendedRebookingInfo-traveler"
              >{`${traveler.firstName} ${traveler.lastName}`}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

ExtendedRebookingInfo.propTypes = {
  rebookingData: PropTypes.shape({
    criteria: PropTypes.arrayOf(PropTypes.string),
    formConstraints: PropTypes.shape({
      passengers: PropTypes.shape({
        isDisabled: PropTypes.bool,
        maxNumberOfTravelers: PropTypes.number,
      }),
    }),
    initialValues: PropTypes.shape({
      passengers: PropTypes.shape({
        adults: PropTypes.number,
        children: PropTypes.number,
        infants: PropTypes.number,
      }),
    }),
    travelers: PropTypes.arrayOf(SelfServiceRebookingTraveler),
  }),
};

export default ExtendedRebookingInfo;
