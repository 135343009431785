import { COMPREHENSIVE_INSURANCE_COVER_GENIUS } from '../products';
import { getPrice, getSelected, getGraphQlSelection } from '../single-choice/utils/selection';
import ComprehensiveInsuranceCoverGenius from './components/ComprehensiveInsuranceCoverGenius';
const {
  name: formSection
} = COMPREHENSIVE_INSURANCE_COVER_GENIUS;
export const comprehensiveInsuranceCoverGenius = {
  formSection,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  ...COMPREHENSIVE_INSURANCE_COVER_GENIUS
};
export default ComprehensiveInsuranceCoverGenius;