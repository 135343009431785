import payment from 'payment';
import { createValidator } from 'revalidate';
import { string as stringUtils } from '@eti/utils';
import { INVALID_CVC } from '../constants/defaultErrorMessages';
export const isCvcNumberOk = (cvc, cardNumber) => payment.fns.validateCardCVC(cvc, payment.fns.cardType(cardNumber));
const isValidCvc = createValidator(message => function (cvcNumber, values) {
  if (values === void 0) {
    values = {};
  }
  const rawCardnumber = values.creditCardForm ? stringUtils.stripSpaces(values.creditCardForm.cardnumber) : null;
  if (!isCvcNumberOk(cvcNumber, rawCardnumber)) {
    return message || INVALID_CVC;
  }
  return null;
}, INVALID_CVC);
export default isValidCvc;