import { getPrice, getSelected, getGraphQlSelection } from '../single-choice/utils/selection';
import { SEATING_BESIDES } from '../products';
import SeatingBesides from './components/SeatingBesides';
import getIcon from './utils/icon';
const formSection = SEATING_BESIDES.name;
export const seatingBesides = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  ...SEATING_BESIDES
};
export default SeatingBesides;