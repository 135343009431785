import * as products from '../../../products';
export const SUPPORTED_PRODUCTS_NAMES = Object.values(products).map(product => product.name);
export const COLORS = {
  black: '#000',
  blue: '#3586d1',
  green: '#10a700',
  grey: '#f8f8f8',
  neutral: '#aaa',
  transparent: 'transparent',
  yellow: '#ffb24e',
  white: '#fff'
};
export const ICON_TYPES = {
  default: 'default',
  inverted: 'inverted',
  neutral: 'neutral'
};