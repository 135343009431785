export const LAYOUT_OPTION_A = 'A : All sections have equal width';
export const LAYOUT_OPTION_B = 'B : Two sections first section 66% second section 33% width';
export const LAYOUT_OPTION_C = 'C : Two sections first section 33% second section 66% width';
export const LAYOUT_OPTION_D =
  'D : Three sections first section 100% second and third section 50% width';

export const FOUR_OUT_OF_TWELVE = '4/12';
export const SIX_OUT_OF_TWELVE = '6/12';
export const SEVEN_OUT_OF_TWELVE = '7/12';
export const TWELVE_OUT_OF_TWELVE = '12/12';

export const ALL_BRANDS = 'All Brands';
export const ALL_MARKETS = 'All Markets';

export const IMAGE = 'Image';
export const RICH_TEXT = 'ContentTypeRichText';

export const LOGO_HEIGHT = 160;
export const LOGO_HEIGHT_XS = 88;
export const LOGO_WIDTH = 160;
export const LOGO_WIDTH_XS = 88;
