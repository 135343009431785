import { price as priceUtil, string } from '@eti/utils';
export const SELECTBOX_VALUE = {
  ALL: 'ALL',
  NONE: 'NONE'
};
export const getTotalPrice = function (options) {
  if (options === void 0) {
    options = [];
  }
  if (!options.length) {
    return null;
  }
  return options.reduce((totalPrice, _ref) => {
    let {
      priceRaw
    } = _ref;
    return totalPrice !== null && priceRaw !== undefined ? totalPrice + priceRaw : null;
  }, 0);
};
export const getMinPrice = function (options) {
  var _options;
  if (options === void 0) {
    options = [];
  }
  const minPrice = (_options = options) === null || _options === void 0 || (_options = _options.filter(_ref2 => {
    let {
      priceRaw
    } = _ref2;
    return priceRaw !== undefined;
  })) === null || _options === void 0 ? void 0 : _options.reduce((total, _ref3) => {
    let {
      priceRaw
    } = _ref3;
    return total && priceRaw !== undefined ? Math.min(priceRaw, total) : priceRaw || 0;
  }, null);
  return minPrice;
};
export const formatOptions = function (options, allTravelersLabel, noTravelersLabel) {
  if (options === void 0) {
    options = [];
  }
  return options.length ? [{
    value: SELECTBOX_VALUE.ALL,
    label: allTravelersLabel,
    id: SELECTBOX_VALUE.ALL.toLocaleLowerCase(),
    exclude: true,
    price: priceUtil.formatPrice(getTotalPrice(options) || 0)
  }, ...options.map(_ref4 => {
    let {
      id,
      name,
      priceRaw
    } = _ref4;
    return {
      value: id,
      label: name,
      priceRaw,
      price: priceUtil.formatPrice(priceRaw)
    };
  }), {
    value: SELECTBOX_VALUE.NONE,
    label: noTravelersLabel,
    id: SELECTBOX_VALUE.NONE.toLocaleLowerCase(),
    exclude: true,
    price: priceUtil.formatPrice(0)
  }] : [];
};
export const formatLabel = _ref5 => {
  let {
    selectedOptions = [],
    allTravelersLabel,
    defaultPlaceholder,
    placeholderSelectedSingular,
    placeholderSelectedPlural,
    noTravelersLabel
  } = _ref5;
  if (!(selectedOptions !== null && selectedOptions !== void 0 && selectedOptions.length)) {
    return defaultPlaceholder;
  }
  if (selectedOptions.some(option => option.value === SELECTBOX_VALUE.NONE)) {
    return noTravelersLabel;
  }
  if (selectedOptions.some(option => option.value === SELECTBOX_VALUE.ALL)) {
    return allTravelersLabel;
  }
  return selectedOptions.length === 1 ? string.insertArgument(placeholderSelectedSingular, selectedOptions.length) : string.insertArgument(placeholderSelectedPlural, selectedOptions.length);
};
export const handleSelectedOption = function (allOptions, previousValues, newValues) {
  var _newValues, _newValues4;
  if (newValues === void 0) {
    newValues = [];
  }
  const newSelectedOptions = newValues && ((_newValues = newValues) === null || _newValues === void 0 ? void 0 : _newValues.filter(newValue => !(previousValues !== null && previousValues !== void 0 && previousValues.some(prevVal => (prevVal === null || prevVal === void 0 ? void 0 : prevVal.value) === newValue.value))));
  const unselectedOption = previousValues === null || previousValues === void 0 ? void 0 : previousValues.filter(prevVal => {
    var _newValues2;
    return newValues && !((_newValues2 = newValues) !== null && _newValues2 !== void 0 && _newValues2.find(newValue => newValue.value === prevVal.value));
  });

  // If new selected options are empty and there were no selected options before, return null
  if (!(newSelectedOptions !== null && newSelectedOptions !== void 0 && newSelectedOptions.length) && !(previousValues !== null && previousValues !== void 0 && previousValues.length)) {
    return undefined;
  }
  const isAllOptionsSelected = allOptions.filter(option => option.value !== SELECTBOX_VALUE.ALL && option.value !== SELECTBOX_VALUE.NONE).every(option => {
    var _newValues3;
    return (_newValues3 = newValues) === null || _newValues3 === void 0 ? void 0 : _newValues3.some(opt => opt.value === option.value);
  });
  const noSpecialOptionsSelected = !((_newValues4 = newValues) !== null && _newValues4 !== void 0 && _newValues4.some(option => option.value === SELECTBOX_VALUE.ALL || option.value === SELECTBOX_VALUE.NONE));

  // If "ALL" is selected, select ALL options except NONE
  if (Array.isArray(newSelectedOptions) && newSelectedOptions !== null && newSelectedOptions !== void 0 && newSelectedOptions.some(option => option.value === SELECTBOX_VALUE.ALL)) {
    return allOptions === null || allOptions === void 0 ? void 0 : allOptions.filter(option => option.value !== SELECTBOX_VALUE.NONE);
  }

  // If all options are selected except "ALL" and "NONE", select "ALL" as well and only dont select "NONE"
  if (isAllOptionsSelected && noSpecialOptionsSelected) {
    return allOptions.filter(option => option.value !== SELECTBOX_VALUE.NONE);
  }

  // If "NONE" is selected, choose only "NONE" and deselect everything else
  if (Array.isArray(newSelectedOptions) && newSelectedOptions !== null && newSelectedOptions !== void 0 && newSelectedOptions.some(option => option.value === SELECTBOX_VALUE.NONE)) {
    return allOptions === null || allOptions === void 0 ? void 0 : allOptions.filter(option => option.value === SELECTBOX_VALUE.NONE);
  }

  // If "ALL" is selected again, remain previousValues
  if (unselectedOption !== null && unselectedOption !== void 0 && unselectedOption.some(option => option.value === SELECTBOX_VALUE.ALL)) {
    return previousValues;
  }

  // If unselect an option, deselect the option and remove ALL, and NONE option
  if (unselectedOption) {
    var _newValues5;
    return Array.isArray(newValues) ? (_newValues5 = newValues) === null || _newValues5 === void 0 ? void 0 : _newValues5.filter(newValue => newValue.value !== SELECTBOX_VALUE.ALL && newValue.value !== SELECTBOX_VALUE.NONE) : previousValues;
  }
  return newValues;
};