import PropTypes from 'prop-types';
import { Suspense, lazy } from 'react';
import { Inline, vars, Stack } from '@etg/wings';
import { css } from '@eti/styles';
import { useTranslation } from '@eti/providers';
import { LoadingDots } from '@eti/components';

const Facebook = lazy(() => import('../components/Facebook'));
const Google = lazy(() => import('../components/Google'));

const wrapperStyles = css`
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
`;

const inlineStyles = css`
  padding-block: 8px 16px;
  padding-inline: 2px;
`;

const socialMediaDividerStyles = css`
  background-color: ${vars.colors.divider};
  height: 1px;
  width: 100%;
`;

const textStyles = css`
  white-space: nowrap;
`;

const loadingDotsStyles = css`
  font-size: 1.25rem;
  margin: 15px;
  text-align: center;
`;

const SocialMediaLoginContainer = ({
  callback,
  setNotification,
  shouldShowFacebookButton,
  shouldShowGoogleButton,
}) => {
  const { t } = useTranslation();

  return (
    <div className={wrapperStyles}>
      <Suspense fallback={<LoadingDots containerStyle={loadingDotsStyles} />}>
        <Inline alignY="center" className={inlineStyles} noWrap>
          <div className={socialMediaDividerStyles} />
          <span className={textStyles}>{t('Postbooking.Login.SocialMedia.Title')}</span>
          <div className={socialMediaDividerStyles} />
        </Inline>
        <Stack align="center" spacing={8}>
          {shouldShowFacebookButton && (
            <Facebook callback={callback} setNotification={setNotification} />
          )}
          {shouldShowGoogleButton && (
            <Google callback={callback} setNotification={setNotification} />
          )}
        </Stack>
      </Suspense>
    </div>
  );
};

SocialMediaLoginContainer.propTypes = {
  callback: PropTypes.func,
  setNotification: PropTypes.func,
  shouldShowFacebookButton: PropTypes.bool,
  shouldShowGoogleButton: PropTypes.bool,
};

export default SocialMediaLoginContainer;
