import { ageTypes, genders, travelerInputs } from '../constants';
const {
  ADULT,
  CHILD,
  INFANT
} = ageTypes;
const {
  MALE,
  FEMALE
} = genders;
const {
  TRAVELER_FORM_SECTION_NAME
} = travelerInputs;
export const resolveLabelFromType = (type, adultLabel, childLabel, infantLabel) => {
  switch (type) {
    case ADULT:
      {
        return adultLabel;
      }
    case CHILD:
      {
        return childLabel;
      }
    case INFANT:
      {
        return infantLabel;
      }
    default:
      {
        return undefined;
      }
  }
};
export const getTravelerFormSectionName = (id, subSection) => `${TRAVELER_FORM_SECTION_NAME}-${id}${subSection ? `.${subSection}-${id}` : ''}`;
export const travelerAgeIcons = {
  [CHILD]: 'child-traveler',
  [INFANT]: 'infant-traveler',
  [ADULT]: 'adult-traveler'
};
export const travelerGenderIcons = {
  [MALE]: 'adult-male',
  [FEMALE]: 'adult-female'
};