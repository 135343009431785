export default {
  small: {
    min: '0',
    max: '767px'
  },
  medium: {
    min: '768px',
    max: '969px'
  },
  large: {
    min: '970px',
    max: '1169px'
  },
  xlarge: {
    min: '1170px'
  }
};