/* eslint-disable no-underscore-dangle */
import { combineReducers, createStore as reduxCreateStore } from 'redux';
import { reducer as form } from 'redux-form';

export const createStore = (initialState, debugEnabled = true) => {
  const reducer = combineReducers({
    form,
  });

  const devTools = debugEnabled
    ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    : undefined;

  return reduxCreateStore(reducer, initialState, devTools);
};
