import { postcodeValidatorExistsForCountry, postcodeValidator } from 'postcode-validator';
import { createValidator } from 'revalidate';
import { INVALID_ZIPCODE } from '../constants/defaultErrorMessages';
export const isValidZipcode = () => createValidator(invalidMessage => (value, _ref) => {
  var _contactInformationFi;
  let {
    contactInformationFields
  } = _ref;
  const countryCode = contactInformationFields === null || contactInformationFields === void 0 || (_contactInformationFi = contactInformationFields.country) === null || _contactInformationFi === void 0 ? void 0 : _contactInformationFi.value;
  if (countryCode && postcodeValidatorExistsForCountry(countryCode) && !postcodeValidator(value, countryCode)) {
    return invalidMessage || INVALID_ZIPCODE;
  }
  return null;
}, INVALID_ZIPCODE);
export default isValidZipcode;