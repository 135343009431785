import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const PriceDropProtectionPage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      image="privacy-policy"
      pageTitle={t('PageTitle.rf.pricedropprotection.action')}
      textKey="PriceDropProtection.Text"
    />
  );
};

export default PriceDropProtectionPage;
