import PropTypes from 'prop-types';
import { List } from '@phosphor-icons/react';
import { vars } from '@etg/wings';
import { css } from '@eti/styles';
import { useTranslation } from '@eti/providers';

const buttonStyles = css`
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  padding: 0;
`;

const MobileMenuButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <button
      aria-controls="mobile menu"
      aria-label={t('SiteMenu.OpenMenu.Label')}
      className={buttonStyles}
      data-testid="mobile-menu-button"
      onClick={onClick}
      title={t('SiteMenu.OpenMenu.Label')}
      type="button"
    >
      <List color={vars.colors.header.content} size={28} weight="regular" />
    </button>
  );
};

MobileMenuButton.propTypes = {
  onClick: PropTypes.func,
};

export default MobileMenuButton;
