import { useQuery } from '@apollo/client';
import { gql } from '@eti/schema-types';

export const PENDING_PROVIDER_BOOKING_QUERY = gql(/* GraphQL */ `
  query getPendingProviderBooking {
    pendingProviderBooking {
      customerEmail
      departureAt
      destination
      fullName
      orderNumberToBe
      origin
    }
  }
`);

const usePendingProviderBooking = () => {
  const { data, loading } = useQuery(PENDING_PROVIDER_BOOKING_QUERY);
  return {
    loading,
    isPendingProviderBooking: Boolean(data?.pendingProviderBooking),
    pendingProviderBookingData: data,
  };
};

export default usePendingProviderBooking;
