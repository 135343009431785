import { connect } from 'react-redux';
import { getData, getTravelers } from '../utils/seatMapTransformer';
import { getTravelerNames } from '../../common/utils/travelerNames';
import { getTravelerGender } from '../../common/utils/travelerGender';
import SeatMap from '../components/SeatMap';
const mapStateToProps = (state, _ref) => {
  var _order$configuration;
  let {
    form,
    order = {},
    travelerLabel
  } = _ref;
  const travelerFormNames = getTravelerNames(form, state, order.travelers);
  const travelerFormGender = getTravelerGender(form, state, order.travelers);
  const travelers = getTravelers(order, travelerFormNames, travelerLabel, travelerFormGender);
  const persuasionIsEnabled = Boolean(order === null || order === void 0 || (_order$configuration = order.configuration) === null || _order$configuration === void 0 || (_order$configuration = _order$configuration.travelerDetails) === null || _order$configuration === void 0 ? void 0 : _order$configuration.persuasionEnabled);
  const persuasion = {
    isEnabled: persuasionIsEnabled
  };
  return {
    bounds: getData(order),
    travelers,
    persuasion
  };
};
export default connect(mapStateToProps)(SeatMap);