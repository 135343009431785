import { constants } from '@eti/utils';
const {
  ADULT,
  CHILD,
  INFANT
} = constants.ageTypes;
export const getTravelerDetails = function (ageType, labels) {
  if (labels === void 0) {
    labels = {};
  }
  switch (ageType) {
    case CHILD:
      return {
        id: 'child-traveler',
        label: labels.child
      };
    case INFANT:
      return {
        id: 'infant-traveler',
        label: labels.infant
      };
    case ADULT:
    default:
      return {
        id: 'adult-traveler',
        label: labels.adult
      };
  }
};