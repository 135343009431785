import { getFormSyncErrors } from 'redux-form';
export const getErrors = (form, field) => state => {
  const errors = getFormSyncErrors(form)(state);
  const fieldErrors = errors && errors[field] ? errors[field] : {};
  return Object.values(fieldErrors).map(text => ({
    text
  }));
};
export const createValidation = errors =>
// TODO ERPRODUCTS-15: Add logic for isValid...
errors && errors.length > 0 ? {
  variant: 'critical',
  errors
} : null;