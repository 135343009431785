import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const DisputeResolutionProcedurePage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      pageTitle={t('PageTitle.rf.dispute-resolution-procedure.action')}
      textKey="DisputeResolutionProcedurePage.HtmlContent"
    />
  );
};
export default DisputeResolutionProcedurePage;
