const BUSINESS = 'BUSINESS';
const ECONOMY = 'ECONOMY';
const FIRST = 'FIRST';
const PREMIUM_BUSINESS = 'PREMIUM_BUSINESS';
const PREMIUM_ECONOMY = 'PREMIUM_ECONOMY';
const PREMIUM_FIRST = 'PREMIUM_FIRST';

export const cabinClasses = {
  BUSINESS,
  ECONOMY,
  FIRST,
  PREMIUM_BUSINESS,
  PREMIUM_ECONOMY,
  PREMIUM_FIRST,
} as const;
