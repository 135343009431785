export const ADDRESS_FORM_SECTION = 'contactInformationFields' as const;

export const addressFields = {
  STREET: 'street',
  STRICT_ZIP_CODE: 'strictZipCode',
  HOUSE_NUMBER: 'houseNumber',
  ZIP_CODE: 'zipCode',
  CITY: 'city',
  COUNTRY: 'country',
  CARE_OF: 'careOf',
  STATE: 'state',
} as const;

export const nameFields = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
} as const;

export const reduxFields = {
  INSTALMENT_FEE: 'instalmentFee',
  METHOD_SUBSECTION_ID: 'id',
  METHOD_SUBSECTION_CODE: 'code',
  PRICE_CHANGE_DISPLAYED: 'priceChangeDisplayed',
  PRICE_IN_CHARGE_CURRENCY: 'priceInChargeCurrency',
  SELECTED_PAYMENT_METHOD: 'selected-payment-method',
  SELECTED_PAYMENT_METHOD_CODE: 'selected-payment-method-code',
  SELECTED_PAYMENT_METHOD_SUB_SECTION: 'sub-section',
  SELECTED_PAYMENT_METHOD_TYPE: 'selected-payment-method-type',
  SHOULD_ADD_INSTALMENT_FEE_IN_TOTAL_AMOUNT: 'shouldAddInstallmentFeeInTotalAmount',
  TOTAL_PRICE: 'totalPrice',
} as const;

export const PAYMENT_CONDITIONS = 'payment-conditions' as const;
export const TAX_DATE_OF_BIRTH = 'taxDateOfBirthInput' as const;
export const TAX_ID = 'taxIdInput' as const;
