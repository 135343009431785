import { ForwardedRef, forwardRef } from 'react';
import { BrandLogo } from '@eti/components';
import { css } from '@eti/styles';
import { constants } from '@eti/utils';

const { brands } = constants;

const getLogoHeightDesktop = (brand: string, siteName: string) => {
  if (siteName === 'flybillet_dk') {
    return '32px';
  }
  switch (brand) {
    case brands.GOTOGATE:
      return '20px';
    case brands.AZULMUNDO:
    case brands.FLIGHTNETWORK:
      return '28px';
    case brands.JOURNEYDETAILS:
    case brands.BUSINESSCLASS:
    case brands.RADISSONHOTELS:
    case brands.MYTRIP:
    case brands.RCI:
    case brands.WYNDHAM:
      return '32px';
    case brands.BOOKING:
    case brands.NUSTAY:
    case brands.SERKO:
    case brands.TRAVELFINDER:
      return '35px';
    case brands.BUDJET:
    case brands.HOTELIERMART:
    case brands.NOGKO:
    case brands.SEAT24:
    case brands.TRAVELPARTNER:
    case brands.TRAVELSTART:
    case brands.TRIPBEAT:
      return '40px';
    case brands.AIRTICKETS24:
    case brands.FLYFAR:
    case brands.GOLEIF:
      return '45px';
    case brands.ADR:
    case brands.BOOKBARBADOS:
    case brands.ROME2RIO:
    case brands.TUI:
      return '48px';
    case brands.AVION:
    case brands.FANTASTICGREECE:
    case brands.FLYGVARUHUSET:
    case brands.PAMEDIAKOPES:
    case brands.SUBIRTE:
    case brands.TRAVELVENTURA:
    case brands.TRIP:
      return '50px';
    case brands.SUPERSAVER:
      return '65px';
    default:
      return '20px';
  }
};

const getLogoHeightMobile = (brand: string, siteName: string) => {
  if (siteName === 'flybillet_dk') {
    return '32px';
  }
  switch (brand) {
    case brands.AZULMUNDO:
    case brands.MYTRIP:
      return '24px';
    case brands.TRAVELSTART:
    case brands.BUDJET:
      return '25px';
    case brands.JOURNEYDETAILS:
    case brands.GOTOGATE:
    case brands.FLIGHTNETWORK:
    case brands.TRAVELFINDER:
      return '28px';
    case brands.FLYFAR:
    case brands.SEAT24:
    case brands.FLYGVARUHUSET:
    case brands.GOLEIF:
    case brands.BOOKBARBADOS:
      return '30px';
    case brands.BUSINESSCLASS:
      return '31px';
    case brands.ADR:
    case brands.RADISSONHOTELS:
      return '32px';
    case brands.ROME2RIO:
      return '38px';
    default:
      return '35px';
  }
};

const getLogoWidthDesktop = (brand: string) => {
  return brand === brands.BUSINESSCLASS ? '237px' : '200px';
};

const getLogoWidthMobile = (brand: string) => {
  return brand === brands.BUSINESSCLASS ? '237px' : '156px';
};

const brandLogoStyles = (brand: string, siteName: string) => css`
  height: ${getLogoHeightMobile(brand, siteName)};
  max-width: ${getLogoWidthMobile(brand)};
  pointer-events: none;

  @media (min-width: 60.625em) {
    height: ${getLogoHeightDesktop(brand, siteName)};
    max-width: ${getLogoWidthDesktop(brand)};
  }
`;

const getWrapperClass = (brand: string) => css`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-top: ${brand === brands.SUPERSAVER ? '5px;' : '0'};

  @media (min-width: 60.625em) {
    margin: ${brand === brands.SUPERSAVER ? '10px 0 0' : '10px 0'};
  }
`;

interface SiteLogoInterface {
  baseUrl: string;
  brand: string;
  siteName: string;
}

const SiteLogo = forwardRef<HTMLElement, SiteLogoInterface>(
  ({ baseUrl, brand, siteName }: SiteLogoInterface, ref) => {
    const brandLogo = (
      <BrandLogo brand={brand} className={brandLogoStyles(brand, siteName)} siteName={siteName} />
    );

    return baseUrl ? (
      <a
        className={getWrapperClass(brand)}
        data-testid="site-logo"
        href={baseUrl || undefined}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
      >
        {brandLogo}
      </a>
    ) : (
      <div
        className={getWrapperClass(brand)}
        data-testid="site-logo"
        ref={ref as ForwardedRef<HTMLDivElement>}
      >
        {brandLogo}
      </div>
    );
  },
);

export default SiteLogo;
