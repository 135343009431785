import { breakpoints, Button, Dialog, Heading, Inline, Stack } from '@etg/wings';
import { css } from '@eti/styles';
import { useState } from 'react';
import { OrderState, PartOfExternalFlights } from '@eti/schema-types';
import { capitalize } from '../utils';
import RadioSelection from './common/RadioSelection';
import SelectBoxSelection from './common/SelectBoxSelection';
import InputSelection from './common/InputSelection';
import { DebugButton } from './index';

const fieldSetStyles = css`
  border: 1px solid #bbb;
  border-radius: 3px;
  padding: 56px 16px 16px;
  position: relative;
`;

const flexStyles = css`
  flex: 1;
`;

const legendStyles = css`
  position: absolute;
  top: 16px;
`;

const rebookEligibilityOptions = [
  {
    value: 'change_trip_yourself',
    label: 'Change trip yourself',
  },
  {
    value: 'change_trip_yourself_no_price_review',
    label: 'Change trip yourself - No price review',
  },
  {
    value: 'change_trip_yourself_ancillaries',
    label: 'Change trip yourself - Ancillaries',
  },
  {
    value: 'change_trip_yourself_ancillaries_no_price_review',
    label: 'Change trip yourself - Ancillaries - No price review',
  },
  {
    value: 'change_trip_yourself_flexible_ticket_trip',
    label: 'Change trip yourself - Flexible ticket',
  },
  {
    value: 'change_flexible_ticket_trip',
    label: 'Change trip with us - Flexible ticket',
  },
  {
    value: 'change_trip_with_us',
    label: 'Change trip with us',
  },
  {
    value: 'change_trip_with_airline',
    label: 'Change trip with airline',
  },
  {
    value: 'change_not_available',
    label: 'Change not available',
  },
  {
    value: 'change_this_pnr_with_us',
    label: 'Change this pnr with us',
  },
  {
    value: 'change_short_departure_trip',
    label: 'Change short departure trip',
  },
  {
    value: 'change_not_confirmed_trip',
    label: 'Change not confirmed trip',
  },
  {
    value: 'change_exchanged_by_airline',
    label: 'Change exchanged by airline',
  },
  {
    value: 'change_not_open_ticket',
    label: 'Change not open ticket',
  },
  {
    value: 'change_trip_unknown',
    label: 'Change trip unknown',
  },
];

const orderStatusOptions = [
  {
    label: 'Initial',
    value: OrderState.Initial,
  },
  {
    label: 'Canceled',
    value: OrderState.Canceled,
  },
  {
    label: 'Changed',
    value: OrderState.Changed,
  },
];

const partOfExternalFlightsOptions = [
  {
    label: 'None',
    value: PartOfExternalFlights.None,
  },
  {
    label: 'Some',
    value: PartOfExternalFlights.Some,
  },
  {
    label: 'All',
    value: PartOfExternalFlights.All,
  },
];

const cancelWithRefundOfferResponse = [
  {
    label: 'Success',
    value: 'SUCCESS',
  },
  {
    label: 'Error: Cancellation process failed',
    value: 'CANCELLATION_PROCESS_FAILED',
  },
  {
    label: 'Error: Order already cancelled',
    value: 'ORDER_ALREADY_CANCELLED',
  },
  {
    label: 'Error: Time expired',
    value: 'TIME_EXPIRED',
  },
];

const scheduleChangeScenario = [
  {
    label: 'No Schedule Change',
    value: 'NO_SCHEDULE_CHANGE',
  },
  {
    label: 'Schedule Change: 1st Bound, 1st Segment',
    value: 'BOUND_1_SEGMENT_1',
  },
  {
    label: 'Schedule Change: 1st Bound, ALL Segments',
    value: 'BOUND_1_SEGMENT_ALL',
  },
  {
    label: 'Schedule Change: ALL Bounds, 1st Segment',
    value: 'BOUND_ALL_SEGMENT_1',
  },
  {
    label: 'Schedule Change: ALL Bounds, ALL Segments',
    value: 'BOUND_ALL_SEGMENT_ALL',
  },
];

const DebugOrderDetailPage = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSubmit = (event: any) => {
    const formData = new FormData(event.target);
    const formEntries = Array.from(formData.entries());

    for (const [key, value] of formEntries) {
      sessionStorage.setItem(`mocked${capitalize(key)}`, String(value));
    }
  };

  return (
    <>
      <DebugButton
        data-testid="order-details-properties-button"
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        Order Details properties
      </DebugButton>
      <Dialog
        footer={
          <Inline align="end" collapseBelow={breakpoints._560}>
            <Button
              data-testid="order-details-properties-save-button"
              form="orderDetailsPropertiesConfigForm"
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </Inline>
        }
        isOpen={isDialogOpen}
        onDismiss={() => {
          setIsDialogOpen(false);
        }}
        title="Order Details properties"
      >
        <form
          action="/debug.orderdetailsproperties.mock.config"
          id="orderDetailsPropertiesConfigForm"
          method="POST"
          onSubmit={handleSubmit}
        >
          <Stack>
            <SelectBoxSelection
              data-testid="orderStatus-dropdown"
              defaultValue={orderStatusOptions[0].value}
              label="Order Status"
              name="orderStatus"
              options={orderStatusOptions}
              placeholder="Please select…"
            />
            <fieldset className={fieldSetStyles}>
              <Stack>
                <Heading as="legend" className={legendStyles} level={3}>
                  Ryanair
                </Heading>
                <SelectBoxSelection
                  data-testid="partOfExternalFlights-dropdown"
                  defaultValue={partOfExternalFlightsOptions[0].value}
                  label="Part of external flights:"
                  name="partOfExternalFlights"
                  options={partOfExternalFlightsOptions}
                  placeholder="Please select…"
                />
                <Inline noWrap>
                  <RadioSelection
                    className={flexStyles}
                    defaultValue="no"
                    id="isEligibleForRefundRequest"
                    label="Is eligible for Refund Request?"
                  />
                  <InputSelection
                    className={flexStyles}
                    defaultValue="350000"
                    id="refundRequestExpirationTime"
                    label="Refund Request expiration time (in seconds)"
                  />
                </Inline>
              </Stack>
            </fieldset>
            <fieldset className={fieldSetStyles}>
              <Stack>
                <Heading as="legend" className={legendStyles} level={3}>
                  Manage trip
                </Heading>
                <fieldset className={fieldSetStyles}>
                  <Stack>
                    <Heading as="legend" className={legendStyles} level={3}>
                      Schedule change
                    </Heading>
                    <SelectBoxSelection
                      data-testid="scheduleChangeScenario-dropdown"
                      defaultValue={scheduleChangeScenario[0].value}
                      label="Schedule Change Scenario"
                      name="scheduleChangeScenario"
                      options={scheduleChangeScenario}
                      placeholder="Set schedule change scenario:"
                    />
                    <Inline className={flexStyles} noWrap>
                      <InputSelection
                        defaultValue="30"
                        id="scheduleChangeInMinutes"
                        label="Schedule change (in minutes)"
                      />
                      <InputSelection
                        defaultValue="1"
                        id="scheduleChangeInDays"
                        label="Schedule change (in days)"
                      />
                    </Inline>
                  </Stack>
                </fieldset>
                <fieldset className={fieldSetStyles}>
                  <Stack>
                    <Heading as="legend" className={legendStyles} level={3}>
                      Rebooking properties
                    </Heading>
                    <SelectBoxSelection
                      data-testid="rebookEligibilityStatus-dropdown"
                      defaultValue={rebookEligibilityOptions[0].value}
                      label="Rebook Eligibility Status"
                      name="rebookEligibilityStatus"
                      options={rebookEligibilityOptions}
                      placeholder="Set rebook eligibility status:"
                    />
                  </Stack>
                </fieldset>
                <fieldset className={fieldSetStyles}>
                  <Stack>
                    <Heading as="legend" className={legendStyles} level={3}>
                      Cancellation properties
                    </Heading>
                    <SelectBoxSelection
                      data-testid="cancelWithRefundOfferResponse-dropdown"
                      defaultValue={cancelWithRefundOfferResponse[0].value}
                      label="Cancel with refund offer - Response"
                      name="cancelWithRefundOfferResponse"
                      options={cancelWithRefundOfferResponse}
                      placeholder="Set cancel with refund offer response:"
                    />
                    <Inline noWrap>
                      <RadioSelection
                        className={flexStyles}
                        defaultValue="no"
                        id="isEligibleForVoid"
                        label="Is eligible for Void?"
                      />
                      <InputSelection
                        className={flexStyles}
                        defaultValue="18000"
                        id="voidExpirationTime"
                        label="Void expiration time (in seconds)"
                      />
                    </Inline>
                    <Inline noWrap>
                      <RadioSelection
                        className={flexStyles}
                        defaultValue="no"
                        id="isEligibleForCancelForAnyReason"
                        label="Is eligible for Cancel For Any Reason?"
                      />
                      <InputSelection
                        className={flexStyles}
                        defaultValue="350000"
                        id="cfarExpirationTime"
                        label="CFAR expiration time (in seconds)"
                      />
                    </Inline>
                    <Inline noWrap>
                      <RadioSelection
                        className={flexStyles}
                        defaultValue="no"
                        id="isEligibleForTicketRules"
                        label="Is eligible for Ticket Rule cancellation?"
                      />
                      <InputSelection
                        className={flexStyles}
                        defaultValue="350000"
                        id="ticketRulesExpirationTime"
                        label="Ticket rules expiration time (in seconds)"
                      />
                    </Inline>
                    <RadioSelection
                      defaultValue="no"
                      id="isCancellationGuaranteePurchased"
                      label="Is Cancellation Guarantee purchased?"
                    />
                    <RadioSelection
                      defaultValue="no"
                      id="isCancellationProtectionPurchased"
                      label="Is Cancellation Protection purchased?"
                    />
                  </Stack>
                </fieldset>
              </Stack>
            </fieldset>
          </Stack>
        </form>
      </Dialog>
    </>
  );
};

export default DebugOrderDetailPage;
