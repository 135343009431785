import PropTypes from 'prop-types';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { css, cx, mediaQueries, resetButtonStyle } from '@eti/styles';
import { useDirection } from '@eti/providers';

const buttonStyles = css`
  ${resetButtonStyle}; /* stylelint-disable-next-line */
  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
`;

const iconStyles = css`
  font-size: 1.125rem;

  @media ${mediaQueries.large.up} {
    font-size: 1.3125rem;
  }
`;

const iconWrapperStyles = css`
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;

  &:hover {
    cursor: pointer;
  }
`;

const CaretButton = ({ children, className, onClick, dataTestId }) => {
  const { isRTL } = useDirection();

  return (
    <button
      className={cx(buttonStyles, className)}
      data-testid={dataTestId}
      onClick={onClick}
      type="button"
    >
      {children}
      <div className={iconWrapperStyles}>
        {isRTL ? (
          <CaretLeft className={iconStyles} color="#a6a6a6" weight="bold" />
        ) : (
          <CaretRight className={iconStyles} color="#a6a6a6" weight="bold" />
        )}
      </div>
    </button>
  );
};

CaretButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
};

export default CaretButton;
