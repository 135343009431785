import { constants } from '@eti/utils';

const { brands } = constants;
const tripStackPartners = ['ts_wl_vi', 'ts_wl_vi_mix'];

const getPartner = (partner) => (tripStackPartners.includes(partner) ? brands.TRIPSTACK : partner);

const getBrandCode = ({ brand = { code: '' }, partner = '' } = {}) => {
  const lowerCasedPartner = getPartner(partner.toLowerCase());
  const isPartner = Object.values(brands).includes(lowerCasedPartner);
  return isPartner ? lowerCasedPartner : brand.code.toLowerCase();
};

export default getBrandCode;
