import screenSizes from './screenSizes';
const upQuery = min => `(min-width: ${min})`;
const onlyQuery = (min, max) => `(min-width: ${min}) and (max-width: ${max})`;
const downQuery = max => `(max-width: ${max})`;
const small = {
  only: downQuery(screenSizes.small.max)
};
const medium = {
  up: upQuery(screenSizes.medium.min),
  only: onlyQuery(screenSizes.medium.min, screenSizes.medium.max),
  down: downQuery(screenSizes.medium.max)
};
const large = {
  up: upQuery(screenSizes.large.min),
  only: onlyQuery(screenSizes.large.min, screenSizes.large.max),
  down: downQuery(screenSizes.large.max)
};
const xlarge = {
  only: upQuery(screenSizes.xlarge.min),
  up: upQuery(screenSizes.xlarge.min)
};
export default {
  small,
  medium,
  large,
  xlarge
};