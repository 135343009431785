import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const PackageTravelInfoPage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      pageTitle={t('PageTitle.rf.info-about-package-holidays.action')}
      textKey="InfoAboutPackageHolidays.Text"
    />
  );
};

export default PackageTravelInfoPage;
