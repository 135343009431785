import { analytics } from '@eti/utils';
import { SERVICE_PACKAGE_REQUEST } from '@eti/products/dist/products';
import { PaymentProduct } from '@eti/schema-types';
import { useSiteContext } from '@eti/providers';
import { useEffect } from 'react';
import { AncillaryProduct, Bundles, CartData, ProductItem, SiteCurrency } from '../models';
import { Product, TravelerPrice } from '../../../../pages/traveler-details/models';
import { PRODUCT_CATEGORIES } from '../constants/productCategories';
import { formatPrice, getAirProduct, getBundleProducts } from '../utils';

const pushEcomPurchaseEventToDatalayer = (
  hasCoupon: boolean,
  siteCurrency: SiteCurrency,
  discountPrice: number,
  obfuscatedOrderReference: string,
  items: ProductItem[],
  totalValue: number,
) => {
  const ecomPurchaseEvent = {
    ecommerce: {
      coupon: hasCoupon ? 'Coupon' : '',
      currency: siteCurrency.code,
      discount: discountPrice,
      items,
      transaction_id: obfuscatedOrderReference,
      value: totalValue,
    },
    event: 'ecom_purchase',
  };

  analytics.pushEntryToDataLayer({ ecommerce: null }, false);
  analytics.pushEntryToDataLayer(ecomPurchaseEvent, false);
};

const getPaymentProducts = (
  paymentProducts: PaymentProduct[],
  siteCurrency: { code: string; exponent: number },
) => {
  return paymentProducts?.map((payment) => {
    return {
      item_id: Number(payment.id),
      item_name: payment.code,
      item_category: PRODUCT_CATEGORIES.PAYMENT,
      price: formatPrice(siteCurrency.exponent, payment?.price?.price?.value),
      quantity: 1,
    };
  });
};
export const getExtraProducts = (
  orderExtraProducts: AncillaryProduct[] | Product[],
  currencyExponent: number,
) => {
  if (!orderExtraProducts?.length) {
    return [];
  }
  return orderExtraProducts.map((product) => {
    const isServicePackageRequest = product.id === String(SERVICE_PACKAGE_REQUEST.id);
    const isAncillaryProduct = 'totalPrice' in product;
    return {
      item_id: Number(product.id),
      item_name: product.code,
      item_category: isServicePackageRequest
        ? PRODUCT_CATEGORIES.SERVICE_CC
        : PRODUCT_CATEGORIES.EXTRA,
      price: formatPrice(currencyExponent, isAncillaryProduct ? product.totalPrice : product.price),
      quantity: product?.numberOfUnits || 1,
    };
  });
};

const pushEcomPurchaseEventForPostbooking = (
  obfuscatedOrderReference: string,
  siteCurrency: { code: string; exponent: number },
) => {
  const storedPostBookingProducts = JSON.parse(
    sessionStorage.getItem('postbookingProducts') ?? 'false',
  ) as AncillaryProduct[] | false;

  if (!storedPostBookingProducts) {
    return;
  }

  const items = [...getExtraProducts(storedPostBookingProducts, siteCurrency.exponent)].map(
    (item, index) => ({ ...item, index }),
  );
  const totalValue = items.reduce((acc, product) => acc + product.price, 0);

  pushEcomPurchaseEventToDatalayer(
    false,
    siteCurrency,
    0,
    obfuscatedOrderReference,
    items,
    totalValue,
  );

  sessionStorage.removeItem('postbookingProducts');
};

const pushEcomPurchaseEvent = (
  bundles: Bundles,
  coupon: string | null,
  discountPrice: number,
  obfuscatedOrderReference: string,
  orderExtraProducts: AncillaryProduct[],
  paymentProducts: PaymentProduct[],
  siteCurrency: SiteCurrency,
  travelerPrices: TravelerPrice[],
) => {
  const items = [
    getAirProduct(travelerPrices, siteCurrency.exponent),
    ...getBundleProducts(bundles, siteCurrency.exponent),
    ...getExtraProducts(orderExtraProducts, siteCurrency.exponent),
    ...getPaymentProducts(paymentProducts, siteCurrency),
  ].map((item, index) => ({ ...item, index }));

  const discount = formatPrice(siteCurrency.exponent, discountPrice);
  const totalValue = items.reduce((acc, product) => acc + product.price, 0) - discount;

  pushEcomPurchaseEventToDatalayer(
    Boolean(coupon),
    siteCurrency,
    discount,
    obfuscatedOrderReference,
    items,
    totalValue,
  );
};

export const useEcomPurchaseDataLayerEffect = (
  bundles: Bundles,
  discountPrice: number,
  isPostBookingOrder: boolean,
  obfuscatedOrderReference: string,
  orderExtraProducts: AncillaryProduct[],
  paymentProducts: PaymentProduct[],
  travelerPrices: TravelerPrice[],
) => {
  const { siteCurrency } = useSiteContext();
  useEffect(() => {
    const cartData = JSON.parse(sessionStorage.getItem('cartData') ?? 'false');
    if (!cartData) {
      return;
    }
    const { coupon }: CartData = cartData;
    if (isPostBookingOrder) {
      pushEcomPurchaseEventForPostbooking(obfuscatedOrderReference, siteCurrency);
    } else {
      pushEcomPurchaseEvent(
        bundles,
        coupon,
        discountPrice,
        obfuscatedOrderReference,
        orderExtraProducts,
        paymentProducts,
        siteCurrency,
        travelerPrices,
      );
    }
    sessionStorage.removeItem('cartData');
  }, [
    bundles,
    discountPrice,
    isPostBookingOrder,
    obfuscatedOrderReference,
    orderExtraProducts,
    paymentProducts,
    siteCurrency,
    travelerPrices,
  ]);
};
