import PropTypes from 'prop-types';
import { Card, LinkButton } from '@etg/wings';
import { DangerousHtmlContent, Icon as EtiIcon } from '@eti/components';
import { css, etiColors } from '@eti/styles';
import { useProperty, useTranslation } from '@eti/providers';
import { SelfServiceRebookingTraveler } from '@eti/types';
import { useToggle } from '../custom-hooks/toggle';
import ExtendedRebookingInfo from './ExtendedRebookingInfo';

const rebookingInfoStyles = css`
  display: flex;
  padding: 6px 0;
`;

const iconStyles = css`
  flex-shrink: 0;
`;

const titleStyles = css`
  font-size: 1rem;
  font-weight: 700;
  margin-top: -3px;
`;

const messageStyles = css`
  font-size: 0.875rem;
`;

const textsStyles = css`
  margin-left: 6px;

  [dir='rtl'] & {
    margin-left: 0;
    margin-right: 6px;
  }
`;

const readMoreStyles = css`
  font-size: 0.875rem;
  margin: 0 22px;
`;

const shouldHideRebookingInfo = (carriersToHideVoucherInfo = '', validatingCarriers = []) => {
  return validatingCarriers.some((carrier) => carriersToHideVoucherInfo.includes(carrier));
};

const RebookingBanner = ({ className, rebookingData, shouldShowRebookingCriteria }) => {
  const { t } = useTranslation();
  const { p } = useProperty();
  const { isOpen, handleOpen } = useToggle(false);

  const displayRebookingCriteria =
    shouldShowRebookingCriteria &&
    !shouldHideRebookingInfo(
      p('SelfServiceRebooking.HideVoucherInfo.Carriers'),
      rebookingData.validatingCarriers,
    );

  return (
    <Card className={className} data-testid="startPage-rebookingBanner-wrapper">
      <div className={rebookingInfoStyles}>
        <EtiIcon className={iconStyles} color={etiColors.confirmation.solid} id="tag" size="1rem" />
        <div className={textsStyles}>
          <h2 className={titleStyles}>{t('SelfServiceRebooking.InformationBox.Title')}</h2>
          <DangerousHtmlContent
            className={messageStyles}
            content={t('SelfServiceRebooking.InformationBox.Content')}
          />
        </div>
      </div>
      {displayRebookingCriteria && (
        <>
          <div className={rebookingInfoStyles}>
            <EtiIcon
              className={iconStyles}
              color={etiColors.warning.solid}
              id="exclamation-triangle"
              size="1rem"
            />
            <div className={textsStyles}>
              <h2 className={titleStyles}>{t('SelfServiceRebooking.InformationBox.Info.Title')}</h2>
              <DangerousHtmlContent
                className={messageStyles}
                content={t('SelfServiceRebooking.InformationBox.Info.Content')}
              />
            </div>
          </div>
          {!isOpen && (
            <LinkButton
              className={readMoreStyles}
              data-testid="startPage-rebookingBanner-readMore-Button"
              onClick={handleOpen}
            >
              {t('SelfServiceRebooking.InformationBox.ReadMore.Link.Text')}
            </LinkButton>
          )}
          {isOpen && <ExtendedRebookingInfo rebookingData={rebookingData} />}
        </>
      )}
    </Card>
  );
};

RebookingBanner.propTypes = {
  className: PropTypes.string,
  rebookingData: PropTypes.shape({
    criteria: PropTypes.arrayOf(PropTypes.string),
    formConstraints: PropTypes.shape({
      passengers: PropTypes.shape({
        isDisabled: PropTypes.bool,
        maxNumberOfTravelers: PropTypes.number,
      }),
    }),
    initialValues: PropTypes.shape({
      passengers: PropTypes.shape({
        adults: PropTypes.number,
        children: PropTypes.number,
        infants: PropTypes.number,
      }),
    }),
    travelers: PropTypes.arrayOf(SelfServiceRebookingTraveler),
    validatingCarriers: PropTypes.arrayOf(PropTypes.string),
  }),
  shouldShowRebookingCriteria: PropTypes.bool,
};

export default RebookingBanner;
