import { SEAT_MAP } from '../products';
import SeatMap from './containers/SeatMapContainer';
import getIcon from './utils/icon';
import { getMinPrice, getPrice, getSelected, getSelection, isSelected } from './utils/selection';
import { getNameInitials } from './utils/getNameInitials';
import { SEATING_PREFERENCE_VALUES } from './utils/constants';
import getGraphQlSelection from './utils/getGraphQlSelection';
import { getData, getTravelers, transformSeatmapPrices } from './utils/seatMapTransformer';
const formSection = SEAT_MAP.name;
export const seatMap = {
  getGraphQlSelection,
  getIcon,
  getMinPrice,
  getPrice: form => state => getPrice(formSection, form)(state).price,
  getMarkup: form => state => getPrice(formSection, form)(state).markup,
  getNameInitials,
  getSelected: getSelected.bind(null, formSection),
  getSelection: getSelection.bind(null, formSection),
  isSelected: isSelected.bind(null, formSection),
  formSection,
  seatingPreferenceValues: SEATING_PREFERENCE_VALUES,
  getTravelers,
  getData,
  transformSeatmapPrices,
  ...SEAT_MAP
};
export default SeatMap;