import { AuthenticationType } from '@eti/schema-types';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import * as authentication from '../index';
const orderInfoUser = {
  email: 'email@value.com',
  orderNumber: 'DEV001',
  loginType: AuthenticationType.OrderInfo
};
const logoutResultMock = {
  data: {
    logoutUser: {
      response: 'SUCCESS'
    }
  }
};
const spyOnUseAuth = function (user, authenticateResult, verifyResult, logoutResult) {
  if (logoutResult === void 0) {
    logoutResult = logoutResultMock;
  }
  return jest.spyOn(authentication, 'useAuth').mockImplementation(() => ({
    user,
    authenticateUser: jest.fn().mockImplementation(() => authenticateResult),
    verifyUser: jest.fn().mockImplementation(() => verifyResult),
    logoutUser: jest.fn().mockImplementation(() => logoutResult),
    authenticatedAction: jest.fn().mockImplementation(fun => fun()),
    authenticateUserResult: {
      loading: false,
      called: false,
      client: new ApolloClient({
        cache: new InMemoryCache({})
      }),
      reset: jest.fn()
    }
  }));
};
export const mockAuthProvider = (authenticated, loginType, isInitiallyLoggedOut) => {
  const user = authenticated ? {
    email: 'email@value.com',
    sessionOrderNumber: 'DEV001',
    loginOrderNumber: 'DEV001',
    loginType
  } : null;
  const authenticateResult = {
    data: {
      authenticateUser: {
        authenticated,
        urlForActualSite: null,
        user
      }
    }
  };
  const verifyResult = {
    data: {
      verifyUser: user
    }
  };
  spyOnUseAuth(isInitiallyLoggedOut ? null : user, authenticateResult, verifyResult);
};
export const mockAuthProviderWithNoAuthenticateUserData = () => {
  const authenticateResult = {
    data: {}
  };
  const verifyResult = {
    data: {
      verifyUser: orderInfoUser
    }
  };
  spyOnUseAuth(null, authenticateResult, verifyResult);
};
export const mockAuthProviderWithOrderOnDifferentSiteSameBrand = loginSource => {
  const urlForActualSiteMapping = {
    ORDER_LOGIN: 'https://gotogate.com/order-load-ref/AAAAAAA',
    POSTBOOKING_LOGIN: 'https://gotogate.com/postbooking-load-ref/AAAAAAA',
    CONTACT_US_LOGIN: 'https://gotogate.com/order-load-ref/AAAAAAA?source=order-details-login&targetTab=contact-us'
  };
  const authenticateResult = {
    data: {
      authenticateUser: {
        authenticated: false,
        urlForActualSite: 'https://gotogate.com',
        orderDetailsUrlForActualSite: urlForActualSiteMapping[loginSource],
        brandCodeForActualSite: 'gotogate',
        brandNameForActualSite: 'GoToGate',
        user: {
          email: 'email@value.com',
          loginOrderNumber: 'DEV001',
          loginType: AuthenticationType.OrderInfo,
          sessionOrderNumber: null
        }
      }
    }
  };
  const verifyResult = {
    data: {
      verifyUser: null
    }
  };
  spyOnUseAuth(null, authenticateResult, verifyResult);
};
export const mockAuthProviderWithOrderOnDifferentSiteDifferentBrand = () => {
  const authenticateResult = {
    data: {
      authenticateUser: {
        authenticated: false,
        urlForActualSite: 'https://mytrip.co.uk',
        orderDetailsUrlForActualSite: 'https://mytrip.co.uk/order-load-ref/AAAAATEST',
        brandCodeForActualSite: 'mytrip',
        brandNameForActualSite: 'Mytrip',
        user: orderInfoUser
      }
    }
  };
  const verifyResult = {
    data: {
      verifyUser: orderInfoUser
    }
  };
  spyOnUseAuth(orderInfoUser, authenticateResult, verifyResult);
};
export const mockAuthProviderWithError = () => {
  jest.spyOn(authentication, 'useAuth').mockImplementation(() => ({
    user: null,
    logoutUser: jest.fn().mockImplementation(() => {}),
    authenticateUser: jest.fn().mockImplementation(() => {}),
    verifyUser: jest.fn().mockImplementation(() => {}),
    authenticatedAction: jest.fn(),
    authenticateUserResult: {
      loading: false,
      called: false,
      client: new ApolloClient({
        cache: new InMemoryCache({})
      }),
      reset: jest.fn()
    }
  }));
};