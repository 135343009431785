const getTravelersText = (numberOfAdults, numberOfChildren, numberOfInfants, texts) => {
  const { adult, adults, child, children, infant, infants } = texts;

  const formatText = (count, singular, plural) =>
    count > 0 ? `${count} ${count === 1 ? singular : plural}` : '';

  const adultsText = formatText(numberOfAdults, adult, adults);
  const childrenText = formatText(numberOfChildren, child, children);
  const infantsText = formatText(numberOfInfants, infant, infants);

  return [adultsText, childrenText, infantsText].filter((text) => text).join(', ');
};

export default getTravelersText;
