import { ReactComponent as StandardSeat } from '@eti/assets/icons/c_flat-seat-standard.svg';
import { ReactComponent as SelectedSeat } from '@eti/assets/icons/c_flat-seat-selected.svg';
import { ReactComponent as ExtraLegSeat } from '@eti/assets/icons/c_flat-seat-extra-legroom.svg';
import { ReactComponent as FlatSeatFront } from '@eti/assets/icons/c_flat-seat-front-row.svg';
import { ReactComponent as EconomyConfort } from '@eti/assets/icons/c_flat-seat-economy-comfort.svg';
import { ReactComponent as ActiveFlatSeat } from '@eti/assets/icons/c_flat-seat-active.svg';
import { SeatCharacteristic } from '@eti/schema-types';
export const getSeatIconId = (mainCharacteristic, selected) => {
  if (selected) {
    return 'c_flat-seat-selected';
  }
  switch (mainCharacteristic) {
    case SeatCharacteristic.LegSpace:
      return 'c_flat-seat-extra-legroom';
    case SeatCharacteristic.FrontOfCabin:
      return 'c_flat-seat-front-row';
    case SeatCharacteristic.EconomyComfort:
      return 'c_flat-seat-economy-comfort';
    case SeatCharacteristic.Normal:
    default:
      return 'c_flat-seat-standard';
  }
};
export const getSeatIcon = (mainCharacteristic, selected) => {
  if (mainCharacteristic === 'active') {
    return ActiveFlatSeat;
  }
  if (selected) {
    return SelectedSeat;
  }
  switch (mainCharacteristic) {
    case SeatCharacteristic.LegSpace:
      return ExtraLegSeat;
    case SeatCharacteristic.FrontOfCabin:
      return FlatSeatFront;
    case SeatCharacteristic.EconomyComfort:
      return EconomyConfort;
    case SeatCharacteristic.Normal:
    default:
      return StandardSeat;
  }
};