import { COLORS } from '../../common/utils/icon/constants';
import { getIcon } from '../../common/utils/icon/icon';
const getStyles = notNeutral => `
  height: auto;
  width: ${notNeutral ? '56%' : '100%'};
`;
const configuration = {
  colors: [COLORS.green, COLORS.white],
  getStyles,
  id: 'refresh',
  useJaggedBackground: true
};
export default type => getIcon({
  ...configuration,
  ...{
    type
  }
});