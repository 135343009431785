import { css, etiColors } from '@eti/styles';
export const toggleBarContainerStyles = css`
  display: flex;
  flex-direction: column;
`;
export const toggleBarStyles = css`
  display: flex;
`;
export const getSelectedStyles = (isSelected, isReadOnly) => css`
  background-color: ${isReadOnly || !isSelected ? etiColors.grayScale[2] : etiColors.white};
`;
export const getSizeStyles = size => css`
  padding: 0.7188rem 0.625rem 0.7188rem 2.5rem;

  html[dir='rtl'] & {
    padding: 0.7188rem 2.5rem 0.7188rem 0.625rem;
  }

  @media (min-width: 60.625em) {
    font-size: ${size === 'large' ? '1rem' : '0.875rem'};
    padding: ${size === 'large' ? '0.5rem 0.625rem 0.5rem 2.4375rem' : '0.3125rem 0.625rem 0.375rem 2.25rem'};

    html[dir='rtl'] & {
      padding: ${size === 'large' ? '0.5rem 2.4375rem 0.5rem 0.625rem' : '0.3125rem 2.25rem 0.375rem 0.625rem'};
    }
  }

  & .etiCustomRadio {
    @media (min-width: 60.625em) {
      top: ${size === 'large' ? '0.5625rem' : '0.3125rem'};
    }
  }
`;
export const radioStyles = isReadOnly => css`
  background-color: #fff;
  border: 1px solid #bbb;
  color: ${isReadOnly ? etiColors.grayScale[10] : etiColors.black};
  cursor: ${isReadOnly ? 'not-allowed' : 'pointer'};
  flex: 1 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & .etiCustomRadio {
    margin-left: 0.625rem;

    html[dir='rtl'] & {
      margin-left: 0;
      margin-right: 0.625rem;
    }
  }

  &:first-child {
    border-radius: 0.1875rem 0 0 0.1875rem;

    html[dir='rtl'] & {
      border-radius: 0 0.1875rem 0.1875rem 0;
    }
  }

  /* stylelint-disable no-descending-specificity */
  &:last-child {
    border-radius: 0 0.1875rem 0.1875rem 0;

    html[dir='rtl'] & {
      border-radius: 0.1875rem 0 0 0.1875rem;
    }
  }

  &:not(:first-child, :last-child) {
    border-radius: 0;
  }

  &:not(:first-child) {
    border-left-width: 0;

    html[dir='rtl'] & {
      border-left-width: 1px;
      border-right-width: 0;
    }
  }
`;