import { connect } from 'react-redux';
import { formValueSelector, change as changeFormValue } from 'redux-form';
import { constants } from '@eti/utils';
import { getTravelersPrimaryFlow } from '../../utils/CheckInBaggageUtil';
import SecondBagCheckBox from '../components/SecondBagCheckBox';
import { CHECK_IN_BAGGAGE, SECOND_BAGGAGE } from '../../../products';
import { setSecondBagValueForTraveler } from '../../utils/secondBagUtils';
export const mapDispatchToProps = (dispatch, props) => {
  return {
    setSecondBagValueinCheckedBag: setSecondBagValueForTraveler(dispatch, CHECK_IN_BAGGAGE.name, props),
    setSecondBagValueinSecondBag: setSecondBagValueForTraveler(dispatch, SECOND_BAGGAGE.name, props)
  };
};
const updateIsSelectedSecondBag = (dispatch, form, productName, secondbagid, isSelected) => {
  if (secondbagid) {
    const secondBaggageSelection = `${productName}.isSelectedSecondBaggage-traveler-${secondbagid}`;
    dispatch(changeFormValue(form, secondBaggageSelection, isSelected));
  }
};
const onChange = (dispatch, _ref, shouldApplySelectionToAll) => {
  let {
    firstTravelerSelectionValue,
    form,
    isFirstTraveler,
    productName,
    secondBagTraveler,
    secondBagTravelers,
    orderSecondBag
  } = _ref;
  return checkboxValue => {
    const {
      id: secondbagid
    } = secondBagTraveler || {};
    updateIsSelectedSecondBag(dispatch, form, productName, secondbagid, checkboxValue);
    const {
      setSecondBagValueinCheckedBag,
      setSecondBagValueinSecondBag
    } = mapDispatchToProps(dispatch, {
      orderSecondBag
    });
    const applySecondBagToAllTravelers = () => {
      if (secondBagTravelers && secondBagTravelers !== null && secondBagTravelers !== void 0 && secondBagTravelers.length) {
        secondBagTravelers === null || secondBagTravelers === void 0 || secondBagTravelers.forEach(traveler => {
          setSecondBagValueinCheckedBag === null || setSecondBagValueinCheckedBag === void 0 || setSecondBagValueinCheckedBag(form, traveler, checkboxValue);
          setSecondBagValueinSecondBag === null || setSecondBagValueinSecondBag === void 0 || setSecondBagValueinSecondBag(form, traveler, checkboxValue);
        });
        secondBagTravelers.slice(1).forEach(traveler => updateIsSelectedSecondBag(dispatch, form, productName, traveler === null || traveler === void 0 ? void 0 : traveler.id, checkboxValue));
      }
    };
    if (isFirstTraveler && shouldApplySelectionToAll) {
      applySecondBagToAllTravelers();
    }
    if (!isFirstTraveler && checkboxValue !== firstTravelerSelectionValue) {
      dispatch(changeFormValue(form, `${productName}.shouldApplySelectionToAll`, false));
    }
    setSecondBagValueinCheckedBag === null || setSecondBagValueinCheckedBag === void 0 || setSecondBagValueinCheckedBag(form, secondBagTraveler, checkboxValue);
    setSecondBagValueinSecondBag === null || setSecondBagValueinSecondBag === void 0 || setSecondBagValueinSecondBag(form, secondBagTraveler, checkboxValue);
  };
};
export const mapStateToProps = (state, _ref2) => {
  let {
    form,
    productName,
    orderSecondBag,
    travelerNumber
  } = _ref2;
  const secondBagTravelers = getTravelersPrimaryFlow(orderSecondBag);
  const travelersWithoutInfantsSecondBag = secondBagTravelers === null || secondBagTravelers === void 0 ? void 0 : secondBagTravelers.filter(trav => trav.ageType !== constants.ageTypes.INFANT);
  const secondBagTraveler = travelersWithoutInfantsSecondBag === null || travelersWithoutInfantsSecondBag === void 0 ? void 0 : travelersWithoutInfantsSecondBag[travelerNumber];
  const {
    id: travelerId
  } = secondBagTraveler || {};
  const isSelectedSecondBaggage = formValueSelector(form)(state, `${productName}.isSelectedSecondBaggage-traveler-${travelerId}`);
  const shouldApplySelectionToAll = formValueSelector(form)(state, `${productName}.shouldApplySelectionToAll`);
  return {
    isSelectedSecondBaggage,
    shouldApplySelectionToAll
  };
};
export const mergeProps = (_ref3, _ref4, OwnProps) => {
  let {
    shouldApplySelectionToAll,
    isSelectedSecondBaggage
  } = _ref3;
  let {
    dispatch,
    ...dispatchProps
  } = _ref4;
  return {
    onChange: onChange(dispatch, OwnProps, shouldApplySelectionToAll),
    isSelectedSecondBaggage,
    ...dispatchProps,
    ...OwnProps
  };
};
export default connect(mapStateToProps, null, mergeProps)(SecondBagCheckBox);