let id = 0;

export function generateID() {
  id += 1;
  return `etiID_${id}`;
}

export function populateListWithId(list) {
  return list && list.length > 0
    ? list.map((listItem) => ({
        ...listItem,
        id: generateID(),
      }))
    : [];
}
