import { useEffect, useState } from 'react';
export const useDidUpdateEffect = (fn, inputs) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    if (isMounted) {
      fn();
    } else {
      setIsMounted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, inputs);
};