import { change as changeFormValue } from 'redux-form';
import { connect } from 'react-redux';
import { ToggleBar } from '@eti/form';
const onChange = (dispatch, _ref) => {
  let {
    isConfirmationEnabled,
    form,
    options,
    persuasionEnabled,
    priceRaw,
    setConfirmationIsOpen,
    setConfirmationOnConfirm,
    setConfirmationOnDecline,
    setPersuasionIsOpen,
    setPersuasionOnConfirm,
    setPersuasionOnDecline
  } = _ref;
  return (event, value, _prevValue, field) => {
    if (persuasionEnabled && value.value === false) {
      event.preventDefault();
      setPersuasionIsOpen(true);
      setPersuasionOnConfirm(() => () => {
        dispatch(changeFormValue(form, field, {
          value: options[1].value,
          priceRaw
        }));
        setPersuasionIsOpen(false);
      });
      setPersuasionOnDecline(() => () => {
        dispatch(changeFormValue(form, field, {
          value: options[0].value,
          priceRaw
        }));
        setPersuasionIsOpen(false);
      });
    }
    if (isConfirmationEnabled && value.value === true) {
      event.preventDefault();
      setConfirmationIsOpen(true);
      setConfirmationOnConfirm(() => () => {
        dispatch(changeFormValue(form, field, {
          value: options[1].value,
          priceRaw
        }));
        setConfirmationIsOpen(false);
      });
      setConfirmationOnDecline(() => () => {
        dispatch(changeFormValue(form, field, {
          value: options[0].value,
          priceRaw
        }));
        setConfirmationIsOpen(false);
      });
    }
  };
};
const mapDispatchToProps = (dispatch, props) => ({
  onChange: onChange(dispatch, props)
});
export default connect(null, mapDispatchToProps)(ToggleBar);