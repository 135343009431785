import userBrowserName from '../../../../../utils/src/device/browserType';
import userDeviceType from '../../../../../utils/src/device/isOfType';
import userOSType from '../../../../../utils/src/device/osType';

declare const Genesys: any;
declare const window: any;

const browser = userBrowserName();
const source = userDeviceType();
const os = userOSType();
const url = window.location.href;

export const initWebMessages = (
  siteName: string,
  siteLanguageCode: string,
  userName?: string,
  userEmail?: string | undefined,
  orderNumber?: string | null | undefined,
  deploymentId?: string,
) => {
  const keyNameGenesys = 'Genesys';
  const genesysUrl = 'https://apps.mypurecloud.de/genesys-bootstrap/genesys.min.js';
  const config = {
    environment: 'euc1',
    deploymentId,
  };
  window._genesysJs = keyNameGenesys;
  window[keyNameGenesys] =
    window[keyNameGenesys] ||
    // eslint-disable-next-line func-names
    function () {
      // eslint-disable-next-line prefer-rest-params
      (window[keyNameGenesys].q = window[keyNameGenesys].q || []).push(arguments);
    };
  window[keyNameGenesys].t = Number(new Date());
  window[keyNameGenesys].c = config;
  const genesysScript = document.createElement('script');
  genesysScript.setAttribute('id', 'webMessagesScript');

  genesysScript.async = true;
  genesysScript.src = genesysUrl;
  genesysScript.addEventListener('load', () => {
    Genesys('subscribe', 'Launcher.ready', () => {});
    Genesys('command', 'Launcher.hide');
    Genesys('subscribe', 'Launcher.hidden', () => {
      Genesys('command', 'Messenger.close');
    });
    Genesys('subscribe', 'Database.ready', () => {
      Genesys('command', 'Database.set', {
        messaging: {
          customAttributes: {
            name: userName,
            siteName,
            language: siteLanguageCode,
            email: userEmail,
            orderNumber,
            os,
            browser,
            source,
            url,
          },
        },
      });
    });

    Genesys('subscribe', 'Conversations.opened', () => {
      Genesys('command', 'Database.update', {
        messaging: {
          customAttributes: {
            name: userName,
            siteName,
            language: siteLanguageCode,
            email: userEmail,
            orderNumber,
            os,
            browser,
            source,
            url,
          },
        },
      });
    });
  });
  document.head.appendChild(genesysScript);
};

export const openWebMessagesChat = (extraInfo: Record<string, string> | null) => {
  Genesys('command', 'Messenger.open');
  if (extraInfo) {
    Genesys('command', 'Database.update', {
      messaging: {
        customAttributes: {
          ...extraInfo,
        },
      },
    });
  }
};

export const closeWebMessagesChat = () => {
  Genesys('command', 'Messenger.close');
  Genesys('command', 'Launcher.hide');
};
