import { css } from '@eti/styles';
export const rowItemSize = css`
  display: flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;
`;
export const resetButtonStyles = css`
  appearance: none;
  background-color: transparent;
  border: 0;
  font-family: inherit;
  padding: 0;
  text-align: left;

  html[dir='rtl'] & {
    text-align: right;
  }
`;