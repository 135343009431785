export const TRAVELER_TYPE_ICON_WIDTH = 20;
export const TRAVELER_TYPE_ICON_SIDE_MARGIN = 14;
export const TRAVELER_INITIALS_ICON_SIDE_MARGIN = 12;
export const TRAVELER_INITIALS_ICON_WIDTH = 24;
export const TRAVELER_BUTTON_WIDTH = TRAVELER_TYPE_ICON_WIDTH + TRAVELER_TYPE_ICON_SIDE_MARGIN * 2 + 1;
export const TRAVELER_ACTIVE_BUTTON_MIN_WIDTH = 220;
export const TRAVELER_ACTIVE_BUTTON_WIDE_MIN_WIDTH = 232;
export const EMPTY_FIELDS_STRING = '--';
export const SEAT_WIDTH = 36;
export const SEAT_HEIGHT = 40;
export const BULKHEAD = 'BULKHEAD';
export const LEG_SPACE = 'LEG_SPACE';
export const BASSINET = 'BASSINET';
export const FRONT_OF_CABIN = 'FRONT_OF_CABIN';
export const ELECTRONIC_CONNECTION = 'ELECTRONIC_CONNECTION';
export const SUITABLE_FOR_ADT_WITH_INF = 'SUITABLE_FOR_ADT_WITH_INF';
export const ECONOMY_COMFORT = 'ECONOMY_COMFORT';
export const NORMAL = 'NORMAL';
export const seatCharacteristics = {
  BULKHEAD,
  LEG_SPACE,
  BASSINET,
  FRONT_OF_CABIN,
  ELECTRONIC_CONNECTION,
  SUITABLE_FOR_ADT_WITH_INF,
  ECONOMY_COMFORT,
  NORMAL
};
export const SELL_SEATING_PREFERENCE = 'SellSeatingPreference';
export const SELL_SEATING_BESIDE = 'SellSeatingBeside';
export const SELL_SEAT_MAP_SEGMENT_NEW = 'SellSeatMapSegmentNew';
export const SELL_SEAT_MAP_SEGMENT = 'SellSeatMapSegment';
export const AISLE = 'AISLE';
export const WINDOW = 'WINDOW';
export const BESIDE = 'BESIDE';
export const NO_OPTION = 'NO_OPTION';
export const SEATING_PREFERENCE_OPTIONS = {
  SELL_SEATING_PREFERENCE,
  SELL_SEATING_BESIDE,
  SELL_SEAT_MAP_SEGMENT_NEW,
  SELL_SEAT_MAP_SEGMENT
};
export const SEATING_PREFERENCE_VALUES = {
  AISLE,
  WINDOW,
  BESIDE,
  NO_OPTION
};
export const PAYMENT = 'PAYMENT';
export const SUMMARY = 'SUMMARY';
export const DEFAULT = 'DEFAULT';
export const CONFIRMATION_MODAL_REDIRECT_TO = {
  PAYMENT,
  SUMMARY,
  DEFAULT
};
export const SEAT_MAP_PERSIST_IN_PRODUCT = 'SEAT_MAP_PERSIST_IN_PRODUCT';
export const SEAT_MAP_PERSIST_IN_PRODUCT_UNBOOKED_SEGMENTS = 'SEAT_MAP_PERSIST_IN_PRODUCT_UNBOOKED_SEGMENTS';
export const CONFIRMATION_MODAL_TYPE = {
  SEAT_MAP_PERSIST_IN_PRODUCT,
  SEAT_MAP_PERSIST_IN_PRODUCT_UNBOOKED_SEGMENTS
};
export const SEAT_MAP_PERSIST_ACTION_ACCEPT = 'SEAT_MAP_PERSIST_ACTION_ACCEPT';
export const SEAT_MAP_PERSIST_ACTION_DISMISS = 'SEAT_MAP_PERSIST_ACTION_DISMISS';
export const CONFIRMATION_MODAL_ACTION_TYPE = {
  SEAT_MAP_PERSIST_ACTION_ACCEPT,
  SEAT_MAP_PERSIST_ACTION_DISMISS
};