import Cookies from 'js-cookie';
import {
  CheckCircle,
  Copy,
  NumberCircleOne,
  NumberCircleThree,
  NumberCircleTwo,
} from '@phosphor-icons/react';
import { useTranslation } from '@eti/providers';
import { breakpoints, Button, Heading, Inline, Stack, vars } from '@etg/wings';
import { css } from '@eti/styles';
import { ContentWrapper, DangerousHtmlContent } from '@eti/components';
import { useState } from 'react';
import { PaymentStatus } from '@eti/schema-types';
import {
  PAYMENT_TRANSACTION_ID,
  PIX_PAYMENT_URL,
  PIX_REDIRECT_URL,
  QR_CODE_DATA,
  QR_CODE_URL,
} from '../payment/payment-methods/pix/constants/pixConstants';
import useClipboard from '../../common/custom-hooks/useClipboard';
import PaymentTimeoutModal from '../../common/error-modals/PaymentTimeoutModal';
import PaymentMethodLogo from '../payment/components/PaymentMethodLogo';
import usePaymentStatus from './hooks/usePaymentStatus';
import { useCountdown } from './hooks/useCountdown';

const headingWrapperStyles = css`
  display: flex;
  justify-content: space-between;
`;

const pageStyles = css`
  font-size: 0.875rem;
  padding-block: 32px;

  @media (min-width: ${breakpoints._768}) {
    max-width: 610px;
    padding-block: 24px;
  }

  @media (min-width: ${breakpoints._970}) {
    max-width: 616px;
  }

  @media (min-width: ${breakpoints._1024}) {
    max-width: 760px;
  }
`;

const cancelButtonStyles = css`
  width: 100%;

  @media (min-width: ${breakpoints._560}) {
    width: auto;
  }
`;

const copyButtonStyles = css`
  width: 200px;
`;

const getInstructionStepIcon = (index: number) => {
  const iconSize = 24;
  const iconWeight = 'fill';
  const iconColor = vars.colors.decoration.dark;

  switch (index) {
    case 0:
      return <NumberCircleOne color={iconColor} size={iconSize} weight={iconWeight} />;
    case 1:
      return <NumberCircleTwo color={iconColor} size={iconSize} weight={iconWeight} />;
    case 2:
      return <NumberCircleThree color={iconColor} size={iconSize} weight={iconWeight} />;
    default:
      return (
        <CheckCircle
          color={vars.colors.signal.positive.light}
          size={iconSize}
          weight={iconWeight}
        />
      );
  }
};

const removeCookieData = () => {
  Cookies.remove(QR_CODE_DATA);
  Cookies.remove(QR_CODE_URL);
  Cookies.remove(PAYMENT_TRANSACTION_ID);
  Cookies.remove(PIX_PAYMENT_URL);
  Cookies.remove(PIX_REDIRECT_URL);
};

const ProcessingPixPage = () => {
  const { t } = useTranslation();

  const { formattedTimeLeft, isExpired, resetStartTime } = useCountdown();
  const [isCopied, copyToClipboard] = useClipboard();
  const [hasRedirected, setHasRedirected] = useState(false);

  const paymentTransactionId = Cookies.get(PAYMENT_TRANSACTION_ID);
  const { status: pixStatus } = usePaymentStatus(paymentTransactionId ?? -1);

  const qrCodeUrl = Cookies.get(QR_CODE_URL);
  const qrCodeData = Cookies.get(QR_CODE_DATA);
  const paymentPageUrl = Cookies.get(PIX_PAYMENT_URL);
  const successRedirectUrl = Cookies.get(PIX_REDIRECT_URL);

  const cancelPayment = () => {
    resetStartTime();
    removeCookieData();

    if (paymentPageUrl) {
      window.location.assign(paymentPageUrl);
    }
  };

  const instructions = [
    t('Processing.Pix.Instructions.1'),
    t('Processing.Pix.Instructions.2'),
    t('Processing.Pix.Instructions.3'),
    t('Processing.Pix.Instructions.CheckBox'),
  ];

  if (pixStatus === PaymentStatus.Completed && successRedirectUrl) {
    resetStartTime();

    if (!hasRedirected) {
      setHasRedirected(true);
      window.location.assign(successRedirectUrl);
    }
  }

  if (isExpired && !hasRedirected) {
    return <PaymentTimeoutModal onClick={cancelPayment} />;
  }

  return (
    <ContentWrapper className={pageStyles}>
      <Stack spacing={24}>
        <div className={headingWrapperStyles}>
          <Heading level={1}>{t('Processing.Pix.Title')}</Heading>
          <PaymentMethodLogo id="pix" />
        </div>
        <DangerousHtmlContent
          content={t(
            'Processing.Pix.Description',
            `<div style='width:60px;display:inline-block;text-align: center;'><strong>${formattedTimeLeft}</strong></div>`,
          )}
        />
        <Inline align="center" spacing={8}>
          <Stack>
            {qrCodeUrl && <img alt="qrCode" src={qrCodeUrl} />}
            {qrCodeData && (
              <Button
                className={copyButtonStyles}
                data-testid="copyButton"
                iconPrefix={<Copy size={20} weight="light" />}
                onClick={() => copyToClipboard(qrCodeData)}
                variant="primary"
              >
                {isCopied
                  ? t('Processing.Pix.CopyLink.Success')
                  : t('Processing.Pix.CopyLink.Button')}
              </Button>
            )}
          </Stack>
        </Inline>
        <Stack as="ol" spacing={8}>
          <Heading level={4}>{t('Processing.Pix.Instructions.Label')}</Heading>
          {instructions.map((instruction, index) => (
            <Inline key={`pix-instruction-${index + 1}`} noWrap>
              <div>{getInstructionStepIcon(index)}</div>
              {instruction}
            </Inline>
          ))}
        </Stack>
        <p>{t('Processing.Pix.Instructions.Description')}</p>
        <Inline align="end">
          <Button className={cancelButtonStyles} onClick={cancelPayment}>
            {t('Processing.Pix.Cancel.Button')}
          </Button>
        </Inline>
      </Stack>
    </ContentWrapper>
  );
};

export default ProcessingPixPage;
