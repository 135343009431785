import { useEffect, useState } from 'react';
const defaultColor = '#0000EE';
const encodeIcon = icon => `url('data:image/svg+xml,${encodeURIComponent(icon)}');`;
export const useDynamicIcon = (color, iconType) => {
  const [icon, setIcon] = useState(encodeIcon(iconType(defaultColor)));
  useEffect(() => {
    var _color$match;
    let iconColor = color;
    const colorVariable = (_color$match = color === null || color === void 0 ? void 0 : color.match(/var\((.*)\)/)) !== null && _color$match !== void 0 ? _color$match : null;
    if (colorVariable) {
      iconColor = getComputedStyle(document.body).getPropertyValue(colorVariable[1]);
    }
    setIcon(encodeIcon(iconType(iconColor)));
  }, [color, iconType]);
  return [icon];
};