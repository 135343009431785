import PropTypes from 'prop-types';
import Media from 'react-media';
import { X } from '@phosphor-icons/react';
import { Button, Inline, breakpoints } from '@etg/wings';
import { css } from '@eti/styles';
import { tripTypes } from '../../../constants/tripTypesConstants';
import {
  DEPARTURE_DATE,
  DESTINATION,
  MULTI_BOUND,
  ORIGIN,
  SINGLE_BOUND,
} from '../constants/formFieldNames';
import Dates from '../containers/DatesContainer';
import OriginAndDestination from './OriginAndDestination';

const removeButtonStyles = css`
  margin-block-start: 21px;

  @media (min-width: ${breakpoints._768}) {
    height: 48px;
    padding: 0;
    width: 48px;
  }
`;

const emptyButtonBoxStyles = css`
  height: 48px;
  width: 48px;
`;

const getFieldName = (fieldName, selectedTripType, index) =>
  selectedTripType !== tripTypes.MULTI_STOP
    ? `${SINGLE_BOUND}.${fieldName}`
    : `${MULTI_BOUND}[${index}].${fieldName}`;

const Bound = ({
  boundIndex,
  dateFormat,
  daysFromTodayForValidDepartureDate,
  form,
  hasRemoveButtons,
  languageCode,
  numberOfBounds,
  onRemove,
  selectedTripType,
  updateBackgroundImage,
  shouldOpenDatePassengerFields,
}) => {
  const getName = (fieldName) => getFieldName(fieldName, selectedTripType, boundIndex);
  const isRemovableTrip = boundIndex > 1;

  return (
    <div data-testid="bound">
      {selectedTripType !== tripTypes.MULTI_STOP ? (
        <Inline
          alignY="bottom"
          collapseBelow="768px"
          spacing={{
            [breakpoints._0]: 24,
            [breakpoints._768]: 0,
          }}
        >
          <OriginAndDestination
            boundIndex={boundIndex}
            destinationName={getName(DESTINATION)}
            form={form}
            originName={getName(ORIGIN)}
            selectedTripType={selectedTripType}
            shouldOpenDatePassengerFields={shouldOpenDatePassengerFields}
            updateBackgroundImage={updateBackgroundImage}
          />
        </Inline>
      ) : (
        <Inline collapseBelow="768px" spacing={24}>
          <OriginAndDestination
            boundIndex={boundIndex}
            destinationName={getName(DESTINATION)}
            form={form}
            originName={getName(ORIGIN)}
            selectedTripType={selectedTripType}
            updateBackgroundImage={updateBackgroundImage}
          />
          <Dates
            boundIndex={boundIndex}
            dateFormat={dateFormat}
            daysFromTodayForValidDepartureDate={daysFromTodayForValidDepartureDate}
            departureDateName={getName(DEPARTURE_DATE)}
            form={form}
            languageCode={languageCode}
            numberOfBounds={numberOfBounds}
            selectedTripType={selectedTripType}
          />
          <Media query={`(min-width: ${breakpoints._768})`}>
            {(matches) =>
              matches && (
                <>
                  {isRemovableTrip ? (
                    <Button
                      className={removeButtonStyles}
                      data-testid={`searchForm-removeTrip-${boundIndex}-non-mobile-button`}
                      onClick={() => {
                        onRemove(boundIndex);
                      }}
                    >
                      <X color="currentColor" size="24px" weight="regular" />
                    </Button>
                  ) : (
                    <>{hasRemoveButtons && <div className={emptyButtonBoxStyles} />}</>
                  )}
                </>
              )
            }
          </Media>
        </Inline>
      )}
    </div>
  );
};

Bound.propTypes = {
  boundIndex: PropTypes.number,
  dateFormat: PropTypes.string,
  daysFromTodayForValidDepartureDate: PropTypes.number,
  form: PropTypes.string,
  formConstraints: PropTypes.shape({
    passengers: PropTypes.shape({
      isDisabled: PropTypes.bool,
      maxNumberOfTravelers: PropTypes.number,
    }),
  }),
  hasRemoveButtons: PropTypes.bool,
  languageCode: PropTypes.string,
  numberOfBounds: PropTypes.number,

  onRemove: PropTypes.func,
  selectedTripType: PropTypes.string,
  shouldOpenDatePassengerFields: PropTypes.func,
  updateBackgroundImage: PropTypes.func,
};

export default Bound;
