import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ContentWrapper, DangerousHtmlContent, Icon, Panel } from '@eti/components';
import { css, cx, etiColors, getTextStyles, mediaQueries } from '@eti/styles';
import { Heading } from '@etg/wings';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useProperty } from '@eti/providers';
import Loading from '../../../common/loading/components/Loading';
import TripReminder from '../../../widgets/trip-reminder/containers/TripReminderContainer';

const panelStyles = css`
  padding: 11.5625rem 0;

  @media (min-width: 48em) {
    padding: 14.0625rem 0;
  }

  @media (min-width: 64em) {
    padding: 16.5625rem 0;
  }
`;

const heroPanelStyles = css`
  padding: 100px 0;
`;

const listStyles = css`
  @media ${mediaQueries.large.up} {
    font-size: 0.875rem;
  }

  a {
    font-weight: 700;
  }

  p {
    margin-bottom: 0.875rem;
    margin-top: 0.25rem;
  }

  table {
    width: 100%;
  }

  ol {
    padding-left: 0.625rem;

    ul {
      padding-left: 2rem;
      padding-top: 1rem;
    }

    li {
      padding-left: 0;
      &::before {
        background-color: transparent;
        content: none;
        height: 0;
        position: initial;
        width: 0;
      }
    }
  }
`;

const printButtonStyles = css`
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin-left: auto;
  padding: 0.3rem 0.6rem;

  [dir='rtl'] & {
    margin-left: 0;
    margin-right: auto;
  }
`;

const contentStyles = css`
  scroll-margin-top: 80px;
`;

const heroTitleStyles = css`
  color: #fff;
  margin-bottom: 16px;
  text-shadow: 0 0 10px #000;
`;

const heroSubTextStyles = css`
  color: #fff;
  text-shadow: 0 0 10px #000;
`;

const scrollIntoViewWithOffset = (selector, offset) => {
  window.scrollTo({
    behavior: 'smooth',
    top:
      document.querySelector(selector).getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offset,
  });
};

const scrollToParagraph = (anchor) => {
  const key = `[name=${anchor}]`;
  const element = document.querySelector(key);
  if (element) {
    setTimeout(
      () =>
        window.requestAnimationFrame(() => {
          scrollIntoViewWithOffset(key, 80);
        }),
      100,
    );
  }
};

const SubPage = ({
  className,
  heroSubText,
  heroTitle,
  image,
  isSvg,
  textKey,
  shouldShowPrintButton,
  pageTitle,
  text = null,
}) => {
  const [content, setContent] = useState(text);
  const [title, setTitle] = useState();
  const [subText, setSubText] = useState();
  const { hash } = useLocation();
  const { p } = useProperty();
  const shouldHideHotelDeals = p('TravelConditions.HideHotelDeals');
  const shouldHideRentalCarDeals = p('TravelConditions.HideRentalCarDeals');
  const hasHeroContent = title && subText;

  const hideHotelsAndRentalCarDeals = useCallback(
    (responseContext) => {
      const contentWrapper = document.createElement('div');
      contentWrapper.innerHTML = responseContext;

      if (shouldHideHotelDeals) {
        const tableOfContentHotelBookingElement = contentWrapper.querySelector(
          `a[href='#MEDIATION_HOTEL']`,
        )?.parentElement;
        tableOfContentHotelBookingElement?.remove();
        const hotelBookingElement =
          contentWrapper.querySelector(`a[name='MEDIATION_HOTEL']`)?.parentElement.parentNode;
        hotelBookingElement?.remove();
      }

      if (shouldHideRentalCarDeals) {
        const tableOfContentRentalCarElement = contentWrapper.querySelector(
          `a[href='#MEDIATION_RENTAL_CAR']`,
        )?.parentElement;
        tableOfContentRentalCarElement?.remove();
        const rentalCarElement = contentWrapper.querySelector(`a[name='MEDIATION_RENTAL_CAR']`)
          ?.parentElement.parentNode;
        rentalCarElement?.remove();
      }
      return String(contentWrapper.innerHTML);
    },
    [shouldHideHotelDeals, shouldHideRentalCarDeals],
  );

  useEffect(() => {
    if (!content) {
      const urlParam = encodeURIComponent(`["${textKey}","${heroTitle}","${heroSubText}"]`);

      const fetchText = async () => {
        const resp = await fetch(`/rpc.fetch-uitexts.do.action?data=${urlParam}&allowHtml=true`);
        const respJson = await resp.json();
        const respText = respJson.model[textKey] || '';
        const respTitle = respJson.model[heroTitle] || '';
        const respSubText = respJson.model[heroSubText] || '';
        const responseContext =
          shouldHideHotelDeals || shouldHideRentalCarDeals
            ? hideHotelsAndRentalCarDeals(respText)
            : respText;
        setContent(responseContext);
        setTitle(respTitle);
        setSubText(respSubText);
      };

      fetchText();
    }
  }, [
    content,
    heroTitle,
    heroSubText,
    hideHotelsAndRentalCarDeals,
    shouldHideHotelDeals,
    shouldHideRentalCarDeals,
    textKey,
  ]);

  useEffect(() => {
    if (content && hash) {
      scrollToParagraph(hash.replace('#', ''));
    }
  }, [content, hash]);

  return (
    <>
      <Helmet title={pageTitle} />
      {content == null ? (
        <Loading dataTestId="sub-page-loading-spinner" />
      ) : (
        <section className={cx(getTextStyles(), listStyles, className)}>
          {image && (
            <Panel
              backgroundImage={image}
              className={cx('image-panel', hasHeroContent ? heroPanelStyles : panelStyles)}
              dataTestId="image-panel"
              isSvg={isSvg}
            >
              {title && (
                <ContentWrapper>
                  <Heading className={heroTitleStyles} data-testid="sub-page-hero-title" level={1}>
                    {title}
                  </Heading>
                  {subText && (
                    <Heading
                      className={heroSubTextStyles}
                      data-testid="sub-page-hero-subtext"
                      level={2}
                    >
                      {subText}
                    </Heading>
                  )}
                </ContentWrapper>
              )}
            </Panel>
          )}
          <Panel>
            <ContentWrapper>
              {shouldShowPrintButton && (
                <button
                  className={cx(`sub-page-print-button ${printButtonStyles}`)}
                  data-testid="sub-page-print-button"
                  dir="rtl"
                  onClick={window.print}
                  type="button"
                >
                  <Icon color={etiColors.grayScale[10]} id="print" size="1rem" />
                </button>
              )}
              <DangerousHtmlContent className={contentStyles} content={content} />
            </ContentWrapper>
          </Panel>
        </section>
      )}
      <TripReminder />
    </>
  );
};

SubPage.propTypes = {
  className: PropTypes.string,
  heroSubText: PropTypes.string,
  heroTitle: PropTypes.string,
  image: PropTypes.string,
  isSvg: PropTypes.bool,
  pageTitle: PropTypes.string,
  shouldShowPrintButton: PropTypes.bool,
  text: PropTypes.string,
  textKey: PropTypes.string,
};

export default SubPage;
