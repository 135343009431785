import deepFilter from 'deep-filter';
import { formValueSelector } from 'redux-form';
import isPlainObj from 'is-plain-obj';
import flatMapDeep from 'lodash/flatMapDeep';
const getMarkup = (markup1, markup2) => {
  if (typeof markup1 !== 'number') {
    return markup2;
  }
  if (typeof markup2 !== 'number') {
    return markup1;
  }
  return markup1 + markup2;
};
const addPrice = (_ref, _ref2) => {
  let {
    price: price1,
    markup: markup1
  } = _ref;
  let {
    price: price2,
    markup: markup2
  } = _ref2;
  return {
    price: price1 + (price2 !== null && price2 !== void 0 ? price2 : 0),
    markup: getMarkup(markup1, markup2)
  };
};
const getPriceRecursive = arr => arr.reduce((total, price) => {
  if (Array.isArray(price)) {
    return addPrice(total, getPriceRecursive(price));
  }
  if (isPlainObj(price) && typeof price.price !== 'number') {
    return addPrice(total, getPriceRecursive(Object.values(price)));
  }
  return addPrice(total, price);
}, {
  price: 0,
  markup: null
});
const filterValues = input => deepFilter(input, value => {
  if (Array.isArray(value) || typeof value === 'string') {
    return value.length > 0;
  }
  if (value && typeof value === 'object') {
    for (const key of Object.keys(value)) {
      return {}.hasOwnProperty.call(value, key);
    }
  }
  return value !== null;
});
export const isSelected = (name, form) => state => formValueSelector(form)(state, `${name}.value.value`) === 'true';
export const getSelection = (name, form) => state => {
  const data = formValueSelector(form)(state, name) || {};
  const filteredData = Object.keys(data).filter(objectKey => objectKey !== 'value').reduce((newObj, key) => {
    const updatedObj = {
      ...newObj
    };
    updatedObj[key] = data[key];
    return updatedObj;
  }, {});
  return isSelected(name, form)(state) ? filterValues(Object.values(filteredData)) : [];
};
export const getSelected = (name, form) => state => boundSegmentIndex => filterValues(formValueSelector(form)(state, `${name}.${boundSegmentIndex}`) || []);
export const getPrice = (name, form) => state => {
  return getPriceRecursive(getSelection(name, form)(state));
};
export const processSeatSelection = seatSelection => {
  const flattenArray = flatMapDeep(seatSelection, bound => Object.values(bound)).filter(selection => selection !== null);
  return flattenArray.slice(1);
};
export const getUpdatedSeatMap = (boundSegmentIndex, travelerId, seatSelection) => {
  const updatedSeatSelection = {};
  Object.entries(seatSelection).forEach(_ref3 => {
    let [keyName, value] = _ref3;
    const selection = value;
    if (keyName === boundSegmentIndex) {
      selection[`traveler${travelerId}`] = null;
    }
    updatedSeatSelection[keyName] = selection;
  });
  const selectedSeats = processSeatSelection(updatedSeatSelection);
  if (selectedSeats.length === 0) {
    updatedSeatSelection.value.value = 'false';
  }
  return updatedSeatSelection;
};
const getAllAvailableSeatsForDeck = deck => {
  const deckSeats = [];
  if (deck) {
    for (const cabin of deck.cabins) {
      for (const row of cabin.rows) {
        for (const seat of row.sellSeatMapSeats) {
          if (seat.isBookable && typeof seat.price === 'number') {
            deckSeats.push(seat);
          }
        }
      }
    }
  }
  return deckSeats;
};
const collectAllSeats = bounds => {
  let allSeats = [];
  for (const bound of bounds) {
    for (const segment of bound.sellSeatMapSegments) {
      allSeats = [...allSeats, ...getAllAvailableSeatsForDeck(segment.upperDeck), ...getAllAvailableSeatsForDeck(segment.mainDeck), ...getAllAvailableSeatsForDeck(segment.lowerDeck)];
    }
  }
  return allSeats;
};
export const getMinPrice = function (bounds) {
  if (bounds === void 0) {
    bounds = [];
  }
  const seats = collectAllSeats(bounds);
  if (seats.length === 0) {
    return 0;
  }
  return seats.reduce((cheapestSeat, currentSeat) => currentSeat.price < cheapestSeat.price ? currentSeat : cheapestSeat).price;
};