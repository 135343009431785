import { OverlayCardTitle, OverlayContent, OverlayFooter, OverlayHeader } from './components/OverlayComponents';
import { OverlayHeaderWithBackButton } from './components/OverlayComponentsBackButton';
import OverlayDrawer from './components/OverlayDrawer';
import { backdropStyles, modalStyles } from './utils/overlayStyles';
export default {
  Drawer: OverlayDrawer,
  Header: OverlayHeader,
  Content: OverlayContent,
  CardTitle: OverlayCardTitle,
  Footer: OverlayFooter,
  ModalStyles: modalStyles,
  BackdropStyles: backdropStyles,
  HeaderWithBackButton: OverlayHeaderWithBackButton
};