/* eslint-disable react/prop-types */
import { breakpoints, Button, Dialog, Inline, Stack } from '@etg/wings';
import { css } from '@eti/styles';
import { useState } from 'react';
import { DebugButton } from '.';

const submitButtonStyles = css`
  min-width: 200px;
`;

const inputRowStyles = css`
  @media (min-width: ${breakpoints._560}) {
    align-items: center;
    justify-content: space-between;

    & > * {
      flex: 1;
    }
  }
`;

const selectStyles = css`
  height: 36px;
  padding-inline-start: 4px;
`;

const ADYEN = 'ADYEN';
const WORLDPAY = 'WORLDPAY';
const PAY_INTERNAL = 'PAY_INTERNAL';

const FAILURE = 'FAILURE';
const PRICE_CHANGE = 'PRICE_CHANGE';
const SUCCESS = 'SUCCESS';
const MANUAL_FRAUD_REVIEW = 'MANUAL_FRAUD_REVIEW';
const PENDING_PROVIDER_BOOKING = 'PENDING_PROVIDER_BOOKING';
const THREE_D_SECURE = '3D_SECURE';

const BANK_ACCEPT = 'BANK_ACCEPT';
const BANK_EXCEPTION = 'BANK_EXCEPTION';
const BANK_PENDING_PROVIDER_BOOKING = 'BANK_PENDING_PROVIDER_BOOKING';
const BANK_PRICE_CHANGE = 'BANK_PRICE_CHANGE';

const paymentServiceProviderOptions = [
  { label: 'Adyen', value: ADYEN },
  { label: 'Worldpay', value: WORLDPAY },
  { label: 'Pay Internal (zero amount cart)', value: PAY_INTERNAL },
];

const cardPaymentEventOptions = {
  [ADYEN]: [
    { label: 'Success', value: SUCCESS },
    { label: 'Failure', value: FAILURE },
    { label: 'Manual fraud review', value: MANUAL_FRAUD_REVIEW },
    { label: 'Pending provider booking', value: PENDING_PROVIDER_BOOKING },
    { label: 'Price change', value: PRICE_CHANGE },
    { label: '3D secure', value: THREE_D_SECURE },
  ],
  [WORLDPAY]: [
    { label: 'Success', value: SUCCESS },
    { label: 'Failure', value: FAILURE },
    { label: 'Manual fraud review', value: MANUAL_FRAUD_REVIEW },
    { label: 'Pending provider booking', value: PENDING_PROVIDER_BOOKING },
    { label: 'Price change', value: PRICE_CHANGE },
    { label: '3D secure', value: THREE_D_SECURE },
  ],
  [PAY_INTERNAL]: [{ label: '', value: '' }],
};

const bankPaymentEventOptions = {
  [ADYEN]: [
    { label: '', value: '' },
    { label: 'Bank success', value: BANK_ACCEPT },
    { label: 'Bank exception', value: BANK_EXCEPTION },
    { label: 'Bank pending provider booking', value: BANK_PENDING_PROVIDER_BOOKING },
    { label: 'Bank price change', value: BANK_PRICE_CHANGE },
  ],
  [WORLDPAY]: [],
  [PAY_INTERNAL]: [],
};

const createOption = ({ label, value }) => (
  <option key={value} value={value}>
    {label}
  </option>
);

const DebugButtonPaymentPageMockConfig = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [paymentServiceProvider, setPaymentServiceProvider] = useState(
    sessionStorage.getItem('paymentServiceProvider') || ADYEN,
  );
  const [makeOrderWithBankPaymentEvent, setMakeOrderWithBankPaymentEvent] = useState(
    sessionStorage.getItem('makeOrderWithBankPaymentEvent') ||
      bankPaymentEventOptions[paymentServiceProvider][0]?.value,
  );
  const [makeOrderWithCardPaymentEvent, setMakeOrderWithCardPaymentEvent] = useState(
    sessionStorage.getItem('makeOrderWithCardPaymentEvent') ||
      cardPaymentEventOptions[paymentServiceProvider][0].value,
  );
  const [thunderbirdEnabled, setThunderbirdEnabled] = useState(
    sessionStorage.getItem('thunderbirdEnabled')
      ? sessionStorage.getItem('thunderbirdEnabled') === 'true'
      : true,
  );
  const [mockedPaymentResponseType, setMockedPaymentResponseType] = useState(
    sessionStorage.getItem('mockedPaymentResponseType') || '',
  );
  const [binVerificationType, setBinVerificationType] = useState(
    sessionStorage.getItem('binVerificationType') || 'FullBin',
  );
  const [doubleCurrencyEnabled, setDoubleCurrencyEnabled] = useState(
    sessionStorage.getItem('doubleCurrencyEnabled') === 'true',
  );
  const [cardInstalmentsEnabled, setCardInstalmentsEnabled] = useState(
    sessionStorage.getItem('cardInstalmentsEnabled') === 'true',
  );
  const [inicisCreditCardEnabled, setInicisCreditCardEnabled] = useState(
    sessionStorage.getItem('inicisCreditCardEnabled') === 'true',
  );
  const openDialog = () => setIsDialogOpen(true);
  const dismissDialog = () => setIsDialogOpen(false);

  const handlePaymentServiceProviderChange = (event) => {
    const { value } = event.target;
    const cardOption = cardPaymentEventOptions[value]?.[0]?.value ?? '';
    const bankOption = bankPaymentEventOptions[value]?.[0]?.value ?? '';

    sessionStorage.setItem('paymentServiceProvider', value);
    setPaymentServiceProvider(value);
    sessionStorage.setItem('makeOrderWithCardPaymentEvent', cardOption);
    setMakeOrderWithCardPaymentEvent(cardOption);
    sessionStorage.setItem('makeOrderWithBankPaymentEvent', bankOption);
    setMakeOrderWithBankPaymentEvent(bankOption);
  };

  const handleMakeOrderWithCardPaymentEventChange = (event) => {
    const { value } = event.target;
    sessionStorage.setItem('makeOrderWithCardPaymentEvent', value);
    setMakeOrderWithCardPaymentEvent(value);
  };

  const handleMakeOrderWithBankPaymentEventChange = (event) => {
    const { value } = event.target;
    sessionStorage.setItem('makeOrderWithBankPaymentEvent', value);
    setMakeOrderWithBankPaymentEvent(value);
  };

  const handleMockedPaymentResponseTypeChange = (event) => {
    const { value } = event.target;
    sessionStorage.setItem('mockedPaymentResponseType', value);
    setMockedPaymentResponseType(value);
  };

  const handleBinVerificationTypeChange = (event) => {
    const { value } = event.target;
    sessionStorage.setItem('binVerificationType', value);
    setBinVerificationType(value);
  };

  const handleThunderbirdEnabledChange = (event) => {
    const { checked } = event.target;
    sessionStorage.setItem('thunderbirdEnabled', checked);
    setThunderbirdEnabled(checked);
  };

  const handleDoubleCurrencyEnabledChange = (event) => {
    const { checked } = event.target;
    sessionStorage.setItem('doubleCurrencyEnabled', checked);
    setDoubleCurrencyEnabled(checked);
  };

  const handleCardInstalmentsEnabledChange = (event) => {
    const { checked } = event.target;
    sessionStorage.setItem('cardInstalmentsEnabled', checked);
    setCardInstalmentsEnabled(checked);
  };

  const handleInicisCreditCardEnabledChange = (event) => {
    const { checked } = event.target;
    sessionStorage.setItem('inicisCreditCardEnabled', checked);
    setInicisCreditCardEnabled(checked);
  };

  return (
    <>
      <DebugButton data-testid="paymentConfig-button" onClick={openDialog}>
        Payment config
      </DebugButton>
      <Dialog
        footer={
          <Inline align="end" collapseBelow={breakpoints._560} spacing={8}>
            <Button
              className={submitButtonStyles}
              data-testid="paymentConfigSave-button"
              form="paymentPageMockConfigForm"
              type="submit"
              variant="primary"
            >
              Reload page
            </Button>
          </Inline>
        }
        isOpen={isDialogOpen}
        onDismiss={dismissDialog}
        title="Mocked payment configuration"
      >
        <form action="/debug.payment.mock.config" id="paymentPageMockConfigForm" method="POST">
          <Stack spacing={8}>
            <label htmlFor="paymentServiceProvider">
              <Inline
                className={inputRowStyles}
                collapseBelow={breakpoints._560}
                spacing={{ [breakpoints._0]: 4, [breakpoints._560]: 16 }}
              >
                <span>Payment service provider</span>
                <select
                  className={selectStyles}
                  id="paymentServiceProvider"
                  name="paymentServiceProvider"
                  onChange={handlePaymentServiceProviderChange}
                  value={paymentServiceProvider}
                >
                  {paymentServiceProviderOptions.map(createOption)}
                </select>
              </Inline>
            </label>

            <label htmlFor="makeOrderWithCardPaymentEvent">
              <Inline
                className={inputRowStyles}
                collapseBelow={breakpoints._560}
                spacing={{ [breakpoints._0]: 4, [breakpoints._560]: 16 }}
              >
                <span>Make order with card payment event</span>
                <select
                  className={selectStyles}
                  id="makeOrderWithCardPaymentEvent"
                  name="makeOrderWithCardPaymentEvent"
                  onChange={handleMakeOrderWithCardPaymentEventChange}
                  value={makeOrderWithCardPaymentEvent}
                >
                  {cardPaymentEventOptions[paymentServiceProvider].map(createOption)}
                </select>
              </Inline>
            </label>

            {bankPaymentEventOptions[paymentServiceProvider].length > 0 && (
              <label htmlFor="makeOrderWithBankPaymentEvent">
                <Inline
                  className={inputRowStyles}
                  collapseBelow={breakpoints._560}
                  spacing={{ [breakpoints._0]: 4, [breakpoints._560]: 16 }}
                >
                  <span>Enable bank payment</span>
                  <select
                    className={selectStyles}
                    id="makeOrderWithBankPaymentEvent"
                    name="makeOrderWithBankPaymentEvent"
                    onChange={handleMakeOrderWithBankPaymentEventChange}
                    value={makeOrderWithBankPaymentEvent}
                  >
                    {bankPaymentEventOptions[paymentServiceProvider].map(createOption)}
                  </select>
                </Inline>
              </label>
            )}

            <label htmlFor="paymentResponseType">
              <Inline
                className={inputRowStyles}
                collapseBelow={breakpoints._560}
                spacing={{ [breakpoints._0]: 4, [breakpoints._560]: 16 }}
              >
                <span>Mock payment response type</span>
                <select
                  className={selectStyles}
                  id="paymentResponseType"
                  name="paymentResponseType"
                  onChange={handleMockedPaymentResponseTypeChange}
                  value={mockedPaymentResponseType}
                >
                  <option>Choose</option>
                  <option value="RECEIPT">RECEIPT</option>
                  <option value="PRICE_CHANGE">PRICE_CHANGE</option>
                  <option value="PAYMENT_REDIRECT">PAYMENT_REDIRECT</option>
                  <option value="PROCESSING_ORDER">PROCESSING_ORDER</option>
                  <option value="PAYMENT_PAGE_WITH_MESSAGE">PAYMENT_PAGE_WITH_MESSAGE</option>
                </select>
              </Inline>
            </label>

            <label htmlFor="binVerificationType">
              <Inline
                className={inputRowStyles}
                collapseBelow={breakpoints._560}
                spacing={{ [breakpoints._0]: 4, [breakpoints._560]: 16 }}
              >
                <span>Bin verification type</span>
                <select
                  className={selectStyles}
                  id="binVerificationType"
                  name="binVerificationType"
                  onChange={handleBinVerificationTypeChange}
                  value={binVerificationType}
                >
                  <option value="FullBin">Full bin</option>
                  <option value="HalfBin">Half bin</option>
                </select>
              </Inline>
            </label>

            <label htmlFor="thunderbirdEnabled">
              <Inline spacing={8}>
                <input
                  checked={thunderbirdEnabled}
                  id="thunderbirdEnabled"
                  name="thunderbirdEnabled"
                  onChange={handleThunderbirdEnabledChange}
                  type="checkbox"
                />
                <span>Enable thunderbird</span>
              </Inline>
            </label>

            <label htmlFor="doubleCurrencyEnabled">
              <Inline spacing={8}>
                <input
                  checked={doubleCurrencyEnabled}
                  id="doubleCurrencyEnabled"
                  name="doubleCurrencyEnabled"
                  onChange={handleDoubleCurrencyEnabledChange}
                  type="checkbox"
                />
                <span>Enable double currency</span>
              </Inline>
            </label>

            <label htmlFor="cardInstalmentsEnabled">
              <Inline spacing={8}>
                <input
                  checked={cardInstalmentsEnabled}
                  id="cardInstalmentsEnabled"
                  name="cardInstalmentsEnabled"
                  onChange={handleCardInstalmentsEnabledChange}
                  type="checkbox"
                />
                <span>Enable card instalments</span>
              </Inline>
            </label>

            <label htmlFor="inicisCreditCardEnabled">
              <Inline spacing={8}>
                <input
                  checked={inicisCreditCardEnabled}
                  id="inicisCreditCardEnabled"
                  name="inicisCreditCardEnabled"
                  onChange={handleInicisCreditCardEnabledChange}
                  type="checkbox"
                />
                <span>Enable Inicis credit card form</span>
              </Inline>
            </label>
          </Stack>
        </form>
      </Dialog>
    </>
  );
};

export default DebugButtonPaymentPageMockConfig;
