import PropTypes from 'prop-types';
import { css, mediaQueries } from '@eti/styles';
import { breakpoints, Column, Columns, Heading, Inline, Stack, vars } from '@etg/wings';
import { ContentWrapper, DangerousHtmlContent } from '@eti/components';
import { CheckCircle, Envelope, HandWaving } from '@phosphor-icons/react';
import { useTranslation } from '@eti/providers';
import { useEffect, useState } from 'react';

import { usePendingProviderDataLayerEffect } from '../../../common/data-layer/ecommerce/custom-hooks/usePendingProviderDataLayerEffect';

const pageStyles = css`
  margin-block: 24px;

  @media ${mediaQueries.medium.up} {
    margin-block: 32px;
  }

  @media ${mediaQueries.large.up} {
    margin-block: 48px;
  }
`;

const imageStyles = css`
  height: 184px;

  @media ${mediaQueries.large.up} {
    height: 368px;
  }
`;

const PendingProviderImage = () => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    let isMounted = true;
    (async () => {
      if (isMounted) {
        setImage((await import(`@eti/assets/images/panel/pending-provider.svg`)).default);
      }
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  return image ? (
    <img alt="Pending provider" className={imageStyles} src={image} />
  ) : (
    <div className={imageStyles} />
  );
};

const columnWidth = { [breakpoints._0]: '6/6', [breakpoints._768]: '2/6' };

const PendingProviderBookingPage = ({
  data: { pendingProviderBooking },
  processingOrderMessage,
}) => {
  const { departureAt, origin, destination, fullName, orderNumberToBe, customerEmail } =
    pendingProviderBooking;
  const { t } = useTranslation();
  usePendingProviderDataLayerEffect(processingOrderMessage);
  return (
    <ContentWrapper className={pageStyles}>
      <Stack spacing={48}>
        <Inline align="center">
          <PendingProviderImage />
        </Inline>
        <Inline align="center">
          <Heading level={1}>{t('PendingProvider.Header', fullName)}</Heading>
        </Inline>
        <Columns collapseBelow={breakpoints._768}>
          <Column width={columnWidth}>
            <Stack>
              <Inline align="center">
                <CheckCircle color={vars.colors.signal.positive.light} size={32} weight="fill" />
              </Inline>
              <Heading level={2}>{t('PendingProvider.Processing.Header')}</Heading>
              <DangerousHtmlContent
                content={t(
                  'PendingProvider.Processing.Text',
                  origin,
                  destination,
                  departureAt,
                  orderNumberToBe,
                )}
                data-testid="pendingProvider-processing-text"
              />
            </Stack>
          </Column>
          <Column width={columnWidth}>
            <Stack>
              <Inline align="center">
                <Envelope size={32} weight="thin" />
              </Inline>
              <Heading level={2}>{t('PendingProvider.Confirmation.Header')}</Heading>
              <DangerousHtmlContent
                content={t('PendingProvider.Confirmation.Text', customerEmail)}
                data-testid="pendingProvider-confirmation-text"
              />
            </Stack>
          </Column>
          <Column width={columnWidth}>
            <Stack>
              <Inline align="center">
                <HandWaving size={32} weight="thin" />
              </Inline>
              <Heading level={2}>{t('PendingProvider.Done.Header')}</Heading>
              <DangerousHtmlContent content={t('PendingProvider.Done.Text')} />
            </Stack>
          </Column>
        </Columns>
      </Stack>
    </ContentWrapper>
  );
};

PendingProviderBookingPage.propTypes = {
  data: PropTypes.shape({
    pendingProviderBooking: PropTypes.shape({
      customerEmail: PropTypes.string,
      departureAt: PropTypes.string,
      destination: PropTypes.string,
      fullName: PropTypes.string,
      orderNumberToBe: PropTypes.string,
      origin: PropTypes.string,
    }),
  }),
  processingOrderMessage: PropTypes.string,
};

export default PendingProviderBookingPage;
