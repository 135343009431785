import { useTranslation } from '@eti/providers';
import SubPage from './SubPage';

const SustainabilityPage = () => {
  const { t } = useTranslation();
  return (
    <SubPage
      heroSubText="Sustainability.Hero.Text"
      heroTitle="Sustainability.Hero.Title"
      image="cookies"
      pageTitle={t('PageTitle.rf.sustainability.action')}
      textKey="Sustainability.HtmlContent"
    />
  );
};

export default SustainabilityPage;
