import { change as changeFormValue } from 'redux-form';
import { connect } from 'react-redux';
import AncillaryToggleBarField from '../components/AncillaryToggleBarField';
const stringToBoolean = value => {
  const val = value === null || value === void 0 ? void 0 : value.toLowerCase().trim();
  return val === 'true' || val === 'false' ? JSON.parse(val) : val;
};
const isSelected = value => stringToBoolean(value.value);
const onChange = (dispatch, _ref) => {
  let {
    isConfirmationEnabled,
    form,
    options,
    persuasionEnabled,
    priceRaw,
    setConfirmationIsOpen,
    setConfirmationOnConfirm,
    setConfirmationOnDecline,
    setPersuasionIsOpen,
    setPersuasionOnConfirm,
    setPersuasionOnDecline
  } = _ref;
  return (e, value, _prevValue, field) => {
    if (persuasionEnabled && !isSelected(value)) {
      e.preventDefault();
      setPersuasionIsOpen(true);
      setPersuasionOnConfirm(() => () => {
        dispatch(changeFormValue(form, field, {
          value: options[1].value,
          priceRaw
        }));
        setPersuasionIsOpen(false);
      });
      setPersuasionOnDecline(() => () => {
        dispatch(changeFormValue(form, field, {
          value: options[0].value,
          priceRaw
        }));
        setPersuasionIsOpen(false);
      });
    }
    if (isConfirmationEnabled && isSelected(value)) {
      e.preventDefault();
      setConfirmationIsOpen(true);
      setConfirmationOnConfirm(() => () => {
        dispatch(changeFormValue(form, field, {
          value: options[1].value,
          priceRaw
        }));
        setConfirmationIsOpen(false);
      });
      setConfirmationOnDecline(() => () => {
        dispatch(changeFormValue(form, field, {
          value: options[0].value,
          priceRaw
        }));
        setConfirmationIsOpen(false);
      });
    }
  };
};
const mapDispatchToProps = (dispatch, props) => ({
  onChange: onChange(dispatch, props)
});
export default connect(null, mapDispatchToProps)(AncillaryToggleBarField);