import { Inline, breakpoints, Stack, Input } from '@etg/wings';
import { ConnectedSelectBox } from '@eti/form';
import { useTranslation } from '@eti/providers';
import { css } from '@eti/styles';
import { FormSection } from 'redux-form';
import { tripTypes } from '../../../constants/tripTypesConstants';
import {
  DEPARTURE_TIME_OF_DAY,
  RETURN_TIME_OF_DAY,
  SINGLE_BOUND,
} from '../constants/formFieldNames';
import { ANY, MORNING, NOON, AFTERNOON } from '../constants/timeOfDay';

const { MULTI_STOP, ONE_WAY } = tripTypes;

const wrapperStyles = css`
  padding-top: 16px;
`;

const selectBoxStyles = css`
  flex: 1;
  min-width: 0;
`;

const inputGroupStyles = css`
  flex: 1;
  position: relative;
  width: 100%;

  @media (min-width: ${breakpoints._768}) {
    width: calc(50% - 24px);
  }
`;

const options = [
  { textKey: 'Air.Search.TimeOfDayAllDay', value: ANY },
  { textKey: 'Air.Search.TimeOfDayMorning', value: MORNING },
  { textKey: 'Air.Search.TimeOfDayMidday', value: NOON },
  { textKey: 'Air.Search.TimeOfDayEvening', value: AFTERNOON },
];

type SelectOption = {
  label: string;
  value: string;
};

const normalizeTimeOfDayValue = (selectedOption: SelectOption) => selectedOption?.value;

interface ExtendedSearchFormFieldsProps {
  selectedTripType?: keyof typeof tripTypes;
}

const ExtendedSearchFormFields = ({ selectedTripType }: ExtendedSearchFormFieldsProps) => {
  const { t } = useTranslation();
  const selectBoxOptions = options.map((option) => ({
    ...option,
    label: t(option.textKey),
  }));

  return (
    <Stack>
      <Inline
        className={wrapperStyles}
        collapseBelow="768px"
        spacing={{
          [breakpoints._0]: 24,
          [breakpoints._768]: 48,
        }}
      >
        <FormSection name={SINGLE_BOUND}>
          <Input.Group className={inputGroupStyles}>
            <ConnectedSelectBox
              className={selectBoxStyles}
              data-testid="searchForm-departureTimeOfDay-selectBox"
              label={t('Searchform.DepartureTime.Label')}
              name={DEPARTURE_TIME_OF_DAY}
              normalize={normalizeTimeOfDayValue}
              options={selectBoxOptions}
            />
            {selectedTripType !== MULTI_STOP && selectedTripType !== ONE_WAY && (
              <ConnectedSelectBox
                className={selectBoxStyles}
                data-testid="searchForm-returnTimeOfDay-selectBox"
                label={t('Searchform.ReturnTime.Label')}
                name={RETURN_TIME_OF_DAY}
                normalize={normalizeTimeOfDayValue}
                options={selectBoxOptions}
              />
            )}
          </Input.Group>
        </FormSection>
      </Inline>
    </Stack>
  );
};

export default ExtendedSearchFormFields;
