const getTopPositionForElement = (selectorOrElement) => {
  const element =
    typeof selectorOrElement === 'string'
      ? document.querySelector(selectorOrElement)
      : selectorOrElement;

  return element.getBoundingClientRect().top;
};

export const scrollToElement = (
  selectorOrElement,
  marginTop = 20,
  behavior = 'smooth',
  isHeaderVisible = true,
) => {
  const elementTopPosition = getTopPositionForElement(selectorOrElement);
  const mainContentTopPosition = isHeaderVisible ? getTopPositionForElement('.etiMainContent') : 0;
  const scrollTopPosition = elementTopPosition - mainContentTopPosition - marginTop;
  const isScrollBehaviorSupported = 'scrollBehavior' in document.documentElement.style;

  if (isScrollBehaviorSupported) {
    window.scrollTo({
      top: scrollTopPosition,
      behavior,
    });
  } else {
    window.scrollTo(0, scrollTopPosition);
  }
};

export default scrollToElement;
