import { css } from '@eti/styles';

export const inputStyles = css`
  && input {
    caret-color: transparent;
    cursor: default;
  }
`;

export const elevateFocusedInputStyles = css`
  &&:focus-within {
    z-index: 10;
  }
`;
