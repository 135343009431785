import { getPrice, getSelected, getGraphQlSelection } from '../single-choice/utils/selection';
import { AIR_HELP } from '../products';
import AirHelp from './components/AirHelp';
import getIcon from './utils/icon';
const formSection = AIR_HELP.name;
export const airHelp = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'traveler'),
  ...AIR_HELP
};
export default AirHelp;