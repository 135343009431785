import PropTypes from 'prop-types';
import { DangerousHtmlContent, Notification } from '@eti/components';

const LoginMessage = ({ className, notification }) => {
  return (
    <div id="loginMessage">
      <Notification
        className={className}
        dataTestId={notification.dataTestId}
        variant={notification.type}
      >
        <DangerousHtmlContent content={notification.message} />
      </Notification>
    </div>
  );
};

LoginMessage.propTypes = {
  className: PropTypes.string,
  notification: PropTypes.shape({
    dataTestId: PropTypes.string,
    message: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default LoginMessage;
