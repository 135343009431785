import { formValueSelector } from 'redux-form';
import { NO_OPTION } from './mealUtil';
export const getMealState = (form, state) => formValueSelector(form)(state, 'meal');
export const isNoOption = value => value === NO_OPTION;
const getAllMealUnits = (form, state) => Object.values(getMealState(form, state) || {});
export const getNumberOfUnits = form => state => getAllMealUnits(form, state).reduce((totalUnits, _ref) => {
  let {
    value
  } = _ref;
  return isNoOption(value) ? totalUnits : totalUnits + 1;
}, 0);
export const getSelected = form => state => getAllMealUnits(form, state).filter(unit => !isNoOption(unit.value));