import { formValueSelector } from 'redux-form';
import { tripTypes } from '../../../constants/tripTypesConstants';
import {
  CABIN_CLASS,
  DIRECT_FLIGHT,
  PASSENGERS,
  SINGLE_BOUND,
  MULTI_BOUND,
  TRIP_TYPE,
} from '../constants/formFieldNames';

const { OPEN_JAW_DOUBLE, OPEN_JAW_SINGLE_ORIGIN, OPEN_JAW_SINGLE_DESTINATION, MULTI_STOP } =
  tripTypes;

export const getSelected = (form, field) => (state) => formValueSelector(form)(state, `${field}`);

export const getCabinClass = (form) => (state) => getSelected(form, CABIN_CLASS)(state);

export const getDirectFlight = (form) => (state) => getSelected(form, DIRECT_FLIGHT)(state);

export const getMultiBound = (form) => (state) => getSelected(form, MULTI_BOUND)(state);

export const getPassengers = (form) => (state) => getSelected(form, PASSENGERS)(state);

export const getSingleBound = (form) => (state) => getSelected(form, SINGLE_BOUND)(state);

export const isMultiStopType = (type) =>
  [OPEN_JAW_SINGLE_ORIGIN, OPEN_JAW_SINGLE_DESTINATION, OPEN_JAW_DOUBLE].includes(type);

export const getTripType = (form) => (state) => {
  const typeValue = getSelected(form, TRIP_TYPE)(state);
  return isMultiStopType(typeValue) ? MULTI_STOP : typeValue;
};
